import store from "../store";
import {loadMorePageSize} from "../../helpers/constants";
import {
    BUNDLES_HAS_MORE_FALSE,
    BUNDLES_HAS_MORE_SERVICES_FALSE,
    BUNDLES_MORE,
    BUNDLES_MORE_SERVICES,
    BUNDLES_SELECT_BUNDLE,
    BUNDLES_SET_UNLOCKED, BUNDLES_UPDATE_ITEM_SERVICES,
    LIVE_SET_UNLOCKED,
    LIVE_SET_UNLOCKED_LIST_ITEMS,
    LIVECHANNELS_SINGLEBUNDLE_UNLOCK,
    PLAYLIST_SET_RESPONSE,
    SET_BUNDLE_UNLOCK,
    SET_FETCHING,
} from "./types";
import {checkIfPlayerOpened, handleHttpError, jsonToFormData} from "../../helpers/functions";
import axios from "axios";
import {getServicesOfBundle as getServicesOfBundleAPI, host, loadMoreBundles as loadMoreAPI} from "../api/endPoints";
import {contentType, localStorageKeys, serviceType} from "../../helpers/enums";
import {getLink, getSeriesPlaylist, previewLive, selectPlaylistItem} from "./playlistActions";
import {getContentInfo} from "./servicesActions";
import {showPurchaseModal} from "./settingsActions";
import {useSelector} from "react-redux";

const dispatch = store.dispatch

export const loadMoreBundles = index => {

    const loadMoreInterval = localStorage.getItem(localStorageKeys.itemsinterval)
    const auth = store.getState().auth.authToken
    const page = index * loadMoreInterval

    const req = {
        auth,
        index: page,
    }

    const params = jsonToFormData(req)
    axios.post(host + loadMoreAPI, params)
        .then(res => {
            if (res.status === 200) {
                const bundles = res.data.bundles
                dispatch({
                    type: BUNDLES_MORE,
                    payload: {bundles, index}
                })

            } else if (res.status === 204) {
                dispatch({
                    type: BUNDLES_HAS_MORE_FALSE,
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const getServicesOfBundle = (bundleId, index = 0) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        bundle_id: bundleId,
        index,
    }
    const params = jsonToFormData(req)
    return axios.post(host + getServicesOfBundleAPI, params)
}

export const getBundleInfo = bundleId => {

    // const bundles = store.getState().bundles.items
    // const bundle = !!bundles && bundles.length > 0 ? bundles.filter(item => item.bundle_id === bundleId)[0] : undefined
    // if (!!bundle) {
    getServicesOfBundle(bundleId)
        .then(res => {
            if (res.status === 200) {
                const {info, services} = res.data
                const newBundle = {...info, services, hasMore: services.length >= loadMorePageSize}
                dispatch({
                    type: BUNDLES_SELECT_BUNDLE,
                    payload: newBundle
                })
            }
            else if (res.status === 204) {
                dispatch({
                    type: BUNDLES_HAS_MORE_SERVICES_FALSE,
                })
                dispatch({
                    type: BUNDLES_SELECT_BUNDLE,
                    payload: {services:[],hasMore:false}
                })
            }

        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const loadMoreBundleServices = (bundleId, index) => {
    const pageSizeInterval = localStorage.getItem(localStorageKeys.itemsinterval)
    const page = index * pageSizeInterval
    getServicesOfBundle(bundleId, page)
        .then(res => {
            if (res.status === 200) {
                const {services} = res.data
                dispatch({
                    type: BUNDLES_MORE_SERVICES,
                    payload: services
                })
            }
            if (res.status === 204) {
                dispatch({
                    type: BUNDLES_HAS_MORE_SERVICES_FALSE,
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })

}

export const isMobileMoneyGetContentInfoBundle = (id,item) => {
    const liveChannels = store.getState().live.items

    dispatch({
        type:SET_FETCHING,
        payload:true
    })
    getContentInfo(contentType.bundle, id).then(res => {
        if (res.status === 200) {
            dispatch({
                type:SET_FETCHING,
                payload:false
            })

            if(res.data.msg.status === 'unlocked'){
                const list = res.data.msg.playlist
                dispatch({
                    type: PLAYLIST_SET_RESPONSE,
                    payload: list
                })


                let firstItem = item.services[0]
                let playItem = list.find(item=>item.service_id === firstItem.service_id)
                if (firstItem.service_type === serviceType.live) {
                    const url = playItem.is_radio == "1" ? playItem.radio_url : playItem.url
                    selectPlaylistItem(playItem, url.indexOf('redirect') > -1 ? true : false)
                } else if (firstItem.service_type === serviceType.vod && firstItem.is_videos_parent === '1') {
                    getSeriesPlaylist(firstItem.service_id,false)
                }

                //set bundle Unlock in services plus single bundle
                if (!!store.getState().bundles.items && store.getState().bundles.items.length > 0){
                    store.getState().bundles.items.forEach((itemm,index) => {
                        if (itemm.bundle_id === item.bundle_id){
                            item.status = 'unlocked'
                            dispatch({
                                type:SET_BUNDLE_UNLOCK,
                                payload:index
                            })
                        }
                    })
                    dispatch({
                        type:BUNDLES_SET_UNLOCKED,
                        payload:item
                    })
                }

                if(item.services.length > 0){
                    item.services.map((item) => {
                        item.status = 'unlocked'
                    })
                    dispatch({
                        type:BUNDLES_UPDATE_ITEM_SERVICES,
                        payload:item.services
                    })
                }

                //unlock home page liveChannels of the bundle
                if (!!item.services && item.services.length > 0){
                    item.services.forEach((bitem) => {
                        if(!!liveChannels && liveChannels.length > 0){
                            liveChannels.forEach((citem) => {
                                if (bitem.service_id === citem.service_id){
                                    citem.status = 'unlocked'
                                }
                            })
                        }
                    })
                }

            }else{
                checkIfPlayerOpened()
                showPurchaseModal()
            }
        }
    }).catch(error => {
        dispatch({
            type:SET_FETCHING,
            payload:false
        })
        handleHttpError(error)
    })
}
