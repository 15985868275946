import {
    ALL_SERVICES_SET_RESPONSE,
    MORE_MOVIES,
    HAS_MORE_FALSE_MOVIES,
    HAS_MORE_FALSE_MOVIE_VIDEOS,
    MORE_MOVIE_VIDEOS,
    SELECT_MOVIE,
    SELECT_MOVIE_VIDEO,
    FAVORITES_ADD_MOVIE_VIDEO,
    FAVORITES_REMOVE_MOVIE_VIDEO,
    SERVICES_MOVIE_SET_UNLOCKED,
    SERVICES_VIDEOSLIST_UNLOCKED_SUBSCRIBTION,
    SERVICES_MOVIE_SETUNLOCK, SERVICES_SUBSCRIBTION_SINGLEMOVIE_SETUNLOCK, MOVIES_SET_FETCHING, SET_VIDEOS
} from "../actions/types";


const initialState = {
    items: [],
    item: {},
    videos:[],
    video: {list: []},
    hasMore: true,
    index: 0,
    fetching:false,
    fetchingMovies:false,
}
const movieReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_SERVICES_SET_RESPONSE:
            return {
                ...state,
                items: action.payload.movies,
                fetchingMovies: true,
                //hasMore: action.payload.live.length >= loadMorePageSize
            }
        case SET_VIDEOS:
            return {
                ...state,
                videos:  [...state.videos, ...action.payload],
                //hasMore: action.payload.live.length >= loadMorePageSize
            }
        case MORE_MOVIES:
            return {
                ...state,
                items: [...state.items, ...action.payload.items],
                hasMore: true,
                index: action.payload.index,
                fetching: false,
            }
        case HAS_MORE_FALSE_MOVIES:
            return {
                ...state,
                hasMore: false,
                fetching: false,
            }
        case HAS_MORE_FALSE_MOVIE_VIDEOS:
            return {
                ...state,
                    item: {
                        ...state.item,
                        hasMoreVideos: false,
                    }
            }
        case MORE_MOVIE_VIDEOS:
            return {
                ...state,
                    item: {
                        ...state.item,
                        videos: [...state.item.videos, ...action.payload.items],
                        hasMoreVideos: true
                    }
            }
        case SELECT_MOVIE:
            return {
                ...state,
                item: action.payload,
            }
        case SELECT_MOVIE_VIDEO:
            return {
                ...state,
                video: action.payload.video,
                item: {...state.item, ...action.payload.service},
            }
        case FAVORITES_ADD_MOVIE_VIDEO:
            return {
                ...state,
                    video: {
                        ...state.video,
                        video: {
                            ...state.video.video,
                            is_fav: 1,
                            fav_id: action.payload.favId
                        }
                    }
            }
        case FAVORITES_REMOVE_MOVIE_VIDEO:
            return {
                ...state,
                    video: {
                        ...state.video,
                        video: {
                            ...state.video.video,
                            is_fav: 0,
                        }
                }
            }
        case SERVICES_MOVIE_SET_UNLOCKED:
            return {
                ...state,
                    item: {
                        ...state.item,
                        status: 'unlocked'
                    }
            }
        case SERVICES_VIDEOSLIST_UNLOCKED_SUBSCRIBTION:
            return {
                ...state,
                    item:{
                        ...state.item,
                        videos: [...state.item.videos, ...action.payload]
                    }
            }
        case SERVICES_MOVIE_SETUNLOCK:
            return {
                ...state,
                    items:[
                        ...state.items.slice(0,action.payload),
                        {...state.items[action.payload], status:'unlocked'},
                        ...state.items.slice(action.payload + 1),
                    ]
            }
        case SERVICES_SUBSCRIBTION_SINGLEMOVIE_SETUNLOCK:
            return {
                ...state,
                    video: {
                        ...state.video,
                        video: {
                            ...state.video.video,
                            status: 'unlocked'
                        }
                    }
            }
        case MOVIES_SET_FETCHING:
            return {
                ...state,
                fetching: true,
            }
        default:
            return state
    }
}
export default movieReducer
