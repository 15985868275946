import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux";
import ReactHLS from 'react-hls';
import Hls from 'hls.js';
import {
    bookmarkVideo, clearPrediction, getPredictions,
    hidePlayer,
    minimizePlayer, setRetryPlayer,
    toggleFullScreen,
    togglePlayer, unsetRetryPlayer,
} from "../../redux/actions/playerActions";
import ReactPlayer from "react-player";
import {globalHistory} from "@reach/router";
import {FiRadio} from "react-icons/fi"
import {BsEyeFill} from "react-icons/bs";
import {BiShareAlt} from "react-icons/bi";
import {AiFillMessage} from "react-icons/ai";
import {MdReport} from "react-icons/md";
import {
    FaBackward,
    FaChevronDown,
    FaChevronUp,
    FaCircle,
    FaCog,
    FaCogs,
    FaCompress,
    FaExpand,
    FaForward,
    FaList,
    FaListUl,
    FaPause,
    FaPlay,
    FaRedo,
    FaStepBackward,
    FaStepForward,
    FaTimes,
    FaVolumeMute,
    FaVolumeUp,
} from "react-icons/fa";
import {bitRates, contentType, localStorageKeys, playerTypes, streamingLimitType} from "../../helpers/enums";
import {
    addDefaultSrc,
    clearLimitTime,
    jsonToFormData,
    localize,
    playlistFetching,
    setDstLink, setHintDialog, timeForRetry,
    toggleScreen, toHHMMSS
} from "../../helpers/functions";
import Chat from "../Chat";
import CollapseGroup from "./CollapseGroup";
import PlayerSlider from "./PlayerSlider";
import OutsideClickHandler from "react-outside-click-handler";
import PlayerPlaylist from "./PlayerPlaylist";
import {hideModal, showPurchaseModal} from "../../redux/actions/settingsActions";
import {useInterval} from "../../helpers/custom-hooks";
import {
    playlistNext,
    playlistPrevious,
    purchaseBundle,
    purchaseService,
    selectPlaylistItem, toggleChat,
    unsetFetching
} from "../../redux/actions/playlistActions";
import {isloadingImages, operationChatEnabled, subtitleLanguages, useragent} from "../../helpers/constants";
import {useTimeoutFn} from "react-use";
import store from "../../redux/store";
import {loadingImages} from "../../assets/img/exports";
import SubscriptionModal from "../SubscriptionModal";
import {bootstrap, getViewersNumber, host, saveVideoBookmark} from "../../redux/api/endPoints";
import ConfirmPurchaseModal from "../SubscriptionModal/ConfirmPurchaseModal";
import axios from "axios";
import {checkFollowStatus, checkViewersNumber, getSessionReportItems, setFollowUnfollowStatus, shareLiveStream} from "../../redux/actions/liveStreamActions";
import LiveReportModel from "../LiveStreamModels";
import ReportReasonInsert from "../LiveStreamModels/ReportReason";
import ViewersList from "../LiveStreamModels/ViewersList";
import {Modal} from "antd";

const Player = props => {
    const [playerHover, setPlayerHover] = useState(true)
    const [change, setChange] = useState(false)
    const [skipTimer, setSkipTimer] = useState(0)
    const [previewDurationCounter, setPreviewDurationCounter] = useState(0)
    const [previewDurationCounterRunning, setPreviewDurationCounterRunning] = useState(false)
    const [playing, setPlaying] = useState(true)
    const [muted, setMuted] = useState(false)
    const [playbackSpeed, setPlaybackSpeed] = useState(1)
    const [playerPopupOptions, setPlayerPopupOptions] = useState([])
    const [popupVisible, setPopupVisible] = useState(false)
    const [playlistVisible, setPlaylistVisible] = useState(false)
    const {purchaseModal,displayPrediction, profileNickname, profileDescription, profileFollowersNumber, avatar, username} = store.getState().settings
    const [playerDuration, setPlayerDuration] = useState(localize('loading'))
    // const {username} = useSelector(state => state.settings)  // just to subscribe to setting changes
    const playlist = useSelector(state => state.playlist)
    const {minimized, visible, fullscreen, chatEnabled, previewDuration, currentLink, item, error, fetching, time, liveSessionEnd} = playlist
    const playerType = playlist.type
    const playerRef = useRef()
    const series = playlist.series
    const playlistCurrentItem = playlist.item
    const [isReadyHoverTimer, cancelHoverTimer, resetHoverTimer] = useTimeoutFn(() => timeoutHover(), 5000);
    const isIOS = useragent.device.model === 'iPhone' || useragent.device.model === 'iPad'
    const [confirmModal, setConfirmModal] = useState(true)
    const [key, setKey] = useState('0')
    const [playlistHover, setPlaylistHover] = useState(false)
    const strimingLimitArray =  !!store.getState().player.items ? store.getState().player.items : []
    const [volumeSlider,setVolumeSlider] = useState(false)
    const [volume,setVolume] = useState(100)
    const sereisMultiLayerID = playlistCurrentItem.service_id_fk
    const [loadPlayer,setPlayerLoad] = useState(false)
    const [playerEnd,setPlayerEnd] = useState(false)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const [isRadioPlayed,setRadioPlayed] = useState(item.is_radio === "1")
    const [chatView,setChatView] = useState(false)
    const {following,showReportModel,showReportModelInsert,reportOptions,viewersNumber,viewerListModelStatus,fetchLoading} = useSelector(state => state.liveStream)
    const [userModal, setUserModal] = useState(false)

    if (!document.createElement('video').canPlayType) {
        window.location.replace('home.html');
    }
    let defaultSubtitleLanguage = !!localStorage.getItem(localStorageKeys.captions) ? localStorage.getItem(localStorageKeys.captions) : subtitleLanguages.none
    let defaultBitRate = !!localStorage.getItem(localStorageKeys.bitRate) ? localStorage.getItem(localStorageKeys.bitRate) : bitRates.auto

    const list = playlist.items.sort(function(a,b){
        if(a.service_name.toLowerCase() < b.service_name.toLowerCase()) return -1;
        if(a.service_name.toLowerCase() > b.service_name.toLowerCase()) return 1;
        return 0;})


    useEffect( () => {
        if(key === 'ErrorPlayer'){
            //to destroy player from loading chunks
            if (playerRef && playerRef.current && playerRef.current.getInternalPlayer('hls')) playerRef.current.getInternalPlayer('hls').destroy()
        }
    },[purchaseModal,minimized,playing,fetching])

    useEffect(() => {
        window.addEventListener('unload', onCLoseWindow, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        let intervalID = ''
        if(playlist.item.isLiveStream) {
            checkFollowStatus(playlist.item.username)
            checkViewersNumber(playlist.item.username)
            if(!currentLink.includes("DVR")){
                intervalID = setInterval(function() {
                    checkViewersNumber(playlist.item.username)
                }, 60000)
            }
        }
        return () => {
            clearInterval(intervalID)
            window.removeEventListener('unload',onCLoseWindow,true)
            document.removeEventListener('fullscreenchange', exitHandler, true);
            clearLimitTime()
            localStorage.setItem(localStorageKeys.captions, !!store.getState().settings.captions ? store.getState().settings.captions : 'none')
            localStorage.setItem(localStorageKeys.bitRate, !!store.getState().settings.bitRate ? store.getState().settings.bitRate : 'auto')
            if (playerRef && playerRef.current && playerRef.current.getInternalPlayer('hls')) playerRef.current.getInternalPlayer('hls').destroy()
        }
    }, [])

    useEffect(() => {
        if(displayPrediction && !playlist.item.isLiveStream){
            clearPrediction()
            getPredictions(playlistCurrentItem.service_id)
        }
        if(minimized) {
            setRadioPlayed(item.is_radio === '1')
            // setKey(Math.random())
        }
    },[playlist.item])

    useEffect(() => {
        if (visible && !minimized) {
            document.querySelector('body').style.overflow = 'hidden'
            document.querySelector('#root').style.paddingBottom = ''
        } else if (visible && minimized) {
            document.querySelector('body').style.overflow = ''
            document.querySelector('#root').style.paddingBottom = '110px'
        } else {
            document.querySelector('body').style.overflow = ''
            document.querySelector('#root').style.paddingBottom = ''
        }

        if (previewDuration > 0) {
            setPreviewDurationCounter(Math.floor(previewDuration / 1000))
            // if (minimized) maximizePlayer()
        }

        return globalHistory.listen(({action}) => {
            //pop go back
            //push change route
            if (/*action === 'PUSH' ||*/ action === 'POP') {
                if (visible && !minimized) {
                    minimizePlayer()
                }
            }
        })

    }, [visible, minimized, previewDuration])

    useEffect(() => {
        return ()=>{
            document.querySelector('html').style.overflow = ''
            document.querySelector('body').style.overflow = ''
            document.querySelector('#root').style.paddingBottom = ''

        }
    }, [visible, minimized])

    function onCLoseWindow() {
        if (playerType !== playerTypes.live) {
            const duration = playerRef.current.getDuration()
            const playedSeconds = parseInt(playerRef.current.getCurrentTime())
            const playedRatio = (playedSeconds / duration) * 100

            let data = new FormData()
            data.append("auth",localStorage.getItem(localStorageKeys.authToken))
            data.append("service_id",store.getState().playlist.item.service_id)
            data.append("video_id",store.getState().playlist.item.video_id)
            // data.append("bookmark",playedSeconds)
            // navigator.sendBeacon(host + saveVideoBookmark, data);

            if (item.is_ppv && item.is_ppv === '1' && playedRatio >= 5 ) {
                data.append("bookmark",playedSeconds)
                navigator.sendBeacon(host + saveVideoBookmark, data);
            } else if (playedRatio >= 99.9) {
                data.append("bookmark",'-1')
                navigator.sendBeacon(host + saveVideoBookmark, data);
            } else if (item.is_ppv !== '1') {
                data.append("bookmark",playedSeconds)
                navigator.sendBeacon(host + saveVideoBookmark, data);
            }
        }
    }

    const exitHandler = (e) => {
        if (document.fullscreenElement) {
            // fullscreen is activated
        } else {
            //For Escape Press
            // fullscreen is cancelled
            if(store.getState().playlist.fullscreen){
                toggleScreen()
            }
        }
    }

    document.onkeydown = function(e) {
        if(playlist.type === contentType.videoRes || playlist.type === playerTypes.series){
            if(e.key === 'ArrowLeft') {
                backward()
            }else if(e.key === 'ArrowRight') {
                forward()
            }
        }
        if(e.code === 'Space'){
            if (!previewDurationCounterRunning && previewDuration <= 0 && change === false) {
                setPlaying(!playing)
            }
        }
    }

    useInterval(() => {
        if (previewDurationCounter <= 0) setPreviewDurationCounterRunning(false)
        else setPreviewDurationCounter(previewDurationCounter => previewDurationCounter - 1)
    }, previewDurationCounterRunning ? 1000 : null)

    const timeoutHover = () => {
        if (playlistVisible && !playlistHover) {
            setPlaylistVisible(false)
        }
        if(!fetching){
            setPlayerHover(false)
        }
    }
    const setPlayerOptions = (e, newSpeed = undefined) => {
        setPopupVisible(false)
        if (isIOS) return

        const hlsInstance = e.getInternalPlayer('hls')
        // if(key === 'resetPlayer'){
        //     hlsInstance.destroy()
        //     setKey('resumePlayer')
        // }
        // hlsInstance.on(window.Hls.Events.SUBTITLE_TRACK_SWITCH,(data) => {
        //     console.log("switch")
        // })
        // hlsInstance.on(window.Hls.Events.SUBTITLE_TRACK_Loading,(data) => {
        //     console.log(data)
        //     console.log("loading")
        // })
        // hlsInstance.on(window.Hls.Events.SUBTITLE_TRACK_Loaded,(data) => {
        //     console.log("laoded")
        //     console.log(data)
        // })
        // hlsInstance.on(window.Hls.Events.LEVEL_SWITCHED,(data) => {
        //     hlsInstance.destroy()
        //     console.log("level switch")
        //     setKey('2')
        //     //console.log(data)
        // })
        // hlsInstance.on(window.Hls.Events.FRAG_LOADED,(data) => {
        //     console.log("frag laoded")
        //     console.log(data)
        // })

        // hlsInstance.on(window.Hls.Events.SUBTITLE_TRACK_SWITCH, (data) => {
        // hlsInstance.on(window.Hls.Events.SUBTITLE_TRACK_Loading, (data) => {
        // hlsInstance.on(window.Hls.Events.SUBTITLE_TRACK_Loaded, (data) => {
        // hlsInstance.on(window.Hls.Events.LEVEL_SWITCHED, (data) => {
        // hlsInstance.on(window.Hls.Events.FRAG_LOADED, data => {
        let {levels, autoLevelEnabled, subtitleTracks, subtitleTrack, nextLevel} = hlsInstance

        //console.log({levels, loadLevel, autoLevelEnabled, subtitleTracks, subtitleTrack,nextLevel}


        if (defaultSubtitleLanguage !== subtitleLanguages.none && subtitleTracks.length > 0){
            const defaultSubtitleIndex = subtitleTracks.findIndex(item => item.lang === defaultSubtitleLanguage)
            if (defaultSubtitleIndex !== -1) {
                hlsInstance.subtitleTrack = defaultSubtitleIndex
                subtitleTrack = defaultSubtitleIndex
            }
        }


        if (!!levels && levels.length > 0){
            let defaultBitRateIndex = -1
            if(defaultBitRate === bitRates.low){
                defaultBitRateIndex = 0
            }else if (defaultBitRate === bitRates.high){
                defaultBitRateIndex = levels.length - 1
            } else if (defaultBitRate === bitRates.none){
                defaultBitRateIndex = levels.length - 2
            }
            if (defaultBitRateIndex !== -1) {
                hlsInstance.nextLevel = defaultBitRateIndex
                nextLevel = defaultBitRateIndex
            }
        }


        let options = [
            {
                title: 'Playback Speed',
                sub: [
                    {
                        title: '0.5',
                        checked: !!newSpeed ? newSpeed === 0.5 : playbackSpeed === 0.5,
                        callback: () => {
                            setPlaybackSpeed(0.5)
                            setPlayerOptions(e, 0.5)
                        }
                    },
                    {
                        title: 'Normal',
                        checked: !!newSpeed ? newSpeed === 1 : playbackSpeed === 1,
                        callback: () => {
                            setPlaybackSpeed(1)
                            setPlayerOptions(e, 1)
                        }
                    },
                    {
                        title: '1.5',
                        checked: !!newSpeed ? newSpeed === 1.5 : playbackSpeed === 1.5,
                        callback: () => {
                            setPlaybackSpeed(1.5)
                            setPlayerOptions(e, 1.5)
                        }
                    },
                    {
                        title: '2',
                        checked: !!newSpeed ? newSpeed === 2 : playbackSpeed === 2,
                        callback: () => {
                            setPlaybackSpeed(2)
                            setPlayerOptions(e, 2)
                        }
                    },
                ]
            },
        ]

        if (levels.length > 0) {
            let sub = levels.map((item, index) => {
                if (item.attrs.RESOLUTION === undefined) return null
                return {
                    title: `${item.attrs.RESOLUTION}, ${(parseInt(item.attrs.BANDWIDTH) / 1024 / 1024).toFixed(2)} Mbps`,
                    checked: nextLevel === index,
                    callback: () => {
                        // hlsInstance.currentLevel = index
                        hlsInstance.nextLevel = index
                        if(item.level === 0)
                        {
                            localStorage.setItem(localStorageKeys.bitRate, bitRates.low)
                            defaultBitRate = bitRates.low
                        }else if (item.level === levels.length - 1){
                            localStorage.setItem(localStorageKeys.bitRate, bitRates.high)
                            defaultBitRate = bitRates.high
                        }else{
                            localStorage.setItem(localStorageKeys.bitRate, bitRates.none)
                            defaultBitRate = bitRates.none
                        }
                        setPlayerOptions(e)

                    }
                }
            })
            sub.unshift({
                title: 'Auto',
                checked: autoLevelEnabled && nextLevel === -1,
                callback: () => {
                    // hlsInstance.currentLevel = index
                    hlsInstance.nextLevel = -1 // -1 to set it to auto
                    localStorage.setItem(localStorageKeys.bitRate, bitRates.auto)
                    defaultBitRate = bitRates.auto
                    setPlayerOptions(e)
                }
            })
            options.push({title: 'Resolution', sub})
        }
        if (subtitleTracks.length > 0) {
            let sub = subtitleTracks.map((item, index) => {
                return {
                    title: item.name,
                    checked: subtitleTrack === index,
                    callback: () => {
                        hlsInstance.subtitleTrack = index
                        defaultSubtitleLanguage = localStorage.setItem(localStorageKeys.captions, subtitleTracks[index].lang)
                        setPlayerOptions(e)
                        //changeCaption(subtitleTracks[index].lang)
                    }
                }
            })
            sub.unshift({
                title: 'None',
                checked: subtitleTrack === -1,
                callback: () => {
                    hlsInstance.subtitleTrack = -1
                    defaultSubtitleLanguage = localStorage.setItem(localStorageKeys.captions, subtitleLanguages.none)
                    setPlayerOptions(e)
                }
            })
            options.push({title: 'Subtitles', sub})
        }
        setPlayerPopupOptions(options)
    }

    const skipPreview = () => {
        hidePlayer()
        showPurchaseModal()
        clearTimeout(skipTimer)
    }

    const onReady = e => {
        if(!!time && time !== ''){
            playerRef.current.seekTo(time, 'seconds')
            timeForRetry('')
        }
        else if (Math.floor(e.getCurrentTime()) === 0 && item.bookmark) {
            playerRef.current.seekTo(item.bookmark, 'seconds')
        }
        if (previewDuration > 0 && !previewDurationCounterRunning) {
            setPreviewDurationCounterRunning(true)
            const skipTimeout = setTimeout(() => {
                if (visible) {
                    hidePlayer()
                    showPurchaseModal()
                }
            }, previewDuration)
            setSkipTimer(skipTimeout)
        }
        clearLimitTime(store.getState().player.items.length - 1)//clear stream limit item after purchase
        //resetHoverTimer()
        setPlayerHover(false)
        setPlayerOptions(e)
        unsetFetching()
        setPlayerLoad(true)
    }

    const onError = (event, data, player, error) => {
        if(liveSessionEnd && playlist.item.isLiveStream){
            setPlaying(false)
            setHintDialog('eventEndDesc',localize('eventEndTitle'))
        }
        // unsetFetching()
        if (!!data && data.type == 'mediaError' && !data.fatal){
            setPlayerHover(true)
        }
        if (data && data.fatal) {
            unsetFetching()
            switch (data.type) {
                case window.Hls.ErrorTypes.NETWORK_ERROR || window.Hls.ErrorTypes.MEDIA_ERROR:
                    // try to recover network error
                    setPlayerHover(true)
                    if((playerType === (playerTypes.series || playerTypes.video)) && loadPlayer) {
                        setPlaying(false)
                        setConfirmModal(true)
                        setRetryPlayer()
                        // player.destroy()
                    }
                    else if(!previewDurationCounterRunning && previewDuration <= 0) {
                        setKey('ErrorPlayer')
                        setPlaying(false)
                        //player.destroy()
                    }
                    break;
                default:
                    // cannot recover
                    //console.log("fatal media error encountered, cannot recover");
                    //setPlayerError()
                    //player.destroy();
                    break;
            }
        }else{
            // if((isSafari || isIOS) && (playerType === playerTypes.series || playerType === playerTypes.video) && playlistCurrentItem.is_encrypted === '1'){// solve issue of media error and load player if no bookmark
            //     const {current} = playerRef
            //     const currentTime = current.getCurrentTime()
            //     // console.log(isNaN(item.bookmark))
            //     // console.log(currentTime)
            //     if(isNaN(item.bookmark) && currentTime <= 1){
            //         current.seekTo('1', 'seconds')
            //     }
            //     if(currentTime === 0.1 /*|| currentTime === 1*/){
            //         setPlaying(false)
            //     }
            // }
        }
    }

    const togglePlaying = e => {
        if(key === 'ErrorPlayer'){
            setKey('1')
        }
        setPlaying(!playing)
    }

    const toggleMuted = e => {
        if(muted){
            setVolume(100)
        }else{
            setVolume(0)
        }
        setMuted(!muted)
    }

    const togglePlaylistVisible = e => {
        setPlaylistVisible(!playlistVisible)
    }

    const _toggleFullScreen = e => {
        toggleFullScreen()
    }

    const toggleRadioPlayed = () => {
        if(isRadioPlayed){
            setDstLink(item.url)
        }else{
            setDstLink(item.radio_url)
        }
        setRadioPlayed(!isRadioPlayed)
    }
    const actionClick = () => {
        setPopupVisible(!popupVisible)
    }

    const onPlayerEnd = e => {
        if ((playerType === playerTypes.series && series.length > 0 && !playlist.item.isLiveStream) || (playerType === playerTypes.live && list.length > 0 && !playlist.item.isLiveStream)) {
            next()
        }else if (playerType === playerTypes.video || playlist.item.isLiveStream) {
            closePlayer()
        }else if(!minimized) togglePlayer(e,true)
    }

    const next = e => {
        const {item, items} = store.getState().playlist
        let selectedItem = undefined
        let selectedIndex = undefined
        if (!item.video_id) { // live channel
            selectedIndex = items.findIndex(iItem => iItem.service_id === item.service_id)
            selectedItem = selectedIndex + 1 === items.length ? items[0] : items[selectedIndex + 1]
        }
        if (playerType !== playerTypes.live) {
            checkbookMark()
        }
        if(playing === false){
            setPlaying(true)
        }
        if(key === 'ErrorPlayer'){
            setKey('1')
        }
        if (playerType === playerTypes.live) {
            setDstLink('')
        }
        setRadioPlayed(selectedItem.is_radio === "1")
        if((playerType === playerTypes.series && series.length > 0) || (playerType === playerTypes.live && selectedItem.url.indexOf('redirect') === -1)){// fix subtitle issue
            if (playerRef && playerRef.current && playerRef.current.getInternalPlayer('hls')) playerRef.current.getInternalPlayer('hls').destroy()
            setKey(Math.random())
            //setKey('resetPlayer')
        }
        playlistNext()
    }

    const previous = e => {
        const {item, items} = store.getState().playlist
        let selectedItem = undefined
        let selectedIndex = undefined
        if (!item.video_id) { //get live channel
            selectedIndex = items.findIndex(iItem => iItem.service_id === item.service_id)
            selectedItem = selectedIndex === 0 ? items[items.length - 1] : items[selectedIndex - 1]
        }

        if (playerType !== playerTypes.live) {
            checkbookMark()
        }
        if(playing === false){
            setPlaying(true)
        }
        if(key === 'ErrorPlayer'){
            setKey('1')
        }
        if (playerType === playerTypes.live) {
            setDstLink('')
        }
        setRadioPlayed(selectedItem.is_radio === "1")
        if((playerType === playerTypes.series && series.length > 0) || (playerType === playerTypes.live && selectedItem.url.indexOf('redirect') === -1)){// fix subtitle issue
            if (playerRef && playerRef.current && playerRef.current.getInternalPlayer('hls')) playerRef.current.getInternalPlayer('hls').destroy()
            setKey(Math.random())
            //setKey('resetPlayer')
        }
        playlistPrevious()
    }

    const forward = e => {
        //e.stopPropagation()
        const {current} = playerRef
        const currentTime = current.getCurrentTime()
        const seekAmount = currentTime + 10
        current.seekTo(seekAmount, 'seconds')
    }

    const backward = e => {
        //e.stopPropagation()
        const {current} = playerRef
        const currentTime = current.getCurrentTime()
        const seekAmount = currentTime - 10
        current.seekTo(seekAmount, 'seconds')
    }

    const onProgress = e => {
        const duration = playerRef.current.getDuration()
        //const durationText = hhmmss(duration)
        //let fullDuration = new Date(null)
        //fullDuration.setSeconds(duration)
        const playedSeconds = parseInt(e.playedSeconds)
        //const playedSecondsText = hhmmss(playedSeconds)
        //let playedDuration = new Date(null)
        //playedDuration.setSeconds(playedSeconds)

        if(currentLink.includes("DVR")){
            // setPlayerDuration('')
            setPlayerDuration(`${toHHMMSS(playerRef.current.getCurrentTime())} / ${toHHMMSS(duration)}`)
        }else{
            setPlayerDuration(`${toHHMMSS(playedSeconds)} / ${toHHMMSS(duration)}`)
        }
    }


    const renderChat = () => {
        if (!operationChatEnabled || minimized || !chatEnabled || !playlistCurrentItem || isRadioPlayed) return null
        return <Chat serviceId={playlist.item.isLiveStream ? playlist.item.username : playlistCurrentItem.service_id} username={username} preview={previewDuration} onChange={(e) => setChange(e)}/>
    }

    const selectFromPlaylistItem = (playlistItem) => {
        if (playerType !== playerTypes.live) {
            checkbookMark()
        }
        if(key === 'ErrorPlayer'){
            setKey('1')
        }
        clearLimitTime()
        if(!!playlistItem.video_id || (playerType === playerTypes.live && playlistItem.url.indexOf('redirect') === -1)){
            if (playerRef && playerRef.current && playerRef.current.getInternalPlayer('hls')) playerRef.current.getInternalPlayer('hls').destroy()
            setKey(Math.random())
        }
        if(playing === false){
            setPlaying(true)
        }
        if(playerType === playerTypes.live){
            setDstLink('')
        }
        setRadioPlayed(playlistItem.is_radio === "1")
        const url = playlistItem.is_radio == "1" ? playlistItem.radio_url : playlistItem.url
        selectPlaylistItem(playlistItem,url.indexOf('redirect') > -1 ? true : false)
    }
    const renderPlayerPlaylist = () => {
        if(purchaseModal.visible === true && playing === true){
            if (playerRef && playerRef.current && playerRef.current.getInternalPlayer('hls')){
                playerRef.current.getInternalPlayer('hls').destroy()
                setPlaying(false)
            }
        }
        // this function is created for the ios as it doesn't respond to outside click handler
        if (!playlistVisible) return null
        else if (playlistVisible && isIOS) {
            return (
                <div className="player-playlist">
                    <PlayerPlaylist
                        mouseCheck={(value) => setPlaylistHover(value)}
                        onClick={(e) => selectFromPlaylistItem(e)}
                    />
                </div>
            )
        } else if (playlistVisible && !isIOS) {
                return (
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            if (playlistVisible) setPlaylistVisible(false)
                        }}
                    >
                        <div className="player-playlist">
                            <PlayerPlaylist
                                mouseCheck={(value) => setPlaylistHover(value)}
                                onClick={(e) => selectFromPlaylistItem(e)}
                            />
                        </div>
                    </OutsideClickHandler>
                )
        }
    }

    const renderPlayerCenter = (playerType) => {
        if (key === 'ErrorPlayer') return <div className='player-action-button' onClick={retryPlayerAction}><FaRedo/></div>
        else if (error) return <p>{localize('errorLoadingVideo')}</p>
        else if (fetching && isloadingImages) return  <img width="8%" src={loadingImages} alt="loadingImages" />
        else if (fetching) return  <p>{localize('loading')}</p>
        else if (!isRadioPlayed) return (
                <>
                    {playlist.type === contentType.videoRes || playlist.type === playerTypes.series ? <div className='player-action-button' onClick={backward}><FaBackward/></div> : null}
                    <div className='player-action-button' onClick={togglePlaying}>{playing ? <FaPause/> : <FaPlay/>}</div>
                    {playlist.type === contentType.videoRes || playlist.type === playerTypes.series ? <div className='player-action-button' onClick={forward}><FaForward/></div> : null}
                </>
            )
    }

    const renderPlayerControls = () => {
        if (previewDuration) {
            return (
                <div className="player-center preview">
                    <div className="skip-button clickable-light" onClick={skipPreview}>
                        <p>{previewDurationCounter}</p>
                        <p>{localize('skip')}</p>
                    </div>
                </div>

            )
        } else if (isIOS) {
            return (
                <div className="player-center preview" style={isRadioPlayed ? {
                    backgroundImage:`url(${item.logo_big})`,
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition:'center',
                    height:'400px'} : null}>
                    {/*{playlist.item.isLiveStream ? <img className='live-stream-icon' src={require('../../assets/img/icons/live_icon.png')}/> : null}*/}
                    {isRadioPlayed && item.is_radio === '0' ? <p style={{position:'absolute',bottom:'-4rem',fontSize:'14px'}}>{localize('radioHint')}</p> : null}
                    <div className="skip-button ios clickable-light" onClick={closePlayer}>
                        <FaTimes/>
                    </div>
                    {!minimized && playlist.item.isLiveStream ? <div className='skip-button ios clickable-light' onClick={() => viewersNumber !== '0' ? checkViewersNumber(playlist.item.username,true) : null}>
                        <BsEyeFill/>
                        {!!viewersNumber ? <p style={{fontSize:'10px'}}>{viewersNumber}</p> : null}
                    </div>  : null}
                    {!minimized && chatView ? <div className='skip-button ios clickable-light' onClick={() => toggleChat()}>
                        <AiFillMessage/>
                    </div>: null}
                    {!minimized && playlist.item.isLiveStream ? <div className='skip-button ios clickable-light' onClick={() => getSessionReportItems()}>
                        <MdReport/>
                    </div>: null}
                    {!minimized && navigator.share && playlist.item.isLiveStream ? <div className='skip-button ios clickable-light' onClick={() => shareLiveStream(playlist.item.live_userName,playlist.item.username)}>
                        <BiShareAlt/>
                    </div>: null}
                    {item.is_radio === "0" ? <div className='skip-button ios clickable-ligh' onClick={toggleRadioPlayed}><img src={isRadioPlayed ? require('../../assets/img/icons/watchnow1.png') : require('../../assets/img/icons/radio.png')} alt='' style={{width:'20px',height:'20px',borderRadius:'unset'}} className="skip-button ios clickable-light"/></div> : null }
                    <div className="skip-button ios clickable-light" onClick={(e) => togglePlayer(e,true)}>
                        {minimized ? <FaChevronUp/> : <FaChevronDown/>}
                    </div>
                    {(!minimized && playerType === playerTypes.series && series.length > 0 && !playlist.item.isLiveStream) || (!minimized && playerType === playerTypes.live && list.length > 0 && !playlist.item.isLiveStream) ?
                    <div className="skip-button ios clickable-light" onClick={togglePlaylistVisible}>
                        <FaList/>
                    </div> : null}
                </div>
            )
        } else {
            return (
                <>
                    <div className={`player-top-bar ${playlist.item.isLiveStream ? 'is-livestream' : ''}`}>
                        <div className='player-action-button' onClick={closePlayer}><FaTimes/></div>
                        {playlist.item.isLiveStream ? <img src={require('../../assets/img/icons/live_icon.png')}/> : <p>{playerType === playerTypes.series ? playlist.item.video_name : playerType === playerTypes.live ? playlist.item.service_name : playerType === playerTypes.video ? playlist.item.video_title : null}</p>}
                        {!playlist.item.isLiveStream ? <div>
                            {item.is_radio === "0" ? <div className='player-action-button' onClick={toggleRadioPlayed}><img src={isRadioPlayed ? require('../../assets/img/icons/watchnow1.png') : require('../../assets/img/icons/radio.png')} alt='' style={{width:'20px',height:'unset'}} className="player-action-button"/></div> : null }
                            {(!minimized && playerType === playerTypes.series && series.length > 0) || (!minimized && playerType === playerTypes.live && list.length > 0) ?
                                playlistVisible ? <div className='player-action-button'><FaListUl/></div> : <div className='player-action-button' onClick={togglePlaylistVisible}><FaList/></div> : null}
                                {!minimized ? <div className='player-action-button' onClick={_toggleFullScreen}>{fullscreen ? <FaCompress/> : <FaExpand/>}</div> : null}
                            {fullscreen ? null : <div className='player-action-button' onClick={(e) => togglePlayer(e,true)}>{minimized ? <FaChevronUp/> : <FaChevronDown/>}</div>}
                        </div> : <div className='live-stream-barList'>
                            {!minimized ? <div className='live-user-info'>
                                <p /*onClick={() => checkUserProfile()}*/>{playlist.item.live_userName}</p>
                                {playlist.item.username !== username ? <img onClick={() => setFollowUnfollowStatus(playlist.item.username,following)} src={following === 0 ? require('../../assets/img/icons/follow.png') : require('../../assets/img/icons/unfollow.png')}/> : null}
                            </div> : null}
                            {!minimized ? <div className='player-action-button' onClick={_toggleFullScreen}>{fullscreen ? <FaCompress/> : <FaExpand/>}</div> : null}
                            {fullscreen ? null : <div className='player-action-button' onClick={(e) => togglePlayer(e,true)}>{minimized ? <FaChevronUp/> : <FaChevronDown/>}</div>}
                            {!minimized ? <div className='player-action-button' onClick={() => viewersNumber !== '0' ? checkViewersNumber(playlist.item.username,true) : null}>
                                <BsEyeFill/>
                                {!!viewersNumber ? <p>{viewersNumber}</p> : null}
                            </div>  : null}
                            {!minimized && navigator.share ? <div className='player-action-button' onClick={() => shareLiveStream(playlist.item.live_userName,playlist.item.username)}>
                                <BiShareAlt/>
                            </div>: null}
                            {!minimized && chatView ? <div className='player-action-button' onClick={() => toggleChat()}>
                                <AiFillMessage/>
                            </div>: null}
                            {!minimized ? <div className='player-action-button' onClick={() => getSessionReportItems()}>
                                <MdReport/>
                            </div>: null}
                        </div>}
                    </div>
                    {/*<div className="player-center" onClick={togglePlaying} onDoubleClick={_toggleFullScreen}>*/}
                    {/*i commented the above line to keep the code that when the user clicks or double clicks the player pauses or full screen*/}
                    <div className={`player-center ${playlist.item.isLiveStream ? 'is-livestream' : ''}`} style={isRadioPlayed ? {
                        backgroundImage:`url(${item.logo_big})`,
                        backgroundRepeat:'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition:'center',
                        height:'400px'} : null}>
                        {renderPlayerCenter()}
                    </div>
                    <div className='player-action-bar'>
                        {playerType === contentType.videoRes || playerType === playerTypes.series || (currentLink.includes("DVR") && !isRadioPlayed) ? (
                            <div className='player-progress-bar'>
                                <PlayerSlider player={playerRef}/>
                            </div>
                        ) : null}
                        <div className='player-buttons-bar'>
                            {isRadioPlayed && item.is_radio === '0' ? <p style={{position:'absolute',bottom:'3rem',fontSize:'14px'}}>{localize('radioHint')}</p> : null}
                            <div>
                                <div className='player-action-button' onClick={togglePlaying}>{playing ? <FaPause/> : <FaPlay/>}</div>
                                {(!minimized && playerType === playerTypes.series && series.length > 0) || (!minimized && playerType === playerTypes.live && list.length > 0 && !playlist.item.isLiveStream) ?
                                    (
                                        <>
                                            <div className='player-action-button' onClick={previous}><FaStepBackward/></div>
                                            <div className='player-action-button' onClick={next}><FaStepForward/></div>
                                        </>
                                    ) : null}

                                {/*<div className={`player-action-button`} onClick={toggleMuted}>{muted ? <FaVolumeMute/> : <FaVolumeUp/>}</div>*/}
                                {/*volume slider*/}
                                <div className={`player-action-button ${volumeSlider ? 'volume' : ''}`} onMouseLeave={() => setVolumeSlider(false)} onMouseOver={() => setVolumeSlider(true)}>
                                <div className={`player-action-button-volume`} onMouseOver={() => setVolumeSlider(true)} onClick={toggleMuted}>
                                    {muted || volume === '0' ? <FaVolumeMute/> : <FaVolumeUp/>}
                                </div>
                                    {volumeSlider ?
                                        <div className="slider-volume">
                                            <input type="range" onChange={(e) => toggleVolume(e)} value={volume} id="myRange" className='slider'></input>
                                        </div>
                                        : null}
                                </div>
                                {playerType === contentType.service ? <div className='player-live-text'>{currentLink.includes("DVR") ? <p>{playerDuration}</p> : <><span><FaCircle/></span>{isRadioPlayed ? localize('radio') : localize('live')}</>}</div> : null}
                                {playerType === contentType.videoRes || playerType === playerTypes.series ? <div className='player-live-text'><p>{playerDuration}</p></div> : null}

                            </div>
                            {!isRadioPlayed && !playlist.item.isLiveStream ?
                                <div>
                                    {popupVisible ? <div className='player-action-button'><FaCogs/></div> : <div className='player-action-button' onClick={actionClick}><FaCog/></div>}
                                </div>
                                : null}
                        </div>

                    </div>

                </>
            )
        }
    }
    const toggleVolume = (e) => {
        setVolume(document.getElementById("myRange").value)
        if(document.getElementById("myRange").value !== '0'){
            setMuted(false)
        }
    }
    const retryPlayerAction = () => {
        //checkbookMark()
        const player = playerRef.current
        if(loadPlayer){
            timeForRetry(player.getCurrentTime())
        }
        unsetRetryPlayer()
        hideModal()
        clearLimitTime()
        setPlaying(true)
        setConfirmModal(false)
        setKey(Math.random())
    }
    const exitPlayer = (e) => {
        checkbookMark()
        hidePlayer(e)
        unsetFetching()
        hideModal()
        clearLimitTime()
        unsetRetryPlayer()
        setConfirmModal(false)
    }
    const checkbookMark = () => {
        if (playerType !== playerTypes.live) {
            const duration = playerRef.current.getDuration()
            const playedSeconds = parseInt(playerRef.current.getCurrentTime())

            const playedRatio = (playedSeconds / duration) * 100
            //if (item.status === undefined || item.status === 'unlocked') {
            //if(playedSeconds > 0){
                //if (item.is_ppv && item.is_ppv === '1' && playedRatio >= 95) {
                  //  bookmarkVideo(playedSeconds, true)
                //} else
                if (item.is_ppv && item.is_ppv === '1' && playedRatio >= 5 && loadPlayer) {
                    bookmarkVideo(playedSeconds,sereisMultiLayerID === null ? undefined : sereisMultiLayerID)
                } else if (playedRatio >= 99.5 && loadPlayer) {
                    bookmarkVideo(playedSeconds,sereisMultiLayerID === null ? undefined : sereisMultiLayerID, true)
                } else if (item.is_ppv !== '1' && loadPlayer) {
                    bookmarkVideo(playedSeconds,sereisMultiLayerID === null ? undefined : sereisMultiLayerID)
                }
            //}
            //}
        }
    }

    const closePlayer = (e) => {
        checkbookMark()
        hidePlayer(e)
        unsetFetching()
        if (playerRef && playerRef.current && playerRef.current.getInternalPlayer('hls')) {
            playerRef.current.getInternalPlayer('hls').destroy()
        }
    }

    const chatTimeOut = () => {
        setTimeout(function() {
            setChatView(true)
        }, 30000);
    }

    const content = () => {
         return (
            <div className={`player-container ${minimized ? 'minimized' : ''} ${chatEnabled ? 'chat-enabled' : ''}`}>
                {fetchLoading ? <div className="fetch-app-pop">
                    <img width="8%" src={loadingImages} alt="loadingImages" />
                </div> : null}
                {playlist.retry ? <> {confirmModal ? (
                    <ConfirmPurchaseModal
                        visible={confirmModal}
                        onCancel={exitPlayer}
                        onOk={retryPlayerAction}
                        title={localize('wrong')}
                        fromRetry={true}
                    />
                ) : null} </> : null}

                <div
                    className={`player ${chatEnabled ? 'chat-enabled' : ''} ${popupVisible || previewDuration || !playing || playlistVisible || minimized || playerHover ? 'paused' : ''} ${fullscreen ? 'fullscreen' : ''} ${isIOS ? 'ios' : ''}`}

                    onMouseMove={() => {
                        if (!playerHover) setPlayerHover(true)
                        resetHoverTimer()
                    }}
                    onTouchStart={() => {
                        if (!playerHover) setPlayerHover(true)
                        resetHoverTimer()
                    }}

                >
                    <div className={`player-controls ${isIOS ? 'ios' : ''}`}>

                        {popupVisible ? (
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    if (popupVisible) setPopupVisible(false)
                                }}
                            >
                                <div className="player-popup">
                                    <CollapseGroup options={playerPopupOptions}/>

                                </div>
                            </OutsideClickHandler>
                        ) : null}
                        {renderPlayerControls()}
                        {renderPlayerPlaylist()}


                        {/*model for streaming Limit*/}
                        {!!strimingLimitArray && strimingLimitArray.length > 0  && !minimized && purchaseModal.visible ? (
                            <SubscriptionModal
                                visible={purchaseModal.visible}
                                gift={purchaseModal.gift}
                                onCancel={() => hideModal()}
                                service={item}
                                onPurchase={purchaseLimit}
                                fromLimit={true} //for setTab into subscription
                                //bundleServices={listBundleInfo} //limit time can't buy from another bundle
                                //loading={playlistFetching}
                            />
                        ) : null}
                    </div>
                    {!minimized && isIOS && playlist.item.isLiveStream ? <div className='live-user-info-ios'>
                        <p>{playlist.item.live_userName}</p>
                        {playlist.item.username !== username ? <img onClick={() => setFollowUnfollowStatus(playlist.item.username,following)} src={following === 0 ? require('../../assets/img/icons/follow.png') : require('../../assets/img/icons/unfollow.png')}/> : null}
                    </div> : null}
                    {/*<ReactHLS*/}
                    {/*    url={currentLink}*/}
                    {/*    autoplay={true}*/}
                    {/*    controls={true}*/}
                    {/*    hlsConfig={{*/}
                    {/*        autoStartLoad: true,*/}
                    {/*        startPosition: -1,*/}
                    {/*        debug: false,*/}
                    {/*        // ...*/}
                    {/*        file: {*/}
                    {/*            forceHLS:true,*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<video id="video" controls autoPlay></video>*/}
                    {/*{load()}*/}


                    {/*<video*/}
                    {/*    id="my-video"*/}
                    {/*    className="video-js"*/}
                    {/*    controls*/}
                    {/*    preload="auto"*/}
                    {/*    width="640"*/}
                    {/*    height="264"*/}
                    {/*    poster="MY_VIDEO_POSTER.jpg"*/}
                    {/*    data-setup="{}"*/}
                    {/*>*/}
                    {/*    <source src={currentLink}/>*/}
                    {/*</video>*/}

                    <ReactPlayer
                        key={key}
                        ref={playerRef}
                        url={currentLink}
                        controls={isIOS && !minimized} // fallback to default controls only if iOS safari
                        playsinline={true}
                        width='100%'
                        height='100%'
                        className='video-player'
                        muted={muted}
                        volume={volume/100}
                        playing={playing}
                        playbackRate={playbackSpeed}
                        onReady={onReady}
                        //onStart={e => console.log('start', e)}
                        //onPlay={e => console.log('play', e)}
                        onProgress={playerType !== contentType.service || currentLink.includes("DVR") ? onProgress : undefined} // if live then no need to track progress
                        // onDuration={e => console.log('duration', e)}
                        // onPause={e => console.log('pause', e)}
                        //onBufferStart={() => playlistFetching(true)}
                        onBuffer={() => playlistFetching(true)}
                        onBufferEnd={() => playlistFetching(false)}
                        //onSeek={e => console.log('seek', e)}
                        onEnded={onPlayerEnd}
                        onError={onError}
                        config={{
                            file: {
                                // forceHLS:isIOS ? true : false,
                                //forceHLS:playlistCurrentItem.is_encrypted === '1' ? true : null,
                                attributes: {
                                    controlsList: "nodownload",
                                },
                                hlsOptions: {
                                    debug: false,
                                    enableSoftwareAES: true,
                                    maxBufferLength:160,
                                    maxMaxBufferLength:160,
                                },
                            }
                        }}
                    />

                </div>

                {playlist.item.isLiveStream && !chatView ? chatTimeOut() : renderChat()}

                {!minimized && !!reportOptions && reportOptions.length > 0 && showReportModel  ? <LiveReportModel sessionItem={playlist.item}/> : null}
                {!minimized && !showReportModel && showReportModelInsert ? <ReportReasonInsert sessionItem={playlist.item}/> : null}
                {!minimized && !showReportModel && !showReportModelInsert && viewerListModelStatus ? <ViewersList/> : null}


                {/*<Modal*/}
                {/*    className={`img-modal`}*/}
                {/*    visible={userModal}*/}
                {/*    footer={null}*/}
                {/*    closable={false}*/}
                {/*    maskClosable={true}*/}
                {/*    onCancel={() => setUserModal(false)}*/}
                {/*    centered*/}
                {/*>*/}
                {/*    <img src={avatar} alt="avatar" onError={(event) => addDefaultSrc(event)} width={300}/>*/}
                {/*    {!!profileNickname ? <p className='nickname'>{profileNickname}</p> : null}*/}
                {/*    {localStorage.getItem(localStorageKeys.isTest) == '1' ? <p className='desc'>{username}</p> : null}*/}
                {/*    {!!profileFollowersNumber ? <p className='followers'>{profileFollowersNumber + ' ' + localize('followers')}</p> : null}*/}
                {/*    {profileDescription ? <p className='desc'>{profileDescription}</p> : null}*/}
                {/*</Modal>*/}
            </div>
        )
    }

    const load = () => {
        if(Hls.isSupported()) {
            var video = document.getElementById('video');
            var hls = new Hls();
            hls.loadSource('https://dst.apli.tv/6215f8ff3b87199/_definst_/smil:myStream.smil/playlist.m3u8?t=6239a98412d2d');
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED,function() {
                video.play();
            });
        }
    }
    const purchaseLimit = (subscription) => {
        if(strimingLimitArray.Type === streamingLimitType.bundle){
            // const bundleItem = store.getState().bundles.item
            const liveChannels = store.getState().live.items
            purchaseBundle(subscription,false,liveChannels)
        }else{
            purchaseService(playlist.items, subscription, false,true)
        }
        setKey(Math.random())
        setPlaying(true)
        hideModal()
    }
    const clicked = () => {
        if(visible && minimized) togglePlayer()
    }

    if (!visible) return null
    return (
        <div className={minimized ? "cursor-pointer" : ""} onClick={minimized ? clicked : null}>{content()}</div>
    )
}

export default Player
