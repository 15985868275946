import {
    ALL_SERVICES_SET_RESPONSE, ALL_SERVICES_SET_RESPONSE_BOOKMARK, ALL_SERVICES_SET_VIDEOS_BOOKMARK,
    AUTH_SET_AUTHORIZED,
    AUTH_SET_FETCHING,
    AUTH_SET_TOKEN,
    AUTH_UNSET_FETCHING,
    AUTH_UNSET_TOKEN,
    BUNDLES_SET_RESPONSE, LIVE_STREAM_LIST,
    MENU_SET_RESPONSE,
    PACKAGES_SET_RESPONSE,
    PLAYLIST_SET_BOOTSTRAP_RESPONSE, SET_FETCHING, SET_PHONE_NUMBER, SET_VIDEOS, SETTING_NOTIFICATIONPOPUP,
    SETTINGS_SET_USERNAME
} from "./types";
import {
    appVersion,
    avatarLink, continueWatchingID, currency, currentOperation,
    defaultBitRate,
    defaultSubtitleLanguage,
    deviceLanguage,
    deviceType, isChrome,
    osName,
    osVersion,
    useragent
} from "../../helpers/constants";
import {authOut, callFirebaseEvent, callGoogleEvent, getCurrentDate, handleHttpError, jsonToFormData, localize, one10LabsCall, setHintDialog, setVerification, shuffle} from "../../helpers/functions";
import axios from "axios";
import {bootstrap, bootstrapTesting, getHeaderEnrProfile, host} from "../api/endPoints";
import store from '../store'
import {Routes} from "../../routes/AppRouter";
import {navigate} from "@reach/router";
import {countryOperator, isMovie, localStorageKeys, operations} from "../../helpers/enums";
import firebase from "firebase/app";
import '@firebase/messaging';
import React from "react";
import {getBehaviorStatistics} from "./servicesActions";
import {useSelector} from "react-redux";
import {setHeaderFetching, showLogoutModel} from "./settingsActions";
import {userFeedback} from "./sharedActions";


const dispatch = store.dispatch

export const bootstrapRegistration = (username,referral = null,isHeaderEnrch) => {
    localStorage.removeItem(localStorageKeys.recevReferral)
    dispatch({
        type: AUTH_SET_FETCHING,
    })
    try {
        if ((/*!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)*/isChrome && Notification.permission === 'granted' || 'default') || (typeof InstallTrigger !== 'undefined' && Notification.permission === 'granted') || (!!useragent.os.name && useragent.os.name === "Android" && Notification.permission === 'granted' || 'default')) {
            if (firebase.apps.length === 0) {
                firebase.initializeApp({
                    apiKey: "AIzaSyBdC4wezOK9gMK50aw6o6cOdH62LelF37Y",
                    authDomain: "aplitv-android-application.firebaseapp.com",
                    databaseURL: "https://aplitv-android-application.firebaseio.com",
                    projectId: "aplitv-android-application",
                    storageBucket: "aplitv-android-application.appspot.com",
                    messagingSenderId: "734295480517",
                    appId: "1:734295480517:web:a6456b1929b4748c37b8ac"
                });
            }
            try {
                firebase.messaging().getToken().then(token => {
                    firebase.messaging().deleteToken(token).then(() => {
                        firebase.messaging().getToken().then(newToken => {
                                    localStorage.setItem(localStorageKeys.pushToken, newToken)
                                    register(username, localStorage.getItem(localStorageKeys.pushToken),referral,isHeaderEnrch)
                                }
                            )
                        }).catch((error) => {
                            register(username, localStorage.getItem(localStorageKeys.pushToken),referral,isHeaderEnrch)
                        })
                    })
                    .catch((err) => {
                        register(username,localStorage.getItem(localStorageKeys.pushToken),referral,isHeaderEnrch)
                    })
            }catch (e) {
                register(username,localStorage.getItem(localStorageKeys.pushToken),referral,isHeaderEnrch)
            }
        }else{
            register(username,localStorage.getItem(localStorageKeys.pushToken),referral,isHeaderEnrch)
        }
        // const messaging = firebase.messaging();
        // messaging.getToken()
        //     .then(oldToken => {
        //         messaging.deleteToken(oldToken).then(() => {
        //             messaging.getToken().then(newToken => {
        //                 localStorage.setItem(localStorageKeys.pushToken,newToken)
        //                 register(username,localStorage.getItem(localStorageKeys.pushToken))
        //             })
        //         })
        //     })
    }catch (e) {
        // console.log("unregister for Firebase" + e)
        register(username,localStorage.getItem(localStorageKeys.pushToken),referral,isHeaderEnrch)
    }
}
const register = (username,pushToken,referral,isHeaderEnrch) => {
    // const deviceId = `${username}-web` //timestamp based
    const deviceId = `${username}-${navigator.userAgent}-web`
    const req = {
        is_test: '0',
        is_login: '0',
        username: username,
        device_id: deviceId,
        language: deviceLanguage,
        device_type: deviceType,
        device_os: osName,
        device_appversion: appVersion,
        device_osversion: osVersion,
        device_pushtoken: pushToken,
        referred_by:referral,
        is_header_enrichment:isHeaderEnrch
    }

    const params = jsonToFormData(req)
    axios.post(bootstrap, params)
    // axios.post(bootstrapTesting, params)
        .then(res => {
            const authToken = res.data.auth
            const code = res.data.verification_code

            dispatch({
                type: AUTH_SET_TOKEN,
                payload: authToken
            })
            setVerification(code)


            if(!!res.data.code){//just received  header enrch. user
                bootstrapCodeActivation(res.data.code,referral, username)
            }
        })
        .catch(error => {
            dispatch({type: AUTH_UNSET_FETCHING,})
            localStorage.removeItem(localStorageKeys.authToken)
            handleHttpError(error,"bootstrapregistration")
        })
}
export const resetRegistration = () => {
    localStorage.removeItem(localStorageKeys.authToken)
    dispatch({
        type: AUTH_UNSET_TOKEN,
    })
}

export const bootstrapCodeActivation = (code,referral,username) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth: auth,
        is_login: '1',
        verification_code: code,
        language: deviceLanguage,
        device_appversion: appVersion,
        referred_by:referral,
        username:username,
    }

    if(!!sessionStorage.getItem(localStorageKeys.pb)) req['pb'] = sessionStorage.getItem(localStorageKeys.pb)
    if(!!sessionStorage.getItem(localStorageKeys.af)) req['af'] = sessionStorage.getItem(localStorageKeys.af)

    const params = jsonToFormData(req)
    dispatch({type: AUTH_SET_FETCHING,})


    // if(!!sessionStorage.getItem(localStorageKeys.af) && sessionStorage.getItem(localStorageKeys.af) == 'one10labs'){
    //     userFeedback('0', `-UserActivationSuccess' - msisdn=${username}`)
    // }

    axios.post(bootstrap, params)
        // axios.post(bootstrapTesting, params)
        .then(res => {
            if (res.status === 200) {
                const isTest = res.data.is_test
                const isTrail = !!res.data.is_trial_flag ? res.data.is_trial_flag : false
                localStorage.setItem(localStorageKeys.isTest, isTest)
                localStorage.setItem(localStorageKeys.authToken, auth)
                localStorage.setItem(localStorageKeys.loggedIn, '1')

                setAuthorized()
                processBootstrapResponse(Routes.root, res, true)
                window.history.replaceState({...window.history.state, fromLogin: false}, 'title')

                //10oneLabsCall
                if(!!sessionStorage.getItem(localStorageKeys.af) && sessionStorage.getItem(localStorageKeys.af) == 'one10labs'){
                    one10LabsCall(isTrail ? 'Success' : 'Error',username)
                }else{
                    sessionStorage.removeItem(localStorageKeys.pb)
                    sessionStorage.removeItem(localStorageKeys.af)
                    sessionStorage.removeItem(localStorageKeys.number)
                    sessionStorage.removeItem(localStorageKeys.userIp)
                }

                // if(isTrail){
                    //userFeedback('0', `-TrailUser' - session=${sessionStorage.getItem(localStorageKeys.af)} - msisdn=${username}`)
                // }

                // sessionStorage.removeItem(localStorageKeys.one10LabsParams)
                // sessionStorage.removeItem(localStorageKeys.userIp)
                // sessionStorage.removeItem(localStorageKeys.pb)
                // sessionStorage.removeItem(localStorageKeys.af)
                // sessionStorage.removeItem(localStorageKeys.number)

                if(typeof window.gtag !== 'undefined'){
                    window.gtag("event", "Registration_Event", {
                        'MSISDN':username,
                        'Account_Creation':getCurrentDate(),
                        'operatorName':localStorage.getItem(localStorageKeys.operatorName),
                        'countryName':localize('symbol')
                    })
                }
                // localStorage.removeItem(localStorageKeys.headerSet)
            }
        })
        .catch(error => {
            dispatch({type: AUTH_UNSET_FETCHING,})
            handleHttpError(error,'bootstrapCodeActivation')
        })
}

export const bootstrapSplash = (authToken, route) => {
    // const {authToken, loggedIn, isTest} = store.getSt
    // ate().auth
    dispatch({
        type: AUTH_SET_TOKEN,
        payload: authToken
    })
    const isTest = !!localStorage.getItem(localStorageKeys.isTest) ? localStorage.getItem(localStorageKeys.isTest) : '0'
    const req = {
        auth: authToken,
        is_test: isTest,
        is_login: '1',
        language: deviceLanguage,
        device_appversion: appVersion,
    }
    const params = jsonToFormData(req)
    axios.post( bootstrap, params)
        .then(res => {
            if (res.status === 200) {
                // below two lines to enable auth circulation

                const newIsTest = res.data.is_test
                if (newIsTest !== isTest) localStorage.setItem(localStorageKeys.isTest, newIsTest)
                processBootstrapResponse(route, res, false)
            }
        })
        .catch(error => {
            handleHttpError(error)
        })

}

const processBootstrapResponse = (route, res, fromLogin = false) => {
    const {data} = res
    const bundles = data.bundles
    const menu = data.menu
    const packages = data.vod_packages
    const purchasedPackages = data.vod_purchased_packages
    const asyncChargingFlow = !!data.modules.Async_Charging_Flow ? data.modules.Async_Charging_Flow : undefined
    const mobileMoney = !!data.modules.Mobile_Money ? data.modules.Mobile_Money : undefined
    const displayPackages = data.modules.VOD_Packages === "1" && data.vod_packages.length > 0
    const displayPrediction = data.modules.Prediction === "1"
    const enablePreviewLive = data.modules.Preview_Live_Channel === "1"
    const playlist = data.playlist
    const services = data.services
    let unreadNotifications =  data.unread_notifications.toString()
    const username = data.msisdn
    const linkCode = data.link_code
    const profileDescription = !!data.description ? data.description : ''
    const profileNickname = !!data.nickname ? data.nickname : ''
    const profileFollowersNumber = !!data.followers ? data.followers : undefined
    const avatar = avatarLink(username)
    const referralCode = !!data.referral_code ? data.referral_code : ''
    const giftCounter = !!data.login_counter ? data.login_counter : null
    const goLivePermissionEnabled = !!data.go_live_object ? data.go_live_object.go_live_permission == 1 : false
    const liveStreamList = !!data.go_live_object ? data.go_live_object.go_live_list : []
    const isTrail = !!data.is_trial_flag ? data.is_trial_flag : false
    const isTailDataInfo = !!data.free_trial_info ? data.free_trial_info : undefined

    const bundleServices = data.bundle_services
    const auth = data.auth
    document.cookie = "auth=" + auth + ";expires=" + 2147483647 + ";domain=.yabadoo.tv;path=/";
    localStorage.setItem(localStorageKeys.authToken, auth)
    localStorage.setItem(localStorageKeys.itemsinterval, data.items_interval)
    localStorage.setItem(localStorageKeys.referralCode, referralCode)

    //add free trial popup info
    if (!!isTailDataInfo && Object.values(isTailDataInfo).length > 0 && isTrail){
        dispatch({
            type:SETTING_NOTIFICATIONPOPUP,
            payload:{notificationPopUp:[isTailDataInfo]}
        })
    }

    if(isTrail && typeof window.gtag !== 'undefined'){
        window.gtag("event", "Free_Trial_Event", {
            'currency':currency,
            'MSISDN':username,
            'operatorName':localStorage.getItem(localStorageKeys.operatorName),
            'countryName':localize('symbol')
        })
        window.gtag('event', 'conversion', {'send_to': 'AW-865963052/s6RrCJSV8qIYEKyY9pwD'})
    }

    playlist.sort(function(a,b){
        if(a.service_name.toLowerCase() < b.service_name.toLowerCase()) return -1;
        if(a.service_name.toLowerCase() > b.service_name.toLowerCase()) return 1;
        return 0;})

    if(goLivePermissionEnabled){
        dispatch({
            type:LIVE_STREAM_LIST,
            payload:liveStreamList.reverse()
        })
    }

    if (!!playlist && playlist.length>0) {
        dispatch({
            type: PLAYLIST_SET_BOOTSTRAP_RESPONSE,
            payload: {playlist, enablePreviewLive}
        })
    }
    if (!!bundles) {
        dispatch({
            type: BUNDLES_SET_RESPONSE,
            payload: {bundles,bundleServices}
        })
    }
    if (!!services) {
        const live = services.filter(item => !!item && item.service_type === 'live')
        const clips = services.filter(item =>!!item && item.service_type === 'vod' && item.is_movie === isMovie.clips)
        const movies = services.filter(item => !!item && item.service_id !== continueWatchingID && item.service_type === 'vod' && item.is_movie === isMovie.movies)
        const series = services.filter(item => !!item && item.service_id !== continueWatchingID && item.service_type === 'vod' && item.is_movie === isMovie.series)
        const plays = services.filter(item => !!item && item.service_type === 'vod' && item.is_movie === isMovie.plays)
        const bookmark = services.filter(item => !!item && item.service_id === continueWatchingID)

        dispatch({
            type: ALL_SERVICES_SET_RESPONSE,
            payload: {clips, movies, series, plays, live, services}
        })
        if(!!bookmark[0]){
            dispatch({
                type: ALL_SERVICES_SET_RESPONSE_BOOKMARK,
                payload: {bookmark}
            })
        }
        //set movies cat bookmark
        if(!!bookmark[0] && !!bookmark[0].videos && bookmark[0].videos.length > 0){
            dispatch({
                type: ALL_SERVICES_SET_VIDEOS_BOOKMARK,
                payload: bookmark[0].videos
            })
        }
        //set movies catg as single movies

        // let x = []
        // if(movies.length > 0){
        //     const a = movies.map((item,index) => {
        //         x.push({item:item.videos[0],service:item})
        //         x.push({item:item.videos[1],service:item})
        //     })
        //     x = shuffle(x)
        // }
        // dispatch({
        //     type:SET_VIDEOS,
        //     payload:x
        // })
        if (!!menu) {
            const allowedTypes = ['1', '2', '5', '8', '9', '13']
            const filteredMenu = menu.filter(item => allowedTypes.includes(item.id.toString()))
            let slideData = []
            if(currentOperation === operations.mtnSyria){
                if (plays.length > 0){
                    slideData.push(plays[0])
                    slideData.push(plays[1])
                }
                if(series.length > 0){
                    slideData.push(series[0])
                    slideData.push(series[1])
                }
                if (movies.length > 0){
                    slideData.push(movies[0])
                    slideData.push(movies[1])
                }
                if (clips.length > 0){
                    slideData.push(clips[0])
                    slideData.push(clips[1])
                }
                if(bundles.length > 0){
                    slideData.push(bundles[0])
                    slideData.push(bundles[1])
                }
            }
            const slides = filteredMenu.map(item => !!item.mobile_logo ? item.mobile_logo : undefined)
            dispatch({
                type: MENU_SET_RESPONSE,
                payload: {menu: filteredMenu,slides:shuffle(slideData),slideImages:slides}
            })
        }
    }
    if (!!packages) {
        dispatch({
            type: PACKAGES_SET_RESPONSE,
            payload: {packages, displayPackages, purchasedPackages}
        })
    }
    if (!!unreadNotifications) {
        unreadNotifications = Number.parseInt(unreadNotifications)
        const caption = !!localStorage.getItem(localStorageKeys.captions) ? localStorage.getItem(localStorageKeys.captions) : defaultSubtitleLanguage
        const bitRate = !!localStorage.getItem(localStorageKeys.bitRate) ? localStorage.getItem(localStorageKeys.bitRate) : defaultBitRate
        dispatch({
            type: SETTINGS_SET_USERNAME,
            payload: {username, linkCode, displayPrediction, avatar, unreadNotifications, caption, bitRate, profileNickname, profileFollowersNumber, profileDescription, giftCounter, asyncChargingFlow, mobileMoney}
        })
    }
    if(route == '/' || window.location.href.indexOf("/referral") > -1){
        route = '/home'
    }
    navigate(route, {state: {fromLogin}, replace: fromLogin,})
}

export const setAuthorized = () =>{
    dispatch({type: AUTH_SET_AUTHORIZED})
}

export const logout = () => {
    showLogoutModel(false)
    if(typeof window.gtag !== 'undefined'){
        window.gtag("event", "Logout_App_Event", {
            'MSISDN':store.getState().settings.username,
            'operatorName':localStorage.getItem(localStorageKeys.operatorName),
            'countryName':localize('symbol')
        })
    }
    getBehaviorStatistics('2')
    authOut()
}

export const headerEnrichment = () => {
    dispatch({
        type: SET_FETCHING,
        payload:true
    })
    document.location.href = 'http://mtntv.mtncameroon.net/crudAPI_mobile/aplitv_getuserinfo_pwa'



    // axios.post(getHeaderEnrProfile)
    //     .then(res => {
    //         if (res.status === 200) {
    //             const phoneNumber = res.data.msisdn
    //             var CryptoJS = require("crypto-js");
    //
    //             var DataKey = CryptoJS.enc.Utf8.parse("1324123456127896");
    //             var DataVector = CryptoJS.enc.Utf8.parse("1326895965465432");
    //             var decrypted = CryptoJS.AES.decrypt(phoneNumber, DataKey, { iv: DataVector });
    //             var decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    //
    //             dispatch({
    //                 type: SET_FETCHING,
    //                 payload:false
    //             })
    //             dispatch({
    //                 type:SET_PHONE_NUMBER,
    //                 payload:decrypted
    //             })
    //         }
    //     })
    //     .catch(error => {
    //         dispatch({
    //             type: SET_FETCHING,
    //             payload:false
    //         })
    //         handleHttpError(error)
    //     })


    // try {
    //     if ((/*!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)*/isChrome && Notification.permission === 'granted') || (typeof InstallTrigger !== 'undefined' && Notification.permission === 'granted') || (!!useragent.os.name && useragent.os.name === "Android" && Notification.permission === 'granted')) {
    //         if (firebase.apps.length === 0) {
    //             firebase.initializeApp({
    //                 apiKey: "AIzaSyBdC4wezOK9gMK50aw6o6cOdH62LelF37Y",
    //                 authDomain: "aplitv-android-application.firebaseapp.com",
    //                 databaseURL: "https://aplitv-android-application.firebaseio.com",
    //                 projectId: "aplitv-android-application",
    //                 storageBucket: "aplitv-android-application.appspot.com",
    //                 messagingSenderId: "734295480517",
    //                 appId: "1:734295480517:web:a6456b1929b4748c37b8ac"
    //             });
    //         }
    //         try {
    //             firebase.messaging().getToken().then(token => {
    //                     firebase.messaging().deleteToken(token).then(() => {
    //                         firebase.messaging().getToken().then(newToken => {
    //                                 localStorage.setItem(localStorageKeys.pushToken, newToken)
    //                             redirectLink(localStorage.getItem(localStorageKeys.pushToken))
    //                             }
    //                         )
    //                     })
    //                 }
    //             )
    //         }catch (e) {
    //             redirectLink(localStorage.getItem(localStorageKeys.pushToken))
    //         }
    //     }else{
    //         redirectLink(localStorage.getItem(localStorageKeys.pushToken))
    //     }
    // }catch (e) {
    //     //console.log("unregister for Firebase" + e)
    //     redirectLink(localStorage.getItem(localStorageKeys.pushToken))
    // }
}

const redirectLink = (token) => {
    dispatch({
        type: SET_FETCHING,
        payload:false
    })
    //const url = encodeURI(`http://cam.mtn.net.sy/userinfo.php/?is_test=0&is_login=0&language=${deviceLanguage}&device_type=${deviceType}&device_os=${osName}&device_appversion=${appVersion}&device_osversion=${osVersion}&device_pushtoken=${token}`)
    const url = encodeURI('http://cam.mtn.net.sy/crudAPI_mobile_test/aplitv_getuserinfo_pwa')
    window.location.href = url
}

export const setHeaderEnrichmentData = (number) => {
    const {authToken} = store.getState().auth
    // if(!!!authToken){
    //     sessionStorage.setItem(localStorageKeys.number,number)
    //
    //     if(localStorage.getItem(localStorageKeys.headerSet) === countryOperator.cameroon.operators.mtn.cuurentOperation){
    //          // localStorage.setItem(localStorageKeys.currentOperation, 'aplitv')
    //          // localStorage.setItem(localStorageKeys.operatorName, 'aplitv')
    //
    //         // localStorage.setItem(localStorageKeys.currentOperation, countryOperator.cameroon.operators.mtn.cuurentOperation)
    //         localStorage.setItem(localStorageKeys.operatorName, countryOperator.cameroon.operators.mtn.name)
    //     }else if(localStorage.getItem(localStorageKeys.headerSet) === countryOperator.cameroon.operators.orange.cuurentOperation){
    //         // localStorage.setItem(localStorageKeys.currentOperation, countryOperator.cameroon.operators.orange.cuurentOperation)
    //         localStorage.setItem(localStorageKeys.operatorName, countryOperator.cameroon.operators.orange.name)
    //     }else if(localStorage.getItem(localStorageKeys.countryName) === countryOperator.mozambique.name){
    //         // localStorage.setItem(localStorageKeys.currentOperation, countryOperator.mozambique.operators.TMCEL.cuurentOperation)
    //         localStorage.setItem(localStorageKeys.operatorName, countryOperator.mozambique.operators.TMCEL.name)
    //     }
    //     window.location.reload()
    // }
    setHeaderFetching(false)
    if(!!!authToken && number !== '0'){
        sessionStorage.setItem(localStorageKeys.number,number)
    }else{
        sessionStorage.removeItem(localStorageKeys.number)
    }
    // window.location.reload()
}
