import React, {useState} from 'react'
import Swiper from 'react-id-swiper';
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {Link} from "@reach/router";

const CarouselRow = props => {
    const title = !!props.title ? props.title : ''
    const route = !!props.route ? props.route : ' '
    const {rtl} = props
    const [swiper, updateSwiper] = useState(null);

    const settings = {
        slidesPerView: 'auto',
        spaceBetween: 5,
        freeMode: true,
        rtl: rtl ? 'rtl': undefined,
        observer: true,
        observeParents: true,
        lazy: true

    };

    return (
        <div className={`carousel-container`}>
            <div className={`carousel-header`}>
                <Link to={route} state={{title}}><h3 className='mb-0' style={{cursor:route !== ' ' ? 'pointer' : 'default',fontFamily:"Montserrat",fontSize:"28px"}}>{title}</h3></Link>
                <div className='carousel-header-right'>
                    <div className='clickable-light' onClick={() => swiper.slidePrev()}>
                        <FiChevronLeft className='carousel-header-right-icon'/>
                    </div>
                    <div className='clickable-light' onClick={() => swiper.slideNext()}>
                        <FiChevronRight className='carousel-header-right-icon'/>
                    </div>
                </div>
            </div>
            <Swiper {...settings} getSwiper={updateSwiper}>
                {props.children.map((item, index) => <span key={index} style={{width: 'unset'}}>{item}</span>)}
            </Swiper>
        </div>
    )
}

export default CarouselRow
