import React from 'react'
import {Modal} from "antd";
import {localize} from "../../helpers/functions";
import {showLogoutModel} from "../../redux/actions/settingsActions";
import {logout} from "../../redux/actions/authActions";

const LogoutModal = props => {
    return (
        <Modal
            className={`notification-modal`}
            visible={true}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => showLogoutModel(false)}
            centered
        >
            <p className={`notification-modal-title`}>{localize('logout_title')}</p>
            <p className={`notification-modal-message-referral`} style={{padding:'unset',textAlign:'unset',fontSize:'10px'}}>{localize('logout_description')}</p>
            <div className='notification-modal-buttons' style={{justifyContent:'space-between'}}>
                <button className='notification-btn' style={{width:'45%',textTransform:'lowercase'}} onClick={() => showLogoutModel(false)}>{localize('cancel')}</button>
                <button className='notification-btn' style={{width:'45%'}} onClick={() => logout()}>{localize('yes')}</button>
            </div>
        </Modal>
    )
}


export default LogoutModal
