import {
    SERIES_HAS_MORE_FALSE,
    SERIES_MORE_SERIES,
    ALL_SERVICES_SET_RESPONSE,
    SERIES_HAS_MORE_FALSE_SERIES_SERVICES,
    SERIES_HAS_MORE_FALSE_SERIES_EPISODES,
    SERIES_MORE_SERIES_SERVICES,
    SERIES_MORE_SERIES_EPISODES, CHANGE_SERIES_SERVICE, SELECT_SERIES, SERIES_SET_FETCHING
} from "../actions/types";


const initialState = {
    items: [],
    item: {services: {items: [], hasMore: true, index: 0}, episodes: {items: [], hasMore: true, index: 0}},
    hasMore: true,
    index: 0,
    fetching: false,
    fetchingSeries:false
}

const seriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_SERVICES_SET_RESPONSE:
            return {
                ...state,
                items: action.payload.series,
                fetchingSeries: true
                //hasMore: action.payload.live.length >= loadMorePageSize
            }
        case SERIES_MORE_SERIES:
            return {
                ...state,
                items: [...state.items, ...action.payload.items],
                hasMore: true,
                index: action.payload.index,
                fetching: false,
            }
        case SERIES_HAS_MORE_FALSE:
            return {
                ...state,
                hasMore: false,
                fetching: false,
            }
        case SERIES_HAS_MORE_FALSE_SERIES_SERVICES:
            return {
                ...state,
                    item: {
                        ...state.item,
                        services: {
                            ...state.item.services,
                            hasMore: false,
                        }
                    }
            }
        case SERIES_HAS_MORE_FALSE_SERIES_EPISODES:
            return {
                    ...state,
                    item: {
                        ...state.item,
                        episodes: {
                            ...state.item.episodes,
                            hasMore: false,
                        }
                    }
            }
        case SERIES_MORE_SERIES_SERVICES:
            return {
                ...state,
                    item: {
                        ...state.item,
                        services: {
                            ...state.item.services,
                            items: action.payload.index === 1 ? action.payload.items : [...state.item.services.items, ...action.payload.items],
                            // items: action.payload.items,
                            hasMore: true,
                            index: action.payload.index,
                        }
                    }
            }
        case SERIES_MORE_SERIES_EPISODES:
            return {
                ...state,
                    item: {
                        ...state.item,
                        episodes: {
                            ...state.item.episodes,
                            items: [...state.item.episodes.items, ...action.payload.items],
                            hasMore: true,
                            index: action.payload.index,
                        }
                    }
            }
        case CHANGE_SERIES_SERVICE:
            return {
                ...state,
                    item: {
                        ...state.item,
                        ...action.payload,
                        changed: true, // just to know if the user clicked one of series services (seasons)
                        episodes: initialState.item.episodes,
                        // services: initialState.series.item.services,
                    }
            }
        case SELECT_SERIES:
            return {
                ...state,
                item: {...initialState.item, ...action.payload}
            }
        case SERIES_SET_FETCHING:
            return {
                ...state,
                fetching: true,
            }
        default:
            return state
    }

}

export default seriesReducer
