import React from 'react'
import {Handles, Slider, Tracks, Rail} from "react-compound-slider";
import {toHHMMSS} from "../../helpers/functions";

const PlayerSlider = props => {

    const player = !!props.player ? props.player : undefined
    const DVR = !!props.dvr ? props.dvr : false
    const value = !!player && !!player.current ? player.current.getCurrentTime() : 0
    const duration = !!player && !!player.current ? player.current.getDuration() ? player.current.getDuration() : 1 : 1

    const buffer = !!player && !!player.current ? player.current.getSecondsLoaded()/duration * 100 : 0

    // console.log(player.current)
    // console.log(value)
    // console.log(toHHMMSS(duration))
    const seek = value => {
        // if(DVR) console.log(toHHMMSS(value))
        if (!!player && !!player.current) player.current.seekTo(value[0], 'seconds')
    }

    return (
        <Slider
            className='player-slider'
            domain={[0, duration]}
            values={[value]}
            onSlideEnd={seek}
            step={1}
        >
            <Rail>
                {({getRailProps}) => (
                    <div className='rail' {...getRailProps()} />
                )}
            </Rail>
            <Handles>
                {({handles, getHandleProps}) => (
                    <div className="slider-handles">
                        {handles.map(handle => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({tracks, getTrackProps}) => (
                    <>
                        {tracks.map(({id, source, target}) => {
                                return (
                                    <React.Fragment key={id}>
                                        <Track
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                        />
                                        <BufferTrack
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                            buffer={buffer}
                                        />
                                    </React.Fragment>
                                )
                            }
                        )}
                    </>
                )}
            </Tracks>
        </Slider>

    )
}

export default PlayerSlider


function Handle({ // your handle component
                    handle: {id, value, percent},
                    getHandleProps
                }) {
    return (
        <div
            className='handle'
            style={{left: `${percent}%`}}
            {...getHandleProps(id)}
        />
    )
}

function Track({source, target, getTrackProps}) { // your own track component
    return (
        <div
            className='main-track playing-track'
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
        />
    )
}

function BufferTrack({source, target, getTrackProps, buffer}) { // your own track component
    return (
        <div
            className='main-track buffer-track'
            style={{
                left: 0,
                width: `${buffer}%`,
            }}
            {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
        />
    )
}
