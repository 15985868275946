import React, {useState} from 'react'
import {Modal} from "antd";
import {localize} from "../../helpers/functions";
import {reportSession, setReportModel, setReportModelStatus} from "../../redux/actions/liveStreamActions";
import store from "../../redux/store";

const ReportReasonInsert = props => {
    const [reasonText, setReasonText] = useState('')
    const sessionItem = !!props.sessionItem ? props.sessionItem : undefined

    return (
        <Modal
            className={`insert-reason-report-modal`}
            visible={true}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => setReportModel({from:'InsertReason',status:false})}
            centered
        >
             <textarea rows="4" placeholder={localize('reportTitle')} onChange={e => setReasonText(e.target.value)} value={reasonText}/>
             <button onClick={() => !!sessionItem ? reportSession(reasonText,sessionItem.sessionId) : null}>{localize('submit')}</button>
        </Modal>
    )
}


export default ReportReasonInsert
