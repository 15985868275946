import React, {useState} from 'react'
import {addRTL, localize} from "../../helpers/functions";
import moment from 'moment';
import {navigate} from "@reach/router";
import {wideLogo} from "../../assets/img/exports";
import {FiX} from "react-icons/fi";
import {currentOperation, isYabadooOperation} from "../../helpers/constants";
import {operations} from "../../helpers/enums";
import store from "../../redux/store";
import {checkLiveSessionStatus} from "../../redux/actions/liveStreamActions";

const NotificationItem = props => {
    const [more, setMore] = useState(false)
    const img = !!props.img ? props.img : wideLogo
    const title = !!props.title ? props.title.length > 33 && !more ? props.title.substr(0, 33) + '...' : props.title : 'Title'
    const descCharLength = 66
    const desc = !!props.desc ? props.desc.length > descCharLength && !more ? props.desc.substr(0, descCharLength) + '...' : props.desc : ''
    const time = !!props.time ? moment(props.time).fromNow() : ''
    const read = !!props.read ? props.read : false
    const route = !!props.route ? props.route : undefined
    const popup = !!props.popup ? props.popup : null
    const formLive = !!props.formLive ? props.formLive : false
    const eventType = !!props.event ? props.event : ''
    const liveSessionId = !!props.liveSessionId ? props.liveSessionId : undefined
    const username = !!props.username ? props.username : undefined
    const {rtl} = store.getState().settings

    const onReadMore = e => {
        e.stopPropagation()
        setMore(!more)
    }

    const onClick = () => {
        props.onClick()
        if(eventType === 'Event' && !!liveSessionId && username) checkLiveSessionStatus(username,liveSessionId)
        if (!!route) navigate(route)
    }

    return (
        <div className={`notification-item-container ${read && popup !== '1' && (currentOperation === (operations.nexttelLocal || operations.nexttel) ? 'read-red' : currentOperation === operations.ishow ? 'read-pink' : isYabadooOperation ? 'read-Yellow' : read && popup !== '1' ? 'read' : '')}`}>
            <div className={`item-card-x-notification clickable-light ${addRTL(rtl)}`} onClick={props.onRemove}>
                <FiX/>
            </div>
            <div className={'add'} onClick={() => onClick()}>
                {props.loading ? (
                    <div className="notification-item-overlay">
                        <span className="spinner-border text-light"/>
                    </div>
                ) : null}
                <img src={img} alt="" className='notification-item-img' style={formLive ? {width:'70px',height:'100px'} : null}/>
                <div className='notification-item-right'>
                    <p className='notification-item-title'>{title}</p>
                    <p className='notification-item-desc'>{desc}</p>
                    <div className='notification-item-footer'>
                        <p className='notification-item-time'>{time}</p>
                        {props.desc.length > descCharLength ? (
                            <div className='clickable-light notification-item-more' onClick={onReadMore}>
                                <p>{more ? localize('readLess') : localize('readMore')}</p>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationItem
