import {
    AUTH_SET_AUTHORIZED,
    AUTH_SET_FETCHING,
    AUTH_SET_TOKEN,
    AUTH_UNSET_FETCHING,
    AUTH_UNSET_TOKEN,
    AUTH_USER_LOG_OUT, SET_PHONE_NUMBER
} from "../actions/types";

const initialState = {
    authToken: undefined,
    fetching: false,
    authorized: false,
    number:undefined,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SET_TOKEN:
            return {
                ...state,
                authToken: action.payload,
                fetching: false
            }
        case AUTH_UNSET_TOKEN:
            return initialState
        case AUTH_USER_LOG_OUT:
            return initialState
        case AUTH_SET_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case AUTH_UNSET_FETCHING:
            return {
                ...state,
                fetching: false
            }
        case AUTH_SET_AUTHORIZED:
            return {
                ...state,
                authorized: true
            }
        case SET_PHONE_NUMBER:
            return {
                ...state,
                number: action.payload
            }
        default:
            return state

    }
}

export default authReducer
