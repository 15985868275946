import {PROFILE_SET_FETCHING, PROFILE_SET_RESPONSE, PROFILE_TOGGLE_AUTO_RENEW,PROFILE_SET_FETCHING_AUTORENEW,PROFILE_UNSET_FETCHING_AUTORENEW, PROFILE_UNSET_FETCHING} from "../actions/types";

const initialState = {
    fetching: false,
    fetchingautoRenew:false,
    items: {
        bundles: [],
        live: [],
        vod: [],
        videos: [],
        packages: []
    }
}

const profileReducer = (state = initialState, action) => {

    switch (action.type) {
        case PROFILE_SET_RESPONSE:
            return {
                ...state,
                items: {...state.items, ...action.payload},
                fetching: false,
            }
        case PROFILE_SET_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case PROFILE_UNSET_FETCHING:
            return {
                ...state,
                fetching: false
            }
        case PROFILE_SET_FETCHING_AUTORENEW:
            return {
                ...state,
                fetchingautoRenew: true
            }
        case PROFILE_UNSET_FETCHING_AUTORENEW:
            return {
                ...state,
                fetchingautoRenew: false
            }
        case PROFILE_TOGGLE_AUTO_RENEW:
            let newItems = {}
            Object.keys(state.items).forEach(key => {
                newItems[key] = state.items[key].map(item => {
                    if (item.orderid === action.payload.orderId) return {...item, is_renew: action.payload.isRenew ? '1' : '0'}
                    else return item
                })
            })
            return {
                ...state,
                items: newItems,
                fetchingautoRenew:false
            }
        default:
            return state

    }

}

export default profileReducer