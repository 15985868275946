import {HISTORY_HAS_MORE_FALSE, HISTORY_LOAD_MORE, HISTORY_SET_RESPONSE, HISTORY_UNSET_RESPONSE} from "../actions/types";

const initialState = {
    items: [],
    hasMore: true,
    index: 0,
}

const historyReducer = (state = initialState, action) =>{

    switch (action.type) {
        case HISTORY_UNSET_RESPONSE:
            return {
                ...initialState
            }
        case HISTORY_SET_RESPONSE:
            return {
                ...state,
                items : action.payload,
                //hasMore: action.payload.length >= loadMorePageSize
            }
        case HISTORY_HAS_MORE_FALSE:
            return {
                ...state,
                hasMore: false
            }
        case HISTORY_LOAD_MORE:
            return {
                ...state,
                items : [...state.items, ...action.payload.items],
                //hasMore: action.payload.items.length >= loadMorePageSize,
                index: action.payload.index
            }
        default:
            return state

    }

}

export default historyReducer