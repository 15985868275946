import {PACKAGES_SET_PURCHASED, PACKAGES_SET_RESPONSE} from "../actions/types";

const initialState = {
    packages: [],
    purchasedPackages: [],
    displayPackages: false,
    fetching: false,
}

const packagesReducer = (state = initialState, action) => {

    switch (action.type) {
        case PACKAGES_SET_RESPONSE:
            return {
                ...state,
                packages: action.payload.packages,
                purchasedPackages: action.payload.purchasedPackages,
                displayPackages: action.payload.displayPackages,
            }
        case PACKAGES_SET_PURCHASED:
            return {
                ...state,
                purchasedPackages: action.payload,
            }
        default:
            return state

    }
}

export default packagesReducer