import {contentType, isMovie, localStorageKeys, operations, playerTypes, streamingLimitType} from "./enums";
import {Routes} from "../routes/AppRouter";
import {globalHistory, navigate} from "@reach/router";
import ReactDOM from 'react-dom';
import {
    ADD_REMOVE_SINGLE_ITEM,
    ADD_SERVICES,
    AUTH_USER_LOG_OUT,
    CHANGE_REFERRAL_STATE, DEVICES_UNSET_FETCHING, FAVORITES_SET_FETCH,
    HANDLE_ERROR, LIVE_STREAM_LIST_1,
    PLAYER_LIMIT_TIME,
    PLAYER_LIMIT_TIME_CLEAR,
    PLAYER_SET_VISIBLE,
    PLAYER_TOGGLE_FULLSCREEN,
    PLAYLIST_SET_FETCHING,
    PLAYLIST_UNSET_FETCHING,
    REMOVE_POPUP_NOTIFICATION, RESET_LOGINPAGE, SELECT_LIVE,
    SET_CODE,
    SET_DST_LINK, SET_FETCHING, SET_IOS_POPUP,
    SET_RELEASE_FETCHING, SET_TIME_RETRY,
    SETTING_NOTIFICATIONPOPUP,
    UNREAD_NOTIFICATION,
    UNSET_HANDLE_ERROR_VISIBLE
} from "../redux/actions/types";
import {
    currentOperation,
    deviceTypePWA,
    pwaLink,
    useragent,
    currency,
    deviceLanguage,
    androidAppLink,
    dstSmile,
    dstDomain,
    continueWatchingID,
    isChrome,
    isActive,
    iOS,
    isSafari,
    isheaderEnrichment
} from "./constants";
import store from '../redux/store';
import firebase from 'firebase/app';
import {hideModal, setHeaderFetching, showPurchaseModal} from "../redux/actions/settingsActions";
// import * as serviceWorker from "../serviceWorker";
import '@firebase/messaging';
import {placeholderImage} from "../assets/img/exports";
import React from "react";
import App from "../App";
import Value from "less/lib/less/tree/value";
import {getContentInfo} from "../redux/actions/servicesActions";
import {getLink, getSeriesPlaylist, selectPlaylistItem, setLiveSessionEnd} from "../redux/actions/playlistActions";
import axios from "axios";
import {getUserTransactionLogs, host, getContinueWatching, getContentLink, oneLabsVisit, oneLabsSuccess, oneLabsError} from "../redux/api/endPoints";
import {useSelector} from "react-redux";
import {checkVODInPackages} from "../redux/actions/packagesActions";
import SeriesCard from "../components/Cards/SeriesCard";
import parse from "less/lib/less/parse";
import {getLiveSessionLink} from "../redux/actions/liveStreamActions";
import {setHeaderEnrichmentData} from "../redux/actions/authActions";
import {campaignLog, userFeedback} from "../redux/actions/sharedActions";

export const initializeFirebase = () => {
    try {
        if ((isChrome && Notification.permission === 'granted') || (typeof InstallTrigger !== 'undefined') || (!!useragent.os.name && useragent.os.name === "Android" && Notification.permission === 'granted')){
            navigator.serviceWorker.getRegistrations().then(registrations => {
                if(registrations.length === 0 && Notification.permission === 'granted'){
                    firebase.initializeApp({
                        apiKey: "AIzaSyBdC4wezOK9gMK50aw6o6cOdH62LelF37Y",
                        authDomain: "aplitv-android-application.firebaseapp.com",
                        databaseURL: "https://aplitv-android-application.firebaseio.com",
                        projectId: "aplitv-android-application",
                        storageBucket: "aplitv-android-application.appspot.com",
                        messagingSenderId: "734295480517",
                        appId: "1:734295480517:web:a6456b1929b4748c37b8ac"
                    });
                    if ('serviceWorker' in navigator) {
                        navigator.serviceWorker.register('../firebase-messaging-sw.js')
                            .then(function (registration) {
                                firebase.messaging().useServiceWorker(registration);
                                //console.log('Registration successful');
                                //registration.scope
                            }).catch(function (err) {
                            //console.log('Service worker registration failed, error:', err);
                        });
                        try {
                            Notification.requestPermission()
                                .then(permission => {
                                    if (permission === 'granted') {
                                        firebase.messaging().getToken()
                                            .then(token => {
                                                localStorage.setItem(localStorageKeys.pushToken, token)
                                                //localstorage save token
                                            })
                                            .catch(
                                                //console.log("error1")
                                            )
                                    } else {
                                       //console.log("bloked")
                                    }
                                })
                                .catch(err => {
                                    //console.log("error2")
                                    // you must enable the push
                                })
                        }catch (e) {
                            //console.log("error3")
                            //console.log("registration failed" + e)
                        }
                    }
                }
            });
        }
    }catch (e) {
        //console.log("Can't register firebase" + e)
    }
}
export function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=" + 2147483647 + ";domain=.yabadoo.tv;path=/";
    }
}
export const addRTL = rtl => rtl ? 'rtl' : ''

export const addDefaultSrc = (ev) => {
    ev.target.src = placeholderImage
}

export const jsonToFormData = object => {
    let form_data = new FormData();
    Object.keys(object).map(key => form_data.append(key, object[key]))
    return form_data
}

export const authOut = () => {
    // if ((!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) || (typeof InstallTrigger !== 'undefined') || (!!useragent.os.name && useragent.os.name === "Android")){
    //     navigator.serviceWorker.getRegistrations().then(registrations => {
    //         if(registrations.length !== 0){
    //             for (let registration of registrations){
    //                 registration.unregister().then(() => {
    //                     console.log('')
    //                 })
    //             }
    //         }
    //     })
    // }
    const dispatch = store.dispatch
    localStorage.removeItem(localStorageKeys.loggedIn)
    localStorage.removeItem(localStorageKeys.authToken)
    localStorage.removeItem(localStorageKeys.bitRate)
    localStorage.removeItem(localStorageKeys.serviceItem)
    localStorage.removeItem(localStorageKeys.captions)
    localStorage.removeItem(localStorageKeys.visible)
    localStorage.removeItem(localStorageKeys.pushToken)
    localStorage.removeItem(localStorageKeys.unreadNotifications)
    localStorage.removeItem(localStorageKeys.referralCode)
    localStorage.removeItem(localStorageKeys.dstKey)
    localStorage.removeItem(localStorageKeys.isTest)
    localStorage.removeItem(localStorageKeys.itemsinterval)
    localStorage.removeItem(localStorageKeys.currentPlaylistIndex)
    sessionStorage.removeItem(localStorageKeys.one10LabsParams)
    sessionStorage.removeItem(localStorageKeys.pb)
    sessionStorage.removeItem(localStorageKeys.af)

    dispatch({type: AUTH_USER_LOG_OUT})
    navigate(Routes.login)
    deleteAllCookies()
    dispatch({
        type: PLAYER_SET_VISIBLE,
        payload: false
    })
    // window.location.reload()
}

export const handleHttpError = (error,from = undefined) => {
    if (error.response) {
        const {status} = error.response
        if (status === 400) {
            if(from === 'autorenewFailed'){
                setHintDialog('autorenewFailed',localize('autorenewFailedDes'))
                return
                //message.error('autorenewFailed')
            }
            else if (from === 'bootstrapCodeActivation'){
                setHintDialog('verificationCodeWrong',localize('verificationCodeWrongTitle'))
                return
                // message.error(localize('verificationCodeWrong'));
            }
            else if (from === 'deactivateDevice'){
                setHintDialog('verificationCodeWrong',localize('verificationCodeWrongTitle'))
                return
                // message.error(localize('verificationCodeWrong'));
            }
            else{
                setHintDialog('please_try_again_later',localize('please_try_again_laterTitle'))
                // message.error(localize('please_try_again_later'));
            }
        }
        if(status === 500){
            setHintDialog('please_try_again_later',localize('please_try_again_laterTitle'))
            // message.error(localize('please_try_again_later'));
        }
        if (status === 401) {
            //setError('unauthorized_access')
            // message.error(localize('unauthorized_access'));
            authOut()
        } else if (status === 403 && from === 'gifting') {
            setHintDialog('no_balance',localize('no_balanceTitle'))
            // message.error(localize('no_balance'))
        } else if (status === 406) {
            if(from === 'reportLiveSession'){
                setHintDialog('reportSuccessDesc2',localize('reportSuccessTitle'))
            }else{
                setHintDialog('not_operator_number',localize('not_operator_numberTitle'))
            }
            // message.error(localize('not_operator_number'))
        } else if (status === 409) { // conflict
            if (!!from && from) {
                setHintDialog('gift_already_owened', localize('gift_already_owenedTitle'))
            } else {
                setHintDialog('owened_content_subtitle', localize('owened_content_subtitleTitle'))
            }
        }
        else if (status === 417 && from === 'gifting'){
            //not valid number to gift
            setHintDialog('not_operator_number',localize('not_operator_numberTitle'))
        } else if (status === 204) {
            setHintDialog('noContent')
            // message.error('No more content');
        } else if (status === 417 && from === "bootstrapregistration"){
            setHintDialog('notValidphoneNumber',localize('notValidphoneNumberTitle'))
            //message.error(localize('notValidphoneNumber'))
        } else if (status === 418 && from === "bootstrapregistration" && !!sessionStorage.getItem(localStorageKeys.number)){ // HE session end - timeout
            setHintDialog('timeOutDesc',localize('timeOutTitle'))
            //message.error(localize('notValidphoneNumber'))
        } else if (status === 408) {
            setHintDialog('haveSubscriptionText',localize('haveSubscriptionTitle'))
        }else if (status === 415 && from === "bootstrapCodeActivation"){
            resetLoginParam(true)
            setHintDialog('codeAttemptsDes',localize('codeAttemptsTitle'))
        }
        else if (status !== 404){
            setHintDialog('nonetwork',localize('nonetworkTitle'))
            // message.error('Unknown error check your network');
        }
    } else if (error.request) {
        setHintDialog('nonetwork',localize('nonetworkTitle'))
        // message.error('Unknown error check your network');
    }
}

export const resetLoginParam = (bool) => {
    store.dispatch({
        type:RESET_LOGINPAGE,
        payload:bool
    })
}

export const setHintDialog = (key, title = undefined) => {
    const dispatch = store.dispatch
    dispatch({
        type:HANDLE_ERROR,
        payload:{errorTitle:!!title ? title : null,errorDescription:localize(`${key}`)}
    })
}
export const getSeconds = (sec,bookmark) => {
    const time = sec.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const seconds = (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2]);

    return ((bookmark / seconds) * 100)
}

export const unsetError = () => {
    const dispatch = store.dispatch
    dispatch({
        type:UNSET_HANDLE_ERROR_VISIBLE,
    })
}

export const removeParentServices = () => {
    const dispatch = store.dispatch
    dispatch({
        type:ADD_SERVICES,
        payload:{items:[],index:-1}
    })
}
export const switchReferral = (state) => {
    const dispatch = store.dispatch
    dispatch({
        type:CHANGE_REFERRAL_STATE,
        payload:state
    })
}
export const disableContextMenu = () => {
    // const isInStandaloneMode = () =>
    //     (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone);
    // if (isInStandaloneMode()) {
    if (deviceTypePWA === 'mobile') {
        window.oncontextmenu = event => {
            event.preventDefault();
            event.stopPropagation();
            return false;
        };
    }
}

export const listenToNotification = () => {
    'serviceWorker' in navigator && navigator.serviceWorker.addEventListener('message', event => {
        const dispatch = store.dispatch
        const playlistItem = store.getState().playlist.item
        const bundleItem = store.getState().bundles.item
        const notificationData = event.data
        const notificationType = !!notificationData.Notification_type ? notificationData.Notification_type : ''
        const notificationTitle = !!notificationData.Title ? notificationData.Title : ''
        const notificationMessage = !!notificationData.Message ? notificationData.Message : ''

        // console.log(notificationData)
        if(!!notificationData && !!notificationData.go_live_event && notificationData.go_live_event === '1'){//user start live session
            const sessionIndexFound = store.getState().liveStream.liveStreamList.findIndex((item) => item.go_live_session === notificationData.go_live_session)
            if(sessionIndexFound === -1){// insert new live session Item
                getLiveSessionLink(notificationData.Object,notificationData.go_live_session).then(res => {
                    if(res.status === 200){
                        let liveSessionObject = {
                            go_live_session:notificationData.go_live_session,
                            id:notificationData.Object,
                            name:notificationData.Title,
                            description:notificationData.Message,
                            link:res.data.msg,
                            logo:notificationData.logo,
                            is_live:1
                        }
                        dispatch({
                            type:ADD_REMOVE_SINGLE_ITEM,
                            payload:{liveObject:liveSessionObject,insert:1}
                        })
                    }
                })
            }
        }

        if(!!notificationData && !!notificationData.go_live_event && notificationData.go_live_event === '0') {//user end live session
            const checkSessionIndex = store.getState().liveStream.liveStreamList.findIndex((item) => item.go_live_session === notificationData.go_live_session)
            if(checkSessionIndex > -1) {
                dispatch({
                    type:ADD_REMOVE_SINGLE_ITEM,
                    payload:{indexToRemove:checkSessionIndex,insert:0}
                })
                setLiveSessionEnd(true)
            }
        }

            if(!!notificationData.is_purchased && notificationData.is_purchased.trim() != "" && typeof window.gtag !== 'undefined'){
            window.gtag("event", "purchase", {
                'item_id':!!notificationData.Object ? notificationData.Object : '',
                'content_type':!!notificationData.Title ? notificationData.Title : '',
                'currency':currency,
                'price':!!notificationData.is_purchased ? notificationData.is_purchased : '',
                'MSISDN':store.getState().settings.username,
                'Account_Creation':getCurrentDate(),
                'operatorName':localStorage.getItem(localStorageKeys.operatorName),
                'countryName':localize('symbol')
            })
        }

        if(notificationTitle.includes("code is")){
            setVerification(notificationMessage)
        }
        if (notificationType !== '' && store.getState().playlist.visible === true){
            const found = store.getState().player.items.findIndex(item => (item.id === notificationData.id))
            if(found === -1){
                if ((playlistItem.service_id === notificationData.Object && notificationData.Service_type === streamingLimitType.live) || (bundleItem.bundle_id === notificationData.Object && notificationData.Type === streamingLimitType.bundle)) {
                    let streamLimitArray = []
                    let titlemsg = ''
                    if (notificationType === '1'){
                        titlemsg = localize('reachLimit')
                    }else if (notificationType === '0'){
                        titlemsg = localize('reachLimit0')
                    }else{
                        titlemsg = localize('reachLimit01')
                    }
                    streamLimitArray.push((notificationData))
                    // console.log(notificationData)
                    dispatch({
                        type: PLAYER_LIMIT_TIME,
                        payload: {items:streamLimitArray,titleMsg:titlemsg},
                    })
                    showPurchaseModal()
                }
            }
        }
        // console.log(notificationData)

        //handle popup notifications
        if (notificationData.is_popup === '1' && notificationData.is_trial !== '1'){
            const found = store.getState().settings.notificationPopUp.findIndex(item => (item.id === notificationData.id))
            if (found === -1){
                let notificationArray = []
                notificationArray.push(notificationData)
                //let notificationDataArray = JSON.parse(localStorage.getItem(localStorageKeys.notifactionPopUpArray))
                //notificationArray.push(notificationData)
                // dispatch({
                //     type:SETTING_NOTIFICATIONPOPUP,
                //     payload:{notificationPopUp:notificationArray}
                // })

                // if(notificationDataArray !== null){
                //     notificationDataArray.forEach((item) => notificationArray.push(item))
                //     notificationArray.push(notificationData)
                // }else{
                //     notificationArray.push(notificationData)
                // }
                dispatch({
                    type:SETTING_NOTIFICATIONPOPUP,
                    payload:{notificationPopUp:notificationArray}
                })
                //localStorage.setItem(localStorageKeys.notifactionPopUpArray,JSON.stringify(notificationArray))
            }
        }else if (notificationData.insert === '1'){
            let unreadNotification = !!localStorage.getItem(localStorageKeys.unreadNotifications) ? localStorage.getItem(localStorageKeys.unreadNotifications) : 0
            localStorage.setItem(localStorageKeys.unreadNotifications,parseInt(unreadNotification) + 1)
            dispatch({
                type: UNREAD_NOTIFICATION,
                payload: store.getState().player.unreadNotification + 1,
            })
        }
    });

}

export const setVerification = code => {
    store.dispatch({
        type: SET_CODE,
        payload: code,
    })
}
export const playlistFetching = set => {
    if(set){
        store.dispatch({
                type: PLAYLIST_SET_FETCHING,
            })
    }else{
        store.dispatch({
            type: PLAYLIST_UNSET_FETCHING,
        })
    }
}

export const setReleaseFetching = data => {
    store.dispatch({
        type: SET_RELEASE_FETCHING,
        payload:data
    })
}

export const redirectCall = (item, link = undefined) => {
    if(store.getState().playlist.visible){
        if (!!item) {
            axios({
                method: 'post',
                url: !!link ? link : `${dstDomain}${item.service_id}${dstSmile}${localStorage.getItem(localStorageKeys.dstKey)}`,
                timeout: 5000// timeout 5 sec to retry,
            }).then(function (res) {
                const serviceID = !!store.getState().playlist.item.service_id ? store.getState().playlist.item.service_id : undefined
                setDstLink('')
                if(serviceID === item.service_id){// if more than one redirect link loaded - check id to fill correct service link
                    setDstLink(res.request.responseURL)
                }
            }).catch(function (error) {
                const videoID = !!item.video_id ? store.getState().playlist.item.video_id : undefined
                const serviceID = !!store.getState().playlist.item.service_id ? store.getState().playlist.item.service_id : undefined
                if((store.getState().playlist.currentLink === '' && !!videoID === item.video_id) || (store.getState().playlist.currentLink === '' &&  serviceID === item.service_id)){
                    redirectCall(item,!!link ? link : undefined)
                }else{
                    return false
                }
            })
        } else{
            axios({
                method: 'post',
                url:link,
                timeout: 5000,//timeout 6 sec to retry
            }).then(function (res) {
                setDstLink(res.request.responseURL)
            }).catch(function (error) {
                if(store.getState().playlist.currentLink === ''){
                    redirectCall(item)
                }else{
                    return false
                }
            })
        }
    }
}

export const  setDstLink = (payload) => {
    //for empty payload (to not get a playlist before give player the correct redirect link)
    store.dispatch({
        type:SET_DST_LINK,
        payload:payload
    })
}

export const toggleScreen = () => {
    store.dispatch({
        type: PLAYER_TOGGLE_FULLSCREEN,
        payload:false,
    })
}
export const clearLimitTime = (index = 0) => {
    store.dispatch({
        type:PLAYER_LIMIT_TIME_CLEAR,
        payload:index,
    })
}
export const localize = key => {
    const currentLang = !!localStorage.getItem(localStorageKeys.lang) ? localStorage.getItem(localStorageKeys.lang) : deviceLanguage
    let langFile = require('../assets/lang/en').default
    if (currentLang === 'ar') langFile = require('../assets/lang/ar').default
    if (currentLang === 'fr') langFile = require('../assets/lang/fr').default
    if (currentLang === 'pt') langFile = require('../assets/lang/pr').default
    if (!!langFile[key]) return langFile[key]
    else console.warn(key, ' is not existed')
}

export const hhmmss = secs => {
    const pad = num => ("0" + num).slice(-2);
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}


export const renderRefreshUI = (registration, {refresh}) => {
    // navigator.serviceWorker.getRegistrations().then(function(registrations) {
    //     for(let registration of registrations) {
    //         // console.log(registration)
    //         registration.unregister().then()
    //     }
    // })


    // const el = document.createElement('div');
    // el.className = 'update-pwa-button'
    // document.querySelector('#root').appendChild(el);
    // ReactDOM.render(
    //     <>
    //         <button className='btn btn-dark' onClick={refresh}>{localize('updateAvailable')}</button>
    //     </>, document.querySelector('.update-pwa-button')
    // );
    // console.log(store.getState().settings.version)
    localStorage.setItem(localStorageKeys.update,'true')
    ReactDOM.render(<App/>, document.getElementById('root'));
}

export const favoriteSetFetch = (payload) => {
    store.dispatch({
        type:FAVORITES_SET_FETCH,
        payload:payload,
    })
}


export const continueWatchingPopupClick = (status,item) => {
    setFirstTimeViewed('0')

    //hide for how to add pwa to home screen Dialog
    store.dispatch({
        type:SET_IOS_POPUP,
        payload:false
    })

    if(status === 'unlocked'){
        const playItem = store.getState().playlist.items.find(i => i.service_id === item.service_id)
        setDstLink('')

        selectPlaylistItem(playItem)
        const url = playItem.is_radio == "1" ? playItem.radio_url : playItem.url
        if (url.indexOf('redirect') > -1){
            let searchParams = new URL(url).searchParams
            localStorage.setItem(localStorageKeys.dstKey, searchParams.get('t'))
            redirectCall(item,url)
        }else {
            localStorage.removeItem(localStorageKeys.dstKey)
        }
    }else{
        localStorage.setItem(localStorageKeys.continueWatchingPopup,'1')
        navigate(Routes.channelId(item.service_id))
    }
}

export const setFirstTimeViewed = (set) => {
    if(set === '-1'){
        sessionStorage.removeItem(localStorageKeys.isFirstTime)
    }else{
        sessionStorage.setItem(localStorageKeys.isFirstTime,set)
    }
}

export const popUpNotificationClick = (index,item) => {
    hideModal()
    const dispatch = store.dispatch
    //const notificationsDataArray = JSON.parse(localStorage.getItem(localStorageKeys.notifactionPopUpArray))
    //const notificationsDataArray = store.getState().settings.notificationPopUp
    //notificationsDataArray.splice(index, 1)
    //localStorage.setItem(localStorageKeys.notifactionPopUpArray,JSON.stringify(notificationsDataArray))
    dispatch({
        type:REMOVE_POPUP_NOTIFICATION,
        payload:index
    })
    if(item.Type === 'Bundle'){
        navigate(Routes.bundleTo(item.Object))
    }else if(item.Type === 'live'){
        navigate(Routes.channelId(item.Object))
    }
}
export const renderHours = hours => {
    const days = Math.floor(hours / 24)
    const renderDays = days > 0 ? <><span className="sub-number">{days}</span> {localize('dayS')}</> : null
    const hoursCount = hours % 24
    const renderHours = hoursCount > 0 ? <><span className="sub-number">{hoursCount}</span> {localize('hourS')}</> : null
    return !!renderDays || !!renderHours ? <>{renderDays} {renderHours}</> : ''
}

export const renderMinutes = (min, inSeconds = false) => {
    if(currentOperation === operations.ishow){
        min = Number(min);
        let h = Math.floor(min / 3600);
        let m = Math.floor(min % 3600 / 60);
        let s = Math.floor(min % 3600 % 60);

        const renderHours = h > 0 ? <><span className="sub-number">{h}</span> {localize('hourS')}</> : null
        const renderMinutes = m > 0 ? <><span className="sub-number">{m}</span> {localize('minuteS')}</> : null
        //const renderSeconds = s > 0 ? <><span className="sub-number">{s}</span> {localize('secondS')}</> : null

        //let hDisplay = h > 0 ? h + (h == 1 ? localize('hourS') : localize('hourS')) : "";
        //let mDisplay = m > 0 ? m + (m == 1 ? localize('minuteS') : localize('minuteS')) : "";
        //let sDisplay = s > 0 ? s + (s == 1 ? localize('secondS') : localize('secondS')) : "";
        return <>{renderHours} {renderMinutes}</>;
    }else{
        let minutes = inSeconds ? min / 60 : min
        const hours = minutes / 60
        const days = Math.floor(hours / 24)
        const renderDays = days > 0 ? <><span className="sub-number">{days}</span> {localize('dayS')}</> : null
        const hoursCount = Math.floor(hours % 24)
        const renderHours = hoursCount > 0 ? <><span className="sub-number">{hoursCount}</span> {localize('hourS')}</> : null
        const minutesCount = Math.floor(minutes % 60)
        const renderMinutes = minutesCount > 0 ? <><span className="sub-number">{minutesCount}</span> {localize('minuteS')}</> : null

        return renderDays || renderHours || renderMinutes ? <>{renderDays} {renderHours} {renderMinutes}</> : ''
    }
}

export const displayShareSheet = () => {
    if (navigator.share) {
        navigator.share({
            title: localize('share'),
            text: localize('shareMsg'),
            url: pwaLink,
        })
            //.then(() => setError('shareSuccess'))
            .catch((error) => console.log('Error sharing', error));
    }
}

export const downloadApp = () => {
    window.open(androidAppLink);
}

export const timeForRetry = (time) => {
    store.dispatch({
        type:SET_TIME_RETRY,
        payload:time
    })
}

export const downloadPwa = (setPopUp) => {
    localStorage.setItem(localStorageKeys.popUp,setPopUp ? '1' : '0')
    const iOSPopUP = store.getState().settings.iOSPopUp
    store.dispatch({
        type:SET_IOS_POPUP,
        payload:!iOSPopUP
    })
}

export const goLive = (serviceID) => {
    getContentInfo(contentType.service, serviceID)
        .then(res => {
            if (res.status === 200) {
                const req = {
                    type: playerTypes.live,
                    chat: true,
                    locked: false,
                    service_id: serviceID,
                }
                getLink(req,res.data.msg)
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const continueWatching = (service_id,purchasedPack,videoId = undefined) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth:auth,
        service_id:service_id,
    }
    if(!!videoId) req['video_id'] = videoId // if from Movie Catg Video
    store.dispatch({
        type:SET_FETCHING,
        payload:true
    })
    const params = jsonToFormData(req)
    axios.post(host + getContinueWatching, params).then(res => {
        if(res.status === 200){
            const service = res.data.msg.service
            const video = res.data.msg.video
            //if(service.service_id === serviceID) {
            //not MultiLayer
            let req = {
                type: playerTypes.video,
                service_id: service.service_id,
                video_id: video.video_id,
                object_name: video.video_name,
                locked: false,
                service:service,
                video:video
            }
            const inPackages = checkVODInPackages(video.video_id)
            if (service.status === 'locked' && inPackages === undefined) {
                if (purchasedPack.length > 0) {
                    for (let i = 0; i < purchasedPack.length; i++) {
                        for (let j = 0; j < purchasedPack[i].types.length; j++) {
                            if (Number(purchasedPack[i].types[j].usr_type_quantity) >= 9999 && purchasedPack[i].types[j].usr_video_type_id === service.is_movie) {
                                req['pkg_usr_id_fk'] = purchasedPack[i].pkg_usr_id
                                getLink(req)
                                break;
                            }
                        }
                    }
                } else {
                    store.dispatch({
                        type:SET_FETCHING,
                        payload:false
                    })
                    showModal(service,video)
                }
            } else {
                if (inPackages) {
                    req['pkg_usr_id_fk'] = inPackages.pkg_usr_id
                    getLink(req)
                } else if (!!video && video.status === 'unlocked') {
                    if (service.is_movie === isMovie.series || service.is_ppv === '0') getSeriesPlaylist(service.service_id, false, video.video_id, video)
                    else getLink(req)
                } else {
                    store.dispatch({
                        type:SET_FETCHING,
                        payload:false
                    })
                    showModal(service,video)
                }
            }
        }
    }).catch(error => {
        store.dispatch({
            type:SET_FETCHING,
            payload:false
        })
    })

}

const showModal = (service,video) => {
    if((service.prices.length > 0 || store.getState().packages.packages.length) && service.is_free != '1'){
        localStorage.setItem(localStorageKeys.visible,'1')
    }else{
        localStorage.setItem(localStorageKeys.visible,'0')
    }
    navigate(Routes.movieTo(video.video_name, service.service_id))
}

export const shareLink = (videoName,service_id = undefined) => {
    //http://play.yabadoo.tv/?Type=Service&Object=5cfa2b085d44a37&Service_type=live
    if (navigator.share) {
        navigator.share({
            title: localize('shareTitle'),
            text: localize('shareMessage')(videoName),
            url: `http://play.yabadoo.tv/?Type=Service&Object=${service_id}&Service_type=live`
            //window.location.href.concat('?share'),
        })
        //.then(() => setError('shareSuccess'))
            .catch((error) => console.log('Error sharing', error));
    }
}

export const loggerMiddleware = store => next => action => {
    let result = next(action)
    console.log('next state', store.getState())
    return result
}
export function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const toHHMMSS = duration => {
    let sec_num = parseInt(duration, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor(sec_num / 60) % 60
    let seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}

export const openPlayer = (service) => {
    const playlistItems = store.getState().playlist.items
    const playItem = playlistItems.find(item=>item.service_id === service.service_id)
    setDstLink('')
    selectPlaylistItem(playItem)
    let url = playItem.is_radio == '1' ? playItem.radio_url : playItem.url
    if (url.indexOf('redirect') > -1){
        let searchParams = new URL(url).searchParams
        localStorage.setItem(localStorageKeys.dstKey, searchParams.get('t'))
        redirectCall(service,url)
    }else {
        localStorage.removeItem(localStorageKeys.dstKey)
    }
}

export const checkIfPlayerOpened = () => {
    if(store.getState().playlist.visible){
        store.dispatch({
            type: PLAYER_SET_VISIBLE,
            payload: false
        })
    }
}

export const secureData = (data,encrypt = false,decrypt = false) => {
    const CryptoJS = require("crypto-js");
    const DataKey = CryptoJS.enc.Utf8.parse("0110011010011001");
    const DataVector = CryptoJS.enc.Utf8.parse("1001100101100110");
    let secureData = ""
    if(decrypt){
        secureData = CryptoJS.AES.decrypt(data, DataKey, {iv: DataVector});
        secureData = CryptoJS.enc.Utf8.stringify(secureData);
    }else{
        secureData = CryptoJS.AES.encrypt(data, DataKey, {iv: DataVector});
    }
    return secureData.toString()
}

export const getCurrentDate = () => {
    var today = new Date()
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
    return date+' '+time
}

export const one10LabsCall = (eventCall,msisdn = undefined) => {
    const auth = store.getState().auth.authToken
    const operatorName = localStorage.getItem(localStorageKeys.operatorName).toLowerCase()

    // - configure the params
    let params = !!sessionStorage.getItem(localStorageKeys.one10LabsParams) ? JSON.parse(sessionStorage.getItem(localStorageKeys.one10LabsParams)) : []
    //set subMethod param
    params["submethod"] = 'Wifi'
    params["ip"] = ''
    params["log_event_name"] = eventCall
    if(!!sessionStorage.getItem(localStorageKeys.number)) params["submethod"] = 'HE'
    if (!!sessionStorage.getItem(localStorageKeys.userIp)) params["ip"] = sessionStorage.getItem(localStorageKeys.userIp)
    //set Number
    if(msisdn) params['msisdn'] = msisdn

    if(eventCall === 'Error') params['desc'] = 'already subscribed'
    if(eventCall === 'Visit') params['desc'] = !!auth ? 'activated' : 'Not activated'
    if(eventCall !== 'Visit') params['opco'] = operatorName

    // - set the one10labs URL
    const url = eventCall === 'Visit' ? oneLabsVisit : eventCall === 'Success' ? oneLabsSuccess : oneLabsError

    makeGetRequestWithParams(url, params).then(data => {
        if (eventCall !== 'Visit'){
            campaignLog(params,'one10labs',data.status + '-' + data.type)
            removeSessionItems()
        }
    }).catch(error => {
        if (eventCall !== 'Visit') {
            campaignLog(params,'one10labs',error.status + '-' + error.message)
            removeSessionItems()
        }
    });
    // axios.get(url,{params}).then(res => {
    //     if(!!auth){
    //         userFeedback('0', `10labsCallSuccess - msisdn=${msisdn} - eventCall=${eventCall}`)
    //     }
    //     // console.log(res.data)
    // }).catch(error => {
    //     //make feedback call api - include error info and msisd
    //     params['error'] = error.message
    //     if(!!auth) userFeedback('0', JSON.stringify(params))
    //     // console.log(error)
    // })

}

const removeSessionItems = () => {
    sessionStorage.removeItem(localStorageKeys.one10LabsParams)
    sessionStorage.removeItem(localStorageKeys.userIp)
    sessionStorage.removeItem(localStorageKeys.pb)
    sessionStorage.removeItem(localStorageKeys.af)
    sessionStorage.removeItem(localStorageKeys.number)
}

function makeGetRequestWithParams(url, params) {
    let paramString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
    let fullUrl = url + '?' + paramString;
    return fetch(fullUrl).then(response => {
        return response;
    });
}

export const configureURLWindowParams = () => {
    if(window.location.hostname === 'yabadoo.mtn.cm') {
        window.location.replace("https://yabadoo.tv")
    }

    //for continue watching Popup - 1st time view
    window.onload = () => {
        if((window.location.pathname === '/home' || window.location.pathname === '/') && !!!sessionStorage.getItem(localStorageKeys.isFirstTime)){
            if(!!!localStorage.getItem(localStorageKeys.liveStreamUsername)){// if pwa open via live stream link no need to show continue watching popup
                setFirstTimeViewed('1')
            }
        }else{
            setFirstTimeViewed('0')
        }
    }

    //show PopUp dialog - inform how to add Pwa as Home app
    const showPopUp = !!!localStorage.getItem(localStorageKeys.popUp) || localStorage.getItem(localStorageKeys.popUp) === '0'
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    if ((window.matchMedia('(display-mode: standalone)').matches) || isInStandaloneMode()) {
        localStorage.setItem(localStorageKeys.popUp,'1')
    }else if ((isChrome && isActive) || (isSafari && !isInStandaloneMode())){
        // if(!showPopUp){
            // const iOSPopUP = store.getState().settings.iOSPopUp
            store.dispatch({
                type:SET_IOS_POPUP,
                payload:showPopUp
            })
        // }
    }

    //access webapp via the referral link
    if(window.location.href.indexOf("?referralCode") > -1 && !!!localStorage.getItem(localStorageKeys.authToken)){
        const url = new URL(window.location.href)
        const referralCode = url.searchParams.get("referralCode")
        localStorage.setItem(localStorageKeys.recevReferral,referralCode)
    }

    //shared Content to open via pwa
    if(window.location.href.indexOf("?share") > -1 && !!!localStorage.getItem(localStorageKeys.authToken)){
        localStorage.setItem(localStorageKeys.shareLink,window.location.href)
    }

    // open shared  link from Android App and User Already Active open it pwa
    else if(window.location.href.indexOf("&Object") > -1) {
        const url = new URL(window.location.href);
        const serviceType = url.searchParams.get("Service_type")
        const type = url.searchParams.get("Type")
        if(serviceType === 'live'){
            if(!!!localStorage.getItem(localStorageKeys.authToken)){// open shared  link from Android App and User Not Active and open pwa
                localStorage.setItem(localStorageKeys.shareLink,`${pwaLink}channel/${url.searchParams.get("Object")}`)
            }else{
                window.location.href = `${pwaLink}channel/${url.searchParams.get("Object")}`
            }
        }else if (type === 'Video'){
            if(!!!localStorage.getItem(localStorageKeys.authToken)){// open shared  link from Android App and User Not Active and open pwa
                localStorage.setItem(localStorageKeys.shareLink,`${pwaLink}vod/${url.searchParams.get("Object")}/${url.searchParams.get("Service")}`)
            }else{
                window.location.href = `${pwaLink}vod/${url.searchParams.get("Object")}/${url.searchParams.get("Service")}`
            }
        }else if (type === 'Bundle'){
            if(!!!localStorage.getItem(localStorageKeys.authToken)){// open shared  link from Android App and User Not Active and open pwa
                localStorage.setItem(localStorageKeys.shareLink,`${pwaLink}bundle/${url.searchParams.get("Object")}`)
            }else{
                window.location.href = `${pwaLink}bundle/${url.searchParams.get("Object")}`
            }
        }
    }

    //open live stream shared link
    if(window.location.href.indexOf("deeplink") > -1){
        window.location.pathname = window.location.pathname.replace('/deeplink','')
        const url = new URL(window.location.href);
        const type = url.searchParams.get("Type")
        const liveSessionId = url.searchParams.get("Session")
        if(type === 'Event'){
            localStorage.setItem(localStorageKeys.liveStreamUsername,url.searchParams.get("Object"))
            if(!!liveSessionId) localStorage.setItem(localStorageKeys.liveSessionId,liveSessionId)
        }
    }

    //Header Enrch. detect the user number
    if(window.location.href.indexOf("number") > -1 && isheaderEnrichment) {
        store.dispatch({
            type: SET_FETCHING,
            payload:false
        })
        setHeaderFetching(false)
        var CryptoJS = require("crypto-js");
        const url = new URL(window.location.href);
        var PREFIX = "number=";
        var number = url.search.substr(url.search.lastIndexOf(PREFIX) + PREFIX.length)
        var DataKey = CryptoJS.enc.Utf8.parse("1322123456127896");
        var DataVector = CryptoJS.enc.Utf8.parse("1326895965465432");
        var decrypted = CryptoJS.AES.decrypt(number, DataKey, {iv: DataVector});
        var decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
        // if(number !== '0' && !!!sessionStorage.getItem(localStorageKeys.number)){
        //     setHeaderEnrichmentData(decrypted)
        // }
        if(!!!sessionStorage.getItem(localStorageKeys.number)){
            setHeaderEnrichmentData(number !== '0' ? decrypted : '0')
        }
    }

    //campaign af & pb - used as params in success code activation call
    if((window.location.href.indexOf('af') > -1 || window.location.href.indexOf('pb') > -1) && !!!localStorage.getItem(localStorageKeys.authToken)){
        const url = new URL(window.location.href);
        const af = url.searchParams.get('af')
        const pb = url.searchParams.get('pb')
        !!af && af !== '' ? sessionStorage.setItem(localStorageKeys.af,af) : sessionStorage.removeItem(localStorageKeys.af)
        !!pb && pb !== '' ? sessionStorage.setItem(localStorageKeys.pb,pb) : sessionStorage.removeItem(localStorageKeys.pb)
    }

    //one10labs campaign link
    if(window.location.href.indexOf('af') > -1){//not authorized user from one10Labs campaign
        const url = new URL(window.location.href);
        const af = url.searchParams.get('af')
        if(af == 'one10labs'){
            const searchParams = new URLSearchParams(new URL(url).search);
            let params = {}
            searchParams.forEach((value, key) => {
                params[key] = value
            })
            sessionStorage.setItem(localStorageKeys.one10LabsParams,JSON.stringify(params))

            // get the user ip address - use in one10labs params
            axios.get('https://api.ipify.org?format=json').then(res => {
                sessionStorage.setItem(localStorageKeys.userIp,res.data.ip)
                one10LabsCall('Visit')
            }).catch(error => {
                one10LabsCall('Visit')
                sessionStorage.removeItem(localStorageKeys.userIp)})
        }
    }
}
