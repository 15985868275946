import React from 'react';
import AppRouter from './routes/AppRouter'
import {Provider} from 'react-redux'
import store from './redux/store'
import './assets/css/main.scss'
import Test from "./routes/Test";
import Head from "./components/Head";
import ReactDOMServer from 'react-dom/server';
import  {Helmet, HelmetProvider } from 'react-helmet-async';
import ShakaPlayer from "shaka-player-react";
import 'shaka-player/dist/controls.css';
import ReactHlsPlayer from 'react-hls-player';

function App() {
    return (
        // <HelmetProvider>
        //     <Helmet>
        //         <title>Turbo Todo</title>
        //         <meta name="description" content="Todos!" />
        //         <meta name="theme-color" content="#008f68" />
        //     </Helmet>
        //     <AppRouter/>
        // </HelmetProvider>
        <Provider store={store}>
            <AppRouter/>
        </Provider>
        // <ReactHlsPlayer
        //     src="https://5d360a57cd213.streamlock.net:8443/6215f8ff3b87199/_definst_/smil:myStream.smil/playlist.m3u8?t=16992614956548ac3792326"
        //     autoPlay={false}
        //     controls={true}
        //     width="100%"
        //     height="auto"
        // />
        // <ReactHLS url="https://5d360a57cd213.streamlock.net:8443/6215f8ff3b87199/_definst_/smil:myStream.smil/playlist.m3u8?t=16992614956548ac3792326" />


);
}


export default App;
