import UA from 'ua-parser-js'
import {bitRates, localStorageKeys, operations} from "./enums";

export const useragent = UA(navigator.userAgent)
const uA = navigator.userAgent.toLowerCase()
export const isAndroid = uA.indexOf("android") > -1
export const deviceLanguage = !!navigator.language ? navigator.language.substr(0, 2) : 'en'


export const isAndroidDevice = /Android/i.test(navigator.userAgent)
export const isIphoneDevice = /iPhone|iPod/i.test(navigator.userAgent)
export const isWindows = /Win/i.test(navigator.platform)
export const isMac = /Mac/i.test(navigator.platform)
export const isChrome = /Chrome|Chromium/i.test(navigator.userAgent)
export const isSafari = /Safari/i.test(navigator.userAgent) && !isChrome

// console.log({isSafari,isChrome,isIphoneDevice,isAndroidDevice,isMac,isWindows})

// const vod = http://dst.apli.tv:1935/vod/_definst_/smil:http1/vod/da8f94affd7b5538d47f78b753c9a37a.mp4.smil/playlist.m3u8?t=5d131ac3e49ca&a=5accadbac72fe14
// const live = http://dst.apli.tv:1935/5b925c82355e243/_definst_/smil:myStream.smil/playlist.m3u8?t=5d131aec294f7
// export const osName =  useragent.os.name ? useragent.os.name : ''
// export const osVersion = '6.0.2'

export const loadMorePageSize = localStorage.getItem(localStorageKeys.itemsinterval)
export const deviceTypePWA = useragent.device.type
export const appVersion = '3.0.7' //2.0.25 for yabadoo //2.0.17 for mtnsyr //2.0.19 for ishow
export const osName = 'Android'
export const osVersion = useragent.browser.version ? useragent.browser.version : ''
export const deviceType = 'Mobile'
export let currentOperation = operations.yabadoo//process.env.REACT_APP_OPERATION
export const isYabadooOperation = currentOperation === operations.yabadoo || currentOperation === operations.tmcel || currentOperation === operations.mtnCameroon || currentOperation === operations.vodacom || currentOperation === operations.orange || operations.aplitv

if(isYabadooOperation)
{
    currentOperation = localStorage.getItem(localStorageKeys.operatorName)
    if(currentOperation === null || currentOperation === operations.aplitv){
        currentOperation = operations.yabadoo
        localStorage.setItem(localStorageKeys.countryName,operations.yabadoo)
        localStorage.setItem(localStorageKeys.operatorName,operations.yabadoo)
    }
}else{
    localStorage.removeItem(localStorageKeys.countryName)
    localStorage.removeItem(localStorageKeys.operatorName)
}



let scheme = 'https://'
let domain = 'cam.apli.tv'
let ivyLink = scheme + domain + "/media/Ivy/ivy_welcome.png"
let apiEndpoint = '/crudAPI_mobile/'
let currency = "$"
let isRenew = '0'
let operationChatEnabled = false
let phoneNumber = {prefix: '961', limit: 11}
let doubleOpt = false // if needs double popup when purchasing
let hideHistoryOrderId = false
let supportEnabled = false
let logoutEnabled = true
let chatConnectLink = username => `${username}@${domain}/web/${username}-web`
let chatRoomLink = id => `${id}@conference.${domain}`
let chatEnableSocket = true
let chatStropheLink = chatEnableSocket ? `ws://${domain}:7443/ws/` : `https://${domain}:7443/http-bind/`
let automaticRenewalMsg = false
let displayAutoRenew = false
let feedbackEnabled = true
let supportedLanguages = {en: 'en', ar: 'ar', fr: 'fr'}
let subtitleLanguages = {arabic: 'ara', english: 'eng', french: 'fre', none: 'none'}
let defaultSubtitleLanguage = subtitleLanguages.none
let defaultBitRate = bitRates.auto
let isClipsRootPage = false // for mtnclip to be the root page is the clips page, used in AppRouter and in devices page
let myContentEnabled = true
let historyEnabled = true
let apliTVWebsite = false//if copyright exist a website page link
let referralEnabled = true
let shareAsReferral = false//change share button action and title
let pwaLink = 'https://pwa.apli.tv/'
let supportLink = 'https://apli.tv/'
let isOneTime = true//for enable isrenew and oneTime scenario
let menuTitleIn = false //for title in center menu bar (ex: NexttelTv)
let expireEnabled = false// for enable red expired date
let smsValid = true//for alAwal since no SMS code sending
let isloadingImages = true//loading GIF
let isDoublePlay = false//double Play for senegal
let dstDomain = 'https://dst2.shashatcomtv.com/redirect/'//for redirect call
let dstSmile = '/smil:myStream.smil?type=m3u8&t=' //for redirect call
let isShareEnabled = false
let continueWatchingID = '111111111cw'
let downloadAPK = false
let androidAppLink = 'https://goo.gl/rf7HH7'
let iphoneAppLink = 'itms-apps://apps.apple.com/app/id1531282025'
let isheaderEnrichment = true
let isActive = true// to show popUp dialog over chrome pc (how to add pwa)
let logoutDialog = true
let facebookLink = ''
let instagramLink = ''
let privacyPolicyURL = ''

if (currentOperation === operations.aplitv) {
    isDoublePlay = true
    apliTVWebsite = true
    scheme = 'https://'
    domain = 'cam.apli.tv'//cam.apli.tv cam.ishow.sy
    apiEndpoint = '/crudAPI_mobile/'
    currency = "USD"
    dstDomain = 'https://dst.apli.tv/redirect/'
    isRenew = '1'
    operationChatEnabled = true
    phoneNumber = {prefix: '961', limit: 11}//{prefix: '961', limit: 11}
    doubleOpt = true
    hideHistoryOrderId = false
    supportEnabled = true
    expireEnabled = true
    logoutEnabled = true
    chatStropheLink = chatEnableSocket ? `wss://${domain}:7443/ws/` : `https://${domain}:7443/http-bind/`
    displayAutoRenew = false
    referralEnabled = true
    isShareEnabled = true
    isActive = true
} else if (currentOperation === operations.ishow) {
    supportedLanguages = {en: 'en', ar: 'ar'}
    subtitleLanguages = {arabic: 'ara', english: 'eng', none: 'none'}
    scheme = 'https://'
    domain = 'cam.ishow.sy'//ishow.sy
    dstDomain = 'https://lb1.ishow.sy:4450/redirect/'
    logoutDialog = false
    apiEndpoint = '/crudAPI_mobile/'
    currency = "SYP"
    isRenew = '1'
    operationChatEnabled = false
    phoneNumber = {prefix: '963', limit: 12}
    doubleOpt = true
    hideHistoryOrderId = false
    supportEnabled = false
    displayAutoRenew = true
    apliTVWebsite = false
    expireEnabled = true
    supportLink = ''
    isOneTime = false
    pwaLink = 'https://iptv.ishow.sy/'
    referralEnabled = true
    isShareEnabled = true
    shareAsReferral = true
    logoutEnabled = true
    androidAppLink = 'https://www.syriatel.sy/'
    downloadAPK = true
} else if (currentOperation === operations.mtnCameroon) {
    androidAppLink = 'https://goo.gl/rf7HH7'
    shareAsReferral = true
    // supportLink = 'https://www.mtn.cm/yabadoo-livechat/livechat'
    supportLink = 'https://m.me/yabadoocameroon'
    pwaLink = 'https://play.yabadoo.tv/'
    apliTVWebsite = true
    subtitleLanguages = {english: 'eng', french: 'fre', none: 'none'}
    supportedLanguages = {en: 'en', fr: 'fr'}
    scheme = 'https://'
    //chatRoomLink = id => `${id}@conference.mtntv.mtncameroon.net`
    domain = 'mtntv.mtncameroon.net'
    apiEndpoint = '/crudAPI_mobile/'
    // domain = 'cam.apli.tv'
    // apiEndpoint = '/crudAPI_mobile_m/'
    downloadAPK = true
    currency = "CFA"
    isRenew = '0'
    operationChatEnabled = true
    phoneNumber = {prefix: '237', limit: 12}
    doubleOpt = true
    hideHistoryOrderId = true
    supportEnabled = true
    logoutEnabled = true
    automaticRenewalMsg = true
    displayAutoRenew = false
    chatStropheLink = chatEnableSocket ? `wss://${domain}:7443/ws/` : `https://${domain}:7443/http-bind/`
    referralEnabled = true
    isDoublePlay = true
    isShareEnabled = true
    isActive = true
    facebookLink = 'https://www.facebook.com/YabadooCameroon'
    instagramLink = 'https://www.instagram.com/yabadoocameroon/'
} else if (currentOperation === operations.tmcel || currentOperation === operations.vodacom) {

    if(currentOperation === operations.tmcel){
        domain = 'cam.tmcel.co.mz'
        dstDomain = 'https://dst1.tmcel.co.mz/redirect/'
    }else{
        domain = 'cam.vm.co.mz'
        dstDomain = 'https://dst1.vm.co.mz/redirect/'
        privacyPolicyURL = 'https://apli.tv/vodacomprivacypolicy.htm'
    }

    facebookLink = 'https://www.facebook.com/YabadooMozambique'
    instagramLink = 'https://www.instagram.com/yabadoomozambique/'
    shareAsReferral = true
    supportLink = 'https://m.me/yabadoomozambique'
    pwaLink = 'https://play.yabadoo.tv/'
    apliTVWebsite = true
    subtitleLanguages = {english: 'eng', portuguese: 'pt', none: 'none'}
    supportedLanguages = {en: 'en', por:'pt'}
    scheme = 'https://'
    //chatRoomLink = id => `${id}@conference.mtntv.mtncameroon.net`
    apiEndpoint = '/crudAPI_mobile/'
    currency = "MT"
    isRenew = '0'
    operationChatEnabled = true
    phoneNumber = {prefix: '258', limit: 12}
    doubleOpt = true
    hideHistoryOrderId = true
    supportEnabled = true
    logoutEnabled = true
    automaticRenewalMsg = true
    displayAutoRenew = false
    chatStropheLink = chatEnableSocket ? `wss://${domain}:7443/ws/` : `https://${domain}:7443/http-bind/`
    referralEnabled = true
    isDoublePlay = true
} else if (currentOperation === operations.orange) {
    androidAppLink = 'https://goo.gl/rf7HH7'
    shareAsReferral = true
    // supportLink = 'https://www.mtn.cm/yabadoo-livechat/livechat'
    supportLink = 'https://m.me/yabadoocameroon'
    pwaLink = 'https://play.yabadoo.tv/'
    apliTVWebsite = true
    subtitleLanguages = {english: 'eng', french: 'fre', none: 'none'}
    supportedLanguages = {en: 'en', fr: 'fr'}
    scheme = 'https://'
    //chatRoomLink = id => `${id}@conference.mtntv.mtncameroon.net`
    domain = 'cam.orange.cm'
    dstDomain = 'https://dst1.orange.cm/redirect/'
    apiEndpoint = '/crudAPI_mobile/'
    currency = "CFA"
    isRenew = '0'
    operationChatEnabled = true
    phoneNumber = {prefix: '237', limit: 12}
    doubleOpt = true
    hideHistoryOrderId = true
    supportEnabled = true
    logoutEnabled = true
    automaticRenewalMsg = true
    displayAutoRenew = false
    chatStropheLink = chatEnableSocket ? `wss://${domain}:7443/ws/` : `https://${domain}:7443/http-bind/`
    referralEnabled = true
    isDoublePlay = true
    isShareEnabled = true
    downloadAPK = true
    isActive = true
    facebookLink = 'https://www.facebook.com/YabadooCameroon'
    instagramLink = 'https://www.instagram.com/yabadoocameroon/'
} else if (currentOperation === operations.yabadoo) {
    pwaLink = 'https://play.yabadoo.tv/'
    subtitleLanguages = {french: 'fre', english: 'eng', none: 'none'}
    isActive = true
}

export const avatarLink = username => `${scheme + domain}/media/profiles/${username}?id=${new Date().getTime()}`


export {
    apliTVWebsite, ivyLink, supportedLanguages, subtitleLanguages, scheme, domain, apiEndpoint, currency, isRenew, operationChatEnabled, phoneNumber, doubleOpt, hideHistoryOrderId, supportEnabled,
    chatConnectLink, chatRoomLink, chatStropheLink, logoutEnabled, automaticRenewalMsg, displayAutoRenew, privacyPolicyURL,
    defaultSubtitleLanguage,defaultBitRate,isShareEnabled, feedbackEnabled, isClipsRootPage, myContentEnabled, historyEnabled, referralEnabled, pwaLink, shareAsReferral, supportLink, isOneTime, menuTitleIn, expireEnabled, isloadingImages, smsValid, isDoublePlay, continueWatchingID, dstSmile, dstDomain, androidAppLink,isheaderEnrichment, isActive, downloadAPK, iphoneAppLink, logoutDialog, facebookLink, instagramLink
}
