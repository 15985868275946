import {
    LIVE_STREAM_LIST,
    SET_FOLLOW,
    SET_SESSION_REPORT_OPTIONS,
    SET_REPORT_MODEL,
    SET_REPORT_MODEL_INSERT,
    SET_VIEWERS_NUMBER,
    SET_SESSION_END_USERINFO,
    SET_SESSION_END_VIEWMODEL,
    SET_VIEWERS_LIST,
    SET_VIEWERS_LIST_STATUS,
    CHANGE_FOLLOW_STATE_VIEWERS_LIST,
    SET_LIVE_LOADING_FETCH,
    ADD_REMOVE_SINGLE_ITEM
} from "../actions/types";


const initialState = {
    liveStreamList:[],
    goLivePermission:false,
    following:0,
    reportOptions:[],
    showReportModel:false,
    showReportModelInsert:false,
    viewersNumber:undefined,
    sessionEndUserInfo:{username:'',nickname:''},
    sessionEndModelView:false,
    viewerList:[],
    viewerListModelStatus:false,
    fetchLoading:false
}

const liveStreamReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIVE_STREAM_LIST:
            return {
                ...state,
                liveStreamList:action.payload,
                goLivePermission:true
            }
        case SET_FOLLOW:
            return {
                ...state,
                following:action.payload
            }
        case SET_SESSION_REPORT_OPTIONS:
            return {
                ...state,
                reportOptions:action.payload,
                showReportModel: true
            }
        case SET_REPORT_MODEL:
            return {
                ...state,
                showReportModel: action.payload
            }
        case SET_REPORT_MODEL_INSERT:
            return {
                ...state,
                showReportModelInsert: action.payload
            }
        case SET_VIEWERS_NUMBER:
            return {
                ...state,
                viewersNumber:action.payload
            }
        case SET_VIEWERS_LIST:
            return {
                ...state,
                viewerList:action.payload,
                viewerListModelStatus: true
            }
        case SET_SESSION_END_USERINFO:
            return {
                ...state,
                sessionEndUserInfo:{
                    ...state.sessionEndUserInfo,
                    username: action.payload.username,
                    nickname: action.payload.nickname
                }
            }
        case SET_SESSION_END_VIEWMODEL:
            return {
                ...state,
                sessionEndModelView:action.payload
            }
        case SET_VIEWERS_LIST_STATUS:
            return {
                ...state,
                viewerListModelStatus:action.payload
            }
        case CHANGE_FOLLOW_STATE_VIEWERS_LIST:
            return {
                ...state,
                viewerList:[
                    ...state.viewerList.slice(0,action.payload.index),
                    {...state.viewerList[action.payload.index], is_follow:{msg:action.payload.msg}},
                    ...state.viewerList.slice(action.payload.index + 1),
                ]
            }
        case SET_LIVE_LOADING_FETCH:
            return {
                ...state,
                fetchLoading:action.payload
            }
        case ADD_REMOVE_SINGLE_ITEM:
            return {
                ...state,
                liveStreamList: action.payload.insert === 1 ? [action.payload.liveObject,...state.liveStreamList] : state.liveStreamList.filter((item,index) => index !== action.payload.indexToRemove)
            }
        default:
            return state
    }
}
export default liveStreamReducer
