import {MESSAGES_ADD_MESSAGE, MESSAGES_SET_MESSAGES, MESSAGES_UNSET_MESSAGES} from "../actions/types";

const initialState = {
    message: {},
    messages: []
}

const chatReducer = (state = initialState, action) => {

    switch (action.type) {
        case MESSAGES_UNSET_MESSAGES:
            return {
                ...state,
                messages: [],
                message: {}
            }
        case MESSAGES_SET_MESSAGES:
            return {
                ...state,
                messages: action.payload
            }
        case MESSAGES_ADD_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload],
                message: action.payload
            }
        default:
            return state

    }

}

export default chatReducer