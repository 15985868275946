import React from 'react'
import {FiStar} from "react-icons/fi";
import {Link} from "@reach/router";
import {addDefaultSrc, addRTL, continueWatching} from "../../../helpers/functions";
import {continueWatchingPlayIcon, placeholderImage} from "../../../assets/img/exports";
import store from "../../../redux/store";
import {FiPlay} from "react-icons/fi";
import {useSelector} from "react-redux";

const SeriesCard = props => {
    const hotBadge = require('../../../assets/img/icons/hot.png')
    const freeBadge = require('../../../assets/img/icons/free.png')
    const newBadge = require('../../../assets/img/icons/newservice.png')
    const img = !!props.img ? props.img : placeholderImage
    const star = !!props.star ? props.star : false
    const title = !!props.title ? props.title : 'Title'
    const desc = !!props.desc ? props.desc.length > 50 ? props.desc.substr(0, 50) + '...' : props.desc : ''
    const route = !!props.route ? props.route : '#'
    const {rtl} = store.getState().settings
    const bookmark = !!props.isBookmark ? props.isBookmark : undefined
    const seriesServiceID = !!props.seriesServiceID ? props.seriesServiceID : undefined
    const found = !!store.getState().bookmark.bookmarkServices ? store.getState().bookmark.bookmarkServices.findIndex((item) => item === seriesServiceID) : -1
    const {purchasedPackages} = useSelector(state => state.packages)

    let badge = undefined
    if (!!props.badge && props.badge === '1') badge = newBadge
    if (!!props.badge && props.badge === '2') badge = hotBadge
    if (!!props.badge && props.badge === '3') badge = freeBadge


    return (
        <div className='series-card-container'>
            {(!!bookmark && bookmark) || found !== -1 ? <img src={continueWatchingPlayIcon} className='series-card-continue-img' onClick={() => continueWatching(seriesServiceID,purchasedPackages)}/> : null}
            <Link to={route} state={{title}}>
                <img src={badge} alt="" className='series-card-badge'/>
                {(!!bookmark && bookmark) ? null : <div className='series-card-overlay'>
                    <p>{desc}</p>
                </div>}
                <img src={img} alt="" className='series-card-img' onError={(event) => addDefaultSrc(event)}/>
                <div className='series-card-footer'>
                    {star ? <FiStar className='series-card-star'/> : null}
                    <p className={`series-card-title ${addRTL(rtl)}`} title={title}>{title}</p>
                    {/*<Tooltip*/}
                    {/*    placement="bottom"*/}
                    {/*    title={title}*/}
                    {/*    className="tooltip"*/}
                    {/*>*/}
                    {/*    <p className={`series-card-title ${addRTL(rtl)}`} title={title}>{title}</p>*/}
                    {/*</Tooltip>*/}
                </div>
            </Link>
        </div>
    )
}

export default SeriesCard
