import React from 'react'
import {addRTL, localize, renderMinutes} from "../../helpers/functions";
import {currency, currentOperation} from "../../helpers/constants";
import {operations, planModelTypes} from "../../helpers/enums";
import store from "../../redux/store";

const SubscriptionItem = props => {
    const hours = props.hours ? props.hours : ''
    const price = props.price ? props.price : 0
    const fullView = props.fullView ? props.fullView : undefined
    const streamLimit = props.streamLimit ? props.streamLimit : undefined
    const discount = props.discount ? props.discount : undefined
    const onClick = props.onClick ? props.onClick : undefined
    const gift = props.gift ? props.gift : false
    const localizedCurrency = localize(currency)
    const playModel = !!props.playModel ? props.playModel : null
    const oneTime = !!props.oneTime ? props.oneTime : undefined
    const isRenew = !!props.isRenew ? props.isRenew : undefined
    const {rtl} = store.getState().settings

    let renderText = ''

    if (fullView === 1){ //means ppv
        renderText = ''
    }else if (hours === '0' && playModel !== planModelTypes.streamingLimit){
        renderText = localize('permanentAccess')
    }else{
        const days = Math.floor(hours / 24)
        const renderDays = days > 0 ? <><span className="sub-number">{days}</span> {localize('dayS')}</> : null
        const hoursCount = Math.floor(hours % 24)
        const renderHours = hoursCount > 0 ? <><span className="sub-number">{hoursCount}</span> {localize('hourS')}</> : null
        renderText = !!renderDays || !!renderHours ? <>{localize('validFor')} {renderDays} {renderHours}</> : ''

    }


    return (
        <div className={`sub-item ${addRTL(rtl)}`} key={Math.random()} onClick={onClick}>
            <div className='sub-item-left'>
                {!!fullView ? <p><span className="sub-number">{fullView}</span> {localize('fullView')}</p> : null}
                {!!streamLimit ? <p><span className="sub-number">{renderMinutes(streamLimit, true)}</span></p> : null}
                <p>{renderText}</p>
                <p className='auto-renew'>{currentOperation === operations.mtnSyria && !!!gift && !!oneTime && !!isRenew ? oneTime === '0' && isRenew === '1' ? localize('autoRenewed2') : localize('notAutoRenewed2') : null}</p>
                <p>{!!props.isDoublePlay ? `${props.isDoublePlay}` :  null}</p>
            </div>
            <button className='sub-item-right'>
                <p><span className="sub-number">{price} {localizedCurrency}</span></p>
                <p>{localize(gift ? 'gift' : 'buy')} {!!discount ? discount + '%' : ''}</p>
            </button>
        </div>
    )
}

export default SubscriptionItem
