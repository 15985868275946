import {operations} from "../../helpers/enums";
import {currentOperation} from "../../helpers/constants";

const fr = {
    shareMessage:(serviceName) => `Regardez ${serviceName} sur yabadoo `,
    shareTitle:'',
    appTitle: 'Yabadoo',
    Or:'OU',
    enjoyableEntertainment: 'Enjoyable Entertainment',
    loginTextFirst: 'Enjoyable',
    loginTextSecond: 'Entertainment',
    search: 'Rechercher',
    searchMsg: `Demandez et vous trouverai...`,
    movies: 'Films',
    refreshTitle:'Obtenez la dernière version',
    refreshDesc:'Une nouvelle version est disponible. Rafraîchissez maintenant et profitez d\'une meilleure expérience',
    movie: 'Film',
    series: 'Séries',
    clips: 'Clips',
    clip: 'Clip',
    plays: 'Plays',
    play: 'Play',
    home: 'Accueil',
    myContent: 'Mes Contenus',
    theContent: 'Des Contenus',
    favorites: 'Favoris',
    history: 'Historique',
    feedback: 'Mon Avis',
    support: 'Support',
    settings: 'Paramètres',
    go: 'Aller',
    contactUs: 'Contactez le support',
    autoRenew: 'Renouvellement automatique',
    autoRenewed: 'Renouvellement automatique',
    autoRenewMsg: 'Les renouvellements automatiques se font à 9:00, 12:00 et 18:00',
    feedbackMsg: `Que pensez-vous de l'application?`,
    feedbackInput: `Merci d'avance pour vos propositions.`,
    feedbackSuccessMsg: 'Merci, nous apprécions vos commentaires.',
    feedbackFillMsg: 'Veuillez remplir le formulaire de commentaires avant de soumettre.',
    submit: 'Envoyer',
    readMore: 'Lire la suite',
    readLess: 'Lire moins',
    notifications: 'Notifications',
    devices: 'Equipements',
    acceptNotifications: `J'accepte d'accepter les notifications push`,
    acceptNotificationsMsg: `Les notifications incluent les derniers programmes télévisés, films, suggestions personnalisées et divers autres sujets`,
    selectLanguage: 'Choisir la langue',
    pressToDeactivate: 'Appuyez pour désactiver',
    linkDevice: 'Relier un équipement',
    linkDeviceMsg1: `Veuillez utiliser le code ci-dessous pour utiliser ce compte avec d'autres équipements: `,
    code: 'Code:',
    linkDeviceMsg2: 'Pour utiliser un autre compte avec cet équipement, veuillez entrer le code de ce compte ci-dessous',
    ok: 'OK',
    myDevices: `Veuillez appuyer sur l'appareil que vous souhaitez désactiver`,
    userProfile: 'Liste des équipements',
    paymentMethod: 'Mode de paiement:',
    deactivate: 'Désactiver',
    deactivateDevice: 'Désactiver équipement',
    deviceId: `Identifiant de l'équipement:`,
    deviceType: `Type d'équipement:`,
    deviceOS:`Système d'exploitation:`,
    deviceOSVersion: `Version du Système d'exploitation:`,
    Version:'Version',
    deviceAppVersion: `Version de l'application:`,
    phoneNumber: 'Numéro de téléphone:',
    verificationCode: `Code d'activation:`,
    verificationCodeWrong:`Vous n'avez pas reçu de SMS? Veuillez réessayer en réinitialisant et si cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème.`,
    verificationCodeWrongTitle:'Oups, le code que vous avez entré est incorrect.',
    sendCode: 'Envoyer',
    register: 'Bienvenue à Yabadoo',
    reset: 'Réinitialiser',
    fromPhoneCredit: 'Crédit téléphone',
    loadMore: 'Load more',
    playlist: 'Playlist',
    youMayAlsoLike: 'Vous pourriez aussi aimer',
    movieS: 'Film(s)',
    youGet: 'Vous Obtenez',
    theyGet: 'Ils Obtiennent',
    dayS: 'jour(s)',
    buy: 'Acheter',
    gift: 'Offrir',
    watch: 'Regarder',
    validFor: 'Valable',
    willDeducted: 'sera déduit de votre forfait',
    subscription: 'Offres',
    packages: 'Pack',
    watchFromYourPackages: `Regarder à partir d'un bouquet`,
    ppv: 'Louer',
    fullView: 'Visionnage',
    hourS: 'heure(s)',
    minuteS: 'minute(s)',
    secondS:'seconde(s)',
    playS: 'Play(s)',
    clipS: 'Clip(s)',
    plan: 'Plan',
    orderId: 'Identifiant',
    paidOn: 'Payé le',
    videos: 'Vidéos',
    expiresWithin: 'Expiré Dans',
    expired:'Expire Sur',
    remainingDuration: 'Temps restant:',
    currentDevice: 'Cet équipement',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    episodes: 'Episode(s)',
    episode: 'Episode',
    giftNumber: 'Numéro de cadeau...',
    live: 'En direct',
    typeMessage: 'Entrez un message...',
    permanentAccess: '(Accès permanent).',
    skip: 'PASSER',
    purchaseConfirm: 'Etes-vous sûr que vous souhaitez acheter?',
    giftConfirm: (name) => `Veuillez confirmer que vous souhaitez envoyer ${name} en cadeau`,
    price: 'Prix:',
    validity: 'Validité:',
    waitSeconds: (seconds) => `Veuillez patienter ${seconds} secondes...`,
    subscribeToEnableChat: 'Abonnez-vous pour activer le chat',
    share: 'Partager',
    SHARE: 'PARTAGER',
    shareMsg: `Avec Yabadoo, vous pouvez profiter de plus de 40 chaînes de télévision en direct sur votre téléphone intelligent sans utiliser vos données`,
    shareReferral1:`Avec Yabadoo, vous pouvez profiter de la télé et radio en direct sans utiliser vos données! Cliquez sur le lien pour vous inscrire et bénéficiez de 30 jours gratuits! `,
    shareReferral2:(referralCode) => `Utilisez le ref-code ${referralCode} et obtenez une journée de télévision gratuite!`,
    noNotifications:'Pas de notifications',
    noHistory: `Vous n'avez encore rien acheté`,
    noFavorites: `Vous n'avez pas encore de contenus favoris`,
    noMyContent: `Il n'y a pas de contenu acheté à afficher.`,
    enterGiftNumber: 'Veuillez entrer un numéro',
    giftSuccess: 'Votre cadeau a été livré avec succès. Continuez votre bon travail!',
    giftSuccessTitle: `Vous venez de rendre quelqu'un très heureux!`,
    send:'ENVOYER',
    selectSeason:'Sélectionnez...',
    searchKeyLength:`S'il vous plaît entrer au moins 3 caractères`,
    unlimited:'Illimitée',
    retry:'Réessayer',
    exit:'Quitter',
    selectCaption:'Choisir Caption',
    selectBitRate:'Choisir La Qualite',



    //Unfound
    wrong:'Quelque chose a mal fonctionné, veuillez réesayer plus tard',
    alsoAvailable:'ou acheter en Bouquets',
    alsoAvailableGift:'ou cadeau en Bouquets',
    msgToAllowPurchese: 'Pour regarder ce clip, veuillez envoyer CLIP au 1055. Amusez-vous!',
    notValidphoneNumber:`Le numéro de téléphone ne semble pas être un numéro valide. Veuillez vérifier et réessayer, si cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème.`,
    unexpectedError: 'Erreur inattendue',
    noItemsOnTheListTitle:'Attention: Pas de chaînes actifs.',
    noItemsOnTheList: `Acheter au moins une chaîne ou contacter votre fournisseur de service`,
    errorLoadingVideo: 'Erreur lors du chargement de la vidéo',
    playerAlreadyOpen: 'Le player est déjà ouvert',
    chooseValidFile: 'Veuillez choisir un fichier valide ...',
    error404: 'Vous semblez perdu ... essayez de chercher quelque chose ...',
    unknownError: 'Erreur inconnue, vérifiez votre réseau',
    aboutUs: 'À propos de nous',
    privacyPolicy: 'Politique de confidentialité',
    termsOfUse: `Conditions d'utilisation`,
    faq: 'FAQ',
    copyright1: 'Copyright © 2020 ',
    copyright2:'ApliTV.',
    copyright3:'Tous les droits sont réservés',
    vodPackages: 'VOD Paquets',
    vodCategories: 'VOD Catégories',
    seasons: 'Saisons',
    chooseSeason: 'Choisissez la saison pour voir les détails',
    no: 'Non',
    yes: 'Oui',
    empty: 'Vide',
    change: 'Changer',
    delete: 'Supprimer',
    changeAvatarMsg: 'Choisissez une option',
    updateAvailable: 'Une nouvelle mise à jour est disponible! Veuillez cliquez pour profiter des dernières fonctionnalités et avantages.',
    refresh: 'Rafraîchir',
    loading: 'Chargement...',
    SYP: 'SYP',
    CFA: 'FCFA',
    MT:'MT',
    USD: '$',
    watchConfirm: 'Êtes-vous sûr de vouloir regarder?',
    name: 'Prénom',
    deleteNotificationsConfirm: 'Etes-vous sûr de vouloir supprimer les notifications? ',
    logout: 'Out',
    seeMore: 'Voir plus',
    seeLess: 'Voir moins',
    pleaseRegisterFirst: `Veuillez vous inscrire d'abord`,
    noData: 'Pas de données',
    chooseyourCountry:'Choisis le pays',
    chooseyourOperator:'Choisis lopérateur',
    please_try_again_laterTitle:`Eh bien, c'est embarrassant;`,
    please_try_again_later:`Il semble que nous ayons des problèmes pour vous connecter au service le plus génial de cette planète! Veuillez réessayer dans quelques minutes, si cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème.`,
    feedback_title:'Parfait!',


    login:'Créer mon compte',
    journey_text:' ',
    like_it:`J'aime bien`,
    love_it:`J'adore!`,
    not_realy:'Bof!',

    unauthorized_access:'Accès non autorisé!',
    owened_content_subtitle:`Actualisez ou rouvrez l'application, puis réessayez d'accéder à ce contenu, et si cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème.`,
    owened_content_subtitleTitle:'Oh wow, il semble que vous possédiez déjà ce contenu!',
    not_operator_number:`Il semble que la personne à qui vous envoyez le cadeau n'est  pas un utilisateur MTN`,
    not_operator_numberTitle:`Oups, nous n'avons pas pu envoyer votre cadeau`,
    nonetwork:'Veuillez vérifier votre connexion et réessayer plus tard',
    noContent:`Il n'y a pas de contenu acheté`,
    setlanguageFailed:'Erreur lors du changement de langue',


    autorenewFailed:`Nous n'avons pas pu enregistrer vos modifications`,
    autorenewFailedDes:`Il peut s'agir d'un problème de connectivité, veuillez réessayer et s'il échoue toujours, contactez l'assistance pour vous aider.`,
    notValidphoneNumberTitle:`Oups, nous n'avons pas pu traiter votre numéro de téléphone`,
    notValidphoneNumberHint:`Numéro de téléphone non valide`,
    nonetworkTitle:'Échec de connexion',


    //new
    deviceExceed:`Vous avez atteint la limite maximale de périphériques enregistrés autorisés. Veuillez désactiver l'un des appareils pour continuer.`,
    no_balanceTitle:'Échec',
    no_balance:'Désolé, la transaction a échoué. Veuillez recharger votre compte.',

    gift_already_owened:`Il semble que la personne à qui vous envoyez le cadeau possède déjà ce contenu, n'hésitez pas à envoyer autre chose.`,
    gift_already_owenedTitle:`Oups, nous n'avons pas pu envoyer votre cadeau`,
    renewed_note:`Votre credit sera debite automatiquement pour renouveler votre abonnement. Si vous ne souhaitez pas renouveler, veuillez visiter la page "Mes contenus" et désactiver le renouvellement automatique.`,
    shareSuccess:`Partage réussi`,
    //streamlimit
    reachLimit:'Vous avez atteint votre limite de streaming, veuillez acheter plus de temps pour continuer à profiter de Yabadoo',
    reachLimit0:'Vous avez atteint votre limite quotidienne pour cette chaîne. Veuillez réessayer demain',
    reachLimit01:'Vous avez atteint votre limite de diffusion pour cette chaîne. Veuillez réessayer demain',
    deactivateSuccessTitle: 'Succès',
    deactivateSuccess: 'Vous vous êtes déconnecté avec succès de cet appareil',

    Message:'Welcome to Yabadoo, the home of the best local and regional video and TV content. Congrats! Because you’ve successfully activated your account, you have won a 7 days subscription to enjoy streaming STV, Canal2 Movies and CRTV without using your internet MB!',
    Title:'Hi, I`m Ivy. Your personal assistant',
    Object:'33',
    Image:'https://cam.mtnsyr.com/media/Ivy/ivy_welcome.png',
    deactiveDevice: 'Veuillez saisir votre code',


    watchFromBundle:'Pour visionner ce contenu, priere de souscrire a une de ces bouquets.',
    giftFromBundle:'Choisi une des bouquets ci-dessous pour envoyer comme cadeau.',
    next:'Next',

    referral_code_checkbox:`J'ai un code de parrainage`,
    enter_referral_code:`Entrez référence code`,
    referral_code:`Référence Code`,
    referral_title:'TV gratuit!',
    referral_description:`Partagez Yabadoo avec vos amis et accédez gratuitement à la télévision chaque fois qu'un nouvel ami s'inscrit en utilisant votre code.`,

    menuTitle:'Yabadoo',
    continueWatching: 'Continuer À Regarder',
    install:'Installer ',
    justTap:'Appuyez simplement sur ',
    thenAddToHomeScreen:` Puis 'Sur l'écran d'accueil'`,
    addToHomeScreen:` Pour ajouter PWA à l'écran d'accueil`,
    notShowAgain:'Ne pas montrer de nouveau',
    downloadAndroid:`Téléchargez l'application mobile`,
    dontShowAgain:`Ne plus afficher cette boîte de dialogue`,
    haveSubscriptionTitle:'Oh, il semble que vous ayez déjà un abonnement actif à ce contenu',
    haveSubscriptionText:'Veuillez contacter le service client pour réactiver votre accès.',
    logout_title:'Êtes-vous sûr de vouloir vous déconnecter ?',
    logout_description:`n.b: La déconnexion n'arrête pas vos abonnements actifs, vous pouvez les gérer depuis l'onglet Mon contenu.`,
    operatorName:'Réseau',
    startingIn:(seconds) => `À partir de ${seconds} ...`,
    nickname:'Entrez votre nom',
    description:'Qui êtes vous?',
    save:'Enregistrer',
    proposals:'Propositions pour vous',
    bundles: 'BOUQUETS',
    channels: 'LIVE',
    proposalFillHint:`Remplissez-le en cliquant sur 'ajouter au favori' dans la page de détails`,
    noSearchTitle:'Désolé, aucun résultat trouvé',
    noSearchHint:'Réessayez en utilisant des mots différents',
    Profile:'Profil',
    radio:'Radio',
    radioHint:`Génial, vous êtes maintenant en mode Radio! Pour revenir au mode TV (vidéo), veuillez appuyer sur l'icône TV en haut à droite.`,
    startWatching:'Commencer à Regarder',
    loyalityStreakTitle:'Cadeau de fidélité',
    loyalityStreakDesc:`Connectez-vous à yabadoo 6 jours successifs et bénéficiez d'un jour d'accès gratuit à notre plus grand bouquet pour vous remercier de votre fidélité. Nous vous aimons!`,
    loyalityStreak0:'Un nouveau départ...',
    loyalityStreak1:'Amène-toi!!!',
    loyalityStreak2:`WoW tu est un peu plus prêt.`,
    loyalityStreak3:`Vas-y, Champione. Tu y es presque.`,
    loyalityStreak4:'Tu es très très proche.',
    loyalityStreak5:'5 jours cochés!!! Connectez-vous demain pour votre cadeau.',
    payMobile:'Par Mobile Money',
    payAirtime:'Par Crédit de communication',
    pendingMobileMoney:'',
    failureMobileMoney:'',
    alreadyPendingMobileMoney:'',
    pendingAirtimeTransaction:`Une transaction a été initiée. Veuillez patienter jusqu'à ce que vous receviez une confirmation pour diffuser vos chaînes préférées`,
    failureAirtimeTransaction:`Votre transaction n'a pas abouti, veuillez réessayer.`,
    alreadyPendingAirtimeTransaction:'Vous avez une transaction en cours pour ce plan. Veuillez patienter pour la confirmation.',
    historyAirtimeName:'Crédit de communication',
    historyMobileMoneyName:'Mobile Money',
    from:'Depuis:',
    timeOutTitle:'Oups, votre session a expiré.',
    timeOutDesc:`Nous allons actualiser votre session, appuyez sur OK pour continuer.`,
    codeAttemptsTitle:`Trop de tentatives d'inscription`,
    codeAttemptsDes:'Votre compte a été temporairement verrouillé, veuillez réessayer après 10 minutes.',
    Question:'Question',



    liveStream:'Live Stream',
    followers:'Followers',
    report:'Signalé',
    reportTitle:'Pourquoi signalez-vous cette session?',
    reportDesc:`Votre rapport est anonyme. Si une personne est en danger immédiat, appelez les services d'urgence locaux attendez pas`,
    reportSuccessTitle:'Signaler une session',
    reportSuccessDesc:`Votre rapport a déjà été reçu, merci de nous avoir aidés à l'améliorer.`,
    reportSuccessDesc2:`Votre rapport a été reçu, merci de nous avoir aidés à l'améliorer.`,
    shareLive:(username) => `Regarder ${username} avec moi sans utiliser vos données `,
    sessionEndTitle:`Cette session n'est plus en direct`,
    follower:'Followers',
    following:'Suivi(e)s',

    eventEndTitle:'Diffusion terminé',
    eventEndDesc:`Cette session n'est plus en direct`,
    symbol:'cm',
}

let operationLang = {}

if (currentOperation === operations.aplitv) {
    operationLang = {
        copyright1: 'Copyright © 2015 ',
        copyright2:'apliTV.',
        copyright3:'Tous les droits sont réservés',
        journey_text:'* En activant votre compte Yabadoo, vous bénéficierez de 7 jours d essai gratuit au bouquet « Le Trio de Découverte ». A la fin de la période d essai gratuit, vous serez facturé 50F par jour. Vous pouvez annuler votre abonnement à tout moment dans le menu « mes contenus ».',
    }
} else if (currentOperation === operations.ishow) {
    operationLang = {}
} else if (currentOperation === operations.mtnSyria) {
    operationLang = {
        copyright1: 'Copyright © 2016 ',
        copyright2:'Mazaji. ',
        copyright3:'Tous les droits sont réservés',
        appTitle: 'MTN TV',
        enjoyableEntertainment: 'MTN TV',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
        mycontent:'Offres',
        journey_text:'* En activant votre compte Yabadoo, vous bénéficierez de 7 jours d essai gratuit au bouquet « Basic bundle ».',

    }
} else if (currentOperation === operations.mtnClipSyria) {
    operationLang = {
        appTitle: 'MTN Clip',
        enjoyableEntertainment: 'MTN Clip',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
    }
} else if (currentOperation === operations.nexttel || currentOperation === operations.nexttelLocal) {
    operationLang = {
        enjoyableEntertainment: 'Mon mobile, ma TV',
        appTitle:'NexttelTV',
        menuTitle:'NeXttelTV',
        loginTextFirst:'Mon mobile, ma TV',
        loginTextSecond:' ',
        bundles:'Bouquets',
        channels:'Chaînes',
        mycontent:'Mon Contenu',
        autoRenew:'Renouveler Auto',
        autoRenewed:'Renouveler Auto',

        feedbackInput:'Laissez une suggestion.',
        feedbackSuccessMsg:'Merci pour votre remarque',
        feedbackFillMsg:'Vous devez remplir vos commentaires',
        submit:'Confirmer',
        devices:'Modifier',
        acceptNotificationsMsg:`Les notifications peuvent inclure des films récemment ajoutés, des émissions de télévision, des suggestions personnalisées ou d'autres notices`,
        selectLanguage:'Changer la langue',
        linkDevice:'Relier un dispositif',
        linkDeviceMsg1:`Si vous désirez lier d'autres dispositifs sur le compte actuel s'il vous plaît utilisez le code suivant: `,
        linkDeviceMsg2:`Si vous souhaitez relier cet appareil à un autre compte , s'il vous plaît entrer le code de ce compte ici: `,
        myDevices:`Appuyez sur l'appareil que vous souhaitez désactiver.`,
        userProfile:'Profil utilisateur',
        deviceId:'Identificateur',
        deviceType:`Type d'appareil:`,
        deviceOSVersion:'Version du SE:',
        verificationCodeWrong:'Le code que vous avez saisi est incorrect. Veillez le vérifier et réessayer à nouveau.',
        verificationCodeWrongTitle:'Échec',
        register:'Bienvenue à NexttelTV',
        shareReferral1:`Avec NexttelTV, vous pouvez profiter de plus de 40 chaînes de télévision en direct sur votre téléphone intelligent sans utiliser vos données: `,
        shareReferral2:(referralCode) => `Utilisez le code de parrainage ${referralCode} et obtenez 7 jours de télévision gratuite !`,

        referral_description:`Partagez NexttelTV avec vos amis et accédez gratuitement à la télévision chaque fois qu'un nouvel ami s'inscrit en utilisant votre code.`,

        // register:'Enregistrer',
        youMayAlsoLike:'écouvrer aussi:',
        watch:'Diffuser',
        subscription: 'Abonner',
        packages:'Paquets',
        watchFromYourPackages:'Regarder du bouquet',
        ppv:'PPV',
        fullView:'Pleine Vue',
        orderId: 'Order Id',
        paidOn:'Versés en',
        expired:'Expiré Le',
        remainingDuration:'Durée restante:',
        currentDevice:'Dispositif actuel',
        typeMessage:'Envoyer un message...',
        name:'Nom',
        shareMsg:`Avec NexttelTV,regarde plus de 40 chaines TV (Boom TV,STV,CRTV,Canal 2,Nina TV…) en direct sur ton smartphone sans utiliser tes mégas`,
        noNotifications:'Pas de notification actifs',
        noFavorites:`Vous n'avez pas Favoris`,
        unexpectedError:'Oops .. Quelque chose a mal tourné',
        giftSuccess:'Votre cadeau a bien été envoyé.',
        giftSuccessTitle:'Succès',
        please_try_again_laterTitle:'Service Indisponible',
        please_try_again_later:'Service indisponible, veuillez réesayer plus tard.',
        feedback_title:'Note!',
        deviceExceed:`Vous avez dépassé le nombre de périphériques autorisés, s'il vous plaît libérer un pour pouvoir ajouter plus.`,

        owened_content_subtitle:`Vous avez déjà acheté ce contenu. Veuillez rafraîchir l'application et réessayer.`,
        owened_content_subtitleTitle:'Échoué',
        no_balance:`Pas assez de balance .. S'il vous plaît recharger`,
        // no_balanceTitle:`Uh oh, il semble que vous n'ayez pas assez d'airtime,`,
        // no_balance:`Veuillez vous assurer que vous disposez suffisamment de crédit mobile Nexttel pour effectuer votre achat et réessayer, si vous pensez que vous en avez assez et que cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème .`,
        //nonetworkTitle:`Veuillez vous assurer de vous connecter via le réseau Nexttel (3G / 4G) pour pouvoir jouer.`,
        //nonetwork:`Soit activer vos données mobiles sur votre appareil, n'oubliez pas que NexttelTV ne consomme pas votre Mo internet. Ou vous pouvez vous connecter via WIFI s'il est attaché à partir d'un autre appareil qui a un plan actif MTN 3G / 4G (partage de connexion ou point d'accès). Assurez-vous d'avoir un bon signal de couverture, sinon changez de lieu et réessayez d'ouvrir l'application. Assurez-vous d'avoir un bon signal de couverture, sinon changez de lieu et réessayez d'ouvrir l'application.`,
        //not_operator_number:`Il semble que la personne à qui vous envoyez le cadeau n'est  pas un utilisateur Nexttel`,
        //notValidphoneNumber:`Le numéro de téléphone ne semble pas être un numéro Nexttel valide. Veuillez vérifier et réessayer, si cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème.`,
        journey_text:`* En activant votre compte NexttelTV, vous bénéficierez de 7 jours d'essai gratuit au bouquet « Bouquet Original ». A la fin de la période d'essai gratuit, vous serez facturé 50F par jour. Vous pouvez annuler votre abonnement à tout moment dans le menu « mes contenus ».`,
        reachLimit:'Limite de diffusion atteinte, souhaitez-vous obtenir plus de temps?',
        reachLimit0:'La limite de streaming pour cette chaine est atteinte.',
        reachLimit01:'La limite de streaming pour cette chaine est atteinte.',
    }
}else if (currentOperation === operations.yabadoo){
    operationLang = {
        enjoyableEntertainment: 'Le meilleur du divertissement',
        loginTextFirst:'Le meilleur',
        loginTextSecond:'du divertissement',
    }
}else if (currentOperation === operations.mtnCameroon){
    operationLang = {
        symbol:'cm',
        Message:`Bienvenue à Yabadoo, la maison des meilleurs contenus vidéo et télévisés locaux et régionaux.Félicitations! Votre compte été activé avec succès, vous avez gagné un abonnement de 7 jours au forfait « Le Trio de Découverte » pour profiter de EQUINOXE, STV and CANAL2 MOVIES en direct sans utiliser votre MB Internet!`,
        Title:'Salut, C`est Ivy. Ton assistante personnel',
        Object:'15',
        Image:'https://mtntv.mtncameroon.net/media/Ivy/ivy_welcome.png',
        no_balanceTitle:`Uh oh, il semble que vous n'ayez pas assez d'airtime,`,
        no_balance:`Veuillez vous assurer que vous disposez suffisamment de crédit mobile MTN pour effectuer votre achat et réessayer, si vous pensez que vous en avez assez et que cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème .`,
        nonetworkTitle:`Veuillez vous assurer de vous connecter via le réseau MTN (3G / 4G) pour pouvoir jouer.`,
        nonetwork:`Soit activer vos données mobiles sur votre appareil, n'oubliez pas que Yabadoo ne consomme pas votre Mo internet. Ou vous pouvez vous connecter via WIFI s'il est attaché à partir d'un autre appareil qui a un plan actif MTN 3G / 4G (partage de connexion ou point d'accès). Assurez-vous d'avoir un bon signal de couverture, sinon changez de lieu et réessayez d'ouvrir l'application. Assurez-vous d'avoir un bon signal de couverture, sinon changez de lieu et réessayez d'ouvrir l'application.`,
        not_operator_number:`Il semble que la personne à qui vous envoyez le cadeau n'est  pas un utilisateur MTN`,
        notValidphoneNumber:`Le numéro de téléphone ne semble pas être un numéro MTN valide. Veuillez vérifier et réessayer, si cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème.`,
        enjoyableEntertainment: 'Le meilleur du divertissement',
        loginTextFirst:'Le meilleur',
        loginTextSecond:'du divertissement',
        journey_text:`* En activant votre compte, vous aurez droit à un abonnement d'essai gratuit de 30 jours au forfait « le trio Découverte », après quoi vous serez facturé 50F / jour. Pour vous désinscrire, vous pouvez visiter le menu «Mon contenu».`,
        // shareReferral1:`Avec Yabadoo, vous pouvez profiter de plus de 81 chaînes de télévision en direct sur votre smart phone sans utiliser vos données: `,
        shareReferral2:(referralCode) => `Utilisez le code de parrainage ${referralCode} et obtenez 30 jours de télévision gratuite !`,
        share:'Accès gratuit à la TV',
        pendingMobileMoney:'Une transaction mobile a été initiée. Veuillez valider via votre application Mobile Money ou en composant *126#.',
        failureMobileMoney:`Votre transaction Mobile Money n'a pas abouti.  Veuillez réessayer.`,
        alreadyPendingMobileMoney:'Vous avez une transaction Mobile Money en attente pour ce plan. Merci de la valider pour accéder à ce contenu Yabadoo.',
        historyMobileMoneyName:'Mobile Money',
    }
}else if(currentOperation === operations.orange){
    operationLang = {
        symbol:'cm',
        no_balanceTitle:`Uh oh, il semble que vous n'ayez pas assez d'airtime,`,
        no_balance:`Veuillez vous assurer que vous disposez suffisamment de crédit mobile Orange pour effectuer votre achat et réessayer, si vous pensez que vous en avez assez et que cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème .`,
        nonetworkTitle:`Veuillez vous assurer de vous connecter via le réseau Orange (3G / 4G) pour pouvoir jouer.`,
        nonetwork:`Soit activer vos données mobiles sur votre appareil, n'oubliez pas que Yabadoo ne consomme pas votre Mo internet. Ou vous pouvez vous connecter via WIFI s'il est attaché à partir d'un autre appareil qui a un plan actif Orange 3G / 4G (partage de connexion ou point d'accès). Assurez-vous d'avoir un bon signal de couverture, sinon changez de lieu et réessayez d'ouvrir l'application. Assurez-vous d'avoir un bon signal de couverture, sinon changez de lieu et réessayez d'ouvrir l'application.`,
        not_operator_number:`Il semble que la personne à qui vous envoyez le cadeau n'est  pas un utilisateur Orange`,
        notValidphoneNumber:`Le numéro de téléphone ne semble pas être un numéro Orange valide. Veuillez vérifier et réessayer, si cela ne fonctionne toujours pas, contactez l'assistance pour résoudre votre problème.`,
        enjoyableEntertainment: 'Le meilleur du divertissement',
        loginTextFirst:'Le meilleur',
        loginTextSecond:'du divertissement',
        // shareReferral1:`Avec Yabadoo, vous pouvez profiter de plus de 81 chaînes de télévision en direct sur votre smart phone sans utiliser vos données: `,
        shareReferral2:(referralCode) => `Utilisez le code de parrainage ${referralCode} et obtenez 30 jours de télévision gratuite !`,
        share:'Accès gratuit à la TV',

        journey_text:`* En activant votre compte, vous aurez droit à un abonnement d'essai gratuit de 30 jours au forfait « le trio Découverte », après quoi vous serez facturé 50F/jour. Pour vous désinscrire, vous pouvez visiter le menu «Mon contenu».`,
        pendingMobileMoney:'Une transaction mobile a été initiée. Veuillez valider via votre application Orange Money ou en composant #150#.',
        failureMobileMoney:`Votre transaction Orange Money n'a pas abouti.  Veuillez réessayer.`,
        alreadyPendingMobileMoney:'Vous avez une transaction Orange Money en attente pour ce plan. Merci de la valider pour accéder à ce contenu Yabadoo.',
        historyMobileMoneyName:'Orange Money',
    }
} else if (currentOperation === operations.alMadar) {
    operationLang = {
        appTitle: 'Al Madar',
        enjoyableEntertainment: 'Al Madar',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
    }
}else if (currentOperation === operations.tmcel || currentOperation === operations.vodacom) {
    operationLang = {
        symbol:'mz',
    }
}
export default {...fr,...operationLang}
