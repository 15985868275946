import {
    ALL_SERVICES_SET_RESPONSE_BOOKMARK,
    SET_SERIES_BOOKMARK,
    ALL_SERVICES_SET_VIDEOS_BOOKMARK,
} from "../actions/types";

const initialState = {
    bookmarkServices:[],
    bookmarkItems:[],
    bookmarkVideos:[],
}

const bookmarkReducer = (state = initialState, action) =>{
    switch (action.type) {
        case SET_SERIES_BOOKMARK:
            return {
                ...state,
                bookmarkServices : action.payload
            }
        case ALL_SERVICES_SET_RESPONSE_BOOKMARK:
            return {
                ...state,
                bookmarkItems:action.payload.bookmark
            }
        case ALL_SERVICES_SET_VIDEOS_BOOKMARK:
            return {
                ...state,
                bookmarkVideos:action.payload
            }
        default:
            return state

    }
}

export default bookmarkReducer
