import React from 'react'
import {wideLogo} from "../../assets/img/exports";

const FetchingData = props => {
    const logo = wideLogo

    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'center',
            alignItems: 'center',
            height: '60vh'
        }}>
            <img src={logo} alt="" width={250}/>
            <span className="spinner-border text-light mt-3"/>
        </div>
    )
}

export default FetchingData
