import React from 'react'
import {Modal} from "antd";
import {localize} from "../../helpers/functions";
import {reportSession, setReportModel, setReportModelStatus} from "../../redux/actions/liveStreamActions";
import store from "../../redux/store";

const LiveReportModel = props => {
    const sessionItem = !!props.sessionItem ? props.sessionItem : undefined
    const reportOptions = store.getState().liveStream.reportOptions

    const renderOptionList = !!reportOptions && reportOptions.length > 0 ? reportOptions.map((item) => {
        return <button onClick={() => item.name == 'Something Else' ? setReportModel({from:'InsertReason',status:true}) : reportSession(item.name,!!sessionItem ? sessionItem.sessionId : '')}>{item.name}</button>
    }) : null

    return (
        <Modal
            className={`live-report-modal`}
            visible={true}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => setReportModel({from:'MainModel',status:false})}
            centered
        >
            <h5>{localize('report')}</h5>
            <p className='report-title'>{localize('reportTitle')}</p>
            <p className='report-desc'>{localize('reportDesc')}</p>
            <div className='report-list'>
                {renderOptionList}
            </div>
        </Modal>
    )
}


export default LiveReportModel
