import {
    ADD_SERVICES,
    CHANGE_BITRATE,
    CHANGE_CAPTION,
    CHANGE_LANG,
    DEVICE_INFO,
    REMOVE_POPUP_NOTIFICATION,
    SET_RELEASE_FETCHING,
    SETTING_CHANGE_AVATAR,
    SETTING_NOTIFICATIONPOPUP,
    SETTING_SET_AVATAR_FETCHING,
    SETTING_SET_DRAWER_VISIBLE,
    SETTING_SET_PURCHASE_MODAL_GIFT_VISIBLE,
    SETTING_SET_PURCHASE_MODAL_VISIBLE,
    SETTING_UNSET_AVATAR_FETCHING,
    SETTING_UNSET_PURCHASE_MODAL_VISIBLE,
    SETTINGS_SET_ACCEPT_NOTIFICATIONS,
    SETTINGS_SET_BOOTSTRAP,
    SETTINGS_SET_NETWORK,
    SETTINGS_SET_UNREAD_NOTIFICATION,
    SETTINGS_SET_USERNAME,
    SETTINGS_SETFETCHING_ACCEPT_NOTIFICATIONS,
    SETTINGS_UNSET_INIT,
    SET_IOS_POPUP,
    SETTINGS_SET_NICKNAME,
    CW_POPUP_VISIBLE,
    SETTINGS_SET_CHARGING_STATUS,
    RESET_LOGINPAGE,
    HEADER_FETCHING

} from "../actions/types";

const avatar = require('../../assets/img/placeholder/avatar.png')
const initialState = {
    lang: 'en',
    captions:'none',
    bitRate:'auto',
    acceptNotifications: true,
    rtl: false,
    unreadNotifications: 0,
    username: '',
    avatar,
    drawerVisible: false,
    fetchingAvatar: false,
    init: true,
    network: 'online',
    fetchingAllowNotfi:false,
    linkCode: '',
    purchaseModal: {visible: false, gift: false},
    deviceinfo:'',
    notificationPopUp:[],
    itemServices:[],
    releaseFetching:false,
    iOSPopUp:false,
    cWPopupVisible:false,
    profileNickname:'',
    profileDescription:'',
    giftCounter:'',
    asyncChargingFlow:'',
    mobileMoney:'',
    chargingStatus:'0',
    resetLogin:false,
    displayPrediction:false,
    headerFetching:false,
    profileFollowersNumber:'',
    // version:'2'
}

const settingsReducer = (state = initialState, action) => {

    switch (action.type) {
        case DEVICE_INFO:
            return {
                ...state,
                deviceinfo:action.payload
            }
        case CHANGE_LANG:
            return {
                ...state,
                lang: action.payload.lang,
                rtl: action.payload.lang === 'ar'
            }
        case CHANGE_CAPTION:
            return {
                ...state,
                captions: action.payload
            }
        case CHANGE_BITRATE:
            return {
                ...state,
                bitRate: action.payload
            }
        case SETTINGS_SET_UNREAD_NOTIFICATION:
            return {
                ...state,
                unreadNotifications: action.payload,
            }
        case SETTINGS_SET_USERNAME:
            return {
                ...state,
                username: action.payload.username,
                linkCode: action.payload.linkCode,
                avatar: action.payload.avatar,
                unreadNotifications: action.payload.unreadNotifications,
                captions: action.payload.caption,
                bitRate: action.payload.bitRate,
                profileNickname: action.payload.profileNickname,
                profileDescription: action.payload.profileDescription,
                profileFollowersNumber:action.payload.profileFollowersNumber,
                giftCounter:action.payload.giftCounter,
                asyncChargingFlow:action.payload.asyncChargingFlow,
                mobileMoney:action.payload.mobileMoney,
                displayPrediction:action.payload.displayPrediction
            }
        case SETTINGS_UNSET_INIT:
            return {
                ...state,
                init: false,
            }
        case SETTINGS_SET_NETWORK:
            return {
                ...state,
                network: action.payload,
            }
        case SETTING_SET_PURCHASE_MODAL_VISIBLE:
            return {
                ...state,
                purchaseModal: {visible: true, gift: false}
            }
        case SETTING_UNSET_PURCHASE_MODAL_VISIBLE:
            return {
                ...state,
                purchaseModal: {visible: false, gift: false}
            }
        case SETTING_SET_PURCHASE_MODAL_GIFT_VISIBLE:
            return {
                ...state,
                purchaseModal: {visible: true, gift: true}
            }
        case SETTINGS_SET_BOOTSTRAP:
            return {
                ...state,
                lang: action.payload.lang,
                rtl: action.payload.lang === 'ar',
                acceptNotifications: action.payload.acceptNotifications === '1',
                captions: action.payload.caption,
                bitRate: action.payload.bitRate,
            }
        case SETTINGS_SET_ACCEPT_NOTIFICATIONS:
            return {
                ...state,
                acceptNotifications: action.payload === '1',
                fetchingAllowNotfi:false,
            }
        case SETTINGS_SETFETCHING_ACCEPT_NOTIFICATIONS:
            return {
                ...state,
                fetchingAllowNotfi:true,
            }
        case SETTING_CHANGE_AVATAR:
            return {
                ...state,
                avatar: action.payload,
                fetchingAvatar: false
            }
        case SETTING_SET_AVATAR_FETCHING:
            return {
                ...state,
                fetchingAvatar: true
            }
        case SETTING_UNSET_AVATAR_FETCHING:
            return {
                ...state,
                fetchingAvatar: false
            }
        case SETTING_SET_DRAWER_VISIBLE:
            return {
                ...state,
                drawerVisible: action.payload
            }
        case SETTING_NOTIFICATIONPOPUP:
            return {
                ...state,
                notificationPopUp:[...state.notificationPopUp, ...action.payload.notificationPopUp]
            }
        case REMOVE_POPUP_NOTIFICATION:
            return {
                ...state,
                notificationPopUp: [
                    ...state.notificationPopUp.slice(0,action.payload),
                    ...state.notificationPopUp.slice(action.payload + 1),
                ]
            }
        case ADD_SERVICES:
            return {
                ...state,
                itemServices: action.payload.index === 1 ? action.payload.items : [...state.itemServices, ...action.payload.items]
            }
        case SET_RELEASE_FETCHING:
            return {
                ...state,
                releaseFetching: action.payload
            }
        case SET_IOS_POPUP:
            return {
                ...state,
                iOSPopUp: action.payload
            }
        case CW_POPUP_VISIBLE:
            return {
                ...state,
                cWPopupVisible:true
            }
        case SETTINGS_SET_NICKNAME:
            return {
                ...state,
                profileNickname: action.payload.profileNickname,
                profileDescription: action.payload.profileDescription
            }
        case SETTINGS_SET_CHARGING_STATUS:
            return {
                ...state,
                chargingStatus:action.payload
            }
        case RESET_LOGINPAGE:
            return{
                ...state,
                resetLogin:action.payload
            }
        case HEADER_FETCHING:
            return{
                ...state,
                headerFetching:action.payload
            }
        default:
            return state

    }

}

export default settingsReducer
