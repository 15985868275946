import {MENU_SET_RESPONSE, MENU_LOGOUT_VISIBLE, SET_LIST_USERS, CHANGE_USER_FOLLOWER_STATUS} from "../actions/types";

const initialState = {
    menu: [],
    slides: [],
    slideImages:[],
    logoutVisible:false,
    followers:[],
    following:[],
}

const menuReducer = (state = initialState, action) => {

    switch (action.type) {
        case MENU_SET_RESPONSE:
            return {
                ...state,
                menu: action.payload.menu,
                slides: [...state.slides,...action.payload.slides],
                slideImages:action.payload.slideImages
            }
        case MENU_LOGOUT_VISIBLE:
            return {
                ...state,
                logoutVisible: action.payload
            }
        case SET_LIST_USERS:
            return {
                ...state,
                followers: action.payload.tab === 1 ? action.payload.list : state.followers,
                following: action.payload.tab === 0 ? action.payload.list : state.following,
            }
        case CHANGE_USER_FOLLOWER_STATUS:
            return {
                ...state,
                followers: [
                    ...state.followers.slice(0,action.payload.index),
                    {...state.followers[action.payload.index], is_follow:{msg:action.payload.status}},
                    ...state.followers.slice(action.payload.index + 1),
                ]
            }
        default:
            return state

    }
}

export default menuReducer
