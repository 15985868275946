export const FETCH_TESTS = 'FETCH_TESTS'
export const NEW_TEST = 'NEW_TEST'
export const CHANGE_LANG = 'CHANGE_LANG'
export const CHANGE_CAPTION = 'CHANGE_CAPTION'
export const CHANGE_BITRATE = 'CHANGE_BITRATE'
export const SET_CURRENT_OPERATION = 'SET_CURRENT_OPERATION'
export const DEVICE_INFO = 'DEVICE_INFO'


//settings action
export const SETTINGS_SET_UNREAD_NOTIFICATION = 'SETTINGS_SET_UNREAD_NOTIFICATION'
export const SETTINGS_SET_USERNAME = 'SETTINGS_SET_USERNAME'
export const SETTINGS_SET_NICKNAME = 'SETTINGS_SET_NICKNAME'
export const SETTINGS_SET_BOOTSTRAP = 'SETTINGS_SET_BOOTSTRAP'
export const SETTINGS_SET_ACCEPT_NOTIFICATIONS = 'SETTINGS_SET_ACCEPT_NOTIFICATIONS'
export const SETTINGS_SETFETCHING_ACCEPT_NOTIFICATIONS = 'SETTINGS_SETFETCHING_ACCEPT_NOTIFICATIONS'
export const SETTINGS_UNSET_INIT = 'SETTINGS_UNSET_INIT'
export const SET_RELEASE_FETCHING = 'SET_RELEASE_FETCHING'
export const SET_IOS_POPUP = 'SET_IOS_POPUP'
export const SETTINGS_SET_NETWORK = 'SETTINGS_SET_NETWORK'
export const SETTING_SET_PURCHASE_MODAL_VISIBLE = 'SETTING_SET_PURCHASE_MODAL_VISIBLE'
export const SETTING_UNSET_PURCHASE_MODAL_VISIBLE = 'SETTING_UNSET_PURCHASE_MODAL_VISIBLE'
export const SETTING_SET_PURCHASE_MODAL_GIFT_VISIBLE = 'SETTING_SET_PURCHASE_MODAL_GIFT_VISIBLE'
export const SETTING_CHANGE_AVATAR = 'SETTING_CHANGE_AVATAR'
export const SETTING_SET_AVATAR_FETCHING = 'SETTING_SET_AVATAR_FETCHING'
export const SETTING_UNSET_AVATAR_FETCHING = 'SETTING_UNSET_AVATAR_FETCHING'
export const SETTING_SET_DRAWER_VISIBLE = 'SETTING_SET_DRAWER_VISIBLE'
export const SETTING_NOTIFICATIONPOPUP = 'SETTING_NOTIFICATIONPOPUP'
export const ADD_SERVICES = 'ADD_SERVICES'
export const REMOVE_POPUP_NOTIFICATION = 'REMOVE_POPUP_NOTIFICATION'
export const CW_POPUP_VISIBLE = 'CW_POPUP_VISIBLE'
export const SETTINGS_SET_CHARGING_STATUS = 'SETTINGS_SET_CHARGING_STATUS'
export const RESET_LOGINPAGE = 'RESET_LOGINPAGE'
export const HEADER_FETCHING = 'HEADER_FETCHING'
// auth action types
export const AUTH_SET_AUTHORIZED = 'AUTH_SET_AUTHORIZED'
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const SET_BOOTSTRAP_FETCHING = 'SET_BOOTSTRAP_FETCHING'
export const AUTH_UNSET_TOKEN = 'AUTH_UNSET_TOKEN'
export const AUTH_USER_LOG_OUT = 'AUTH_USER_LOG_OUT'
export const AUTH_SET_FETCHING = 'AUTH_SET_FETCHING'
export const AUTH_UNSET_FETCHING = 'AUTH_UNSET_FETCHING'


// bundles action types
export const BUNDLES_SET_RESPONSE = 'BUNDLES_SET_RESPONSE'
export const BUNDLES_SET_FETCHING = 'BUNDLES_SET_FETCHING'
export const BUNDLES_UNSET_FETCHING = 'BUNDLES_UNSET_FETCHING'
export const BUNDLES_ERROR = 'BUNDLES_ERROR'
export const BUNDLES_MORE = 'BUNDLES_MORE'
export const BUNDLES_HAS_MORE_FALSE = 'BUNDLES_HAS_MORE_FALSE'
export const BUNDLES_MORE_SERVICES = 'BUNDLES_MORE_SERVICES'
export const BUNDLES_HAS_MORE_SERVICES_FALSE = 'BUNDLES_HAS_MORE_SERVICES_FALSE'
export const BUNDLES_SELECT_BUNDLE = 'BUNDLES_SELECT_BUNDLE'
export const BUNDLES_SET_UNLOCKED = 'BUNDLES_SET_UNLOCKED'
export const BUNDLES_UPDATE_ITEM_SERVICES = 'BUNDLES_UPDATE_ITEM_SERVICES'

export const SET_BUNDLE_UNLOCK = 'SET_BUNDLE_UNLOCK'
// menu action types
export const MENU_SET_RESPONSE = 'MENU_SET_RESPONSE'
export const MENU_LOGOUT_VISIBLE = 'MENU_LOGOUT_VISIBLE'
export const SET_LIST_USERS = 'SET_LIST_USERS'
export const CHANGE_USER_FOLLOWER_STATUS = 'CHANGE_USER_FOLLOWER_STATUS'

// packages action types
export const PACKAGES_SET_RESPONSE = 'PACKAGES_SET_RESPONSE'
export const PACKAGES_SET_PURCHASED = 'PACKAGES_SET_PURCHASED'


// playlist action types
export const PLAYLIST_SET_FETCHING = 'PLAYLIST_SET_FETCHING'
export const PLAYLIST_UNSET_FETCHING = 'PLAYLIST_UNSET_FETCHING'
export const PLAYLIST_SET_BOOTSTRAP_RESPONSE = 'PLAYLIST_SET_BOOTSTRAP_RESPONSE'
export const PLAYLIST_SET_RESPONSE = 'PLAYLIST_SET_RESPONSE'
export const PLAYLIST_SET_SERIES = 'PLAYLIST_SET_SERIES'
export const PLAYLIST_SET_CURRENT_LINK = 'PLAYLIST_SET_CURRENT_LINK'
export const PLAYLIST_SELECT_ITEM_LIVE = 'PLAYLIST_SELECT_ITEM_LIVE'
export const PLAYLIST_SELECT_ITEM_LIVE_PRICES = 'PLAYLIST_SELECT_ITEM_LIVE_PRICES'
export const PLAYLIST_SELECT_ITEM = 'PLAYLIST_SELECT_ITEM'
export const PLAYLIST_ADD_ITEM = 'PLAYLIST_ADD_ITEM'
export const PLAYLIST_SET_ERROR = 'PLAYLIST_SET_ERROR'
export const SET_DST_LINK = 'SET_DST_LINK'
export const PLAYLIST_ADD_PREDICTIONS = 'PLAYLIST_ADD_PREDICTIONS'
export const SET_FETCHING_PREDICTION = 'SET_FETCHING_PREDICTION'
export const TOGGLE_CHAT = 'TOGGLE_CHAT'
export const SET_LIVE_SESSION_END = 'SET_LIVE_SESSION_END'

// favorites types
export const FAVORITES_SET_RESPONSE = 'FAVORITES_SET_RESPONSE'
export const FAVORITES_UNSET_RESPONSE = 'FAVORITES_UNSET_RESPONSE'
export const FAVORITES_HAS_MORE_FALSE = 'FAVORITES_HAS_MORE_FALSE'
export const FAVORITES_LOAD_MORE = 'FAVORITES_LOAD_MORE'
export const FAVORITES_REMOVE = 'FAVORITES_REMOVE'
export const FAVORITES_SET_FETCH = 'FAVORITES_SET_FETCH'

// history types
export const HISTORY_SET_RESPONSE = 'HISTORY_SET_RESPONSE'
export const HISTORY_UNSET_RESPONSE = 'HISTORY_UNSET_RESPONSE'
export const HISTORY_HAS_MORE_FALSE = 'HISTORY_HAS_MORE_FALSE'
export const HISTORY_LOAD_MORE = 'HISTORY_LOAD_MORE'

// search types
export const SEARCH_SET_RESPONSE = 'SEARCH_SET_RESPONSE'
export const SEARCH_SET_RESPONSE_MOVIES = 'SEARCH_SET_RESPONSE_MOVIES'
export const SEARCH_SET_RESPONSE_CLIPS = 'SEARCH_SET_RESPONSE_CLIPS'
export const SEARCH_SET_RESPONSE_SERIES = 'SEARCH_SET_RESPONSE_SERIES'
export const SEARCH_SET_RESPONSE_BUNDLES = 'SEARCH_SET_RESPONSE_BUNDLES'
export const SEARCH_SET_RESPONSE_LIVE = 'SEARCH_SET_RESPONSE_LIVE'
export const SEARCH_SET_RESPONSE_PLAYS = 'SEARCH_SET_RESPONSE_PLAYS'
export const SEARCH_SET_KEYWORD = 'SEARCH_SET_KEYWORD'
export const SEARCH_SET_FETCHING = 'SEARCH_SET_FETCHING'
export const SEARCH_UNSET_FETCHING = 'SEARCH_UNSET_FETCHING'

// notification types
export const NOTIFICATION_SET_RESPONSE = 'NOTIFICATION_SET_RESPONSE'
export const NOTIFICATION_LOAD_MORE = 'NOTIFICATION_LOAD_MORE'
export const NOTIFICATION_HAS_MORE = 'NOTIFICATION_HAS_MORE'
export const NOTIFICATION_SET_FETCHING = 'NOTIFICATION_SET_FETCHING'
export const NOTIFICATION_UNSET_FETCHING = 'NOTIFICATION_UNSET_FETCHING'
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR'
export const NOTIFICATION_SET_READ = 'NOTIFICATION_SET_READ'

// devices types
export const DEVICES_SET_RESPONSE = 'DEVICES_SET_RESPONSE'
export const DEVICES_SET_FETCHING = 'DEVICES_SET_FETCHING'
export const DEVICES_UNSET_FETCHING = 'DEVICES_UNSET_FETCHING'
export const DEVICES_ADD = 'DEVICES_ADD'
export const DEVICES_REMOVE = 'DEVICES_REMOVE'
export const DEVICES_SELECT_ITEM = 'DEVICES_SELECT_ITEM'


// profile types
export const PROFILE_SET_RESPONSE = 'PROFILE_SET_RESPONSE'
export const PROFILE_SET_FETCHING = 'PROFILE_SET_FETCHING'
export const PROFILE_UNSET_FETCHING = 'PROFILE_UNSET_FETCHING'
export const PROFILE_TOGGLE_AUTO_RENEW = 'PROFILE_TOGGLE_AUTO_RENEW'
export const PROFILE_SET_FETCHING_AUTORENEW = 'PROFILE_SET_FETCHING_AUTORENEW'
export const PROFILE_UNSET_FETCHING_AUTORENEW = 'PROFILE_UNSET_FETCHING_AUTORENEW'
// player types
export const PLAYER_SET_VISIBLE = 'PLAYER_SET_VISIBLE'
export const PLAYER_SET_RETRY = 'PLAYER_SET_RETRY'
export const SET_TIME_RETRY = 'SET_TIME_RETRY'
export const PLAYER_UNSET_RETRY = 'PLAYER_UNSET_RETRY'
export const PLAYER_SET_MINIMIZED = 'PLAYER_SET_MINIMIZED'
export const PLAYER_TOGGLE_MINIMIZED = 'PLAYER_TOGGLE_MINIMIZED'
export const PLAYER_TOGGLE_FULLSCREEN = 'PLAYER_TOGGLE_FULLSCREEN'
export const PLAYER_SET_CURRENT_LINK = 'PLAYER_SET_CURRENT_LINK'
export const PLAYER_CHANGE_CURRENT_LINK = 'PLAYER_CHANGE_CURRENT_LINK'
export const PLAYER_OPEN_LIVE_PLAYER = 'PLAYER_OPEN_LIVE_PLAYER'
export const PLAYER_PREVIEW_LIVE = 'PLAYER_PREVIEW_LIVE'


export const PLAYER_LIMIT_TIME = 'PLAYER_LIMIT_TIME'
export const PLAYER_LIMIT_TIME_CLEAR = 'PLAYER_LIMIT_TIME_CLEAR'
export const SET_CODE = 'SET_CODE'
export const UNREAD_NOTIFICATION = 'UNREAD_NOTIFICATION'
export const SET_FETCHING = 'SET_FETCHING'


// messages types
export const MESSAGES_SET_MESSAGES = 'PLAYER_SET_MESSAGES'
export const MESSAGES_UNSET_MESSAGES = 'PLAYER_UNSET_MESSAGES'
export const MESSAGES_ADD_MESSAGE = 'PLAYER_ADD_MESSAGE'


// live Reducer
export const LIVE_SET_RESPONSE = 'LIVE_SET_RESPONSE'
export const LIVE_MORE_LIVE ='LIVE_MORE_LIVE'
export const LIVE_HAS_MORE_FALSE = 'LIVE_HAS_MORE_FALSE'
export const SELECT_LIVE = 'SELECT_LIVE'
export const FAVORITE_ADD_LIVE = 'FAVORITE_ADD_LIVE'
export const FAVORITE_REMOVE_LIVE = 'FAVORITE_REMOVE_LIVE'
export const LIVE_SET_UNLOCKED = 'LIVE_SET_UNLOCKED'
export const LIVE_SET_UNLOCKED_LIST_ITEMS = 'LIVE_SET_UNLOCKED_LIST_ITEMS'
export const LIVECHANNELS_SINGLEBUNDLE_UNLOCK = 'LIVECHANNELS_SINGLEBUNDLE_UNLOCK'
export const LIVE_SET_FETCHING = 'LIVE_SET_FETCHING'


// clips Reducer
export const CLIPS_SET_RESPONSE = 'CLIPS_SET_RESPONSE'
export const CLIPS_MORE_CLIPS = 'CLIPS_MORE_CLIPS'
export const HAS_MORE_FALSE_CLIPS = 'HAS_MORE_FALSE_CLIPS'
export const SELECT_CLIPS = 'SELECT_CLIPS'
export const CLIPS_SETUNLOCK = 'CLIPS_SETUNLOCK'
export const CLIPS_SET_FETCHING = 'CLIPS_SET_FETCHING'

//series Reducer
export const SERIES_SET_RESPONSE = 'SERIES_SET_RESPONSE'
export const SERIES_MORE_SERIES = 'SERIES_MORE_SERIES'
export const SERIES_HAS_MORE_FALSE = 'SERIES_HAS_MORE_FALSE'
export const SERIES_HAS_MORE_FALSE_SERIES_SERVICES = 'SERIES_HAS_MORE_FALSE_SERIES_SERVICES'
export const SERIES_HAS_MORE_FALSE_SERIES_EPISODES = 'SERIES_HAS_MORE_FALSE_SERIES_EPISODES'
export const SERIES_MORE_SERIES_SERVICES = 'SERIES_MORE_SERIES_SERVICES'
export const SERIES_MORE_SERIES_EPISODES = 'SERIES_MORE_SERIES_EPISODES'
export const CHANGE_SERIES_SERVICE = 'CHANGE_SERIES_SERVICE'
export const SELECT_SERIES = 'SELECT_SERIES'
export const SERIES_SET_FETCHING = 'SERIES_SET_FETCHING'



//plays Reducer
export const PLAYS_SET_RESPONSE = 'PLAYS_SET_RESPONSE'
export const MORE_PLAYS = 'MORE_PLAYS'
export const HAS_MORE_FALSE_PLAYS = 'HAS_MORE_FALSE_PLAYS'
export const SELECT_PLAY = 'SELECT_PLAY'
export const PLAYS_SET_FETCHING = 'PLAYS_SET_FETCHING'

//movie Reducer
export const MOVIE_SET_RESPONSE = 'MOVIE_SET_RESPONSE'
export const MORE_MOVIES = 'MORE_MOVIES'
export const HAS_MORE_FALSE_MOVIES = 'HAS_MORE_FALSE_MOVIES'
export const HAS_MORE_FALSE_MOVIE_VIDEOS = 'HAS_MORE_FALSE_MOVIE_VIDEOS'
export const MORE_MOVIE_VIDEOS = 'MORE_MOVIE_VIDEOS'
export const SELECT_MOVIE = 'SELECT_MOVIE'
export const SELECT_MOVIE_VIDEO = 'SELECT_MOVIE_VIDEO'
export const FAVORITES_ADD_MOVIE_VIDEO = 'FAVORITES_ADD_MOVIE_VIDEO'
export const FAVORITES_REMOVE_MOVIE_VIDEO = 'FAVORITES_REMOVE_MOVIE_VIDEO'
export const SERVICES_MOVIE_SET_UNLOCKED = 'SERVICES_MOVIE_SET_UNLOCKED'
export const SERVICES_VIDEOSLIST_UNLOCKED_SUBSCRIBTION = 'SERVICES_VIDEOSLIST_UNLOCKED_SUBSCRIBTION'
export const SERVICES_MOVIE_SETUNLOCK = 'SERVICES_MOVIE_SETUNLOCK'
export const SERVICES_SUBSCRIBTION_SINGLEMOVIE_SETUNLOCK = 'SERVICES_SUBSCRIBTION_SINGLEMOVIE_SETUNLOCK'
export const MOVIES_SET_FETCHING = 'MOVIES_SET_FETCHING'
export const SET_VIDEOS = 'SET_VIDEOS'


// services action types
export const ALL_SERVICES_SET_RESPONSE = 'ALL_SERVICES_SET_RESPONSE'
export const ALL_SERVICES_SET_RESPONSE_BOOKMARK = 'ALL_SERVICES_SET_RESPONSE_BOOKMARK'
export const ALL_SERVICES_SET_VIDEOS_BOOKMARK = 'ALL_SERVICES_SET_VIDEOS_BOOKMARK'
export const SERVICES_SINGLEMOVIE_SET_UNLOCKED = 'SERVICES_SINGLEMOVIE_SET_UNLOCKED'

//error
export const HANDLE_ERROR = 'HANDLE_ERROR'
export const UNSET_HANDLE_ERROR_VISIBLE = 'UNSET_HANDLE_ERROR_VISIBLE'
export const CHANGE_REFERRAL_STATE = 'CHANGE_REFERRAL_STATE'


//bookmark
export const SET_SERIES_BOOKMARK = 'SET_SERIES_BOOKMARK'

//liveStream
export const LIVE_STREAM_LIST = 'LIVE_STREAM_LIST'
export const SET_SESSION_REPORT_OPTIONS = 'SET_SESSION_REPORT_OPTIONS'
export const SET_FOLLOW = 'SET_FOLLOW'
export const SET_REPORT_MODEL = 'SET_REPORT_MODEL'
export const SET_REPORT_MODEL_INSERT = 'SET_REPORT_MODEL_INSERT'
export const SET_VIEWERS_NUMBER = 'SET_VIEWERS_NUMBER'
export const SET_VIEWERS_LIST = 'SET_VIEWERS_LIST'
export const SET_SESSION_END_USERINFO = 'SET_SESSION_END_USERINFO'
export const SET_SESSION_END_VIEWMODEL = 'SET_SESSION_END_VIEWMODEL'
export const SET_VIEWERS_LIST_STATUS = 'SET_VIEWERS_LIST_STATUS'
export const CHANGE_FOLLOW_STATE_VIEWERS_LIST = 'CHANGE_FOLLOW_STATE_VIEWERS_LIST'
export const SET_LIVE_LOADING_FETCH = 'SET_LIVE_LOADING_FETCH'
export const ADD_REMOVE_SINGLE_ITEM = 'ADD_REMOVE_SINGLE_ITEM'
