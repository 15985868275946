import {
    LIVE_MORE_LIVE,
    ALL_SERVICES_SET_RESPONSE,
    LIVE_HAS_MORE_FALSE,
    SELECT_LIVE,
    FAVORITE_ADD_LIVE,
    FAVORITE_REMOVE_LIVE,
    LIVE_SET_UNLOCKED,
    LIVE_SET_UNLOCKED_LIST_ITEMS,
    LIVECHANNELS_SINGLEBUNDLE_UNLOCK,
    LIVE_SET_FETCHING
} from "../actions/types";


const initialState = {
    items: [],
    item: {list: []},
    hasMore: true,
    index: 0,
    fetching:false,
    fetchingChannels:false
}

const liveReducer = (state = initialState, action) => {
        switch (action.type) {
            case ALL_SERVICES_SET_RESPONSE:
                return {
                    ...state,
                        items: action.payload.live,
                    fetchingChannels:true
                        //hasMore: action.payload.live.length >= loadMorePageSize
                }
            case LIVE_MORE_LIVE:
                return {
                    ...state,
                    items: [...state.items, ...action.payload.items],
                    hasMore: true,
                    index: action.payload.index,
                    fetching: false,
                }
            case LIVE_HAS_MORE_FALSE:
                return {
                    ...state,
                    hasMore: false,
                    fetching: false,
                }
            case SELECT_LIVE:
                return {
                    ...state,
                    item: action.payload
                }
            case FAVORITE_ADD_LIVE:
                return {
                    ...state,
                        item: {
                            ...state.item,
                            is_fav: 1,
                            fav_id: action.payload.favId
                        }
                }
            case FAVORITE_REMOVE_LIVE:
                return {
                    ...state,
                    item: {
                        ...state.item,
                        is_fav: 0,
                        fav_id: undefined
                    }
                }
            case LIVE_SET_UNLOCKED:
                return {
                    ...state,
                        item: {
                            ...state.item,
                            status: 'unlocked'
                        }
                }
            case LIVE_SET_UNLOCKED_LIST_ITEMS:
                return {
                    ...state,
                        items:[
                            ...state.items.slice(0,action.payload),
                            {...state.items[action.payload], status:'unlocked'},
                            ...state.items.slice(action.payload + 1),
                        ]
                }
            case LIVECHANNELS_SINGLEBUNDLE_UNLOCK:
                return {
                    ...state,
                        items: [...state.items, ...action.payload.items],
                }
            case LIVE_SET_FETCHING:
                return {
                    ...state,
                    fetching: true,
                }
            default:
                return state
        }
}
export default liveReducer
