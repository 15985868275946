import store from "../store";
import {jsonToFormData} from "../../helpers/functions";
import axios from "axios";
import {campaignLogCall, host, saveUserFeedback} from "../api/endPoints";


export const userFeedback = (rating, feedback) => {
    const auth = store.getState().auth.authToken
    const req = {auth, rating, feedback}
    const params = jsonToFormData(req)
    return axios.post(host + saveUserFeedback, params)
}

export const campaignLog = (param,campaignName,status) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        submethod:param['submethod'],
        username:param['msisdn'],
        operator:param['opco'],
        log_event_name:param['log_event_name'],
        campaign_name:campaignName,
        log_status:status
    }
    const params = jsonToFormData(req)
    return axios.post(host + campaignLogCall, params)
}
