import React from 'react'
import {localize, shuffle} from "../../helpers/functions";
import {wideLogo} from "../../assets/img/exports";
import CarouselRow from "../CarouselRow";
import {Routes} from "../../routes/AppRouter";
import ChannelCard from "../Cards/ChannelCard";

const Empty = props => {

    /*
    * empty types
    * default
    * notifications
    * favorites
    * history
    * myContent
    * */

    const type = props.type ? props.type : 'default'
    const list = props.list ? props.list : undefined

    const renderList = !!list && list.length > 0 ? list.map((item,index) => {
        return (
            <ChannelCard
                key={index}
                route={Routes.channelId(item.service_id)}
                img={item.logo}
                title={item.service_name}
                desc={item.service_description}
                star={!!item.status && item.status ==='unlocked'}
                badge={!!item.is_hotnew ? item.is_hotnew : undefined}
            />
        )
    }) : null

    let renderEmpty = (
        <>
            <img src={wideLogo} alt="" width={200}/>
            <p className='mt-2'>{localize('noData')}</p>
        </>
    )

    if(type==='notifications'){
        renderEmpty = (
            <>
                <img src={require('../../assets/img/placeholder/notification_list_no_content.png')} alt="" width={100}/>
                <p>{localize('noNotifications')}</p>
            </>
        )
    }else if (type === 'favorites'){
        renderEmpty = (
            <>
                <img src={require('../../assets/img/placeholder/favorte_list_no_content.png')} alt="" width={100}/>
                <h2 style={{fontFamily:'Arial Rounded MT Bold',fontSize:'20px',margin:'0px'}}>{localize('noFavorites')}</h2>
                <p style={{fontSize:'12px'}}>{localize('proposalFillHint')}</p>
                {!!list && list.length > 0 ? <div style={{
                    position: 'relative',
                    width: '100%',
                    bottom: '0px',
                    top:'2rem'
                }}>
                    <CarouselRow key={0} title={localize('proposals')}>
                        {renderList}
                    </CarouselRow>
                </div> : null}
            </>
        )
    }else if (type === 'history'){
        renderEmpty = (
            <>
                <img src={require('../../assets/img/placeholder/history_list_no_content.png')} alt="" width={100}/>
                <p className='mt-2'>{localize('noHistory')}</p>
            </>
        )
    }else if (type === 'myContent'){
        renderEmpty = (
            <>
                <img src={require('../../assets/img/placeholder/mycontent_list_no_content.png')} alt="" width={100}/>
                <p className='mt-2'>{localize('noMyContent')}</p>
            </>
        )
    }else if (type === 'search'){
        renderEmpty = (
            <>
                {!!list ? <>
                    <img src={require('../../assets/img/placeholder/search-Empty.png')} alt="" width={100}/>
                    <h2 style={{fontFamily:'Arial Rounded MT Bold',fontSize:'20px',margin:'0px'}}>{localize('noSearchTitle')}</h2>
                    <p style={{fontSize:'12px'}}>{localize('noSearchHint')}</p>
                </> : null }
                {!!list && list.length > 0 ? <div style={{
                    position: 'relative',
                    width: '100%',
                    bottom: '0px',
                    top:'2rem'
                }}>
                    <CarouselRow key={0} title={localize('proposals')}>
                        {renderList}
                    </CarouselRow>
                </div> : null}
            </>
        )
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            // position:'relative',
            justifyContent: type === 'favorites' || 'search' ? null : 'center',
            alignItems: 'center',
            height: type === 'favorites' || 'search' ? 'unset' : '60vh',
            top: '2rem',

        }}>

            {renderEmpty}
        </div>
    )
}

export default Empty
