import {PACKAGES_SET_PURCHASED, PLAYLIST_SET_FETCHING, PLAYLIST_UNSET_FETCHING} from "./types";
import {handleHttpError, jsonToFormData, localize, setHintDialog} from "../../helpers/functions";
import {getLink, processOrder} from "./playlistActions";
import axios from "axios";
import {host, watchVodFromPackage} from "../api/endPoints";
import store from "../store";
import {hideModal} from "./settingsActions";
import {playerTypes} from "../../helpers/enums";

const dispatch = store.dispatch

export const purchasePackage = (pkg, service, video, gift_to = undefined, isRenew = undefined) => {
    let params = {
        type: 'package',
        pkg_id: pkg.pkg_id,
        pkg_price: pkg.pkg_price,
        pkg_duration: pkg.pkg_duration,
        is_renew:isRenew,
        label:pkg.pkg_label,
        gift_to
    }
    dispatch({type: PLAYLIST_SET_FETCHING,})
    processOrder(params)
        .then(res => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            if (res.status === 200) {
                const items = res.data.msg
                if (gift_to) {
                    setHintDialog('giftSuccess',localize('giftSuccessTitle'))
                    // message.success(localize('giftSuccess'))
                } else {
                    dispatch({
                        type: PACKAGES_SET_PURCHASED,
                        payload: items
                    })
                    if (video) {
                        const selectedPackage = items.find(item => {
                            let found = false
                            if (item.pkg_id_fk === pkg.pkg_id && item.pkg_usr_price === pkg.pkg_price && item.pkg_usr_duration === pkg.pkg_duration) {
                                const typeIndex = item.types.findIndex(iItem => iItem.usr_video_type_id === service.is_movie && Number.parseInt(iItem.usr_type_remaining) > 0)
                                if (typeIndex !== -1) found = true
                            }
                            return found
                        })
                        watchFromPackage(selectedPackage, service.service_id, video.video_id, video.video_name)
                    }
                }
                hideModal()
            }
        })
        .catch(error => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            hideModal()
            handleHttpError(error)
        })
}

export const watchFromPackage = (pkg, service_id, video_id, video_name, videoFound = undefined) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        pkg_usr_id_fk: pkg.pkg_usr_id,
        pkg_price: pkg.pkg_usr_price,
        pkg_duration: pkg.pkg_usr_duration,
        video_id,
        service_id,
    }
    const params = jsonToFormData(req)
    dispatch({type: PLAYLIST_SET_FETCHING,})
    axios.post(host + watchVodFromPackage, params)
        .then(res => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            if (res.status === 200) {
                hideModal()
                const items = res.data.msg
                dispatch({
                    type: PACKAGES_SET_PURCHASED,
                    payload: items
                })
                const params = {
                    type: playerTypes.video,
                    pkg_usr_id_fk: pkg.pkg_usr_id,
                    service_id: service_id,
                    video_id: video_id,
                    object_name: video_name,
                }
                getLink(params)
            }
        })
        .catch(error => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            handleHttpError(error)
        })
}

export const checkVODInPackages = videoId => {
    const {purchasedPackages} = store.getState().packages
    return purchasedPackages.find(item => item.VOD ? item.VOD.find(item => item.video_id_fk === videoId) : '-1')
}
