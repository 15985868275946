import {
    SEARCH_SET_FETCHING,
    SEARCH_SET_KEYWORD,
    SEARCH_SET_RESPONSE,
    SEARCH_UNSET_FETCHING,
    SEARCH_SET_RESPONSE_MOVIES,
    SEARCH_SET_RESPONSE_BUNDLES,
    SEARCH_SET_RESPONSE_LIVE,
    SEARCH_SET_RESPONSE_SERIES,
    SEARCH_SET_RESPONSE_CLIPS,
    SEARCH_SET_RESPONSE_PLAYS
} from "../actions/types";

const initialState = {
    fetching: false,
    keyword: '',
    moviesresult:[],
    clipsresult:[],
    seriesresult:[],
    playsresult:[],
    liveresult:[],
    bundlesresult:[],
    recommendations: [],
    tags: [],
    msg: [],
    logo:'',
    name:'',
    subtitle:'',
    status:''
}

const searchReducer = (state = initialState, action) => {

    switch (action.type) {
        case SEARCH_SET_RESPONSE:
            return {
                ...state,
                recommendations: action.payload.recommendations,
                tags: action.payload.tags,
                msg: action.payload.msg,
                fetching: false,
                logo:action.payload.msg.logo,
                name:action.payload.msg.name,
                status:action.payload.status,
                subtitle:action.payload.msg.subtitle
            }
        case SEARCH_SET_RESPONSE_MOVIES:
            return {
                ...state,
                moviesresult:action.payload
            }
        case SEARCH_SET_RESPONSE_CLIPS:
            return {
                ...state,
                clipsresult:action.payload
            }
        case SEARCH_SET_RESPONSE_SERIES:
            return {
                ...state,
                seriesresult:action.payload
            }
        case SEARCH_SET_RESPONSE_PLAYS:
            return {
                ...state,
                playsresult:action.payload
            }
        case SEARCH_SET_RESPONSE_LIVE:
            return {
                ...state,
                liveresult:action.payload
            }
        case SEARCH_SET_RESPONSE_BUNDLES:
            return {
                ...state,
                bundlesresult:action.payload
            }
        case SEARCH_SET_KEYWORD:
            return {
                ...state,
                keyword: action.payload,
                //status:''
            }
        case SEARCH_SET_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case SEARCH_UNSET_FETCHING:
            return {
                ...state,
                fetching: false
            }
        default:
            return state

    }

}

export default searchReducer
