export const operations = {
    // mtnCameroon: 'mtnCameroon',
    mtnCameroon: 'MTN',

    // orange:'orange',
    orange:'Orange',
    mtnSyria: 'mtnSyria',
    mtnClipSyria: 'mtnClipSyria',
    tmcel:'TMCEL',
    // tmcel:'tmcel',
    ishow: 'ishow',
    nexttel: 'nexttel',
    nexttelLocal: 'nexttelLocal',
    aplitv: 'aplitv',
    alMadar: 'alMadar',
    yabadoo:'yabadoo',
    senegal:'senegal',
    alAwal:'shashatcomtv',
    // vodacom:'vodacom',
    vodacom:'Vodacom'
}


export const localStorageKeys = {
    authToken: 'authToken',
    userIp:'userIp',
    one10LabsParams:'one10LabsParams',
    visit:'visit',
    iOS:'iOS',
    update:'update',
    renew:'renew',
    bookmark:'bookmark',
    pushToken: 'pushToken',
    loggedIn: 'loggedIn',
    isTest: 'isTest',
    acceptNotifications: 'acceptNotifications',
    lang: 'lang',
    uuid: 'uuid', // represents a uniq identifier for the user
    currentPlaylistIndex: 'currentPlaylistIndex',// current played item index in live player
    captions:'none',
    bitRate:'bitRate',
    currentOperation:'currentOperation',
    operatorName:'operatorName',
    itemsinterval:'itemsinterval',
    countryName:'countryName',
    serviceItem:'service_array',
    //notifactionPopUpArray:'notifactionPopUpArray',
    unreadNotifications:'unreadNotifications',
    additionalTime:'additionalTime',
    referralCode:'referralCode',
    reload:'',
    visible:'visible',
    dstKey:'dstKey',
    popUp:'popUp',
    continueWatchingPopup:'continueWatchingPopup',
    shareLink:'shareLink',
    isFirstTime:'isFirstTime',
    number:'number',
    headerSet:'headerSet',
    recevReferral:'recevReferral',
    liveStreamUsername:'liveStreamUsername',
    af:'af',
    pb:'pb',
    liveSessionId:'liveSessionId',
    acceptPolicy:'acceptPolicy',
}
export const countryOperator = {
    cameroon: {
        name:'Cameroon',
        operators:{
            mtn:{
                name:'MTN',
                cuurentOperation:operations.mtnCameroon,
            },
            orange:{
                name:'Orange',
                cuurentOperation:operations.orange
            }
        }
    },
    // senegal: {
    //     name:'Senegal',
    //     operators:{
    //         expreso:{
    //             name:'Expresso',
    //             cuurentOperation:operations.senegal
    //         },
    //     }
    // },
    mozambique: {
        name:'Mozambique',
        operators:{
            TMCEL:{
                name:'TMCEL',
                cuurentOperation:operations.tmcel
            },
            Vodacom:{
                name:'Vodacom',
                cuurentOperation:operations.vodacom
            },
        }
    }
}

export const isMovie = {
    clips: '0',
    movies: '1',
    series: '2',
    plays: '3',
    live: undefined, // if service type === live then no need to check is movie
}

export const serviceType = {
    vod: 'vod',
    live: 'live',
}

export const contentType = {
    service: 'service',
    video: 'Video',
    videoRes: 'video',
    bundle: 'bundle',
    pkg: 'package',
}
export const streamingLimitType = {
    live:'live',
    bundle:'Bundle'
}
export const hotNew = {
    '1': require('../assets/img/icons/newservice.png'),
    '2': require('../assets/img/icons/hot.png'),
    '3': require('../assets/img/icons/free.png'),
}

export const hotNewValues = {
    new: '1',
    hot: '2',
    free: '3',
}

export const purchaseTabs = {
    subscription: 0,
    packages: 1,
    fromPackage: 2,
    ppv: 3,
    none: 4,
}

export const purchaseTypes = {
    subscription: 'subscription',
    packages: 'packages',
    fromPackage: 'fromPackage',
    ppv: 'ppv',
    none: 'none',
}

export const playerTypes = {
    video: 'video',
    live: 'service',
    bundle: 'bundle',
    series: 'series',
    // vod: 'vod',
    // subscription: 'subscription',
    // live: 'live',

}

export const planModelTypes = {
    streamingLimit: '1',
    subscriptionPlan: '0'
}

// export const subtitleLanguages = {
//     arabic: 'ara',
//     english: 'eng',
//     french: 'fre',
//     none: 'none'
// }
export const bitRates = {
    auto:'auto',
    low:'low',
    high:'high',
    none:'none'
}
