import React, {useState} from 'react'
import {FiChevronUp, FiChevronDown, FiCheck} from "react-icons/fi";


// const items = [
//     {
//         title: 'first',
//         sub: [
//             {
//                 title: 'sub first',
//                 checked: false,
//                 callback: e => console.log(e)
//             },
//             {
//                 title: 'sub second',
//                 checked: false,
//                 callback: e => console.log(e)
//             },
//             {
//                 title: 'sub third',
//                 checked: true,
//                 callback: e => console.log(e)
//             },
//         ]
//     },
//     {
//         title: 'second',
//         sub: [
//             {
//                 title: 'sub first',
//                 checked: false,
//                 callback: e => console.log(e)
//             },
//             {
//                 title: 'sub second',
//                 checked: true,
//                 callback: e => console.log(e)
//             },
//             {
//                 title: 'sub third',
//                 checked: false,
//                 callback: e => console.log(e)
//             },
//         ]
//     },
// ]

const CollapseGroup = props => {

    const [opened, setOpened] = useState(undefined)
    const [open, setOpen] = useState(false)

    const items = !!props.options ? props.options : []
    if (!items || items.length === 0) return null
    return (
        <div className='collapse-group'>
            {items.map((item, index) => {
                const itemOpened = index === opened
                return (
                    <React.Fragment key={index}>
                        <button className={`btn btn-dark item ${itemOpened && open ? 'selected' : ''}`} onClick={() => {
                            if((index !== opened && !open) || index === opened) setOpen(!open)
                            setOpened(index)
                        }}>
                            {item.title}
                            {itemOpened && open ? <FiChevronDown/> : <FiChevronUp/>}
                        </button>
                        <div className={itemOpened && open ? '' : 'd-none'}>
                            {item.sub.map((sItem, sIndex) => {
                                if(!sItem) return null
                                return (
                                    <button className={`btn btn-dark sub-item`} key={sIndex} onClick={() => sItem.callback()}>
                                        {sItem.checked ? <FiCheck className={`sub-item-icon`}/> : <div className={`sub-item-icon`}/>}
                                        {sItem.title}
                                    </button>
                                )
                            })}
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default CollapseGroup
