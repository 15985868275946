import {
    NOTIFICATION_CLEAR,
    NOTIFICATION_HAS_MORE,
    NOTIFICATION_LOAD_MORE,
    NOTIFICATION_SET_FETCHING,
    NOTIFICATION_SET_READ,
    NOTIFICATION_SET_RESPONSE,
    NOTIFICATION_UNSET_FETCHING,
} from "../actions/types";

const initialState = {
    fetching: false,
    items: [],
    hasMore: true
}

const notificationReducer = (state = initialState, action) => {

    switch (action.type) {
        case NOTIFICATION_SET_RESPONSE:
            return {
                ...state,
                items: action.payload,
                //hasMore: action.payload.length >= loadMorePageSize,
                fetching: false,
            }
        case NOTIFICATION_LOAD_MORE:
            return {
                ...state,
                items: [...state.items, ...action.payload.items],
                //hasMore: action.payload.items.length >= loadMorePageSize,
                fetching: false,
            }
        case NOTIFICATION_CLEAR:
            return {
                ...initialState,
                hasMore: false,
                fetching: false
            }
        case NOTIFICATION_SET_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case NOTIFICATION_UNSET_FETCHING:
            return {
                ...state,
                fetching: false
            }
        case NOTIFICATION_HAS_MORE:
            return {
                ...state,
                fetching:false,
                hasMore: action.payload
            }
        case NOTIFICATION_SET_READ:
            const newItems = state.items.map(item => item.id === action.payload ? {...item, is_read: '1'} : item)
            return {
                ...state,
                items: newItems,
                fetching: false
            }
        default:
            return state

    }

}

export default notificationReducer
