import {DEVICES_ADD, DEVICES_REMOVE, DEVICES_SELECT_ITEM, DEVICES_SET_FETCHING, DEVICES_SET_RESPONSE, DEVICES_UNSET_FETCHING} from "../actions/types";

const initialState = {
    item: {},
    items: [],
    fetching: false,
}

const devicesReducer = (state = initialState, action) =>{

    switch (action.type) {
        case DEVICES_SET_FETCHING:
            return {
                ...state,
                fetching : true
            }
        case DEVICES_UNSET_FETCHING:
            return {
                ...state,
                fetching : false
            }
        case DEVICES_SET_RESPONSE:
            return {
                ...state,
                items : action.payload
            }
        case DEVICES_ADD:
            return {
                ...state,
                items : [...state.items, action.payload]
            }
        case DEVICES_REMOVE:
            const newItems = state.items.filter(item => item.device_id !== action.payload)
            return {
                ...state,
                items : newItems
            }
        case DEVICES_SELECT_ITEM:
            const device = state.items.filter(item => item.device_id === action.payload)
            return {
                ...state,
                item: device
            }
        default:
            return state

    }

}

export default devicesReducer
