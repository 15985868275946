// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA


const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    // console.log(
                    //   'This web app is being served cache-first by a service ' +
                    //     'worker. To learn more, visit https://bit.ly/CRA-PWA'
                    // );
                });
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config);
            }
        });
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            // console.log(
                            //   'New content is available and will be used when all ' +
                            //     'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                            // );

                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            // console.log('Content is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then(response => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}


// *** push notification service worker

// export function registerPushSW() {
//     const applicationServerKey = 'BARNjY8Uq3OlSY6btz5sbWeumXSduU1ljiobhK0-6Mww0wm2J2Hx2oML88sMcbsz_jiRHL2euFJphcIYQF0oSy4';
//
//     const urlBase64ToUint8Array = base64String => {
//         const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
//         //const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
//         const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
//
//
//         const rawData = window.atob(base64);
//         const outputArray = new Uint8Array(rawData.length);
//
//         for (let i = 0; i < rawData.length; ++i) {
//             outputArray[i] = rawData.charCodeAt(i);
//         }
//         return outputArray;
//     }
//
//
//     const getToken = subscription => {
//         return subscription.endpoint
//             .substring(36, subscription.endpoint.length);
//     }
//
//     let token = undefined
//     const is_safari = useragent.browser.name === 'Safari'
//
//     if ('Notification' in window && !is_safari) {
//
//         Notification.requestPermission().then(permission => {
//             if (permission === 'granted') {
//                 const pushServiceworkerURL = `${process.env.PUBLIC_URL}/sw/push-sw.js`
//                 if ('PushManager' in window && 'serviceWorker' in navigator) {
//                     navigator.serviceWorker
//                         .register(pushServiceworkerURL)
//                         .then(registration => {
//                             if (!navigator.serviceWorker.controller) {
//                                 registration.pushManager.getSubscription()
//                                     .then(function (subscription) {
//
//
//                                         // const key = subscription.getKey('p256dh');
//                                         // token = subscription.getKey('auth');
//                                         // const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];
//                                         //
//                                         //
//                                         // const subscriptionObject = JSON.stringify({
//                                         //     endpoint: subscription.endpoint,
//                                         //     publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
//                                         //     authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
//                                         //     contentEncoding
//                                         // })
//                                         //
//                                         // console.log(subscriptionObject)
//
//
//                                         let isSubscribed = !(subscription === null)
//                                         if (isSubscribed) {
//                                             token = getToken(subscription)
//                                             localStorage.setItem(localStorageKeys.pushToken, token)
//                                         } else {
//                                             registration.pushManager.subscribe({
//                                                 userVisibleOnly: true,
//                                                 applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
//                                             }).then(subscription => {
//                                                 token = getToken(subscription)
//                                                 localStorage.setItem(localStorageKeys.pushToken, token)
//                                             })
//                                         }
//                                     });
//                             }
//                         })
//                         .catch(err => {
//                             console.error('push sw registration failed: ', err)
//                         });
//                     return token
//
//                 } else {
//                     console.error('user denied the permission')
//                 }
//             } else {
//                 console.error('service worker not present')
//             }
//         });
//     } else {
//         console.warn('browser does not support notifications')
//     }
//
// }
