import React from "react"
import PropTypes from "prop-types"
import {localize} from "../../helpers/functions";
import {Helmet} from "react-helmet";
import ReactDOMServer from 'react-dom/server';
import {Handles} from "react-compound-slider";
import App from "../../App";



function Head({ description, lang, meta, title, logo = undefined }) {
    // console.log(logo)
    const siteMetadata = {
        title: localize('appTitle'),
        description: 'Enjoyable Entertainment',
        author: 'ApliTV'
    }
    const metaDescription = description || siteMetadata.description

    return (
        <Helmet
        htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                // {
                //     property: `og:url`,
                //     content: `https://reedbarger.com`,
                // },
                // {
                //     property: `og:image`,
                //     content: `http://reedbarger.com/static/ca-styled-logo-5205dbb0983e1531d228c98584ab5711.png`,
                // },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                // {
                //     property: `og:type`,
                //     content: `website`,
                // },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
    />
    )
}
Head.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

Head.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default Head
