import React from 'react'
import {currency} from "../../helpers/constants";
import {addDefaultSrc, localize} from "../../helpers/functions";
import {placeholderImage} from "../../assets/img/exports";

const SubscriptionCarouselItem = props => {
    const placeholder = placeholderImage
    const packageOpen = require('../../assets/img/icons/package_open.png')
    const packageClose = require('../../assets/img/icons/package_close.png')

    const title = props.title ? props.title : ''
    const img = props.img ? props.img : placeholder
    const onClick = props.onClick ? props.onClick : undefined
    const selected = props.selected ? props.selected : false
    const price = props.price ? props.price : ''
    const unselectedImg = props.unselectedImg ? props.unselectedImg : packageClose
    const selectedImg = props.selectedImg ? props.selectedImg : packageOpen
    const localizedCurrency = localize(currency)
    const fromBundle = !!props.fromBundle ? true : false

    let render = null
    if (onClick && !fromBundle) {
        render = (
            <div className={` clickable ${selected ? 'selected' : ''}`} onClick={onClick}>
                <p className='sub-carousel-price'><span className="sub-number">{price} {localizedCurrency}</span></p>
                <img src={selected ? selectedImg : unselectedImg} alt=""/>
                <p className='sub-carousel-title'>{title}</p>
            </div>

        )
    }else if (onClick && fromBundle){
        render = (
            <div className='clickable-bundle' onClick={onClick}>
                <img src={img} style={{borderRadius:'8px'}} alt="" onError={addDefaultSrc}/>
                <p className='sub-carousel-title'>{title}</p>
            </div>
        )
    }
    else {
        render = (
            <>
                <img src={img} style={{borderRadius:'8px'}} alt="" onError={addDefaultSrc}/>
                <p className='sub-carousel-title'>{title}</p>
            </>
        )
    }
    return render
}

export default SubscriptionCarouselItem
