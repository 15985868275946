import React from 'react'
import {Modal} from "antd";
import {addRTL, localize, switchReferral} from "../../helpers/functions";
import {localStorageKeys, operations} from "../../helpers/enums";
import {androidAppLink, currentOperation, downloadAPK, pwaLink} from "../../helpers/constants";
import {useSelector} from "react-redux";

const ReferralModel = props => {
    const referralCode = localStorage.getItem(localStorageKeys.referralCode)
    const rtl = useSelector(state => state.settings.rtl)

    const shareCode = () => {
        if (navigator.share) {
            // let shareData = {url: window.location.origin.concat(`/referral/?referralCode=${referralCode}`)}
            let shareData = {url: window.location.origin.concat(`/?referralCode=${referralCode}`)}
            let androidLink = <p><a href=""></a></p>
            if(downloadAPK){
                androidLink = <p><a href={androidAppLink}></a></p>
            }

            if(navigator.userAgent.indexOf("Firefox") == -1){
                shareData = {
                    title: localize('share'),
                    text: `${localize('shareReferral1')}${window.location.origin.concat(`/?referralCode=${referralCode}`)}`,
                    // text: `${localize('shareReferral1')}${window.location.origin.concat(`/referral/?referralCode=${referralCode}`)}`,
                }
            }
            navigator.share(shareData)
                .then(() => switchReferral(false))
                .catch((error) => {
                    switchReferral(false)
                    console.log('Error sharing', error)
                });
        }
    }
    return (
        <Modal
            className={`notification-modal`}
            visible={true}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => switchReferral(false)}
            centered
        >
            <p className={`notification-modal-title`}>{localize('referral_title')}</p>
            <p className={`notification-modal-message-referral ${addRTL(rtl)}`}>{localize('referral_description')}</p>
            <p className='notification-modal-referral-code'>{referralCode}</p>
            <div className='notification-modal-buttons'>
                <button className='notification-btn' onClick={() => shareCode()}>{localize('SHARE')}</button>
            </div>

        </Modal>

    )
}


export default ReferralModel
