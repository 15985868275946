import {
    CHANGE_BITRATE,
    CHANGE_CAPTION,
    CHANGE_LANG, CW_POPUP_VISIBLE,
    DEVICE_INFO, HEADER_FETCHING, MENU_LOGOUT_VISIBLE,
    SETTING_CHANGE_AVATAR,
    SETTING_SET_AVATAR_FETCHING,
    SETTING_SET_DRAWER_VISIBLE,
    SETTING_SET_PURCHASE_MODAL_GIFT_VISIBLE,
    SETTING_SET_PURCHASE_MODAL_VISIBLE,
    SETTING_UNSET_AVATAR_FETCHING,
    SETTING_UNSET_PURCHASE_MODAL_VISIBLE,
    SETTINGS_SET_ACCEPT_NOTIFICATIONS,
    SETTINGS_SET_BOOTSTRAP, SETTINGS_SET_CHARGING_STATUS,
    SETTINGS_SET_NETWORK,
    SETTINGS_SETFETCHING_ACCEPT_NOTIFICATIONS,
    SETTINGS_UNSET_INIT
} from "./types";
import {localStorageKeys, operations} from "../../helpers/enums";
import {message} from "antd";
import {handleHttpError, jsonToFormData, setHintDialog} from "../../helpers/functions";
import axios from "axios";
import {getSetUserSettings, getUserProfileInfo, host, saveUserFeedback, saveUserProfile, storeProfilePicture} from "../api/endPoints";
import store from "../store";
import {currentOperation, defaultBitRate, defaultSubtitleLanguage, supportedLanguages} from "../../helpers/constants";
import {avatarIcon} from "../../assets/img/exports";

const dispatch = store.dispatch

export const changeLang = lang => {
    if (supportedLanguages.ar === lang || supportedLanguages.fr === lang || supportedLanguages.en === lang || supportedLanguages.por === lang){
        if (lang === 'ar') require('moment/locale/ar-kw')
        const auth = store.getState().auth.authToken
        const req = {auth, language: lang}
        const params = jsonToFormData(req)
        axios.post(host + getSetUserSettings, params)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem(localStorageKeys.lang, lang)
                    dispatch({
                        type: CHANGE_LANG,
                        payload: {lang}
                    })
                }
            })
            .catch(error => {
                handleHttpError(error)
            })

    } else {
        setHintDialog('setlanguageFailed')
        // message.error('Error in changing language')
    }
}

export const deviceinfoSet = device => {
    dispatch({
        type:DEVICE_INFO,
        payload:device
    })
}
export const changeCaption = Caption => {
    localStorage.setItem(localStorageKeys.captions, Caption)
    dispatch({
        type: CHANGE_CAPTION,
        payload: Caption
    })
}
export const changeBitRate = bitRate => {
    localStorage.setItem(localStorageKeys.bitRate, bitRate)
    dispatch({
        type: CHANGE_BITRATE,
        payload: bitRate
    })
}

export const changeAcceptNotifications = value => {
    const auth = store.getState().auth.authToken
    const acceptNotifications = value ? '1' : '0'
    const req = {auth, flag: acceptNotifications}
    const params = jsonToFormData(req)
    dispatch({
        type: SETTINGS_SETFETCHING_ACCEPT_NOTIFICATIONS
    })
    axios.post(host + getSetUserSettings, params)

        .then(res => {
            if (res.status === 200) {
                localStorage.setItem(localStorageKeys.acceptNotifications, acceptNotifications)
                dispatch({
                    type: SETTINGS_SET_ACCEPT_NOTIFICATIONS,
                    payload: acceptNotifications
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const unsetInit = () => {
    dispatch({
        type: SETTINGS_UNSET_INIT,
    })
}

export const changeNetworkStats = (status) => {
    dispatch({
        type: SETTINGS_SET_NETWORK,
        payload: status
    })
}

export const showPurchaseModal = () => {
    dispatch({type: SETTING_SET_PURCHASE_MODAL_VISIBLE,})
}

export const hideModal = () => {
    localStorage.setItem(localStorageKeys.visible,'0')
    dispatch({type: SETTING_UNSET_PURCHASE_MODAL_VISIBLE,})
    // dispatch({
    //     type:PLAYER_LIMIT_TIME,
    //     payload:{items:[],titleMsg:''},
    // })
}

export const updateContinueWatchingModal = (value) => {
    // localStorage.setItem(localStorageKeys.visible,'0')
    dispatch({
        type: CW_POPUP_VISIBLE,
    })
}

export const showGiftModal = () => {
    dispatch({type: SETTING_SET_PURCHASE_MODAL_GIFT_VISIBLE,})
}

export const setBootstrap = (lang, acceptNotifications) => {
    const caption = !!localStorage.getItem(localStorageKeys.captions) ? localStorage.getItem(localStorageKeys.captions) : defaultSubtitleLanguage
    const bitRate = !!localStorage.getItem(localStorageKeys.bitRate) ? localStorage.getItem(localStorageKeys.bitRate) : defaultBitRate

    if (currentOperation === operations.yabadoo || currentOperation === operations.tmcel || currentOperation === operations.orange || currentOperation === operations.mtnCameroon){//plus
        if(localStorage.getItem(localStorageKeys.countryName) === null){
            localStorage.setItem(localStorageKeys.countryName,operations.yabadoo)
            localStorage.setItem(localStorageKeys.operatorName,operations.yabadoo)
            // localStorage.setItem(localStorageKeys.currentOperation,operations.yabadoo)
        }else if (localStorage.getItem(localStorageKeys.operatorName) === null){
            localStorage.setItem(localStorageKeys.operatorName,operations.yabadoo)
        }
        // else if (localStorage.getItem(localStorageKeys.currentOperation) === null){
        //     localStorage.setItem(localStorageKeys.currentOperation,operations.yabadoo)
        // }
    }

    store.dispatch({
        type: SETTINGS_SET_BOOTSTRAP,
        payload: {lang, acceptNotifications, caption, bitRate}
    })
}
export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
export const removeProfilePicture = file => {
    const auth = store.getState().auth.authToken
    const pic = file
    const req = {auth, profile: pic}
    const params = jsonToFormData(req)
    dispatch({type: SETTING_SET_AVATAR_FETCHING,})
    axios.post(host + storeProfilePicture, params)
        .then(res => {
            if (res.status === 200) {
                //setError(res.data.msg)
                //message.success(res.data.msg)

                const reader = new FileReader();
                reader.onload = e => dispatch({type: SETTING_CHANGE_AVATAR, payload:avatarIcon})
                reader.readAsDataURL(pic);

            }
        })
        .catch(error => {
            dispatch({type: SETTING_UNSET_AVATAR_FETCHING})
            handleHttpError(error)
        })
}
export const changeProfilePicture = e => {
    const auth = store.getState().auth.authToken
    const pic = e.target.files[0]
    const req = {auth, profile: pic}
    const params = jsonToFormData(req)
    dispatch({type: SETTING_SET_AVATAR_FETCHING,})
    axios.post(host + storeProfilePicture, params)
        .then(res => {
            if (res.status === 200) {
                //setError(res.data.msg)
                message.success(res.data.msg)

                const reader = new FileReader();
                reader.onload = e => dispatch({type: SETTING_CHANGE_AVATAR, payload: e.target.result})
                reader.readAsDataURL(pic);

            }
        })
        .catch(error => {
            dispatch({type: SETTING_UNSET_AVATAR_FETCHING})
            handleHttpError(error)
        })
}

export const deleteProfilePicture = e => {
    const auth = store.getState().auth.authToken
    const pic = e
    const req = {auth, profile: pic}
    const params = jsonToFormData(req)
    dispatch({type: SETTING_SET_AVATAR_FETCHING,})
    axios.post(host + storeProfilePicture, params)
        .then(res => {
            if (res.status === 200) {
                //setError(res.data.msg)
                message.success(res.data.msg)

                const reader = new FileReader();
                reader.onload = e => dispatch({type: SETTING_CHANGE_AVATAR, payload: e.target.result})
                reader.readAsDataURL(pic);

            }
        })
        .catch(error => {
            dispatch({type: SETTING_UNSET_AVATAR_FETCHING})
            handleHttpError(error)
        })
}

export const resetProfilePicture = () => {
    const avatarPlaceholder = require('../../assets/img/placeholder/avatar.png')
    dispatch({type: SETTING_CHANGE_AVATAR, payload: avatarPlaceholder})
}

export const setDrawerVisible = (visible = false) => {
    dispatch({
        type: SETTING_SET_DRAWER_VISIBLE,
        payload: visible
    })
}

export const showLogoutModel = (state) => {
    dispatch({
        type: MENU_LOGOUT_VISIBLE,
        payload: state
    })
}

export const setUserProfile = (nickName, description) => {
    const auth = store.getState().auth.authToken
    const msisdn = store.getState().settings.username
    let req = {
        auth,
        msisdn,
        nickname: nickName,
        description: description,
    }
    const params = jsonToFormData(req)
    return axios.post(host + saveUserProfile, params)
}

export const getUserInfo = (username) => {
    const auth = store.getState().auth.authToken
    const msisdn = username
    let req = {
        auth,
        msisdn
    }
    const params = jsonToFormData(req)
    return axios.post(host + getUserProfileInfo, params)
}

export const setChargeStatus = (status) => {
    dispatch({
        type: SETTINGS_SET_CHARGING_STATUS,
        payload: status
    })
}

export  const setHeaderFetching = (value) => {
    dispatch({
        type: HEADER_FETCHING,
        payload: value
    })
}
