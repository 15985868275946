// this file for operation specific images imports
import {currentOperation} from "../../helpers/constants";
import {operations} from "../../helpers/enums";

let loginBackground = require('./bg/login-bg.jpg')
let wideLogo = require('./logos/yabadoo-logo.png')
let wideLogoMenu = require('./logos/yabadoo-logo.png')
let menuCenterLogo = require('./logos/yabadoo-logo.png')
let loadingImages = require('./b2.gif')
let placeholderImage = require('./yabadoo-icon-512x512.png')
let iconCamera = require('./icons/profile_camera.png')
let refreshIcon = require('./icons/icons8-update-left-rotation-96.png')
let avatarIcon = require('./placeholder/avatar.png')
let continueWatchingPlayIcon = require('./icons/playIcon.png')
let trailerIcon = require('./icons/watchtrailer xxhdpi.png')
let shareIcon = require('./icons/shareIcon.png')
let installAppChrome = require('./icons/Download-button.png')
let installAppChromeAndroidDevice = require('./icons/output-onlinepngtools (2).png')
let shareIconChrome = require('./icons/addToHomeScreenChrome.jpg')
let ratingOff = require('./icons/star_off.png')
let ratingOn = require('./icons/star_on.png')

if (currentOperation === operations.aplitv) {
    iconCamera = require('./icons/profillle_camera_locally.png')
    loadingImages = require('./APLITV-GIF.gif')
} else if (currentOperation === operations.ishow) {
    wideLogo = require('./logos/ishow-wide-logo.png')
    wideLogoMenu = require('./logos/ishow-wide-logo.png')
    menuCenterLogo = require('./logos/ishow-wide-logo.png')
    loadingImages = require('./iShowGIF.gif')
    iconCamera = require('./icons/profile_camera-ishow.png')
    loginBackground = require('./bg/iShowBK.png')
    placeholderImage = require('./logos/ishowPlaceholder.png')
} else if (currentOperation === operations.mtnSyria) {
    loginBackground = require('./bg/login-bg-mtn.jpg')
    wideLogo = require('./logos/mtn-logo.png')
    wideLogoMenu = require('./logos/mtn-logo.png')
    menuCenterLogo = require('./logos/mtn-logo.png')
    loadingImages = require('./MtnSyria.gif')
    placeholderImage = require('./mtn-tv-512x512.png')
} else if (currentOperation === operations.mtnClipSyria) {
    loginBackground = require('./bg/login-bg-mtn.jpg')
    wideLogo = require('./logos/mtn-logo.png')
    wideLogoMenu = require('./logos/mtn-logo.png')
    menuCenterLogo = require('./logos/mtn-logo.png')
    loadingImages = require('./MtnSyria.gif')
    placeholderImage = require('./mtn-tv-512x512.png')
} else if (currentOperation === operations.nexttel || currentOperation === operations.nexttelLocal) {
    wideLogo = require('./logos/nexttel-wide-logo.png')
    wideLogoMenu = require('./logos/nexttel-wide-logo.png')
    menuCenterLogo = require('./logos/nexttellogo-512.png')
    placeholderImage = require('./placeholder/nexttelPlaceHolder.png')
    loadingImages = require('./APLITV-GIF.gif')
    iconCamera = require('./icons/profile_camera_nex.png')
    loginBackground = require('./bg/nexttel-background.png')
} else if (currentOperation === operations.mtnCameroon || currentOperation === operations.yabadoo || currentOperation === operations.orange || currentOperation === operations.tmcel || currentOperation === operations.vodacom) {
    loginBackground = require('./bg/web-login.png')
} else if (currentOperation === operations.alMadar) {
    wideLogo = require('./logos/almadar-wide-logo.png')
    wideLogoMenu = require('./logos/almadar-wide-logo.png')
    menuCenterLogo = require('./logos/almadar-wide-logo.png')
    loadingImages = require('./APLITV-GIF.gif')
}else if (currentOperation === operations.alAwal){
    loginBackground = require('./bg/sh1Back.png')
    wideLogo = require('./logos/shashatcomLogo.png')
    iconCamera = require('./icons/profillle_camera_shashatcom.png')
    loadingImages = require('./sh-loading-gif.gif')
    wideLogoMenu = require('./icons/sh-light.png')
    menuCenterLogo = require('./icons/sh-light.png')
    placeholderImage = require('./logos/shashatcomLogo.png')
}


export {loginBackground,continueWatchingPlayIcon,wideLogo,shareIconChrome, shareIcon, loadingImages, placeholderImage, iconCamera, menuCenterLogo, wideLogoMenu, refreshIcon, avatarIcon, trailerIcon, installAppChrome, ratingOff, ratingOn, installAppChromeAndroidDevice}
