import React, {useEffect, useState} from 'react'
import {Modal, Switch} from 'antd';
import {addRTL, localize, renderHours} from "../../helpers/functions";
import store from "../../redux/store";
import 'antd/lib/switch/style/index.css'
import {currentOperation, isOneTime} from "../../helpers/constants";
import {operations} from "../../helpers/enums";
import {setChargeStatus} from "../../redux/actions/settingsActions";


const ConfirmPurchaseModal = props => {
    const title = props.title ? props.title : localize('purchaseConfirm')
    const name = props.name ? props.name : ''
    const price = props.price ? props.price : ''
    const duration = props.duration ? renderHours(props.duration) : ''
    const giftPhoneNumber = props.giftPhoneNumber ? props.giftPhoneNumber : ''
    const loading = !!props.loadingArrow ? props.loadingArrow : false
    const type = !!props.type ? props.type : undefined
    const {rtl,mobileMoney} = store.getState().settings
    const oneTime = !!props.oneTime ? props.oneTime : ''
    const isRenew = !!props.isRenew ? props.isRenew : ''
    const [renew,setRenew] = useState(isRenew === '1' ? true : false)
    const isDoublePlay = !!props.isDoublePlay ? props.isDoublePlay : undefined
    const [isMobileMoney,setMobileMoneyChecked] = useState(false)
    const [isAirTime,setAirTime] = useState(true)

    const toggleSwitch = (renew) => {
        setRenew(!renew)
        props.onSwitch(!renew)
    }

    const updateCheckBox = (airTime,mobile) => {
        setAirTime(airTime)
        setMobileMoneyChecked(mobile)
        setChargeStatus(airTime ? "0" : "1")
    }

    useEffect(() => {
        setChargeStatus("0")
    },[])

    return (
        <Modal
            className={`confirm-modal`}
            visible={!!props.visible ? !!props.visible : false}
            footer={null}
            closable={false}
            maskClosable={false}
            onCancel={props.onCancel}
            centered
        >
            {loading ? (
                <div className="sub-overlay">
                    <span className="spinner-border"/>
                </div>
            ) : null}

            <p className={`confirm-modal-title ${addRTL(rtl)}`}>{title}</p>
            {name !== '' && !props.gift ? <p className={`confirm-modal-desc ${addRTL(rtl)}`}>{localize('name')}: {name}</p> : null}
            {name !== '' && !props.gift ? <p className={`confirm-modal-desc ${addRTL(rtl)}`}>{localize('price')}: {price}</p> : null}
            {duration !== '' && !props.gift ? <p className={`confirm-modal-desc ${addRTL(rtl)}`}>{currentOperation === operations.ishow ? localize('valid') : localize('validFor')}: {duration} {isDoublePlay}</p> : null}
            {props.gift ?  <p className={`confirm-modal-title ${addRTL(rtl)}`}>{giftPhoneNumber}</p> : null }

            <div className={`row-switch ${addRTL(rtl)}`}>
                {!!type && !props.gift && isOneTime ? <p className={`confirm-modal-desc`} style={{fontSize:"11px"}}>{!!oneTime && oneTime === '0' && isRenew === '1' ? localize('renewed_note') : !!oneTime && oneTime === '1' ? localize('notrenewed_note') : null}</p> :
                    !!type && !props.gift ? <p className={`confirm-modal-desc`} style={{fontSize:"12px"}}>{localize('renewed_note')}</p> : null}
                {currentOperation === operations.ishow && !props.gift && !!name && oneTime === '0' ? <Switch checked={renew} onClick={() => toggleSwitch(renew)}/> : null}
            </div>


            {mobileMoney === "1" ? <div className="mobile-payment-dialog">
                <label className="checkbox-field">
                    <input
                        type="checkbox"
                        className='checkbox-payment'
                        checked={isAirTime}
                        onChange={() => !isAirTime ? updateCheckBox(true,false) : null}
                    />
                    {localize('payAirtime')}
                </label>
                <label className="checkbox-field">
                    <input
                        type="checkbox"
                        className='checkbox-payment'
                        checked={isMobileMoney}
                        onChange={() => isAirTime ? updateCheckBox(false,true) : null}
                    />
                    {localize('payMobile')}
                </label>
            </div> : null}
            <div className='confirm-modal-buttons'>
                <button className='btn btn-dark bordered' onClick={props.onOk}>{props.gift ? localize('send') : props.fromRetry ? localize('retry') : localize('ok')}</button>
                {props.onCancel ? <button className='btn btn-dark bordered' onClick={props.onCancel}>{props.fromRetry ? localize('exit') : localize('cancel')}</button> : null}
            </div>

        </Modal>

    )
}

export default ConfirmPurchaseModal
