import {
    PLAYER_CHANGE_CURRENT_LINK,
    PLAYER_OPEN_LIVE_PLAYER,
    PLAYER_PREVIEW_LIVE,
    PLAYER_SET_CURRENT_LINK,
    PLAYER_SET_MINIMIZED,
    PLAYER_SET_RETRY,
    PLAYER_SET_VISIBLE,
    PLAYER_TOGGLE_FULLSCREEN,
    PLAYER_TOGGLE_MINIMIZED,
    PLAYER_UNSET_RETRY,
    PLAYLIST_ADD_ITEM,
    PLAYLIST_SELECT_ITEM,
    PLAYLIST_SELECT_ITEM_LIVE,
    PLAYLIST_SELECT_ITEM_LIVE_PRICES,
    PLAYLIST_SET_BOOTSTRAP_RESPONSE,
    PLAYLIST_SET_CURRENT_LINK,
    PLAYLIST_SET_ERROR,
    PLAYLIST_SET_FETCHING,
    PLAYLIST_SET_RESPONSE,
    PLAYLIST_SET_SERIES,
    PLAYLIST_UNSET_FETCHING,
    SET_DST_LINK,
    SET_TIME_RETRY, PLAYLIST_ADD_PREDICTIONS, SET_FETCHING_PREDICTION,TOGGLE_CHAT,
    SET_LIVE_SESSION_END
} from "../actions/types";
import {playerTypes} from "../../helpers/enums";

const initialState = {
    items: [],
    series: [],
    item: {},
    prices:[],
    currentLink: '',
    fetching: false,
    enablePreviewLive: false,
    visible: false,
    minimized: false,
    fullscreen: false,
    type: undefined, //service type
    chatEnabled: false,
    error: false,
    previewDuration: 0,
    retry:false,
    time:'',
    predictions:{},
    fetchingPrediction: false,
    liveSessionEnd:false
}

const playlistReducer = (state = initialState, action) => {

    switch (action.type) {
        case PLAYLIST_SET_RESPONSE:
            return {
                ...state,
                items: action.payload,
                item: action.payload[0],
                currentLink: action.payload[0].url,
                fetching: false,
            }
        case PLAYLIST_SET_BOOTSTRAP_RESPONSE:
            return {
                ...state,
                items: action.payload.playlist,
                item: action.payload.playlist[0],
                currentLink: action.payload.playlist[0].url,
                fetching: false,
                enablePreviewLive: action.payload.enablePreviewLive,
            }
        case PLAYLIST_SET_SERIES:
            return {
                ...state,
                series: action.payload.list,
                item: action.payload.item,
                //currentLink: action.payload.item.url,
                type: playerTypes.series,
                chatEnabled: false,
                fetching: true,
                visible: true,
            }
        case PLAYLIST_SET_CURRENT_LINK:
            return {
                ...state,
                currentLink: action.payload,
                fetching: false,
            }
        case PLAYLIST_ADD_ITEM:
            return {
                ...state,
                items: [...state.items, action.payload],
                chatEnabled: true,
                type: playerTypes.live,
                currentLink: action.payload.url,
                //fetching: false,
                visible: true,
                previewDuration: 0,
            }
        case PLAYLIST_SELECT_ITEM:
            return {
                ...state,
                item: action.payload,
                //currentLink: action.payload.url ? action.payload.url : state.currentLink,
                //fetching: true,
                error: false,
            }
        case PLAYLIST_SELECT_ITEM_LIVE:
            return {
                ...state,
                item: action.payload,
                //currentLink: action.payload.url ? action.payload.url : state.currentLink, //DST
                prices:action.payload.prices,
                type: playerTypes.live,
                chatEnabled: true,
                visible: true,
                //fetching: true,
                error: false,
            }
        case PLAYLIST_SELECT_ITEM_LIVE_PRICES:
            return {
                ...state,
                prices:action.payload
            }
        case PLAYLIST_SET_FETCHING:
            return {
                ...state,
                fetching: true,
            }
        case PLAYLIST_UNSET_FETCHING:
            return {
                ...state,
                fetching: false,
            }
        case PLAYER_SET_VISIBLE:
            return {
                ...state,
                visible: action.payload,
                minimized: false,
                fullscreen: false,
                previewDuration: 0
            }
        case PLAYER_SET_RETRY:
            return {
                ...state,
                retry:action.payload
            }
        case SET_TIME_RETRY:
            return {
                ...state,
                time:action.payload
            }
        case PLAYER_UNSET_RETRY:
            return {
                ...state,
                retry:action.payload
            }
        case PLAYER_SET_MINIMIZED:
            return {
                ...state,
                minimized: action.payload
            }
        case PLAYER_TOGGLE_MINIMIZED:
            return {
                ...state,
                minimized: !state.minimized
            }
        case PLAYER_TOGGLE_FULLSCREEN:
            return {
                ...state,
                fullscreen: !!action.payload ? action.payload : !state.fullscreen
            }
        case PLAYER_SET_CURRENT_LINK:
            return {
                ...state,
                visible: true,
                type: playerTypes.video,
                chatEnabled: false,
                //currentLink: action.payload.url,
                item: action.payload,
            }
        case PLAYER_CHANGE_CURRENT_LINK:
            return {
                ...state,
                currentLink: action.payload.link,
                item: action.payload.item,
            }
        case PLAYER_OPEN_LIVE_PLAYER:
            return {
                ...state,
                visible: true,
                fetching: true,
                error: false,
                type: playerTypes.live,
                chatEnabled: true,
                previewDuration: 0,
                item: action.payload,
                liveSessionEnd:false
                //currentLink: action.payload.url,
            }
        case PLAYER_PREVIEW_LIVE:
            return {
                ...state,
                visible: true,
                type: playerTypes.live,
                chatEnabled: true,
                item: action.payload,
                previewDuration: action.payload.previewDuration,
                //currentLink: action.payload.url,
                is_encrypted: action.payload.is_encrypted,
            }
        case PLAYLIST_SET_ERROR:
            return {
                ...state,
                error: true
            }
        case SET_DST_LINK:
            return {
                ...state,
                currentLink:action.payload,
            }
        case PLAYLIST_ADD_PREDICTIONS:
            return {
                ...state,
                predictions: action.payload
            }
        case SET_FETCHING_PREDICTION:
            return {
                ...state,
                fetchingPrediction: action.payload
            }
        case TOGGLE_CHAT:
            return {
                ...state,
                chatEnabled: action.payload
            }
        case SET_LIVE_SESSION_END:
            return {
                ...state,
                liveSessionEnd:action.payload
            }
        default:
            return state

    }
}

export default playlistReducer
