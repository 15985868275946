import React, {useEffect, useState} from 'react'
import {Modal} from "antd";
import Swiper from 'react-id-swiper';
import {localize, setHintDialog} from "../../helpers/functions";
import {currency, currentOperation, displayAutoRenew, doubleOpt, isClipsRootPage, phoneNumber} from "../../helpers/constants";
import {isMovie, localStorageKeys, operations, planModelTypes, purchaseTabs, purchaseTypes, serviceType} from "../../helpers/enums";
import {useSelector} from "react-redux";
import {purchasePackage, watchFromPackage} from "../../redux/actions/packagesActions";
import SubscriptionItem from "./SubscriptionItem";
import SubscriptionCarouselItem from "./SubscriptionCarouselItem";
import {hideModal} from "../../redux/actions/settingsActions";
import ConfirmPurchaseModal from "./ConfirmPurchaseModal";
import {Routes} from "../../routes/AppRouter";
import {navigate} from "@reach/router";
import store from "../../redux/store";

const SubscriptionModal = props => {
    const additionalTime = !!store.getState().player.items ? store.getState().player.items : undefined
    const selectedlimitTime = !!additionalTime ? store.getState().player.items[store.getState().player.items.length - 1] : undefined
    const streamLimitTitle = !!store.getState().player.titleMsg ? store.getState().player.titleMsg : undefined
    const loadingArrow = useSelector(state => state.playlist.fetching)
    const {packages, purchasedPackages, displayPackages} = useSelector(state => state.packages)
    const {rtl, purchaseModal} = useSelector(state => state.settings)
    const [giftNumber, setGiftNumber] = useState(phoneNumber.prefix)
    const [tab, setTab] = useState(0)
    const [selectedPackage, setSelectedPackage] = useState(0)
    const [activeTabs, setActiveTabs] = useState([purchaseTabs.none])
    const [availablePackages, setAvailablePackages] = useState([])
    const [confirmModal, setConfirmModal] = useState(0)
    const loading = !!props.loading ? props.loading : false
    const video = !!props.video ? props.video : undefined
    const service = !!props.service ? props.service : undefined
    const {gift} = props
    const packageMovies = require('../../assets/img/icons/package_movie.png')
    const packageSeries = require('../../assets/img/icons/package_series.png')
    const packagePlays = require('../../assets/img/icons/package_plays.png')
    const packageClips = require('../../assets/img/icons/package_clips.png')
    const localizedCurrency = localize(currency)
    const {visible} = useSelector(state => state.playlist)
    const servicePrices = !!service.prices ? service.prices : store.getState().playlist.prices
    // const filteredPackages = !!packages && packages.length > 0 ? packages.filter(item => item.types.find(iItem => iItem.video_type_id === service.is_movie && Number.parseInt(iItem.pkg_quantity) > 0)) : []
    const filteredPackages = !!packages && packages.length > 0 ? packages.filter(item => item.types.find(iItem => Number.parseInt(iItem.pkg_quantity) > 0)) : []
    // const [renew,setRenew] = useState('1')


    const carouselSettings = {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        rtl: rtl ? 'rtl' : undefined,
        observer: true,
        observeParents: true,
        lazy: true
    };

    useEffect(() => {
        if (!!service && service.service_id && !!video) {
            let availablePackagesForItem = []
            if (service.is_movie === isMovie.movies) {
                purchasedPackages.forEach(item => {
                    const found = item.types.findIndex(iItem => iItem.usr_video_type_id === isMovie.movies && Number.parseInt(iItem.usr_type_remaining) > 0)
                    if (found !== -1) availablePackagesForItem.push(item)
                })
            } else if (service.is_movie === isMovie.series) {
                purchasedPackages.forEach(item => {
                    const found = item.types.findIndex(iItem => iItem.usr_video_type_id === isMovie.series && Number.parseInt(iItem.usr_type_remaining) > 0)
                    if (found !== -1) availablePackagesForItem.push(item)
                })
            } else if (service.is_movie === isMovie.clips) {
                purchasedPackages.forEach(item => {
                    const found = item.types.findIndex(iItem => iItem.usr_video_type_id === isMovie.clips && Number.parseInt(iItem.usr_type_remaining) > 0)
                    if (found !== -1) availablePackagesForItem.push(item)
                })
            } else if (service.is_movie === isMovie.plays) {
                purchasedPackages.forEach(item => {
                    const found = item.types.findIndex(iItem => iItem.usr_video_type_id === isMovie.plays && Number.parseInt(iItem.usr_type_remaining) > 0)
                    if (found !== -1) availablePackagesForItem.push(item)
                })
            }
            if (availablePackagesForItem.length > 0 && displayPackages && !gift) {
                setActiveTabs([purchaseTabs.fromPackage])
                setTab(purchaseTabs.fromPackage)
                setAvailablePackages(availablePackagesForItem)
            } else {
                if (service.is_ppv === "1") {
                    if (displayPackages) setActiveTabs([purchaseTabs.ppv, purchaseTabs.packages])
                    else setActiveTabs([purchaseTabs.ppv])
                    setTab(purchaseTabs.packages)
                } else if (servicePrices.length > 0 || (servicePrices.length === 0 && !!props.bundleServices && props.bundleServices.length > 0)) {
                    if (displayPackages) setActiveTabs([purchaseTabs.subscription, purchaseTabs.packages])
                    else setActiveTabs([purchaseTabs.subscription])
                    setTab(purchaseTabs.subscription)
                } else if (displayPackages) {
                    setActiveTabs([purchaseTabs.packages])
                    setTab(purchaseTabs.packages)
                }
            }
        } else if (!!service && (service.service_id || service.bundle_id) && video === undefined) {
            if (service.is_ppv === "1") {
                setActiveTabs([purchaseTabs.ppv])
                setTab(purchaseTabs.ppv)
            } else if (!!props.fromLimit && props.fromLimit && !!servicePrices && servicePrices.length > 0){
                setActiveTabs([purchaseTabs.subscription])
                setTab(purchaseTabs.subscription)
            }else if ((servicePrices.length > 0 /*|| (servicePrices.length === 0 && !!props.bundleServices && props.bundleServices.length > 0)*/ || (localStorage.getItem(localStorageKeys.visible) !== '0'))) {
                // setActiveTabs([purchaseTabs.subscription])
                if (displayPackages && service.service_type === serviceType.vod){// check if service not bundle
                    if (additionalTimePlan === -1 && visible){ // service has no subscription model and already exist a packages
                        setActiveTabs([purchaseTabs.packages])
                        setTab(purchaseTabs.packages)
                    }
                    else if((!visible || (visible && additionalTimePlan !== -1)) && servicePrices.length > 0){ // model while player not visible or visible and has plan model for additional time
                        setActiveTabs([purchaseTabs.subscription, purchaseTabs.packages])
                        setTab(purchaseTabs.subscription)
                    }else if(servicePrices.length === 0){
                        setActiveTabs([purchaseTabs.packages])
                        setTab(purchaseTabs.packages)
                    }
                }
                else{
                    setActiveTabs([purchaseTabs.subscription])
                    setTab(purchaseTabs.subscription)
                }
            } else if (displayPackages) {
                setActiveTabs([purchaseTabs.packages])
                setTab(purchaseTabs.packages)
            }
        }

        return () => {
            if (purchaseModal.visible) hideModal()
        }

    }, [service, video, displayPackages, gift, purchaseModal.visible, purchasedPackages])

    const _purchaseSubscription = subscription => {
        if (doubleOpt) {
            const title = localize('purchaseConfirm')
            const name = !!subscription.is_movie && subscription.is_movie === '1' ? subscription.video_title : !!subscription.service_name ? subscription.service_name : subscription.bundle_name
            const duration = subscription.duration
            const doublePlay = subscription.double_play
            const price = `${subscription.balance}  ${localize(currency)}`
            const type = purchaseTypes.subscription
            const oneTime = !!subscription.one_time ? subscription.one_time : ''
            const isRenew = currentOperation === operations.mtnSyria ? !!oneTime && oneTime === '0' && subscription.is_renew === '1' ? subscription.is_renew : '0' : currentOperation === operations.ishow ? '1' : subscription.is_renew
            // const isRenew = !!subscription.is_renew ? subscription.is_renew : currentOperation === operations.ishow ? '1' : undefined
            const callback = () => {
                props.onPurchase(subscription,'',currentOperation === operations.ishow ? !!localStorage.getItem(localStorageKeys.renew) ? localStorage.getItem(localStorageKeys.renew) : '1' : isRenew)
                localStorage.removeItem(localStorageKeys.renew)
            }
            setConfirmModal({title, name, duration, price, callback, type, oneTime, isRenew, doublePlay})
        } else {
            props.onPurchase(subscription,'',!!subscription.is_renew ? subscription.is_renew : currentOperation === operations.ishow ? '1' : undefined)
        }
    }
    const _giftSubscription = subscription => {
        if (giftNumber === phoneNumber.prefix) {
            setHintDialog('enterGiftNumber')
            // message.error(localize('enterGiftNumber'))
        } else if (giftNumber.length !== phoneNumber.limit) {
            setHintDialog('notValidphoneNumber',localize('notValidphoneNumberTitle'))
            // message.error(localize('notValidphoneNumber'))
        } else {
            if (doubleOpt) {
                const title = localize('giftConfirm')(!!subscription.service_name ? subscription.service_name : !!subscription.bundle_name ? subscription.bundle_name : '')
                const name = subscription.service_name
                const duration = subscription.duration
                const price = `${subscription.balance}  ${localize(currency)}`
                const callback = () => {
                    props.onPurchase(subscription, giftNumber)
                    setGiftNumber('')
                }
                setConfirmModal({title, name, duration, price, callback})
            } else {
                props.onPurchase(subscription, giftNumber)
                setGiftNumber('')
            }
        }
    }

    const _purchasePPV = ppv => {
        if (doubleOpt) {
            const title = localize('purchaseConfirm')
            const name = ppv.video_title
            const duration = ppv.duration
            const price = `${ppv.video_price}  ${localize(currency)}`
            const callback = () => props.onPurchase(ppv)
            setConfirmModal({title, name, duration, price, callback})

        } else {
            props.onPurchase(ppv)
            setGiftNumber('')
        }
    }

    const _giftPPV = ppv => {
        if (giftNumber === phoneNumber.prefix) {
            setHintDialog('enterGiftNumber')
            // message.error(localize('enterGiftNumber'))
        } else if (giftNumber.length !== phoneNumber.limit) {
            setHintDialog('notValidphoneNumber',localize('notValidphoneNumberTitle'))
            // message.error(localize('notValidphoneNumber'))
        } else {
            if (doubleOpt) {
                const title = localize('giftConfirm')(ppv.video_title)
                const name = ppv.video_title
                const duration = ppv.duration
                const price = `${ppv.video_price}  ${localize(currency)}`
                const callback = () => {
                    props.onPurchase(ppv, giftNumber)
                    setGiftNumber('')
                }
                setConfirmModal({title, name, duration, price, callback})

            } else {
                props.onPurchase(ppv, giftNumber)
                setGiftNumber('')
            }
        }
    }

    const _purchasePackage = pkg => {
        if (doubleOpt) {
            const title = localize('purchaseConfirm')
            const type = purchaseTypes.packages
            const name = pkg.pkg_label
            const duration = pkg.pkg_duration
            const price = `${pkg.pkg_price}  ${localize(currency)}`
            const oneTime = !!pkg.one_time ? pkg.one_time : ''
            const isRenew = !!pkg.is_renew ? pkg.is_renew : currentOperation === operations.ishow ? '1' : undefined
            const callback = () => {
                purchasePackage(pkg, service, video, '', currentOperation === operations.ishow ? !!localStorage.getItem(localStorageKeys.renew) ? localStorage.getItem(localStorageKeys.renew) : '1' : isRenew)
                localStorage.removeItem(localStorageKeys.renew)
            }
            setConfirmModal({title, name, duration, price, callback, type, oneTime , isRenew})
        } else {
            purchasePackage(pkg, service, video, '', !!pkg.is_renew ? pkg.is_renew : currentOperation === operations.ishow ? '1' : undefined)
        }
    }

    const _giftPackage = pkg => {
        if (giftNumber === phoneNumber.prefix) {
            setHintDialog('enterGiftNumber')
            // message.error(localize('enterGiftNumber'))
        } else if (giftNumber.length !== phoneNumber.limit) {
            setHintDialog('notValidphoneNumber',localize('notValidphoneNumberTitle'))
            // message.error(localize('notValidphoneNumber'))
        } else {
            if (doubleOpt) {
                const title = localize('giftConfirm')(pkg.pkg_label)
                const name = pkg.pkg_label
                const duration = pkg.pkg_duration
                const price = `${pkg.pkg_price}  ${localize(currency)}`
                const callback = () => {
                    purchasePackage(pkg, service, video, giftNumber)
                    setGiftNumber('')
                }
                setConfirmModal({title, name, duration, price, callback})
            } else {
                purchasePackage(pkg, service, video, giftNumber)
                setGiftNumber('')
            }
        }
    }


    const _watchFromPackage = pkg => {
        if (doubleOpt) {
            const title = localize('watchConfirm')
            const callback = () => watchFromPackage(pkg, service.service_id, video.video_id, video.video_name)
            setConfirmModal({title, name: '', price: '', callback})
        } else {
            watchFromPackage(pkg, service.service_id, video.video_id, video.video_name)
        }
    }

    const additionalTimePlan = !!service && servicePrices ? servicePrices.findIndex(item => item.plan_model.toString() === planModelTypes.streamingLimit) : -1


    let modalBody = null
    if (tab === purchaseTabs.subscription) {
        const prices = !!service && servicePrices ? servicePrices : []
        let list = []
        //in case find bundles for the channel (porps.bundleServices)
        // const findList = () => {
        if(!!service && !!service.services && service.services.length > 0){
            list = service.services
        }else if (!!props.bundleServices && props.bundleServices.length > 0){
            list = props.bundleServices
        }else if (!!service && !!service.episodes && service.episodes.items.length > 0 && !!props.bundleServices && props.bundleServices.length === 0){
            service.episodes.items.map((item,i) => {
                list.push(item)
            })
        }
        // !!service && !!service.services && service.services.length > 0 ? service.services : !!props.bundleServices ? props.bundleServices : []
        const videoItem = !!video ? video : {}
        const renderItems = prices.map((item, index) => {
            const hours = !!item ? item.duration : ''
            const playModel = !!item ? item.plan_model.toString() : undefined
            const disconnectTime = !!item ? Number.parseInt(item.disconnect_time) : ''
            const balance = !!item ? Number.parseFloat(item.balance) : 0
            const oldBalance = !!item ? Number.parseFloat(item.old_balance) : 0
            const discount = oldBalance - balance > 0 ? 100 - (balance * 100) / (oldBalance) : undefined
            const isDoublePlay = !!item.double_play ? item.double_play : ' '
            const oneTime = !!item.one_time ? item.one_time : undefined
            const isRenew = !!item.is_renew ? item.is_renew : currentOperation === operations.ishow ? '1' : undefined


            if(playModel === planModelTypes.streamingLimit && !!additionalTime && additionalTime.length > 0) {
                return (
                    <SubscriptionItem
                        key={index}
                        hours={hours}
                        price={balance}
                        streamLimit={disconnectTime}
                        discount={discount}
                        onClick={() => !gift ? _purchaseSubscription({...service, ...videoItem, ...item}) : null}
                        gift={gift}
                        playModel={playModel}
                        isDoublePlay={isDoublePlay}
                    />
                )
            }else if (playModel === planModelTypes.subscriptionPlan && !!additionalTime && additionalTime.length === 0){
                return (
                    <SubscriptionItem
                        key={index}
                        hours={hours}
                        oneTime={oneTime}
                        isRenew={isRenew}
                        price={balance}
                        streamLimit={disconnectTime}
                        discount={discount}
                        onClick={() => gift ? _giftSubscription({...service, ...videoItem, ...item}) : _purchaseSubscription({...service, ...videoItem, ...item})}
                        gift={gift}
                        isDoublePlay={isDoublePlay}
                    />
                )
            }
        })

        const renderCarousel = list.map((item, index) => {
            const title = !!item.service_name ? item.service_name : !!item.bundle_name ? item.bundle_name : !!item.video_title ? item.video_title : null
            return (
                <div className='sub-carousel-item' key={index}>
                    <SubscriptionCarouselItem
                        onClick = {!!item.bundle_name ? () => routeToBundle(item,gift) : null}
                        fromBundle={item.bundle_name}
                        title={title}
                        img={item.logo_big}
                    />
                </div>
            )
        })

        const routeToBundle = (item,gift = undefined) => {
            hideModal()
            if((item.prices.length > 0) || (!!item.is_free ? item.is_free != '1' : null)){
                if(gift){
                    localStorage.setItem(localStorageKeys.visible,'2')
                }else{
                    localStorage.setItem(localStorageKeys.visible,'1')
                }
            }else{
                localStorage.setItem(localStorageKeys.visible,'0')
            }
            // if (servicePrices.length === 0 && !!props.bundleServices && props.bundleServices.length > 0) localStorage.setItem(localStorageKeys.visible,'1')
            navigate(Routes.bundleTo(item.bundle_id))
        }
        const listTitle = () => {
            if(!!list && list.length > 0 && servicePrices.length !== 0){
                if (gift) return (<p style={{height:'30px'}} className='text-center'>{localize('alsoAvailableGift')}</p>)
                else return (<p style={{height:'30px'}} className='text-center'>{localize('alsoAvailable')}</p>)
            }else if (servicePrices.length === 0 && !!props.bundleServices && props.bundleServices.length > 0){
                if (gift) return (<p style={{height:'30px'}} className='text-center'>{localize('giftFromBundle')}</p>)
                else return (<p style={{height:'40px'}} className='text-center'>{localize('watchFromBundle')}</p>)
            }
        }
        modalBody = (
            <div className="sub-body">
                {displayAutoRenew && !gift ? <p className='text-center'>{localize('autoRenewed')}</p> : null}
                {renderItems}
                {!!service.service_id && !!props.bundleServices && !!props.bundleServices.length > 0 ? listTitle() : null}{/*check if box shown in bundle page or not*/}
                {list.length > 0 ? (
                    <Swiper {...carouselSettings}>
                        {renderCarousel}
                    </Swiper>
                ) : null}
            </div>
        )
    } else if (tab === purchaseTabs.packages && !!packages[selectedPackage] && filteredPackages.length > 0) {
        // below filter to display only the packages that matches the is_movie (service type)
        const selectedPackageItem = filteredPackages[selectedPackage]
        const hours = !!selectedPackageItem ? selectedPackageItem.pkg_duration : null
        const days = Math.floor(hours / 24)
        const renderDays = days > 0 ? <><span className="sub-number">{days}</span> {localize('dayS')}</> : null
        const hoursCount = hours % 24
        const renderHours = hoursCount > 0 ? <><span
            className="sub-number">{hoursCount}</span> {localize('hourS')}</> : null

        modalBody = (
            <div className="sub-body">
                <Swiper {...carouselSettings}>
                    {!!filteredPackages && filteredPackages.map((item, index) => {
                        if (item.pkg_is_enabled === '1') {
                            return (
                                <div className='sub-carousel-item' key={index}>
                                    <SubscriptionCarouselItem
                                        onClick={() => setSelectedPackage(index)}
                                        selected={selectedPackage === index}
                                        title={item.pkg_label}
                                        price={item.pkg_price}
                                    />
                                </div>
                            )
                        }
                        return null
                    })}
                </Swiper>

                <div className='sub-you-get'>
                    <h5>{localize(gift ? 'theyGet' : 'youGet')}</h5>
                    <div className='sub-row row'>
                        {selectedPackageItem.types.map((item, index) => {
                            if (item.video_type_id === isMovie.movies) {
                                return (
                                    <div className='sub-row-item col' key={index}>
                                        <img src={packageMovies} alt="" className='sub-icon'/>
                                        <p><span className="sub-number">{Number(item.pkg_quantity) >= 9999 ? localize('unlimited'): item.pkg_quantity}</span> {localize('movieS')}
                                        </p>
                                    </div>
                                )
                            } else if (item.video_type_id === isMovie.series) {
                                return (
                                    <div className='sub-row-item col' key={index}>
                                        <img src={packageSeries} alt="" className='sub-icon'/>
                                        <p><span className="sub-number">{Number(item.pkg_quantity) >= 9999 ? localize('unlimited'): item.pkg_quantity}</span> {localize('series')}
                                        </p>
                                    </div>
                                )
                            } else if (item.video_type_id === isMovie.clips) {
                                return (
                                    <div className='sub-row-item col' key={index}>
                                        <img src={packageClips} alt="" className='sub-icon'/>
                                        <p><span className="sub-number">{Number(item.pkg_quantity) >= 9999 ? localize('unlimited'): item.pkg_quantity}</span> {localize('clipS')}
                                        </p>
                                    </div>
                                )
                            } else if (item.video_type_id === isMovie.plays) {
                                return (
                                    <div className='sub-row-item col' key={index}>
                                        <img src={packagePlays} alt="" className='sub-icon'/>
                                        <p><span className="sub-number">{Number(item.pkg_quantity) >= 9999 ? localize('unlimited'): item.pkg_quantity}</span> {localize('playS')}
                                        </p>
                                    </div>
                                )
                            }
                            return null
                        })}
                    </div>
                </div>
                <div className="sub-buy">
                    <div className='text-center'>
                        <p>{localize('validFor')} {renderDays} {renderHours}</p>
                        {displayAutoRenew ? <p>{localize('autoRenewed')}</p> : null}
                    </div>
                    <button className='sub-buy-button'
                            onClick={() => gift ? _giftPackage(selectedPackageItem) : _purchasePackage(selectedPackageItem)}>
                        <p>{localize(gift ? 'gift' : 'buy')} <span
                            className="sub-number">{selectedPackageItem.pkg_price} {localizedCurrency}</span></p>
                    </button>
                </div>

            </div>
        )
    } else if (tab === purchaseTabs.fromPackage) {
        // purchasedPackages.map((ipkg) => {
        //     ipkg.types.map((tpkg) => {
        //         if(Number(tpkg.usr_type_quantity >= 9999)){
        //             if((tpkg.usr_video_type_id === '0' && from === 'Clips')
        //             ||(tpkg.usr_video_type_id === '1' && from === 'Movies')
        //             ||(tpkg.usr_video_type_id === '2' && from === 'Series')
        //             ||(tpkg.usr_video_type_id === '3' && from === 'Plays')){
        //                 watchFromPackage(ipkg, service.service_id, video.video_id, video.video_name)
        //             }
        //         }
        //     })
        // })
        let itemText = ''
        if (service.is_movie === isMovie.movies) itemText = localize('movie')
        else if (service.is_movie === isMovie.series) itemText = localize('episode')
        else if (service.is_movie === isMovie.clips) itemText = localize('clip')
        else if (service.is_movie === isMovie.plays) itemText = localize('play')

        modalBody = (
            <div className="sub-body">

                <div className="movie-row">
                    <img src={video.logo} alt=""/>
                    <div className='movie-row-right'>
                        <p><span className="sub-number">1</span> {itemText}</p>
                        <p>{localize('willDeducted')}</p>
                    </div>
                </div>

                {availablePackages.length > 0 && availablePackages.map((pkg, index) => {
                    return (
                        <div className='sub-item' key={index} onClick={() => _watchFromPackage(pkg)}>
                            <div className='sub-row row'>

                                {pkg.types.map((item, index) => {
                                    if (item.usr_video_type_id === isMovie.movies) {
                                        return (
                                            <div className='sub-row-item col' key={index}>
                                                <img src={packageMovies} alt="" className='sub-icon'/>
                                                <p><span
                                                    className="sub-number">{item.usr_type_remaining}</span> {localize('movieS')}
                                                </p>
                                            </div>
                                        )
                                    } else if (item.usr_video_type_id === isMovie.series) {
                                        return (
                                            <div className='sub-row-item col' key={index}>
                                                <img src={packageSeries} alt="" className='sub-icon'/>
                                                <p><span
                                                    className="sub-number">{item.usr_type_remaining}</span> {localize('series')}
                                                </p>
                                            </div>
                                        )
                                    } else if (item.usr_video_type_id === isMovie.clips) {
                                        return (
                                            <div className='sub-row-item col' key={index}>
                                                <img src={packageClips} alt="" className='sub-icon'/>
                                                <p><span
                                                    className="sub-number">{item.usr_type_remaining}</span> {localize('clipS')}
                                                </p>
                                            </div>
                                        )
                                    } else if (item.usr_video_type_id === isMovie.plays) {
                                        return (
                                            <div className='sub-row-item col' key={index}>
                                                <img src={packagePlays} alt="" className='sub-icon'/>
                                                <p><span
                                                    className="sub-number">{item.usr_type_remaining}</span> {localize('playS')}
                                                </p>
                                            </div>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                            <button className='sub-item-right'>
                                <p>{localize('watch')}</p>
                            </button>
                        </div>
                    )
                })}

            </div>
        )
    } else if (tab === purchaseTabs.ppv) {
        const hours = !!video ? video.duration : ''
        const price = !!video ? video.video_price : ''
        const balance = !!video ? Number.parseFloat(video.balance) : 0
        const oldBalance = !!video ? Number.parseFloat(video.old_balance) : 0
        const discount = oldBalance - balance > 0 ? 100 - ((balance * 100) / (oldBalance)) : undefined
        modalBody = (
            <div className="sub-body">
                <SubscriptionItem
                    hours={hours}
                    price={price}
                    fullView={1}
                    discount={discount}
                    onClick={() => gift ? _giftPPV({...service, ...video}) : _purchasePPV({...service, ...video})}
                    gift={gift}
                />
            </div>
        )
    }

    let renderTabs = activeTabs.map(item => {
        if (item === purchaseTabs.subscription) {
            return (
                <div key={item} className={`sub-tab ${tab === 0 ? activeTabs.length === 1 ? 'seletedfromPck' : 'selected' : ''}`}
                     onClick={() => setTab(purchaseTabs.subscription)}>
                    {!!additionalTime && !!additionalTime.length > 0 ? (
                        <span className="limit-number">{!!streamLimitTitle ? streamLimitTitle : selectedlimitTime.Message}</span>
                        ): <p>{localize('subscription')}</p>}
                </div>
            )
        }
        if (item === purchaseTabs.packages) {
            return (
                <div key={item} className={`sub-tab ${tab === 1 ? activeTabs.length === 1 ? 'seletedfromPck' : 'selected' : ''}`}
                     onClick={() => setTab(purchaseTabs.packages)}>
                    {tab === 1 && visible && !!additionalTime && !!additionalTime.length > 0 /*only pck tab and is limit reach model */ ? <span className="limit-number">{!!streamLimitTitle ? streamLimitTitle : selectedlimitTime.Message}</span> : <p>{localize('packages')}</p>}
                </div>
            )
        }
        if (item === purchaseTabs.fromPackage) {
            return (
                <div key={item} className={`sub-tab ${tab === 2 ? 'seletedfromPck' : ''}`}
                     onClick={() => setTab(purchaseTabs.fromPackage)}>
                    <p>{localize('watchFromYourPackages')}</p>
                </div>
            )
        }
        if (item === purchaseTabs.ppv) {
            return (
                <div key={item} className={`sub-tab ${tab === 3 ? activeTabs.length === 1 ? 'seletedfromPck' : 'selected' : ''}`}
                     onClick={() => setTab(purchaseTabs.ppv)}>
                    <p>{localize('ppv')}</p>
                </div>
            )
        }
        return null
    })

    const giftPhoneInput = props.gift && (
        <div className="sub-gift-input">
            <input type="text" className='form-control' placeholder={localize('giftNumber')} value={giftNumber}
                   maxLength={phoneNumber.limit}
                   onChange={e => e.target.value.match(/^(\s*|\d+)$/) && e.target.value.slice(0, 3) === phoneNumber.prefix ? setGiftNumber(e.target.value) : giftNumber}/>
        </div>
    )

    let clipsPurcheseModal = null
    if (isClipsRootPage) {
        clipsPurcheseModal = (
            <ConfirmPurchaseModal
                visible={!!props.visible ? props.visible : false}
                onOk={props.onCancel}
                title={localize('msgToAllowPurchese')}
            />

        )
    } else {
        clipsPurcheseModal = (
            <>
                <div className='sub-tabs'>
                    {renderTabs}
                </div>

                {giftPhoneInput}
                {modalBody}
                {confirmModal ? (
                    <ConfirmPurchaseModal
                        visible={confirmModal}
                        onCancel={() => setConfirmModal(false)}
                        onSwitch={(renew) => renew ? localStorage.setItem(localStorageKeys.renew,'1') : localStorage.setItem(localStorageKeys.renew,'0')}
                        onOk={confirmModal.callback}
                        title={confirmModal.title}
                        name={confirmModal.name}
                        duration={confirmModal.duration}
                        type={!!confirmModal.type ? confirmModal.type : undefined}
                        oneTime={!!confirmModal.oneTime ? confirmModal.oneTime : undefined}
                        isRenew={!!confirmModal.isRenew ? confirmModal.isRenew : undefined}
                        price={confirmModal.price}
                        giftPhoneNumber={giftNumber}
                        gift={gift}
                        loadingArrow={loadingArrow}
                        isDoublePlay={confirmModal.doublePlay}
                    />
                ) : null}
            </>
        )
    }
    return (
        <Modal
            className={`sub-modal`}
            visible={!!props.visible ? props.visible : false}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={props.onCancel}
        >
            {loading && !doubleOpt ? (
                <div className="sub-overlay">
                    <span className="spinner-border"/>
                </div>
            ) : null}
            {clipsPurcheseModal}
        </Modal>
    )
}

export default SubscriptionModal
