import React from 'react'
import GeneralError from "../Fillers/GeneralError";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <GeneralError/>;
        }
        return this.props.children;
    }
}

const WithSettings = Component => {
    const WithSettingsHOC = props => {
        return (
            <ErrorBoundary>
                <Component {...props}/>
            </ErrorBoundary>
        )
    }
    return WithSettingsHOC
}
export default WithSettings