import {apiEndpoint, domain, scheme} from "../../helpers/constants";

export const host = scheme + domain + apiEndpoint

export const bootstrap = host + 'aplitv_bootstrap'
// export const bootstrap = 'http://cam.mtn.net.sy/crudAPI_mobile_test/aplitv_bootstrap'
export const loadMoreServices = 'apliTV_loadMoreServices'
export const loadMoreBundles = 'apliTV_loadMoreBundles'
export const loadMoreVideosOfServices = 'apliTV_getVideosOfService'
export const getContentInfo = 'aplitv_getContentInfo'
export const getContentLink = 'aplitv_getContentLink'
export const getSeriesPlaylist = 'aplitv_getSeriesPlaylist'
export const getServicesOfParent = 'aplitv_getServicesOfParent'
export const getServicesOfBundle = 'aplitv_getServicesOfBundle'
export const getUserFavorites = 'aplitv_getUserFavorites'
export const addUserFavorites = 'aplitv_addUserFavorites'
export const removeUserFavorites = 'aplitv_removeUserFavorites'
export const search = 'ivy_search'
export const processNewOrder = 'aplitv_processNewOrder'
export const getUserTransactionLogs = 'aplitv_getUserTransactionLogs'
export const getContinueWatching = 'continue_watching'
export const saveUserFeedback = 'aplitv_saveUserFeedback'
export const incrementNotificationClicks = 'aplitv_incrementNotificationClicks'
export const getUserNotifications = 'apliTV_getUserNotifications'
export const clearNotification = 'apliTV_clearNotification'
export const getConnectedDevices = 'aplitv_getConnectedDevices'
export const linkUserDevice = 'aplitv_linkUserDevice'
export const releaseUserDevice = 'aplitv_releaseUserDevice'
export const getUserProfile = 'aplitv_getUserProfile'
export const toggleOrderRenew = 'aplitv_toggleOrderRenew'
export const watchVodFromPackage = 'aplitv_watchVodFromPackage'
export const getSetUserSettings = 'aplitv_getSetUserSettings'
export const saveVideoBookmark = 'aplitv_saveVideoBookmark'
export const getServicePrediction = 'aplitv_getPrediction'
export const addUserPrediction = 'aplitv_addUserPrediction'
export const contentPreview = 'aplitv_contentPreview'
export const storeProfilePicture = 'aplitv_storeProfilePicture'
export const saveUserProfile = 'aplitv_saveNickName'
export const getUserProfileInfo = 'aplitv_getNickName'
export const GetBehaviorStatistics = 'aplitv_getBehaviorStatistics'
export const getFollowStatus = 'aplitv_checkFollowStatus'
export const setFollowUnFollow = 'aplitv_followUnfollowChannel'
export const getViewersNumber = 'aplitv_liveViewerCount'
export const getSessionReportOptions = 'aplitv_getSessionReportOptions'
export const reportLiveSession = 'aplitv_reportLiveSession'
export const getFollowersFollowing = 'aplitv_getFollowersFollowing'
// export const getHeaderEnrProfile = host + 'aplitv_getuserinfo'
export const getHeaderEnrProfile = 'http://cam.mtn.net.sy/crudAPI_mobile_test/aplitv_getuserinfo'
export const oneLabsVisit = 'https://track.one10labs.net/agency/DCB/VisitLogs'
export const oneLabsSuccess = 'https://track.one10labs.net/agency/DCB/SuccessLogs'
export const oneLabsError = 'https://track.one10labs.net/agency/DCB/ErrorLogs'
export const campaignLogCall = 'aplitv_saveCampaignLog'
