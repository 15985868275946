import {PLAYER_LIMIT_TIME, PLAYER_LIMIT_TIME_CLEAR, SET_CODE, UNREAD_NOTIFICATION, SET_FETCHING} from "../actions/types";

const initialState = {
    items: [],
    code:'',
    unreadNotification:'',
    titleMsg:'',
    fetch:false,
}

const playerReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLAYER_LIMIT_TIME:
            return {
                ...state,
                items: !!action.payload.items ? [...state.items,...action.payload.items] : [],
                titleMsg: action.payload.titleMsg
            }
        case PLAYER_LIMIT_TIME_CLEAR:
            return {
                ...state,
                items: [
                    ...state.items.slice(0,action.payload),
                    ...state.items.slice(action.payload + 1),
                ]
            }
        case SET_CODE:
            return {
                ...state,
                code: action.payload,
            }
        case UNREAD_NOTIFICATION:
            return {
                ...state,
                unreadNotification: action.payload,
            }
        case SET_FETCHING:
            return {
                ...state,
                fetch: action.payload,
            }
        default:
            return state
    }
}

export default playerReducer
