import React from 'react'
import {wideLogo} from "../../assets/img/exports";

const Splash = props => {
    return(
        <div className='splash-screen'>
            <img src={wideLogo} alt="logo" width={400}/>
            <span className="spinner-border text-light my-5"/>
        </div>
    )
}

export default Splash