import store from "../store";
import {
    checkIfPlayerOpened, getCurrentDate,
    handleHttpError,
    jsonToFormData,
    localize, redirectCall,
    removeParentServices, setDstLink,
    setHintDialog
} from "../../helpers/functions";
import axios from "axios";
import {
    contentPreview,
    getContentLink,
    getSeriesPlaylist as getSeriesPlaylistAPI,
    host,
    processNewOrder
} from "../api/endPoints";
import {currency, dstDomain, dstSmile} from "../../helpers/constants";
import {
    BUNDLES_SET_UNLOCKED, BUNDLES_UPDATE_ITEM_SERVICES,
    LIVE_SET_UNLOCKED_LIST_ITEMS,
    LIVECHANNELS_SINGLEBUNDLE_UNLOCK,
    PLAYER_PREVIEW_LIVE,
    PLAYER_SET_CURRENT_LINK,
    PLAYLIST_ADD_ITEM,
    PLAYLIST_SELECT_ITEM,
    PLAYLIST_SELECT_ITEM_LIVE,
    PLAYLIST_SELECT_ITEM_LIVE_PRICES,
    PLAYLIST_SET_ERROR,
    PLAYLIST_SET_FETCHING,
    PLAYLIST_SET_RESPONSE,
    PLAYLIST_SET_SERIES,
    PLAYLIST_UNSET_FETCHING,
    SERVICES_MOVIE_SETUNLOCK,
    SERVICES_SINGLEMOVIE_SET_UNLOCKED,
    SERVICES_SUBSCRIBTION_SINGLEMOVIE_SETUNLOCK,
    SERVICES_VIDEOSLIST_UNLOCKED_SUBSCRIBTION,
    SET_BUNDLE_UNLOCK, SET_FETCHING, SET_LIVE_SESSION_END, SET_SERIESVIDEO_BOOKMARK, TOGGLE_CHAT
} from "./types";
import {localStorageKeys, playerTypes, serviceType} from "../../helpers/enums";
import {hideModal, showPurchaseModal} from "./settingsActions";
import {getBehaviorStatistics, getSeriesInfo, getVideoInfo, selectChannel} from "./servicesActions";
import {getBundleInfo} from "./bundlesActions";

const dispatch = store.dispatch


export const processOrder = request => {
    const auth = store.getState().auth.authToken
    let req = undefined
    if (request.type === 'service') {
        req = {
            auth,
            service_id: request.service_id,
            balance: request.balance,
            duration: request.duration,
            disconnect_time: request.disconnect_time,
            is_renew: request.is_renew,
            payment_mode:request.payment_mode
        }
        if (request.gift_to) req['gift_to'] = request.gift_to
    } else if (request.type === 'video') {
        req = {
            auth,
            service_id: request.service_id,
            balance: request.balance,
            duration: request.duration,
            object_name: request.object_name
        }
        if (request.gift_to) req['gift_to'] = request.gift_to
    } else if (request.type === 'bundle') {
        req = {
            auth,
            bundle_id: request.bundle_id,
            balance: request.balance,
            duration: request.duration,
            disconnect_time: request.disconnect_time,
            is_renew: request.is_renew,
            payment_mode:request.payment_mode
        }
        if (request.gift_to) req['gift_to'] = request.gift_to
    } else if (request.type === 'package') {
        req = {
            auth,
            pkg_id: request.pkg_id,
            pkg_price: request.pkg_price,
            pkg_duration: request.pkg_duration,
            is_renew:request.is_renew,
        }
        if (request.gift_to) req['gift_to'] = request.gift_to
    }

    const params = jsonToFormData(req)
    return axios.post(host + processNewOrder, params)
        .then(res => {
            // request to facebook analytics when purchasing any content
            return res
        })


}

export const purchaseService = (items, service, gift_to = undefined, chat = false, isRenew = undefined) => {
    const paymentStatus = store.getState().settings.chargingStatus
    const params = {
        type: 'service',
        service_id: service.service_id,
        balance: service.balance,
        duration: service.duration,
        disconnect_time: service.disconnect_time,
        is_renew:isRenew,
        label:service.service_name,
        payment_mode:paymentStatus,
        gift_to
    }
    dispatch({type: PLAYLIST_SET_FETCHING})
    processOrder(params)
        .then(res => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            const params = {
                type: playerTypes.live,
                service_id: service.service_id,
                is_movie: service.is_movie,
                chat
            }
            hideModal()
            if (gift_to) {
                if(res.status === 202){
                    if(res.data.payment_mode === 1){
                        setHintDialog('pendingMobileMoney')
                    }else{
                        setHintDialog('pendingAirtimeTransaction')
                    }
                }else if(res.status === 200){
                    setHintDialog('giftSuccess',localize('giftSuccessTitle'))
                }
            }
            else{
                if(res.status === 200){
                    if(typeof window.gtag !== 'undefined'){
                        window.gtag("event", "purchase", {
                            'item_id':service.service_id,
                            'content_type':service.service_name,
                            'currency':currency,
                            'price':service.balance,
                            'MSISDN':store.getState().settings.username,
                            'Account_Creation':getCurrentDate(),
                            'operatorName':localStorage.getItem(localStorageKeys.operatorName),
                            'countryName':localize('symbol')
                        })
                    }
                    if (!!items && items.length > 0) {
                        items.forEach((item, index) => {
                            if (item.service_id === service.service_id) {
                                dispatch({
                                    type: LIVE_SET_UNLOCKED_LIST_ITEMS,
                                    payload: index
                                })
                            }
                        })
                    }
                    getLink(params, service)
                }else if(res.status === 405){
                    if(res.data.payment_mode === 1){
                        setHintDialog('alreadyPendingMobileMoney')
                    }else{
                        setHintDialog('alreadyPendingAirtimeTransaction')
                    }
                }else if(res.status === 410){
                    if(res.data.payment_mode === 1){
                        setHintDialog('failureMobileMoney')
                    }else{
                        setHintDialog('failureAirtimeTransaction')
                    }
                }else if(res.status === 202){
                    if(res.data.payment_mode === 1){
                        setHintDialog('pendingMobileMoney')
                    }else{
                        setHintDialog('pendingAirtimeTransaction')
                    }
                }
            }
        })
        .catch(error => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            hideModal()
            if(error.response.status === 405){
                if(error.response.data.payment_mode === 1){
                    setHintDialog('alreadyPendingMobileMoney')
                }else{
                    setHintDialog('alreadyPendingAirtimeTransaction')
                }
            }else if(error.response.status === 410){
                if(error.response.data.payment_mode === 1){
                    setHintDialog('failureMobileMoney')
                }else{
                    setHintDialog('failureAirtimeTransaction')
                }
            }else{
                handleHttpError(error, 'gifting')
            }
        })

}

export const purchaseSeries = (service = undefined, gift_to = undefined, video = undefined, services = undefined, videos = undefined, isRenew = undefined) => {
    if(videos === undefined){
        videos = store.getState().movies.video.list
    }
    if(service.is_movie === '1') {
        const params = {
            type: 'service',
            service_id: service.service_id,
            balance: service.balance,
            duration: service.duration,
            disconnect_time: service.disconnect_time,
            is_renew:isRenew,
            label:service.service_name,
            gift_to
        }
        dispatch({
            type: PLAYLIST_SET_FETCHING,
        })
        processOrder(params)
            .then(res => {
                if (res.status === 200) {
                    hideModal()
                    if (gift_to) {
                        setHintDialog('giftSuccess',localize('giftSuccessTitle'))
                        // message.success(localize('giftSuccess'))
                    } else {
                        if (!!services && services.length > 0) {
                            services.forEach((sitem, i) => {
                                if (sitem.service_id === service.service_id) {
                                    dispatch({
                                        type: SERVICES_SUBSCRIBTION_SINGLEMOVIE_SETUNLOCK
                                    })
                                    dispatch({
                                        type:SERVICES_MOVIE_SETUNLOCK,
                                        payload:i
                                    })
                                }
                            })
                        }
                        getSeriesPlaylist(service.service_id, true, service.video_id)
                        //if (videos === undefined){
                        //}
                        if (videos.length > 0) {
                            // if (!!videos && videos.length > 0) {
                            //     const videoCards = videos.map((vItem, vIndex) => {
                            //             if (vItem.video_id === video.video_id) {
                            //                 vItem.status = 'unlocked'
                            //             }
                            //         }
                            //     )
                            // }
                            // dispatch({type: SERVICES_SINGLEMOVIE_SET_UNLOCKED, payload: videos})
                            videos.forEach((item, i) => {
                                item.status = 'unlocked'
                            })
                            dispatch({
                                type: SERVICES_VIDEOSLIST_UNLOCKED_SUBSCRIBTION,
                                payload: videos
                            })
                        }
                        // getSeriesPlaylist(service.service_id, true, service.video_id)
                    }
                }
                dispatch({type: PLAYLIST_UNSET_FETCHING,})
            })
            .catch(error => {
                dispatch({type: PLAYLIST_UNSET_FETCHING,})
                hideModal()
                handleHttpError(error,'gifting')
            })
    }else{
        const params = {
            type: 'service',
            service_id: service.service_id,
            balance: service.balance,
            duration: service.duration,
            disconnect_time: service.disconnect_time,
            is_renew:isRenew,
            label:service.service_name,
            gift_to
        }
        dispatch({
            type: PLAYLIST_SET_FETCHING,
        })
        processOrder(params)
            .then(res => {
                if (res.status === 200) {
                    hideModal()
                    if (gift_to) {
                        setHintDialog('giftSuccess',localize('giftSuccessTitle'))
                        // message.success(localize('giftSuccess'))
                    } else {
                        getSeriesPlaylist(service.service_id, true, service.video_id)
                        // if (!!services && services.length > 0) {
                        //     const unlockService = services.map((sitem, i) => {
                        //         if (sitem.service_id === service.service_id) {
                        //             sitem.status = 'unlocked'
                        //         }
                        //     })
                        //     dispatch({
                        //         type: SERVICES_CLIPS_SETUNLOCK,
                        //         payload: services
                        //     })
                        // }

                //set video to unlock case
                        dispatch({
                            type: SERVICES_SUBSCRIBTION_SINGLEMOVIE_SETUNLOCK
                        })
                    }
                }
                dispatch({type: PLAYLIST_UNSET_FETCHING,})
            })
            .catch(error => {
                dispatch({type: PLAYLIST_UNSET_FETCHING,})
                hideModal()
                handleHttpError(error,'gifting')
            })
    }

}

export const purchaseVideo = (video, gift_to = undefined) => {
    const params = {
        type: 'video',
        service_id: video.service_id,
        balance: video.video_price,
        duration: video.duration,
        object_name: video.video_name,
        label:video.video_title,
        gift_to
    }
    dispatch({
        type: PLAYLIST_SET_FETCHING,
    })
    processOrder(params)
        .then(res => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            const params = {
                type: playerTypes.video,
                service_id: video.service_id,
                video_id: video.video_id,
                object_name: video.video_name,
                is_movie: video.is_movie,
            }
            hideModal()
            if (gift_to) {
                setHintDialog('giftSuccess',localize('giftSuccessTitle'))
                // message.success(localize('giftSuccess'))
            }
            else {
                const videoList = store.getState().movies.items
                if (!!videoList && videoList.length > 0){
                    videoList.forEach((item,index) => {
                        if (!!item.videos && item.videos.length > 0) {
                            item.videos.forEach((vItem, vIndex) => {
                                if(vItem.video_id === video.video_id){
                                    vItem.status = 'unlocked'
                                }
                            }
                            )}
                    })
                    dispatch({type:SERVICES_SINGLEMOVIE_SET_UNLOCKED,payload:videoList})
                }
                getLink(params)
            }
        })
        .catch(error => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            hideModal()
            handleHttpError(error,'gifting')
        })
}

export const purchaseBundle = (bundle, gift_to = undefined, liveChannels = undefined, is_renew = undefined) => {
    const paymentStatus = store.getState().settings.chargingStatus
    const params = {
        type: 'bundle',
        bundle_id: bundle.bundle_id,
        balance: bundle.balance,
        duration: bundle.duration,
        disconnect_time: bundle.disconnect_time,
        is_renew:is_renew,
        payment_mode:paymentStatus,
        label:bundle.bundle_name,
        gift_to
    }
    dispatch({
        type: PLAYLIST_SET_FETCHING,
    })
    processOrder(params)
        .then(res => {
        hideModal()
        dispatch({type: PLAYLIST_UNSET_FETCHING,})
        if (gift_to) {
            if(res.status === 202){
                if(res.data.payment_mode === 1){
                    setHintDialog('pendingMobileMoney')
                }else{
                    setHintDialog('pendingAirtimeTransaction')
                }
            }else if(res.status === 200){
                setHintDialog('giftSuccess',localize('giftSuccessTitle'))
            }
            // message.success(localize('giftSuccess'))
        }
        else if (res.status === 200) {
            if(typeof window.gtag !== 'undefined') {
                window.gtag("event", "purchase", {
                    'item_id': bundle.bundle_id,
                    'content_type': bundle.bundle_name,
                    'currency': currency,
                    'price': bundle.balance,
                    'MSISDN': store.getState().settings.username,
                    'Account_Creation': getCurrentDate(),
                    'operatorName': localStorage.getItem(localStorageKeys.operatorName),
                    'countryName': localize('symbol')
                })
            }

            const list = res.data.msg
            dispatch({
                type: PLAYLIST_SET_RESPONSE,
                payload: list
            })
            // hideModal()
            localStorage.setItem(localStorageKeys.visible,'0')
            const firstItem = bundle.services[0]
            const playItem = list.find(item => item.service_id === firstItem.service_id)

            if (firstItem.service_type === serviceType.live) {
                const url = playItem.is_radio == "1" ? playItem.radio_url : playItem.url
                selectPlaylistItem(playItem,url.indexOf('redirect') > -1 ? true : false)
            } else if (firstItem.service_type === serviceType.vod && firstItem.is_videos_parent === '1') {
                // getSeriesPlaylist(firstItem.service_id)
                getSeriesPlaylist(firstItem.service_id,false)
            }
            //set bundle Unlock in services plus single bundle
            if (!!store.getState().bundles.items && store.getState().bundles.items.length > 0){
                store.getState().bundles.items.forEach((item,index) => {
                    if (item.bundle_id === bundle.bundle_id){
                        bundle.status = 'unlocked'
                        dispatch({
                            type:SET_BUNDLE_UNLOCK,
                            payload:index
                        })
                    }
                })
                dispatch({
                    type:BUNDLES_SET_UNLOCKED,
                    payload:bundle
                })
            }

            if(bundle.services.length > 0){
                bundle.services.map((item) => {
                    item.status = 'unlocked'
                })
                dispatch({
                    type:BUNDLES_UPDATE_ITEM_SERVICES,
                    payload:bundle.services
                })
            }

            //unlock liveChannels of the bundle
            if (!!bundle.services && bundle.services.length > 0){
                bundle.services.forEach((bitem,bindex) => {
                    if(!!liveChannels && liveChannels.length > 0){
                        liveChannels.forEach((citem,cindex) => {
                            if (bitem.service_id === citem.service_id){
                                citem.status = 'unlocked'
                            }
                        })
                    }
                })
                // dispatch({
                //     type:LIVECHANNELS_SINGLEBUNDLE_UNLOCK,
                //     payload:liveChannels
                // })
            }

            //getBundleInfo(bundle.bundle_id)
        }
        else if(res.status === 202){
            if(res.data.payment_mode === 1){
                setHintDialog('pendingMobileMoney')
            }else{
                setHintDialog('pendingAirtimeTransaction')
            }
        }
    }).catch(error => {
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            hideModal()
            if(error.response.status === 405){
                if(error.response.data.payment_mode === 1){
                    setHintDialog('alreadyPendingMobileMoney')
                }else{
                    setHintDialog('alreadyPendingAirtimeTransaction')
                }
            }else if(error.response.status === 410){
                if(error.response.data.payment_mode === 1){
                    setHintDialog('failureMobileMoney')
                }else{
                    setHintDialog('failureAirtimeTransaction')
                }
            }else{
                handleHttpError(error,'gifting')
            }
        })

}

export const playlistNext = () => {
    const {item, items, series} = store.getState().playlist
    let selectedItem = undefined
    let selectedIndex = undefined
    if (item.video_id) {
        selectedIndex = series.findIndex(iItem => iItem.video_id === item.video_id)
        selectedItem = selectedIndex + 1 === series.length ? series[0] : series[selectedIndex + 1]
    } else {
        selectedIndex = items.findIndex(iItem => iItem.service_id === item.service_id)
        selectedItem = selectedIndex + 1 === items.length ? items[0] : items[selectedIndex + 1]
    }
    const url = selectedItem.is_radio == "1" ? selectedItem.radio_url : selectedItem.url
    selectPlaylistItem(selectedItem, url.indexOf('redirect') > -1 ? true : false)
}

export const playlistPrevious = () => {
    const {item, items, series} = store.getState().playlist
    let selectedItem = undefined
    let selectedIndex = undefined
    if (item.video_id) {
        selectedIndex = series.findIndex(iItem => iItem.video_id === item.video_id)
        selectedItem = selectedIndex === 0 ? series[series.length - 1] : series[selectedIndex - 1]
    } else {
        selectedIndex = items.findIndex(iItem => iItem.service_id === item.service_id)
        selectedItem = selectedIndex === 0 ? items[items.length - 1] : items[selectedIndex - 1]
    }
    const url = selectedItem.is_radio == "1" ? selectedItem.radio_url : selectedItem.url
    selectPlaylistItem(selectedItem, url.indexOf('redirect') > -1 ? true : false)
}

export const previewLive = request => {
    const {enablePreviewLive} = store.getState().playlist

    if (!enablePreviewLive) {
        checkIfPlayerOpened()
        showPurchaseModal()
    } else {
        dispatch({
            type:SET_FETCHING,
            payload:true
        })
        const auth = store.getState().auth.authToken
        const req = {
            auth,
            service_id: request.service_id,
            object_name: request.object_name,
        }
        dispatch({type: PLAYLIST_SET_FETCHING,})
        const params = jsonToFormData(req)
        axios.post(host + contentPreview, params)
            .then(res => {
                dispatch({
                    type:SET_FETCHING,
                    payload:false
                })
                dispatch({type: PLAYLIST_UNSET_FETCHING,})
                if (res.status === 200) {
                    const link = res.data.msg
                    const is_encrypted = res.data.is_encrypted
                    const can_preview = parseInt(res.data.can_preview)
                    const duration = parseInt(res.data.duration) * 1000
                    if (can_preview === 0) {
                        checkIfPlayerOpened()
                        showPurchaseModal()
                    } else {
                        setDstLink('')
                        if (link.indexOf('redirect') > -1){
                            let searchParams = new URL(link).searchParams
                            localStorage.setItem(localStorageKeys.dstKey,searchParams.get('t'))
                            // localStorage.setItem(localStorageKeys.dstNum,'2')
                            axios.post(link).then(res => {
                                setDstLink(res.request.responseURL)
                            })
                        }else {
                            setDstLink(link)
                            localStorage.removeItem(localStorageKeys.dstKey)
                            // localStorage.setItem(localStorageKeys.dstNum,'1')
                        }
                        dispatch({
                            type: PLAYER_PREVIEW_LIVE,
                            payload: {service_id: request.service_id, url: link, previewDuration: duration,is_encrypted:is_encrypted}
                        })
                    }

                }
            })
            .catch(error => {
                dispatch({
                    type:SET_FETCHING,
                    payload:false
                })
                dispatch({type: PLAYLIST_UNSET_FETCHING,})
                handleHttpError(error)
            })
    }


}

export const selectPlaylistItem = (item, fromDST2 = undefined) => {
    const serviceIndex = store.getState().live.items.findIndex(i => i.service_id === item.service_id)
    const prices = !!serviceIndex && serviceIndex !== -1 ? store.getState().live.items[serviceIndex].prices : []

    getBehaviorStatistics('4',item.service_id)
    if(typeof window.gtag !== 'undefined'){
        window.gtag("event", item.is_radio == '1' ? "Radio_Event" : "ViewerShip_Event", {
            'MSISDN':store.getState().settings.username,
            'item_id':item.service_name,
            'content_type':item.is_radio == '1' ? 'Radio'  : 'Live',
            'currency':currency,
            'operatorName':localStorage.getItem(localStorageKeys.operatorName),
            'countryName':localize('symbol')
        })
    }

    const {items} = store.getState().playlist
    const url = item.is_radio == "1" ? item.radio_url : item.url
    if (item && item.service_id && !item.video_id) { // means that is play list is live
        if(!!fromDST2 && fromDST2  /*|| item.url.indexOf('dst2') > -1*/) {
            setDstLink('')
            setItemWithNoLink(item)
            redirectCall(item,url)
        }else if (!!!localStorage.getItem(localStorageKeys.dstKey)){
            //from dst1
            setDstLink(url)
            setItemWithNoLink(item)
        }else{
            //from dst2 but no need to redirect
            setItemWithNoLink(item)
        }
        // const currentIndex = items.findIndex(i => i.service_id === item.service_id)
        // localStorage.setItem(localStorageKeys.currentPlaylistIndex, currentIndex)
        localStorage.setItem(localStorageKeys.currentPlaylistIndex, item.service_id)
        dispatch({
            type: PLAYLIST_SELECT_ITEM_LIVE_PRICES,
            payload: prices
        })
    } else if (item && item.video_id) {
        if (url.indexOf('redirect') > -1) {
            setDstLink('')
            setVodDSTLink('',item,true)
            redirectCall(item,url)

        }else{
            setDstLink(url)
            setVodDSTLink('',item,true)
        }
    }
}

export const getLink = (request, object = undefined, video = undefined) => {
    const auth = store.getState().auth.authToken
    const list = store.getState().playlist.items
    const service = !!request.service ? request.service : undefined
    const videoBookmark = !!request.video ? request.video : undefined

    let req = undefined

    if (request.type === playerTypes.live) {
        req = {
            auth,
            service_id: request.service_id,
        }
    } else if (request.type === playerTypes.video) {
        req = {
            auth,
            service_id: request.service_id,
            video_id: request.video_id,
        }
        if (!!request.pkg_usr_id_fk) req['pkg_usr_id_fk'] = request.pkg_usr_id_fk  // if it is from package
        if (!!request.object_name) req['object_name'] = request.object_name  // if not from package
    }
    dispatch({type: PLAYLIST_SET_FETCHING,})
    dispatch({
        type:SET_FETCHING,
        payload:true
    })
    const params = jsonToFormData(req)
    axios.post(host + getContentLink, params)
        .then(res => {
            //dispatch({type: PLAYLIST_UNSET_FETCHING,})
            const link = res.data.is_radio === "1" ? res.data.radio_url : res.data.msg
            const radio_url = !!res.data.radio_url ? res.data.radio_url : null
            const is_multicast = res.data.is_multicast
            const bookmark = res.data.bookmark
            const encrypted = res.data.is_encrypted
            if (res.status === 200 || res.status === 409) {
                dispatch({
                    type:SET_FETCHING,
                    payload:false
                })
                if (request.type === 'service' && object) {
                    const playlistItem = {
                        service_id: object.service_id,
                        service_name: object.service_name,
                        service_type: object.service_type,
                        prices: object.prices,
                        url: link,
                        is_watermark: object.is_watermark,
                        is_multicast,
                        is_radio:object.is_radio,
                        radio_url:radio_url,
                        logo: object.logo,
                        logo_big: object.logo_big,
                        is_encrypted:!!encrypted ? encrypted : null,
                        balance: object.balance,
                        duration: object.duration,
                        disconnect_time: object.disconnect_time,
                        is_movie: object.is_movie
                    }
                    setDstLink('')
                    if (link.indexOf('redirect') > -1) {
                        let searchParams = new URL(link).searchParams
                        localStorage.setItem(localStorageKeys.dstKey, searchParams.get('t'))
                    }
                    selectPlaylistItem(playlistItem)
                    if (link.indexOf('redirect') > -1){
                        // let searchParams = new URL(res.data.msg).searchParams
                        // localStorage.setItem(localStorageKeys.dstKey,searchParams.get('t'))
                        // localStorage.setItem(localStorageKeys.dstNum,'2')
                        redirectCall(!!object ? object : undefined,link)
                    }else {
                        localStorage.removeItem(localStorageKeys.dstKey)
                        // localStorage.setItem(localStorageKeys.dstNum,'1')
                    }
                    // if (res.data.msg.indexOf('redirect') > -1){
                    //     let searchParams = new URL(res.data.msg).searchParams
                    //     localStorage.setItem(localStorageKeys.dstKey,searchParams.get('t'))
                    //     // localStorage.setItem(localStorageKeys.dstNum,'2')
                    //     axios.post(res.data.msg).then(res => {
                    //         setDstLink(res.request.responseURL)
                    //     })
                    // }else {
                    //     localStorage.removeItem(localStorageKeys.dstKey)
                    //     // localStorage.setItem(localStorageKeys.dstNum,'1')
                    // }
                    const isItemInList = list.findIndex(item => item.service_id === playlistItem.service_id) !== -1
                    if (!isItemInList) dispatch({type: PLAYLIST_ADD_ITEM, payload: playlistItem})
                    if (request.locked !== false) selectChannel(request.service_id)
                } else {
                    setDstLink('')
                    let videoItem = {}
                    if(!!service && !!videoBookmark){
                        videoItem = {
                            ...service,
                            ...videoBookmark,
                            bookmark: parseInt(bookmark),
                            is_encrypted:!!encrypted ? encrypted : null,
                            url: link
                        }
                    }else{
                        videoItem = {
                            ...store.getState().movies.item,
                            ...store.getState().movies.video.video,
                            is_encrypted:!!encrypted ? encrypted : null,
                            bookmark: parseInt(bookmark),
                            url: link
                        }
                    }
                    dispatch({type: PLAYER_SET_CURRENT_LINK, payload: videoItem})
                    if (link.indexOf('redirect') > -1){
                        setDstLink('')
                        let searchParams = new URL(link).searchParams
                        localStorage.setItem(localStorageKeys.dstKey,searchParams.get('t'))
                        // localStorage.setItem(localStorageKeys.dstNum,'2')
                        redirectCall(videoItem,link)
                    }else {
                        setDstLink(link)
                        localStorage.removeItem(localStorageKeys.dstKey)
                    }
                    if (request.locked !== false) getVideoInfo(request.object_name, request.service_id) // refresh the video under the player
                }

            }
        })
        .catch(error => {
            dispatch({
                type:SET_FETCHING,
                payload:false
            })
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            handleHttpError(error)
        })
}

export const getSeriesPlaylist = (service_id, locked = true, video_id = undefined, video = undefined) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        service_id,
    }
    dispatch({type: PLAYLIST_SET_FETCHING,})
    dispatch({
        type:SET_FETCHING,
        payload:true
    })
    const params = jsonToFormData(req)
    axios.post(host + getSeriesPlaylistAPI, params)
        .then(res => {
            //dispatch({type: PLAYLIST_UNSET_FETCHING,})
            if (res.status === 200) {
                const list = res.data.playlist
                const bookmarkRes = res.data.bookmark ? res.data.bookmark : undefined
                let currentPlaylistItem = undefined
                const newList = list.map(item => {
                    // const bookmark = bookmarks.find(iItem => iItem.video_id === item.video_id)
                    const bookmark = bookmarkRes ? item.video_id === bookmarkRes.video_id : undefined
                    if (bookmark) {
                        currentPlaylistItem = {...item, bookmark: parseInt(bookmarkRes.bookmark), service_id}
                        return currentPlaylistItem
                    }
                    return {...item, service_id}
                })
                if (!currentPlaylistItem) currentPlaylistItem = newList[0]
                if (!!video_id) {
                    const isVideoInPlaylist = list.find(item => item.video_id === video_id)
                    if (isVideoInPlaylist) currentPlaylistItem = isVideoInPlaylist
                    currentPlaylistItem.service_id = service_id
                    if(res.data.bookmark && isVideoInPlaylist.video_id === res.data.bookmark.video_id){
                        currentPlaylistItem.bookmark = parseInt(bookmarkRes.bookmark)
                    }
                }
                // dispatch({
                //     type:SET_SERIESVIDEO_BOOKMARK,
                //     payload:video
                // })
                dispatch({
                    type:SET_FETCHING,
                    payload:false
                })
                setDstLink('')// to not show the shift if content was played before
                if (currentPlaylistItem.url.indexOf('redirect') > -1) {
                    setVodDSTLink(newList,currentPlaylistItem)
                    redirectCall(currentPlaylistItem,currentPlaylistItem.url)
                    // axios.post(currentPlaylistItem.url).then(res => {
                    //     if(res.status === 200){
                    //         //setDstLink(res.request.responseURL)
                    //     }
                    // }).catch(error => {
                    //     setDstLink(currentPlaylistItem.url)
                    //     setVodDSTLink(newList,currentPlaylistItem,false)// just to show the player
                    // })
                }else{
                    setDstLink(currentPlaylistItem.url)
                    setVodDSTLink(newList,currentPlaylistItem, false)
                }
                // if (currentPlaylistItem.url.indexOf('redirect') > -1) {
                //     setVodDSTLink(newList,currentPlaylistItem)
                //     axios.post(currentPlaylistItem.url).then(res => {
                //         if(res.status === 200){
                //             setDstLink(res.request.responseURL)
                //         }
                //     }).catch(error => {
                //         setDstLink(currentPlaylistItem.url)
                //         setVodDSTLink(newList,currentPlaylistItem,false)// just to show the player
                //     })
                // }else{
                //     setDstLink(currentPlaylistItem.url)
                //     setVodDSTLink(newList,currentPlaylistItem, false)
                // }
                if (locked){
                    removeParentServices()
                    getSeriesInfo(service_id)
                }
            }
        })
        .catch(error => {
            dispatch({
                type:SET_FETCHING,
                payload:false
            })
            dispatch({type: PLAYLIST_UNSET_FETCHING,})
            handleHttpError(error)
        })

}

const setItemWithNoLink = item => {
    dispatch({
        type: PLAYLIST_SELECT_ITEM_LIVE,
        payload: item
    })
}

const setVodDSTLink = (newList,item,inPlayerSelect = undefined) => {
    if (inPlayerSelect){// select episode from player playlist
        dispatch({
            type: PLAYLIST_SELECT_ITEM,
            payload: item
        })
    }else{
        dispatch({
            type: PLAYLIST_SET_SERIES,
            payload: {list: newList, item: item}
        })
    }
}
export const unsetFetching = () => {
    const {fetching} = store.getState().playlist
    if (fetching) dispatch({type: PLAYLIST_UNSET_FETCHING})
}

export const setPlayerError = () => {
    const {error} = store.getState().playlist
    if (!error) dispatch({type: PLAYLIST_SET_ERROR})
}

export const toggleChat = () => {
    const {chatEnabled} = store.getState().playlist
    dispatch({
        type:TOGGLE_CHAT,
        payload:!chatEnabled
    })
}

export const setLiveSessionEnd = (bool) => {
    dispatch({
        type:SET_LIVE_SESSION_END,
        payload:bool
    })
}

