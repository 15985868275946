import React, {useEffect} from 'react'
import NotificationItem from "./NotificationItem";
import {FiTrash} from "react-icons/fi";
import {localize} from "../../helpers/functions";
import {
    clearNotificationBadge,
    clearNotifications,
    getNotifications,
    incrementNotification
} from "../../redux/actions/notificationActions";
import {useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import LoadMoreSpinner from "../../components/Fillers/LoadMoreSpinner";
import Empty from "../../components/Fillers/Empty";
import FetchingData from "../../components/Fillers/FetchingData";
import {contentType, localStorageKeys, serviceType} from "../../helpers/enums";
import {Modal} from 'antd';
import {Routes} from "../../routes/AppRouter";
import {loadingImages} from "../../assets/img/exports";
import store from "../../redux/store";

const Notifications = props => {
    const notifications = useSelector(state => state.notification)
    const unreadNotifications = !!localStorage.getItem(localStorageKeys.unreadNotifications) ? localStorage.getItem(localStorageKeys.unreadNotifications) : null
    const {items, hasMore, fetching} = notifications

    useEffect(() => {
        if (unreadNotifications > 0) clearNotificationBadge()
        getNotifications()
    }, [unreadNotifications])


    const renderItems = !!items && items.length > 0 ? items.map((item, index) => {
        let route = undefined
        let formLive = false
        if (!!item.content_type) {
            if (item.content_type.toLowerCase() === contentType.service) {
                if (!!item.service_type && item.service_type.toLowerCase() === serviceType.live) {
                    formLive = true
                    route = Routes.channelId(item.content_id)
                }
                if (!!item.service_type && item.service_type.toLowerCase() === serviceType.vod) route = Routes.moviesCategoriesTo(item.content_id)
            } else if (item.content_type.toLowerCase() === contentType.videoRes) {
                route = Routes.movieTo(item.content_id, item.service_id)
            } else if (item.content_type.toLowerCase() === contentType.bundle) {
                formLive = true
                route = Routes.bundleTo(item.content_id)
            }

        }

        return item.id === undefined ? null : (
                <NotificationItem
                    key={index}
                    title={item.name}
                    desc={item.subtitle}
                    route={route}
                    read={item.is_read === '0'}
                    time={item.created}
                    formLive={formLive}
                    img={item.logo}
                    onClick={() => item.is_read === '1' ? !!props.onClick ? props.onClick() : null : onclickNotification(item.id)}
                    loading={false}
                    popup={item.is_popup}
                    event={item.content_type}
                    liveSessionId={item.go_live_session}
                    username={item.content_id}
                    onRemove={() => clearNotifications(item.id)}
                />
        )
    }) : []

    const onclickNotification = (id) => {
        incrementNotification(id)
        props.onClick()
    }

    const loadMore = page => {
        if(!store.getState().services.errorVisible && !fetching) getNotifications(page)
    }

    const confirmClear = () => {
        Modal.confirm({
            title: localize('deleteNotificationsConfirm'),
            okText: localize('ok'),
            cancelText: localize('cancel'),
            onOk() {
                clearNotifications()
            },
            onCancel() {
            },
        });

    }

    return (
        <div className={`settings-container`}>
            {fetching ?
                <div className="settings-container-pop">
                    <img width="10%" src={loadingImages} alt="loadingImages" />
                </div>
            : null }
            <div className={`notification-container`}>
                {!!renderItems && renderItems.length > 0 ? (
                    <>
                        <div className='notification-header'>
                            <h4 className='text-center'>{localize('notifications')}</h4>
                            <div className='clickable-light notification-header-icon' onClick={confirmClear}>
                                <FiTrash/>
                            </div>
                        </div>

                        <InfiniteScroll
                            pageStart={0}
                            loadMore={loadMore}
                            hasMore={hasMore}
                            useWindow={!props.scrollRef}
                            getScrollParent={() => props.scrollRef ? props.scrollRef.current : undefined}
                            loader={<LoadMoreSpinner key={Math.random()}/>}
                        >
                            {renderItems}
                        </InfiniteScroll>
                    </>
                ) : !hasMore && items.length === 0 ? <Empty type='notifications'/> : <FetchingData/>}

            </div>

        </div>
    )
}

export default Notifications
