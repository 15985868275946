import {currentOperation} from "../../helpers/constants";
import {localStorageKeys, operations} from "../../helpers/enums";

const en = {
    shareMessage:(serviceName) => `Watch ${serviceName} on yabadoo `,
    shareTitle:'',
    movie: 'Movie',
    series: 'Series',
    clips: 'Clips',
    clip: 'Clip',
    plays: 'Plays',
    play: 'Play',
    home: 'Home',
    myContent: 'My Content',
    theContent: 'The Content',
    favorites: 'Favorites',
    history: 'History',
    feedback: 'Feedback',
    support: 'Support',
    settings: 'Settings',
    go: 'Go',
    search: 'Search',
    searchMsg: `You name it... I will find it...`,
    movies: 'Movies',
    contactUs: 'Contact Us',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',



    continueWatching: 'Continue Watching',
    appTitle: 'Yabadoo',
    Or:'OR',
    enjoyableEntertainment: 'Enjoyable Entertainment',
    loginTextFirst: 'Enjoyable',
    loginTextSecond: 'Entertainment',
    refreshTitle:'Get on the latest Version',
    refreshDesc:'There is a new version available. Refresh now and Enjoy Watching',
    faq: 'FAQ',
    copyright1: 'Copyright © 2022',
    copyright2:'Aplitv.',
    copyright3:'All Rights Reserved',
    autoRenew: 'Auto Renew',
    autoRenewed: 'Auto Renewed for cancellation go to My content',
    autoRenewed2:'Autorenewed',
    notAutoRenewed2:'Not Autorenewed',
    autoRenewMsg: 'Automatic renewals happen at 9:00, 12:00 and 18:00',
    feedbackMsg: 'Tell us what you think of the app?',
    feedbackInput: 'Give us your feedback, we will get back to you promptly.',
    feedbackSuccessMsg: 'Thank you, we appreciate and value your feedback.',
    feedbackFillMsg: 'Please complete the feedback form before you submit.',
    submit: 'Confirm',
    readMore: 'Read more',
    readLess: 'Read less',
    notifications: 'Notifications',
    devices: 'Devices',
    acceptNotifications: 'I agree to accept push notifications',
    acceptNotificationsMsg: 'Notifications include the latest TV programs, movies, personalized suggestions and various other notices',
    selectLanguage: 'Select Language',
    pressToDeactivate: 'Press to deactivate',
    linkDevice: 'Link a device',
    linkDeviceMsg1: 'If you want to link other devices to the current account please use the following code:',
    code: 'Code:',
    linkDeviceMsg2: 'If you wish to link this device to another account, please enter the code of that account here:',
    ok: 'OK',
    myDevices: 'Please press on the device you want to deactivate.',
    userProfile: 'User Profile',
    operatorName:'Network',
    paymentMethod: 'Payment Method',
    deactivate: 'Deactivate',
    deactivateDevice: 'Deactivate Device',
    deviceId: 'Device Id:',
    deviceType: 'Device Type:',
    deviceOS: 'Device OS:',
    deviceOSVersion: 'Device OS Version:',
    Version:'Version',
    deviceAppVersion: 'Device App Version:',
    phoneNumber: 'Phone Number:',
    verificationCode: 'Activation Code:',
    verificationCodeWrong:'Did not receive a SMS ? Please retry by resetting and if it still does not work, contact support to solve your problem.',
    verificationCodeWrongTitle:'Oops,  the code you entered is  incorrect.',
    sendCode: 'Send Code',
    register: 'Register',
    reset: 'Reset',
    fromPhoneCredit: 'From phone credit',
    loadMore: 'Load more',
    playlist: 'Playlist',
    youMayAlsoLike: 'You may also like: ',
    movieS: 'Movie(s)',
    youGet: 'You Get',
    theyGet: 'They Get',
    dayS: 'day(s)',
    buy: 'Buy',
    gift: 'Gift',
    watch: 'Watch',
    validFor: 'Valid for',
    valid:'Validity',
    willDeducted: 'Will be deducted from your package',
    subscription: 'Subscription',
    packages: 'Packages',
    watchFromYourPackages: 'Watch from package',
    ppv: 'Rent',
    fullView: 'Single View',
    hourS: 'Hour(s)',
    minuteS: 'Minute(s)',
    secondS:'Second(s)',
    playS: 'Play(s)',
    clipS: 'Clip(s)',
    plan: 'Plan',
    orderId: 'Order Id',
    paidOn: 'Paid On',
    videos: 'Videos',
    vodPackages: 'VOD packages',
    vodCategories: 'VOD categories',
    expiresWithin: 'Expires within',
    expired:'Expired On',
    remainingDuration: 'Remaining duration',
    currentDevice: 'Current device',
    confirm: 'Confirm',
    cancel: 'Cancel',
    seasons: 'Seasons',
    episodes: 'Episodes',
    episode: 'Episode',
    chooseSeason: 'Choose season to view details',
    giftNumber: 'Gift number...',
    live: 'Live',
    typeMessage: 'Type a message...',
    permanentAccess: '(Permanent Access).',
    skip: 'SKIP',
    no: 'No',
    yes: 'Yes',
    empty: 'Empty',
    change: 'Change',
    delete: 'Delete',
    changeAvatarMsg: 'Choose one option',
    updateAvailable: 'A new update is available. Please click to enjoy the latest features and benefits',
    refresh: 'Refresh',
    loading: 'Loading...',
    SYP: 'SYP',
    CFA: 'FCFA',
    MT:'MT',
    USD: '$',
    purchaseConfirm: 'Are you sure you wish to purchase?',
    giftConfirm: (name) => `Please confirm you want to send ${name} as a gift to`,
    watchConfirm: 'Are you sure you wish to watch?',
    name: 'Name',
    price: 'Price',
    validity: 'Validity:',
    deleteNotificationsConfirm: 'Are you sure you want to delete the notifications?',
    waitSeconds: (seconds) => `Please wait ${seconds} seconds...`,
    subscribeToEnableChat: 'Subscribe to enable chat',
    logout: 'Logout',
    share: 'Share',
    SHARE: 'SHARE',
    shareMsg: 'With Yabadoo you can enjoy 40+ TV stations live on your device without using your data',
    shareReferral1:'With Yabadoo you can enjoy live TV and Radio without using your data! Click the link to register and get 30 days for free!  ',
    shareReferral2:(referralCode) => `Use the ref-code ${referralCode} and get one day of free TV!`,

    seeMore: 'See More',
    seeLess: 'See Less',
    aboutUs: 'About Us',
    // errors
    pleaseRegisterFirst: 'Please register first',
    noData: 'No Data',
    noNotifications: 'No Notifications',
    noHistory: `You haven't bought anything yet`,
    noFavorites: `You don't have any Favorites`,
    noMyContent: 'There was no purchased content to display.',
    unknownError: 'Please check your connectivity and try again later',
    enterGiftNumber: 'Please enter gift number',
    error404: 'You seem lost... try search something...',
    chooseValidFile: 'Please choose a valid file...',
    playerAlreadyOpen: 'The player is already opened',
    unexpectedError: 'Oops.. Something went wrong',
    sharingError:'',
    noItemsOnTheListTitle:'Warning: No active channels',
    noItemsOnTheList: 'Please purchase any channel or contact your service provider',
    errorLoadingVideo: 'Error in loading the video',

    //success
    giftSuccess: 'Your gift was successfully delivered. Keep up the good work!',
    giftSuccessTitle: 'You just made someone very happy!',

    msgToAllowPurchese: 'To watch this clip, please send CLIP to 1055. Enjoy!',

    notValidphoneNumber:'The phone number does not seem to be a valid number. Please check and retry, if it still does not work, contact support to solve your problem ',
    send:'SEND',
    selectSeason:'Select...',
    searchKeyLength:'Please enter at least 3 characters',

    alsoAvailable:'or buy in Bundle',
    alsoAvailableGift:'or gift in Bundle',
    unlimited:'Unlimited',
    wrong:'Something went wrong, please try again later.',
    retry:'Retry',
    exit:'Exit',
    selectCaption:'Select Caption',
    selectBitRate:'Select Bit Rate',
    chooseyourCountry:'Choose Country',
    chooseyourOperator:'Choose Operator',
    please_try_again_laterTitle:'Well, this is embarrassing;',
    please_try_again_later:'Problemas de ligação! Por favor tenta novamente, se o problema persistir liga para a linha do cliente.',
    renewed_note:'Your airtime will be deducted automatically to renew your subscription. If you no longer wish to renew, please visit the "My Contents" page and disable the auto-renewal. A discount may be applied at the renewal time.',
    notrenewed_note:'This is not an automatically renewed subscription.',
    feedback_title:'Got it!',


    login:'REGISTER',
    journey_text:' ',
    like_it:'Like it',
    love_it:'Love it!',
    not_realy:'Not really',


    unauthorized_access:'Unauthorized Access',
    no_balanceTitle:'Failed',
    no_balance:'Unfortunately your transaction was declined, please recharge.',
    owened_content_subtitle:'Refresh or re-open the app, and retry to access this content again, and if it still does not work, contact support to solve your problem.',
    owened_content_subtitleTitle:'Oh wow, it seems you already own this content!',
    not_operator_number:'Seems the pesrson you are sending the gift to is not a valid user.',
    not_operator_numberTitle:'Oops, we were unable to send your gift',

    nonetworkTitle:'Failure',
    nonetwork:'Please check your connectivity and try again later',
    noContent:'There was no more content to display',
    setlanguageFailed:'Error in changing language',


    autorenewFailed:'We were unable to save your changes',
    autorenewFailedDes:'Might be a connectivity issue, please try again and if it still fails, contact support to help you.',
    notValidphoneNumberTitle:'Oops, we were unable to process your phone number',
    notValidphoneNumberHint:'Invalid phone number',
    confirmation_code_text :'A confirmation code has been sent via SMS.',
    //new
    deviceExceed:'You have reached the maximum limit of allowed registered devices. Kindly deactivate one of the devices to proceed.',
    uh_oh:'UH-OH',
    gift_already_owenedTitle:'Oops, we were unable to send your gift',
    gift_already_owened:'Seems the person you are sending the gift to already owns that content, feel free to send something else.',
    shareSuccess:'Successful share',

    //streamlimit
    reachLimit:'You have reached your streaming limit, please purchase more time in order to continue enjoying Yabadoo',
    reachLimit0:'You have reached your daily limit for this channel. Please try again tomorrow',
    reachLimit01:'You have reached your streaming limit for this channel. Please try again tomorrow',
    deactivateSuccessTitle: 'Success',
    deactivateSuccess: 'You have successfully logged out from this device',


    //new user journey
    Message:'Welcome to Yabadoo, the home of the best local and regional video and TV content. Congrats! Because you’ve successfully activated your account, you have won a 7 days subscription to enjoy streaming STV, Canal2 Movies and CRTV without using your internet MB!',
    Title:'Hi, I`m Ivy. Your personal assistant',
    Object:'33',
    Image:'https://cam.mtnsyr.com/media/Ivy/ivy_welcome.png',
    deactiveDevice: 'Please enter your code',


    watchFromBundle:'To watch this content you have to subscribe to one of the below bundle(s).',
    giftFromBundle:'Choose one of the below bundles that you wish to gift.',
    next:'Next',

    referral_code_checkbox:'I have a referral code',
    enter_referral_code:'Enter Referral Code',
    referral_code:'Referral Code',
    referral_title:'Get free TV access!',
    referral_description:'Share Yabadoo with friends and get free TV access each time a friend registers using your code',

    menuTitle:'Yabadoo',
    install:'Install ',
    justTap:'Just tap ',
    thenAddToHomeScreen:` then 'Add to Home Screen'`,
    addToHomeScreen:` to Add PWA to Home Screen`,
    notShowAgain:`Don't Show Again`,

    downloadAndroid:'Download Mobile App',

    dontShowAgain:`Don't show this Dialog again`,
    haveSubscriptionTitle:'Oh, it seems you already have an active subscription to that content',
    haveSubscriptionText:'Please contact customer service to re-enable your access.',

    logout_title:'Are you sure you want to logout ? ',
    logout_description:'p.s: Logging out does not stop your active subscriptions, you can manage them from the My Content tab.',
    startingIn:(seconds) => `Starting in ${seconds} ...`,
    nickname:'Enter Your Name',
    description:'Who are you?',
    save:'Save',
    proposals:'Proposals for you',
    bundles: 'BUNDLES',
    channels: 'LIVE',
    proposalFillHint:`Fill it by clicking 'add to favorite' in details page`,
    noSearchTitle:'Sorry, no results found',
    noSearchHint:'Try again using different words',
    Profile:'Profile',


    startWatching:'Start Watching',

    radio:'Radio',
    radioHint:'Awesome, You are now in Radio Mode! To switch back to TV Mode (Video), please press on the TV icon to the top right.',


    loyalityStreakTitle:'Loyality Gift',
    loyalityStreakDesc:'Login to Yabadoo 6 days in a row and get one day free access to our biggest bouquet to appreciate your loyalty. We love you!',
    loyalityStreak0:'A fresh new start...',
    loyalityStreak1:'Bring it on!!!',
    loyalityStreak2:`WoW you are a little closer.`,
    loyalityStreak3:`Go On Champ. You are almost there.`,
    loyalityStreak4:'You are very close!',
    loyalityStreak5:'5 days checked!!! log in tomorrow for your gift.',

    payMobile:'Pay with Mobile Money',
    payAirtime:'Pay with Airtime',

    pendingMobileMoney:'',
    failureMobileMoney:'',
    alreadyPendingMobileMoney:'',
    pendingAirtimeTransaction:'A Transaction has been initiated. Please be patient for a confirmation to stream your favorite channels.',
    failureAirtimeTransaction:`Your transaction wasn't successful, please try again.`,
    alreadyPendingAirtimeTransaction:'You have a pending transaction to this plan. Please be patient for confirmation.',
    historyAirtimeName:'Airtime',
    historyMobileMoneyName:'Mobile Money',
    from:'From:',
    timeOutTitle:'Oops, your session has expired.',
    timeOutDesc:`We're going to refresh your session, press OK to continue.`,

    codeAttemptsTitle:'Too many registration attempts',
    codeAttemptsDes:'Your account has been temporarily locked, please retry after 10 minutes.',

    Question:'Question',

    liveStream:'Live Stream',
    followers:'Follower(s)',
    report:'Report',
    reportTitle:'Why are you reporting this session?',
    reportDesc:`Your report is anonymous. If someone is in immediate danger, call the lcoal emergency services - don't wait`,
    reportSuccessTitle:'Report session',
    reportSuccessDesc:'Your Report has been received, thank you for helping us improve.',
    reportSuccessDesc2:'Your Report has already been received, thank you for helping us improve.',
    shareLive:(username) => `Watch ${username} with me without using your data `,
    sessionEndTitle:'This session is no longer Live',
    follower:'Followers',
    following:'Following',

    eventEndTitle:'Broadcast Ended',
    eventEndDesc:'This session is no longer Live',
    symbol:'cm',
}


let operationLang = {}

if (currentOperation === operations.aplitv) {
    operationLang = {
        loginTextFirst:' ',
        loginTextSecond:' ',
        register:'Welcome To Yabadoo',
        copyright1: 'Copyright © 2015',
        copyright2:'Aplitv.',
        copyright3:'All Rights Reserved',
        journey_text:'*By activating your account, your will be eligible for a free 7 days trial subscription to the bundle “Le Trio de Découverte” after which you will be charged 50F/day. To unsubscribe, you can visit the menu “My contents”.',
    }
} else if(currentOperation === operations.alAwal){
    operationLang = {
        appTitle: 'ShashatcomTV',
        loginTextFirst:' ',
        loginTextSecond:' ',
        shareReferral1:'With ShashatcomTV you can enjoy 40+ TV stations live on your smart phone without using your data: ',
        shareReferral2:(referralCode) => `Use the ref-code ${referralCode} and get one day of free TV!`,
        reachLimit:'You have reached your streaming limit, please purchase more time in order to continue enjoying ShashatcomTV',
        referral_description:'Share ShashatcomTV with friends and get free TV access each time a friend registers using your code',
        journey_text:' ',
        shareMsg: 'With ShashatcomTV you can enjoy 40+ TV stations live on your device',
        copyright1: 'Copyright © 2020 ',
        copyright2:'ShashatcomTV.',
        copyright3:'All Rights Reserved',
        verificationCodeWrongTitle:'Failure',
        verificationCodeWrong:'Wrong Verification Code',
    }
} else if (currentOperation === operations.ishow) {
    operationLang = {
        shareMessage:(serviceName) => `Watch ${serviceName} on iShow`,
        appTitle:'iShow',
        enjoyableEntertainment: 'iShow',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
        feedbackSuccessMsg: 'Thank you for your feedback.',
        feedbackFillMsg:'You must fill in your feedback first',
        verificationCodeWrongTitle:'Failure',
        verificationCodeWrong:'Wrong Verification Code',
        giftSuccess: 'The gifting process was successfully done.',
        giftSuccessTitle: 'Success',
        notValidphoneNumberTitle:'Failure',
        notValidphoneNumber:'Invalid Number',
        please_try_again_laterTitle:'Unable to connect',
        please_try_again_later:'Your internet connictin with iShow appon not allowd to ru',
        nonetworkTitle:'Unable to connect',
        nonetwork:'Check your internet connection and make sure you have an active iShow package, so you can access the application, for Activation *007#',
        renewed_note:'Enable/Disable Auto Renew',
        notrenewed_note:'The subscription will not be auto-renewed',
        no_balance:'Not enough balance.',
        owened_content_subtitle:'You have already purchased this content, please refresh the app and try again.',
        owened_content_subtitleTitle:'Failed',
        not_operator_numberTitle:'Failed',
        autorenewFailed:'Autorenew Failed',
        reachLimit:'You have reached your streaming limit, please purchase more time in order to continue enjoying iShow',
        not_operator_number:`Sorry you can't gift the requested number. You can gift Syriatel numbers only.`,
        shareMsg:'With iShow app, enjoy 40+ TV channels live on your smartphone without using your data bundle:',
        fullView:'Full View',
        ppv:'PPV',
        copyright1: 'Copyright © ',
        copyright2:'iShow. ',
        autorenewFailedDes:'Your Autorenew selection was failed please try again later.',
        copyright3:'All Rights Reserved',


        Message:'Welcome to iShow, it’s the right time all the time! Your account has been successfully activated and YOU HAVE WON A 7 DAYS SUBSCRIPTION to the bundle “Basic bundle” to enjoy Sama TV, Lana TV , Dreams, Dreams2 and MBC 2 Without using your data bundle.Your subscription will be renewed at 50 SYP/day. To cancel to go to “My contents” in the app.',
        Title:'Hi, I`m Ivy. Your personal assistant',
        Object:'13',
        Image:'https://cam.mtnsyr.com/media/Ivy/ivy_welcome.png',

        referral_code_checkbox:'I have a referral code',
        enter_referral_code:'Enter Referral Code',
        shareReferral1:'With iShow app, enjoy 40+ TV channels live on your smartphone without using your data bundle: ',
        shareReferral2:(referralCode) => `Use the referral code ${referralCode} and get 7 days of free TV!`,
        share:'Get Free TV',
        referral_code:'Referral Code',
        referral_title:'Get free TV access!',
        referral_description:'Share iShow app with friends and get free TV access each time a friend registers with your code ',
        Or:' Or ',

    }
} else if (currentOperation === operations.mtnSyria) {
    operationLang = {
        shareMessage:(serviceName) => `Watch ${serviceName} on MTNTV`,
        shareTitle:'',
        autoRenewMsg:`If there's not enough funds, the subscription plan will be downgraded on autorenewal`,
        shareMsg:'Share the App',
        renewed_note:'Note: The subscription will be auto-renewed.',
        notrenewed_note:'Note: The subscription will not be auto-renewed',
        acceptNotificationsMsg: 'Notifications may include newly added movies, Tv shows, personalized suggestions or other notices',
        acceptNotifications: 'Accept push notifications',
        deactivateSuccessTitle: 'Success',
        deactivateSuccess: 'You have successfully released this device',
        gift_already_owened:'The user you are trying to gift already owns that content.',
        gift_already_owenedTitle:'Failed',
        please_try_again_laterTitle:'Failure',
        please_try_again_later:'Unable to connect with the service. Please try again later',
        nonetworkTitle:'Failure',
        nonetwork:'Please check your connectivity and try again later',
        not_operator_number:'The user you are trying to gift is not a MTN user.',
        not_operator_numberTitle:'Failed',
        giftSuccess: 'Your gift has been successfully sent',
        giftSuccessTitle: 'Success',
        owened_content_subtitle:'You have already purchased this content, please refresh the app and try again.',
        owened_content_subtitleTitle:'Failed',
        verificationCodeWrongTitle:'Failure',
        verificationCodeWrong:'Wrong Verification Code',
        notValidphoneNumberTitle:'Failure',
        notValidphoneNumber:'Please enter a valid phone number',
        autorenewFailed:'Autorenew Failed',
        autorenewFailedDes:'We regret to inform you that your subscription failed to auto renew',
        copyright1: 'Copyright © 2016 ',
        copyright2:'Mazaji. ',
        copyright3:'All Rights Reserved',
        appTitle: 'MTN TV',
        enjoyableEntertainment: 'MTN TV',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
        myContent: 'My Subscriptions',
        journey_text:'*By activating your account, your will be eligible for a free 7 days trial subscription to the bundle “Basic bundle”. ',
        feedbackFillMsg: 'Please fill your feedback first.',

        reachLimit:'You have reached your streaming limit, please purchase more time in order to continue enjoying MTN',

        Message:'Welcome to MTNTV, it’s the right time all the time! Your account has been successfully activated and YOU HAVE WON A 7 DAYS SUBSCRIPTION to the bundle “Basic bundle” to enjoy Sama TV, Lana TV , Dreams, Dreams2 and MBC 2 Without using your data bundle.Your subscription will be renewed at 50 SYP/day. To cancel to go to “My contents” in the app.',
        Title:'Hi, I`m Ivy. Your personal assistant',
        Object:'13',
        Image:'https://cam.mtnsyr.com/media/Ivy/ivy_welcome.png',

        referral_code_checkbox:'I have a referral code',
        enter_referral_code:'Enter Referral Code',
        shareReferral1:(referralCode) => `Login for free now to MTNTV without consuming any internet package by using the following referral code: ${referralCode}`,
        shareReferral2:'Enjoy the main package for free for a week for the first time, and also watch all the matches, your favorite movies and series, and many international and Arab stations: ',

        // shareReferral1:'With MTNTV app, enjoy 40+ TV channels live on your smartphone without using your data bundle: ',
        // shareReferral2:(referralCode) => `Use the referral code ${referralCode} and get 7 days of free TV!`,
        share:'Get Free TV',
        referral_code:'Referral Code',
        referral_title:'Get free TV access!',
        referral_description:'Share MTNTV app with friends and get free TV access each time a friend registers with your code ',
        Or:' Or ',

        no_balance:'Please make sure you have enough balance to run your purchase and retry, if you believe you have enough and it still does not work, contact support to solve your problem.',

    }
} else if (currentOperation === operations.mtnClipSyria) {
    operationLang = {
        appTitle: 'MTN Clip',
        enjoyableEntertainment: 'MTN Clip',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
    }
} else if (currentOperation === operations.nexttel || currentOperation === operations.nexttelLocal) {
    operationLang = {
        enjoyableEntertainment: 'My mobile, my TV',
        loginTextFirst:' ',
        loginTextSecond:' ',
        appTitle:'NexttelTV',
        menuTitle:'NeXttelTV',
        feedbackSuccessMsg: 'Thank you for your feedback.',
        feedbackFillMsg:'You must fill in your feedback first',
        acceptNotificationsMsg:'Notifications may include newely added movies, Tv shows, personalized suggestions or other notices',
        verificationCodeWrong:'The code you entered is incorrect. Please check it and try again.',
        verificationCodeWrongTitle:'Failure',
        ppv:'PPV',
        fullView:'Full View',
        shareMsg:'With NexttelTV app,enjoy 40+ TV channels(Boom TV,STV,CRTV,Canal 2,Nina TV…) live on your smartphone without using your data bundle',
        giftSuccess:'Your gift has been successfully sent.',
        giftSuccessTitle:'Success',
        please_try_again_laterTitle:'Service Unavailable',
        please_try_again_later:'Service unavailable, please try again later.',
        journey_text:`*By activating your account, your will be eligible for a free 7 days trial subscription to the bundle “Bouquet Original” after which you will be charged 50F/day. To unsubscribe, you can visit the menu “My contents”.`,

        shareReferral1:'With NexttelTV you can enjoy 40+ TV stations live on your smart phone without using your data: ',
        shareReferral2:(referralCode) => `Use the ref-code ${referralCode} and get one day of free TV!`,
        owened_content_subtitle:'You have already purchased this content, please refresh the app and try again.',
        owened_content_subtitleTitle:'Failed',
        no_balance:'No enough balance.. Please recharge',
        deviceExceed:'You have exceeded the number of allowed devices, please release one to be able to add more.',
        //nonetworkTitle:'Failure',
        //nonetwork:'Please check you connectivity and try again later',
        // no_balanceTitle:`Uh oh, seems you don't have enough airtime,`,
        // no_balance:'Please make sure you have enough Nexttel mobile credit to run your purchase and retry,  if you believe you have enough and it still does not work, contact support to solve your problem.',
        //nonetworkTitle:'Please make sure you connect via MTN (3G/4G) network to be able to play.',
        //nonetwork:`Either activate your mobile data on your device baring in mind that NexttelTV does not consume your internet MB. Or you can connect via WIFI if it's tethered from another device which has a MTN 3G/4G active plan (Tethering or Hotspot) Or Make sure you have good coverage signal, if not, change location and retry to open the app. If non of the that works and you are still facing issues, please contact support.`,
        //not_operator_number:'Seems the pesrson you are sending the gift to is not a Nexttel user.',
        //notValidphoneNumber:'The phone number does not seem to be a valid Nexttel number. Please check and retry, if it still does not work, contact support to solve your problem ',
        register:'Welcome To NexttelTV',
        reachLimit:'Streaming limit reached, would you like to get more time?',
        reachLimit0:'You have reached your streaming limit for this channel.',
        reachLimit01:'You have reached your streaming limit for this channel.',

        referral_description:'Share NexttelTV app with friends and get free TV access each time a friend registers using your code',

    }
}else if (currentOperation === operations.mtnCameroon){
    operationLang = {
        symbol:'cm',
        no_balanceTitle:`Uh oh, seems you don't have enough airtime,`,
        no_balance:'Please make sure you have enough MTN mobile credit to run your purchase and retry,  if you believe you have enough and it still does not work, contact support to solve your problem.',
        nonetworkTitle:'Please make sure you connect via MTN (3G/4G) network to be able to play.',
        nonetwork:`Either activate your mobile data on your device baring in mind that Yabadoo does not consume your internet MB. Or you can connect via WIFI if it's tethered from another device which has a MTN 3G/4G active plan (Tethering or Hotspot) Or Make sure you have good coverage signal, if not, change location and retry to open the app. If non of the that works and you are still facing issues, please contact support.`,
        not_operator_number:'Seems the pesrson you are sending the gift to is not a MTN user.',
        notValidphoneNumber:'The phone number does not seem to be a valid MTN number. Please check and retry, if it still does not work, contact support to solve your problem ',
        journey_text:'*By activating your account, your will be eligible for a free 30 days trial subscription to the bundle « le trio Découverte » after which you will be charged 50F/day. To unsubscribe, you can visit the menu “My contents”.',
        register:'Welcome To Yabadoo',

        Message:'Welcome to Yabadoo, it’s the right time all the time! Your account has been successfully activated and YOU HAVE WON A 7 DAYS SUBSCRIPTION to the bundle “Trio de Découverte” to enjoy EQUINOXE, STV and CANAL2 MOVIES. Without using your data bundle!',
        Title:'Hi, I`m Ivy. Your personal assistant',
        Object:'15',
        Image:'https://mtntv.mtncameroon.net/media/Ivy/ivy_welcome.png',
        // shareReferral1:'With Yabadoo you can enjoy 81+ TV stations live on your smart phone without using your data: ',
        shareReferral2:(referralCode) => `Use the referral code ${referralCode} and get 30 days of free TV!`,
        share:'Get Free TV',
        pendingMobileMoney:'A mobile transaction has been initiated. Please validate via your Mobile Money app or dial *126#.',
        failureMobileMoney:`Your Mobile Money transaction wasn't successful.  Please try again.`,
        alreadyPendingMobileMoney:'You have a pending Mobile Money transaction to this plan. Please validate it to access this Yabadoo content.',
        historyMobileMoneyName:'Mobile Money',

    }
}else if (currentOperation === operations.yabadoo){
    operationLang = {
        register:'Welcome To Yabadoo',
    }
}else if (currentOperation === operations.orange){
    operationLang = {
        symbol:'cm',
        no_balanceTitle:`Uh oh, seems you don't have enough airtime,`,
        no_balance:'Please make sure you have enough Orange mobile credit to run your purchase and retry,  if you believe you have enough and it still does not work, contact support to solve your problem.',
        nonetworkTitle:'Please make sure you connect via Orange (3G/4G) network to be able to play.',
        nonetwork:`Either activate your mobile data on your device baring in mind that Yabadoo does not consume your internet MB. Or you can connect via WIFI if it's tethered from another device which has a Orange 3G/4G active plan (Tethering or Hotspot) Or Make sure you have good coverage signal, if not, change location and retry to open the app. If non of the that works and you are still facing issues, please contact support.`,
        not_operator_number:'Seems the pesrson you are sending the gift to is not a Orange user.',
        notValidphoneNumber:'The phone number does not seem to be a valid Orange number. Please check and retry, if it still does not work, contact support to solve your problem ',
        register:'Welcome To Yabadoo',

        // shareReferral1:'With Yabadoo you can enjoy 81+ TV stations live on your smart phone without using your data: ',
        shareReferral2:(referralCode) => `Use the referral code ${referralCode} and get 30 days of free TV!`,
        share:'Get Free TV',
        journey_text:'*By activating your account, your will be eligible for a free 30 days trial subscription to the bundle « le trio Découverte » after which you will be charged 50F/day. To unsubscribe, you can visit the menu “My contents”.',
        pendingMobileMoney:'A mobile transaction has been initiated. Please validate via your Orange Money app or dial #150#.',
        failureMobileMoney:`Your Orange Money  transaction wasn't successful.  Please try again.`,
        alreadyPendingMobileMoney:'You have a pending Orange Money transaction to this plan. Please validate it to access this Yabadoo content.',
        historyMobileMoneyName:'Orange Money',

    }
}else if (currentOperation === operations.tmcel || currentOperation === operations.vodacom){
    operationLang = {
        symbol:'mz',
        no_balanceTitle:`Uh oh, seems you don't have enough airtime,`,
        no_balance:`Please make sure you have enough ${localStorage.getItem(localStorageKeys.operatorName)} mobile credit to run your purchase and retry,  if you believe you have enough and it still does not work, contact support to solve your problem.`,
        nonetworkTitle:`Please make sure you connect via ${localStorage.getItem(localStorageKeys.operatorName)} (3G/4G) network to be able to play.`,
        nonetwork:`Either activate your mobile data on your device baring in mind that Yabadoo does not consume your internet MB. Or you can connect via WIFI if it's tethered from another device which has a ${localStorage.getItem(localStorageKeys.operatorName)} 3G/4G active plan (Tethering or Hotspot) Or Make sure you have good coverage signal, if not, change location and retry to open the app. If non of the that works and you are still facing issues, please contact support.`,
        not_operator_number:`Seems the pesrson you are sending the gift to is not a ${localStorage.getItem(localStorageKeys.operatorName)} user.`,
        notValidphoneNumber:`The phone number does not seem to be a valid ${localStorage.getItem(localStorageKeys.operatorName)} number. Please check and retry, if it still does not work, contact support to solve your problem `,
        register:'Welcome To Yabadoo',

        // shareReferral1:'With Yabadoo you can enjoy 81+ TV stations live on your smart phone without using your data: ',
        shareReferral2:(referralCode) => `Use the referral code ${referralCode} and get 30 days of free TV!`,
        share:'Get Free TV',
        journey_text:'*By activating your account, your will be eligible for a free 30 days trial subscription to the bundle « Pacote De Prata » after which you will be charged 8MT/day. To unsubscribe, you can visit the menu “My contents”.',
        pendingMobileMoney:'A mobile transaction has been initiated. Please validate via your mKesh app',
        failureMobileMoney:`Your mKesh transaction wasn't successful.  Please try again.`,
        alreadyPendingMobileMoney:'You have a pending mKesh transaction to this plan. Please validate it to access this Yabadoo content.',
        historyMobileMoneyName:'mKesh',

    }
}else if (currentOperation === operations.alMadar) {
    operationLang = {
        appTitle: 'Al Madar',
        enjoyableEntertainment: 'Al Madar',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
    }
}


export default {...en, ...operationLang}
