import React from 'react'
import {FiStar} from "react-icons/fi";
import {Link} from "@reach/router";
import {addDefaultSrc, addRTL} from "../../../helpers/functions";
import {placeholderImage} from "../../../assets/img/exports";
import store from "../../../redux/store";

const ChannelCard = props => {
    const hotBadge = require('../../../assets/img/icons/hot.png')
    const freeBadge = require('../../../assets/img/icons/free.png')
    const newBadge = require('../../../assets/img/icons/newservice.png')
    const img = !!props.img ? props.img : placeholderImage
    const star = !!props.star ? props.star : false
    const title = !!props.title ? props.title : 'Title'
    const desc = !!props.desc ? props.desc.length > 60 ? props.desc.substr(0, 60) + '...' : props.desc : ''
    const route = !!props.route ? props.route : '#'
    const {rtl} = store.getState().settings

    let badge = undefined
    if (!!props.badge && props.badge === '1') badge = newBadge
    if (!!props.badge && props.badge === '2') badge = hotBadge
    if (!!props.badge && props.badge === '3') badge = freeBadge

    return(
        <div className='bundle-card-container'>
            <Link to={route} state={{title}}>
                <img src={badge} alt="" className='bundle-card-badge'/>
                {/*<div className='bundle-card-overlay'>*/}
                    {/*<p>{desc}</p>*/}
                {/*</div>*/}
                <img src={img} alt="" className='bundle-card-img' onError={(event) => addDefaultSrc(event)}/>
                {star ? <FiStar className='bundle-card-star'/> : null}

                {/*<div className='bundle-card-footer'>*/}
                    {/*{star ? <FiStar className='bundle-card-star'/> : null}*/}
                    {/*<p className={`bundle-card-title ${addRTL(rtl)}`} title={title}>{title}</p>*/}

                    {/*<Tooltip*/}
                    {/*    placement="bottom"*/}
                    {/*    title={title}*/}
                    {/*    className="tooltip"*/}
                    {/*>*/}
                    {/*    <p className={`channel-card-title ${addRTL(rtl)}`} title={title}>{title}</p>*/}
                    {/*</Tooltip>*/}
                {/*</div>*/}
            </Link>
        </div>
    )
}

export default ChannelCard
