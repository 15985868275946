import store from '../store'
import {
    ALL_SERVICES_SET_VIDEOS_BOOKMARK,
    PLAYER_OPEN_LIVE_PLAYER,
    PLAYER_SET_MINIMIZED,
    PLAYER_SET_RETRY,
    PLAYER_SET_VISIBLE,
    PLAYER_TOGGLE_FULLSCREEN,
    PLAYER_TOGGLE_MINIMIZED,
    PLAYER_UNSET_RETRY, PLAYLIST_ADD_PREDICTIONS, SET_FETCHING, SET_FETCHING_PREDICTION, SET_SERIES_BOOKMARK
} from "./types";
import {localStorageKeys} from "../../helpers/enums";
import {getCurrentDate, handleHttpError, jsonToFormData, localize, redirectCall, setDstLink, setHintDialog} from "../../helpers/functions";
import axios from "axios";
import {getContentLink, host, saveVideoBookmark, getServicePrediction, addUserPrediction} from "../api/endPoints";
import {getBehaviorStatistics} from "./servicesActions";
import {currency} from "../../helpers/constants";
import {getUserInfo} from "./settingsActions";
import {followStatus} from "./liveStreamActions";

const dispatch = store.dispatch

export const maximizePlayer = () => {
    dispatch({
        type: PLAYER_SET_MINIMIZED,
        payload: false
    })
}

export const minimizePlayer = () => {
    dispatch({
        type: PLAYER_SET_MINIMIZED,
        payload: true
    })
}

export const togglePlayer = (e,active = false) => {
    if(active)
    {
        e.stopPropagation()
    }
    dispatch({
        type: PLAYER_TOGGLE_MINIMIZED,
    })
}

export const hidePlayer = (e) => {
    if(e) e.stopPropagation()
    dispatch({
        type: PLAYER_SET_VISIBLE,
        payload: false
    })
}

export const setRetryPlayer = (e) => {
    dispatch({
        type: PLAYER_SET_RETRY,
        payload: true
    })
}
export const unsetRetryPlayer = (e) => {
    dispatch({
        type: PLAYER_UNSET_RETRY,
        payload: false
    })
}

export const showPlayer = () => {
    dispatch({
        type: PLAYER_SET_VISIBLE,
        payload: true
    })
}

export const toggleFullScreen = () => {
    const fullScreen = store.getState().playlist.fullscreen
    if (fullScreen) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
        else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    } else {
        const elem = document.querySelector('.player')
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }
    dispatch({
        type: PLAYER_TOGGLE_FULLSCREEN,
    })
}

const findIndex = (serviceId) => {
    const currentIndex = store.getState().playlist.items.findIndex(i => i.service_id === serviceId)
    return currentIndex === -1 ? '0' : currentIndex
}

export const openLivePlayer = () => {
    const playlist = store.getState().playlist.items
    const auth = store.getState().auth.authToken
    if (playlist.length > 0){
        const currentPlaylistIndex = localStorage.getItem(localStorageKeys.currentPlaylistIndex)
        const currentIndex = !currentPlaylistIndex ? '0' : findIndex(currentPlaylistIndex)
        // const currentIndex = !currentPlaylistIndex || currentPlaylistIndex === '-1' ? 0 : parseInt(currentPlaylistIndex)
        let item = playlist[currentIndex]
        let req = {
            auth,
            service_id: item.service_id,
        }
        const params = jsonToFormData(req)
        setDstLink('')
        // axios.post(host + getContentLink, params).then(res => {
        //     if (res.status === 200) {
        if (!currentPlaylistIndex || currentPlaylistIndex === '0') {
            item = playlist[0]
            localStorage.setItem(localStorageKeys.currentPlaylistIndex, item.service_id)
        }

        const url = item.is_radio == '1' ? item.radio_url : item.url
        if (url.indexOf('redirect') > -1) {
            let searchParams = new URL(url).searchParams
            localStorage.setItem(localStorageKeys.dstKey, searchParams.get('t'))

            openLiveSetLink(item,false)
            redirectCall(item,url)
        }else{
            localStorage.removeItem(localStorageKeys.dstKey)
            openLiveSetLink(item,true)
        }
        getBehaviorStatistics('4',item.service_id)
        if(typeof window.gtag !== 'undefined'){
            window.gtag("event", item.is_radio == '1' ? "Radio_Event" : "ViewerShip_Event", {
            'MSISDN':store.getState().settings.username,
            'item_id':item.service_name,
            'content_type':item.is_radio == '1' ? 'Radio'  : 'Live',
            'currency':currency,
            'operatorName':localStorage.getItem(localStorageKeys.operatorName),
            'countryName':localize('symbol')
            })
        }
        // }
        // }).catch(error => {
        //     handleHttpError(error)
        // })
        }else {
        setHintDialog('noItemsOnTheList',localize('noItemsOnTheListTitle'))
    }
}

export const openLiveSetLink = (item,set = undefined) => {
    dispatch({
        type: PLAYER_OPEN_LIVE_PLAYER,
        payload: item
    })
    if(set){
        setDstLink(item.is_radio === "1" ? item.radio_url : item.url)
    }
}
export const bookmarkVideo = (bookmark,multiLayerID, clear = false) => {
    if (bookmark !== undefined) {
        const auth = store.getState().auth.authToken
        const {service_id, video_id, is_movie} = store.getState().playlist.item
        const newBookmark = clear ? -1 : bookmark
        const bookmarkServices = store.getState().bookmark.bookmarkServices
        const bookmarkVideos = store.getState().bookmark.bookmarkVideos
        const req = {
            auth, service_id, video_id, bookmark: newBookmark
        }
        let serviceBookmark = []
        if(clear){
            //delete video form movies CW Category
            const foundV = bookmarkVideos.findIndex((item) => item.video_id === video_id)
            if(foundV != -1 && is_movie == "1"){
                bookmarkVideos.splice(foundV,1)
            }

            //delete bookmark for already added series
            bookmarkServices.map((item,i) => { //if multiLayer Service
                if (item !== (multiLayerID === undefined ? service_id : multiLayerID)){
                    serviceBookmark.push(item)
                }
            })
            //set bookmark 0 for series service
            store.getState().series.items.map((item,i) => {
                if((item.service_id === (multiLayerID === undefined ? service_id : multiLayerID)) &&  is_movie == "2"){
                    item.is_bookmark = "0"
                }
            })
        }
        else{
            //bookmark videos (is_movie 1)
            //add video bookmark to CW categ Home page
            let bookmarkList = []
            const foundV = bookmarkVideos.findIndex((item) => item.video_id === video_id)
            if(foundV === -1 && is_movie == "1"){
                bookmarkList.push(store.getState().playlist.item)
                bookmarkVideos.map((item,i) => {
                    bookmarkList.push(item)
                })
                dispatch({
                    type: ALL_SERVICES_SET_VIDEOS_BOOKMARK,
                    payload: bookmarkList
                })
            }

            //check if not Found, add bookmark series service Id
            const found = bookmarkServices.findIndex((item) => item === (multiLayerID === undefined ? service_id : multiLayerID))
            if(found === -1){
                serviceBookmark.push(multiLayerID === undefined ? service_id : multiLayerID)// if multiLayer Service
            }
            bookmarkServices.map((item,i) => {
                serviceBookmark.push(item)
            })
        }
        dispatch({
            type:SET_SERIES_BOOKMARK,
            payload:serviceBookmark
        })

        const params = jsonToFormData(req)
        axios.post(host + saveVideoBookmark, params)
            .then(res => {
                //localStorage.setItem(localStorageKeys.bookmark,JSON.stringify(bookmarkArray))
            })
            .catch(error => {
                //handleHttpError(error)
            })
    }
}

export const clearPrediction = () => {
    dispatch({
        type:PLAYLIST_ADD_PREDICTIONS,
        payload:{}
    })
}

export const getPredictions = (service_id) => {
    const auth = store.getState().auth.authToken
    const username = store.getState().settings.username
    const language = store.getState().settings.lang
    const req = {
        auth, service_id,username,language
    }

    dispatch({
        type:SET_FETCHING_PREDICTION,
        payload:true
    })

    const params = jsonToFormData(req)

    axios.post(host + getServicePrediction, params)
        .then(res => {
            dispatch({
                type:SET_FETCHING_PREDICTION,
                payload:false
            })
            dispatch({
                type:PLAYLIST_ADD_PREDICTIONS,
                payload:res.data.prediction
            })
        })
        .catch(error => {
            dispatch({
                type:SET_FETCHING_PREDICTION,
                payload:false
            })
            handleHttpError(error)
        })

}

export const addPrediction = (answerIndex,serviceId) => {
    const auth = store.getState().auth.authToken
    const username = store.getState().settings.username
    const prediction = store.getState().playlist.predictions

    const req = {
        auth,
        username,
        prediction_id:prediction.prediction_id,
        prediction_title:prediction.prediction_question,
        answer_id:prediction.answers[answerIndex].answer_id,
        answer_title:prediction.answers[answerIndex].answer_title
    }
    const params = jsonToFormData(req)
    dispatch({
        type:SET_FETCHING_PREDICTION,
        payload:true
    })
    axios.post(host + addUserPrediction, params)
        .then(res => {
            dispatch({
                type:SET_FETCHING_PREDICTION,
                payload:false
            })
            getPredictions(serviceId)
        })
        .catch(error => {
            dispatch({
                type:SET_FETCHING_PREDICTION,
                payload:false
            })
            handleHttpError(error)
        })
}
