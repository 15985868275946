import {operations} from "../../helpers/enums";
import {currentOperation} from "../../helpers/constants";

const ar = {
    shareMessage:(serviceName) => ``,
    shareTitle:'',
    appTitle: 'Yabadoo',
    Or:' ',
    enjoyableEntertainment: 'الترفيه الممتع',
    loginTextFirst: 'Enjoyable',
    loginTextSecond: 'Entertainment',
    search: 'بحث',
    searchMsg: `...أنت سمها ... ونحن سنجدها`,
    verificationCodeWrong:'رمز التحق الخاطئ',
    bundles: 'باقات',
    channels: 'قنوات',
    refreshTitle:'احصل على أحدث إصدار',
    refreshDesc:'هناك نسخة جديدة متاحة. قم بالتحديث الآن واستمتع بالمشاهدة',
    valid:'الصلاحية',
    movies: 'أفلام',
    movie: 'فيلم',
    series: 'مسلسلات',
    confirmation_code_text:'تم إرسال رمز التأكيد عبر الرسائل القصيرة',
    clips: 'مقاطع',
    clip: 'مقطع',
    plays: 'مسرحيات',
    play: 'مسرحية',
    home: 'الصفحة الرئيسية',
    myContent: 'اشتراكاتي',
    theContent: 'المحتوى',
    favorites: 'المفضلة',
    history: 'السجل',
    feedback: 'اقتراحات',
    support: 'الدعم',
    settings: 'الإعدادت',
    aboutUs: 'عنا',
    privacyPolicy: 'سياسة الخصوصية',
    termsOfUse: 'شروط الاستخدام',
    faq: 'الأسئلة الشائعة',
    go: 'ابحث',
    contactUs: 'تواصل معنا',
    copyright1: '© 2020 ',
    copyright2:'ApliTV.',
    copyright3:'جميع الحقوق محفوظة',
    autoRenew: 'تجديد تلقائي',
    autoRenewed2:'تتجدد تلقائياً',
    notAutoRenewed2:'لا تتجدد تلقائياً',
    autoRenewed: 'تتجدد تلقائيا، يمكن الإلغاء من قائمة باقاتي',
    autoRenewMsg: 'التجديد التلقائي يحصل عند الساعة : 9:00 , 12:00 و 18:00',
    FeedbackMsg: 'ما هو رأيك بالتطبيق؟',
    feedbackInput: 'شكراً لتجاوبك، سنقوم بالرد قريباً',
    feedbackSuccessMsg: 'شكراً لتجاوبك',
    feedbackFillMsg: '. الرجاء تعبئة رأيك قبل الأرسال',
    submit: 'تأكيد',
    readMore: 'لقراءة المزيد',
    readLess: 'لقراة أقل',
    notifications: 'الإشعارات',
    devices: 'الأجهزة',
    acceptNotifications: 'قبول الإشعارات',
    acceptNotificationsMsg: 'قد تتضمن الإشعارات أفلام جديدة أو إعلانات تلفزيونية أو اقتراحات مخصصة أو إشعارات أخرى',
    selectLanguage: 'أختر اللغة',
    pressToDeactivate: 'إضغط لإلغاء التفعيل',
    linkDevice: 'أربط جهاز',
    linkDeviceMsg1: ':إذا أردت ربط أجهزة أخرى للحساب الحالي الرجاء استخدام الكود التالي',
    code: ':الكود',
    linkDeviceMsg2: 'إذا أردت ربط هذا الجهاز مع حساب آخر, الرجاء إدخال كود الحساب هنا',
    myDevices:'يرجى الضغط على الجهاز الذي تريد إزالته',
    ok: 'موافق',
    userProfile: 'حساب المستخدم',
    paymentMethod: 'طريقة الدفع',
    deactivate: 'إلغاء التفعيل',
    deactivateDevice: 'إلغاء تفعيل الجهاز',
    deviceId: ':رقم الجهاز',
    deviceType: ':نوع الجهاز',
    deviceOS: ':نظام تشغيل الجهاز',
    deviceOSVersion: ':إصدار نظام التشغيل للجهاز',
    Version:'النسخة',
    deviceAppVersion: ':إصدار تطبيق الجهاز',
    phoneNumber: 'رقم الهاتف',
    verificationCode: 'كود التحقق',
    sendCode: 'إرسال الكود',
    register: 'تسجيل',
    login:'تسجيل',
    reset: 'إعادة ضبط',
    fromPhoneCredit: 'من رصيد الهاتف',
    loadMore: 'تحميل المزيد',
    playlist: 'قائمة التشغيل',
    youMayAlsoLike: 'قد يعجبك أيضاً',
    movieS: 'أفلام',
    youGet: 'ستحصل على',
    theyGet: 'سيحصلون على',
    dayS: 'أيام',
    buy: 'اشتري',
    gift: 'اهدي',
    watch: 'مشاهدة',
    validFor: 'صالح لغاية',
    willDeducted: 'سوف يتم خصمه من باقتك',
    subscription: 'الاشتراك',
    packages: 'باقات',
    watchFromYourPackages: 'مشاهدة من باقاتك',
    ppv: 'شراء عرض',
    fullView: 'مشاهدة كاملة',
    hourS: 'ساعات',
    minuteS: 'دقائق',
    secondS:'ثواني',
    playS: 'مسرحيات',
    clipS: 'مقاطع',
    plan: 'الاشتراك',
    orderId: 'رقم تعريف الطلب',
    paidOn: 'تاريخ الشراء',
    videos: 'فيديوهات',
    vodPackages: 'باقات فيديو',
    vodCategories: 'تصنيفات فيديو',
    expiresWithin: 'ستنتهي الصلاحية خلال',
    expired:'انتهت يوم',
    remainingDuration: 'الوقت المتبقى',
    currentDevice: 'الجهاز الحالي',
    confirm: 'تأكيد',
    cancel: 'إلغاء',
    seasons: 'المواسم',
    episodes: 'حلقات',
    episode: 'حلقة',
    chooseSeason: 'اختر موسم لعرض التفاصيل',
    giftNumber: 'رقم المستلم...',
    live: 'مباشر',
    typeMessage: 'اكتب رسالة',
    permanentAccess: 'الدخول بشكل دائم',
    skip: 'تخطي',
    no: 'لا',
    yes: 'نعم',
    empty: 'فارغ',
    change: 'تغيير',
    delete: 'حذف',
    changeAvatarMsg: 'أختر واحد من الخيارات',
    updateAvailable: 'هناك تحديث جديد اضغط للتحديث',
    refresh: 'تحديث',
    loading: 'تحميل...',
    SYP: 'ل.س',
    CFA: 'FCFA',
    USD: '$',
    purchaseConfirm: 'هل انت متأكد من عملية الشراء؟',
    giftConfirm: (name) => ` هل انت متأكد من عملية الإهداء${name} إلى `,
    watchConfirm: 'هل انت متأكد من عملية المشاهدة؟',
    name: 'الأسم',
    price: 'السعر',
    validity: 'الصلاحية',
    deleteNotificationsConfirm: 'هل فعلاً تريد حذف الإشعارات؟',
    waitSeconds: (seconds) => `الرجاء الانتظار  ${seconds} ثانية...`,
    subscribeToEnableChat: 'الرجاء الاشتراك لتفعيل المحادثة',
    playerAlreadyOpen: 'المشغل مفتوح',
    logout: 'خروج',
    share: 'مشاركة التطبيق',
    SHARE: 'مشاركة',
    shareMsg: 'شارك التطبيق',
    seeMore: 'المزيد',
    seeLess: 'القليل',


    // errors
    pleaseRegisterFirst: 'الرجاء التسجيل أولاً',
    noData: 'لا يوجد بيانات',
    noNotifications: 'لا يوجد إشعارات',
    noHistory: 'لا يوجد تاريخ',
    noFavorites: 'ليس لديك أية تفضيلات',
    noMyContent: '.عندما تقوم بالاشتراك او بشراء محتوى سوف يظهر هنا',
    unknownError: 'خطأ غير متوقع, الرجاء التأكد من الاتصال بالشبكة',
    enterGiftNumber: 'الرجاء إدخال رقم الإهداء',
    error404: '...يبدو أنك ضعت  ...  جرب البحث عن شيء',
    chooseValidFile: '...الرجاء اختيار ملف صالح',
    unexpectedError: 'خطأ غير متوقع',
    noItemsOnTheListTitle:'تحذير: لا يوجد قنوات فعالة',
    noItemsOnTheList: 'يرجى شراء أي قناة أو الاتصال بمزود الخدمة',
    errorLoadingVideo: 'خطأ في تشغيل الفيديو',

    //success
    giftSuccess: 'تم إرسال الإهداء بنجاح',

    msgToAllowPurchese:' إلى CLIP لمشاهدة هذا الكليب، يرجى إرسال 1055. استمتع',
    notValidphoneNumber:'رقم هاتف غير صحيح',
    send:'إرسال',
    selectSeason:'أختر...',
    searchKeyLength:'يجب أن تكون كلمة البحث 3 أحرف على الأقل',
    alsoAvailable:'أو اشتري ضمن باقات',
    alsoAvailableGift:'أو إهداء ضمن باقات',
    unlimited:'غير محدود',
    wrong:'هناك شيء خاطئ, الرجاء معاودة المحاولة',
    retry:'إعادة المحاولة',
    exit:'خروج',
    selectCaption:'حدد ترجمة',
    selectBitRate:'حدد معدل البت',
    please_try_again_later:'غير قادر على الاتصال الرجاء معاودة المحاولة',
    renewed_note:'ملاحظة: سيتم تجديد الاشتراك تلقائيا',
    notrenewed_note:'ملاحظة: لن سيتم تجديد الاشتراك تلقائيا',
    feedback_title:'تمام',
    journey_text:'.عند قيامك بتسجيل الدخول لأول مرة سوف تحصل على اشتراك مجاني لسبعة ايام بالباقة الاساسية، تجدد تلقائيا بعد ذلك بشكل يومي',
    like_it:'أعجبني',
    love_it:'ممتاز',
    not_realy:'لم يعجبني',
    unauthorized_access:'الدخول غير مسموح',
    no_balance:'لسوء الحظ تم رفض عملية التحويل',
    no_balanceTitle:'خطأ',
    owened_content_subtitle:'قد سبق واشتريت هذا المحتوى، يرجىإعدات تشغيل التطبيق.',
    not_operator_number:'المستخدم الذي تحاول إهدائه رقم مشغله غير صالح',
    not_operator_numberTitle:'خطأ',
    autorenewFailed:'فشل التجديد التلقائي',
    notValidphoneNumberTitle:'خطأ',
    autorenewFailedDes:'اختيارك للتجديد التلقائي قد فشل الرجاء المحاولة لاحقا',
    nonetwork:'الرجاء التأكد من الشبكة والمحاولة لاحقاً',
    noContent:'لا يوجد محتوى للعرض',
    setlanguageFailed:'خطأ في تغيير اللغة',
    verificationCodeWrongTitle:'خطأ',
    owened_content_subtitleTitle:'فشلت العملية',
    giftSuccessTitle:'نجاح',
    notValidphoneNumberHint:'رقم هاتف غير صحيح',
    nonetworkTitle:'فشل الاتصال',
    please_try_again_laterTitle:'المخدم غير متوفر',

    //new
    uh_oh:'إنتباه',
    deviceExceed:'لقد تجاوزت عدد الأجهزة المسموح بها ، يرجى إطلاق جهاز واحد لتتمكن من إضافة المزيد',
    gift_already_owened:'المستخدم الذي تحاول إهدائه يمتلك المحتوى مسبقاً',
    gift_already_owenedTitle:'خطأ',
    //streamlimit
    reachLimit:'لقد وصلت إلى الحد اليومي لهذه القناة،',
    reachLimit0:'لقد وصلت إلى الحد اليومي لهذه القناة، يرجى إعادة المحاولة غدا',
    reachLimit01:'لقد وصلت إلى الحد اليومي لهذه القناة،',
    deactivateSuccessTitle: 'نجاح',
    deactivateSuccess: 'لقد تم فصل جهازك بنجاح',
    shareSuccess:'تمت المشاركة بنجاح',


    Message:'Welcome to Yabadoo, the home of the best local and regional video and TV content. Congrats! Because you’ve successfully activated your account, you have won a 7 days subscription to enjoy streaming STV, Canal2 Movies and CRTV without using your internet MB!',
    Title:'مرحبًا ، أنا أيفي. مساعدك الشخصي.',
    Object:'33',
    Image:'https://cam.mtnsyr.com/media/Ivy/ivy_welcome.png',
    deactiveDevice: 'يرجى إدخال الرمز الخاص بك',


    watchFromBundle:'لمشاهدة هذا المحتوى ، الرجاء الاشتراك باحد الباقات أدناه',
    giftFromBundle:'إختر أحد الباقات لارسالها كهدية',
    next:'التالي',

    install:'تثبيت ',
    justTap:'فقط اضغط ',
    thenAddToHomeScreen:`  ثم إضافة إلى الشاشة الرئيسية `,
    addToHomeScreen:` للإضافة إلى الشاشة الرئيسية `,
    notShowAgain:'لا تظهر مرة أخرى',

    downloadAndroid:'تحميل تطبيق أندرويد',

    dontShowAgain:`لا تظهر هذا المحتوى مرة أخرى`,

    codeAttemptsTitle:`محاولات تسجيل كثيرة جدًا`,
    codeAttemptsDes:'تم قفل حسابك مؤقتًا ، يرجى إعادة المحاولة بعد 10 دقائق.',
}

let operationLang = {}

if (currentOperation === operations.aplitv) {
    operationLang = {
        loginTextFirst:' ',
        loginTextSecond:' ',
    }
} else if(currentOperation === operations.alAwal){
    operationLang = {
        copyright1: '© 2020 ',
        copyright2:'ShashatcomTV ',
        copyright3:'جميع الحقوق محفوظة',
        appTitle: 'ShashatcomTV',
        enjoyableEntertainment: 'ShashatcomTV',
        loginTextFirst:' ',
        loginTextSecond:' ',
        journey_text:' ',
    }
}
else if (currentOperation === operations.ishow) {
    operationLang = {
        shareMessage:(serviceName) => `iShow على ${serviceName}  شاهد`,
        appTitle:'iShow',
        enjoyableEntertainment: 'iShow',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
        searchMsg:'اسال ... سوف اجدها لك ...',
        myContent:'باقاتي',
        feedbackInput:'.أعطنا رأيك بالتطبيق وسوف نعود إليك على الفور',
        feedbackSuccessMsg:'رأيك يهمنا. شكراً لك',
        feedbackFillMsg:'يرجى ملء جميع الحقول',
        pressToDeactivate:'اضغط لإزالة تفعيل الجهاز',
        linkDevice:'ربط الأجهزة',
        linkDeviceMsg1:':إذا كنت ترغب في ربط أجهزة أخرى إلى الحساب الجاري الرجاء استخدام الرمز التالي',
        linkDeviceMsg2:':إذا كنت ترغب في ربط هذا الجهاز إلى حساب آخر، الرجاء إدخال الرمز من الحساب الآخر هنا',
        code:'الرمز',
        verificationCodeWrong:'لقد أدخلت رمزاً خاطئاً',
        typeMessage:'... أرسل رسالة',
        permanentAccess:'(امتلاك دائم)',
        no_balance:'الرصيد غير كافي',
        not_operator_number:'.عذراً لا يمكن إهداء الرقم المطلوب. علماً أنه يمكنك إهداء أرقام سيريتل  فقط',
        not_realy:'مقبول',
        love_it:'رائع',
        like_it:'جيد',
        feedback_title:'نعم',
        notrenewed_note:' لن يتم تجديدالاشتراك تلقائيا',
        renewed_note:'تفعيل/إلغاء التفعيل التلقائي',
        selectCaption:'إختر الترجمة',
        selectBitRate:'إختر الجودة',
        searchKeyLength:'الرجاء إدخال 3 أحرف على الأقل',
        send:'أرسل',
        giftSuccess:'تمت عملية الإهداء بنجاح',
        unknownError:'يرجى التحقق من الاتصال بالإنترنت والمحاولة مرة أخرى',
        noMyContent:'لا يوجد أي محتوى تم شراؤه للعرض',
        noFavorites:'لا يوجد لديك أي محتويات مفضلة',
        noHistory:'لم تشتري أي شيء حتى الآن',
        noNotifications: 'لا يوجد أية إشعارات',
        nonetwork:'غير قادر على الاتصال,الرجاء التأكد من اتصالك بالانترنت أو من وجود باقة iShow مفعلة,للتفعيل #007*',
        shareMsg:'استمتع بأكثر من 40 قناة تلفزيونية على هاتفك الذكي دون استخدام حزمة البيانات الخاصة بك . مع تطبيق iShow',
        subscribeToEnableChat:'يرجى الشترك لتفعيل خدمة الدردشة',
        waitSeconds: (seconds) => `يرجى الانتظار  ${seconds} ثانية...`,
        packages:'الحزم',
        watchFromYourPackages:'المشاهدة من الحزم',
        verificationCode:'رمز التفعيل',
        Version:':رقم الإصدار',
        userProfile:'ملف تعريفي للمستخدم',
        ok:'نعم',
        youMayAlsoLike:':تمتع أيضاً بمشاهدة',
        fromPhoneCredit:'من رصيد أو فاتورة رقمك',
        reset:'إعادة المحاولة',
        willDeducted:'سيتم خصمها من الحزمة الخاصة بك',
        validFor:'صالحة لغاية',
        gift:'هدية',
        purchaseConfirm:'هل أنت متأكد أنك ترغب في الشراء؟',
        price:':رسم الاشتراك',
        expiresWithin:'تنتهي خلال',
        vodCategories:'فئات الفيديو عندالطلب',
        orderId:'رقم الطلب',
        copyright1: '© ',
        copyright2:'iShow. ',
        copyright3:'جميع الحقوق محفوظة',
        autorenewFailed:'فشل في التجديد التلقائي',
        autorenewFailedDes:'.خطأ في عملية التجديد التلقائي، يرجى إعادة المحاولة في وقت آخر وشكراً',

        please_try_again_later:'اتصال الإنترنت الخاص بك لا يعمل مع تطبيق iShow',
        please_try_again_laterTitle:'فشل الاتصال',
        notValidphoneNumber:'الرقم غير مطابق',

        referral_code_checkbox:'لدي رمز الإحالة',
        enter_referral_code:'أدخل رمز الإحالة',
        Or:' أو ',
        shareReferral1:' استمتع مع تطبيق iShow بأكثر من 40 قناة تلفزيونية على هاتفك الذكي دون استخدام حزمة البيانات الخاصة بك: ',
        shareReferral2:(referralCode) => `استخدم رمز الإحالة ${referralCode} واحصل على 7 أيام من التلفزيون المجاني! `,
        share:'احصل على تلفزيون مجاني',
        referral_code: 'رمز الإحالة',
        referral_title:'!تلفزيون مجاني',
        referral_description:`شارك iShow  واحصل مع الأصدقاء على باقة مجانية في كل مرة يسجلفيها صديق جديد باستخدام الرمز الخاص بك.`,
    }
} else if (currentOperation === operations.mtnSyria) {
    operationLang = {
        shareMessage:(serviceName) => `MTN TV على ${serviceName}  شاهد`,
        copyright1: '© 2016 ',
        copyright2:'Mazaji ',
        renewed_note:'ملاحظة: سيتم تجديد الاشتراك تلقائيا.',
        copyright3:'جميع الحقوق محفوظة',
        appTitle: 'MTN TV',
        enjoyableEntertainment: 'MTN TV',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
        autoRenewMsg:'في حال عدم وجود رصيد كافي للاشتراك بالمحتوى المطلوب سيتم التجديد بالفئة الأقل',
        Message:'مبروك لقد تم تفعيل البرنامج وقد حصلت على اشتراك لمدة 7 أيام بالباقة الأساسية التي تضم القنوات التالية " سما، لنا، دريم، دريم2، mbc2 " يمكنك مشاهدة جميع القنوات بشكل مجاني دون استهلاك اية بيانات سوف يتجدد اشتراكك بالباقة الأساسية بقيمة 50 ليرة سورية باليوم. لإلغاء الاشتراك اذهب الى قائمة المشتريات في البرنامج وقم بالإلغاء',
        Title:'مرحبًا ، أنا أيفي. مساعدك الشخصي.',
        Object:'13',
        Image:'https://cam.mtnsyr.com/media/Ivy/ivy_welcome.png',

        referral_code_checkbox:'لدي رمز الإحالة',
        enter_referral_code:'أدخل رمز الإحالة',
        Or:' أو ',
        shareReferral1:(referralCode) => ` سجّل اشتراكك الآن مجاناً في MTNTV ودون أي استهلاك من باقة الإنترنت وذلك باستخدام رمز الإحالة التالي: ${referralCode}`,
        shareReferral2:'واستمتع بالباقة الأساسية مجاناً لمدة أسبوع لأوّل مرّة، وشاهد أيضاً جميع المباريات، أفلامك ومسلسلاتك المفضلة والكثير من المحطات العالمية والعربية: ',
        //shareReferral1:' استمتع مع تطبيق MTNTV بأكثر من 40 قناة تلفزيونية على هاتفك الذكي دون استخدام حزمة البيانات الخاصة بك: ',
        //shareReferral2:(referralCode) => `استخدم رمز الإحالة ${referralCode} واحصل على 7 أيام من التلفزيون المجاني! `,
        share:'احصل على تلفزيون مجاني',
        referral_code: 'رمز الإحالة',
        referral_title:'!تلفزيون مجاني',
        referral_description:`شارك الرمز الخاص بك في MTNTV مع أصدقائك لتحصل على الباقة الذهبية مجاناً والتي تتضمن جميع محتويات التطبيق ولمدة 7 أيام، وذلك في كل مرّة يسجل فيها صديق جديد لك في التطبيق`,

        no_balance:'يرجى التأكد من وجود رصيد كاف لإتمام عملية الشراء. اذا كان لديك رصيد كاف و لا تستطيع الاشتراك يرجى الاتصال بخدمة الزبائن',
    }
} else if (currentOperation === operations.mtnClipSyria) {
    operationLang = {
        appTitle: 'MTN Clip',
        enjoyableEntertainment: 'MTN Clip',
        loginTextFirst: ' ',
        loginTextSecond: ' ',

    }
} else if (currentOperation === operations.nexttel || currentOperation === operations.nexttelLocal || currentOperation === operations.yabadoo || currentOperation === operations.orange) {
    operationLang = {}
} else if (currentOperation === operations.mtnCameroon) {
    operationLang = {}
} else if (currentOperation === operations.alMadar) {
    operationLang = {
        appTitle: 'Al Madar',
        enjoyableEntertainment: 'Al Madar',
        loginTextFirst: ' ',
        loginTextSecond: ' ',
    }
}


export default {...ar, ...operationLang}
