import {Modal} from "antd";
import {changeViewerFollowStatus, viewersListModel} from "../../redux/actions/liveStreamActions";
import React from "react";
import store from "../../redux/store";
import {localize} from "../../helpers/functions";
import {localStorageKeys} from "../../helpers/enums";


const ViewersList = props => {
    const viewersList = store.getState().liveStream.viewerList
    const isTest = localStorage.getItem(localStorageKeys.isTest)

    return (
        <Modal
            className={`viewers-list-model`}
            visible={true}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => viewersListModel(false)}
            centered
        >
            <h5>Viewers</h5>
            {!!viewersList && viewersList.length > 0 ? viewersList.map((item,index) => {
                return (
                    <div className='viewers-list-item'>
                        <div className='user-info'>
                            <img src={item.logo}/>
                            <div className='user-info-items'>
                                <p>{item.name}</p>
                                <p>{item.description}</p>
                                <p>{isTest !== '0' ? item.username : ''}</p>
                                <p>{item.followers} {localize('followers')}</p>
                            </div>
                        </div>
                        <img onClick={() => changeViewerFollowStatus(item.username,item.is_follow.msg,index)} className='follow-icon' src={item.is_follow.msg === 0 ? require('../../assets/img/icons/follow_yellow.png') : require('../../assets/img/icons/following_yellow.png')}/>
                    </div>
                )
            }) : null}
        </Modal>
    )

}

export default ViewersList
