import store from "../store";
import {MESSAGES_ADD_MESSAGE, MESSAGES_SET_MESSAGES, MESSAGES_UNSET_MESSAGES} from "./types";

const dispatch = store.dispatch

export const setMessages = messages  => {
    dispatch({
        type: MESSAGES_SET_MESSAGES,
        payload: messages
    })

}

export const unsetMessages = ()  => {
    dispatch({
        type: MESSAGES_UNSET_MESSAGES,
    })

}

export const addMessage = message  => {
    dispatch({
        type: MESSAGES_ADD_MESSAGE,
        payload: message
    })

}
