import {FAVORITES_HAS_MORE_FALSE, FAVORITES_LOAD_MORE, FAVORITES_REMOVE, FAVORITES_SET_FETCH, FAVORITES_SET_RESPONSE, FAVORITES_UNSET_RESPONSE} from "../actions/types";

const initialState = {
    items: [],
    hasMore: true,
    index: 0,
    fetch:false
}

const favoriteReducer = (state = initialState, action) =>{

    switch (action.type) {
        case FAVORITES_UNSET_RESPONSE:
            return {
                ...initialState
            }
        case FAVORITES_SET_RESPONSE:
            return {
                ...state,
                items : action.payload,
                //hasMore: action.payload.length >= loadMorePageSize
            }
        case FAVORITES_HAS_MORE_FALSE:
            return {
                ...state,
                hasMore: false
            }
        case FAVORITES_LOAD_MORE:
            return {
                ...state,
                items : [...state.items, ...action.payload.items],
                //hasMore: action.payload.items.length >= loadMorePageSize,
                index: action.payload.index
            }
        case FAVORITES_REMOVE:
            // const index = state.items.findIndex(item=> item.id === action.payload)
            const index = action.payload
            return {
                ...state,
                items:[
                    ...state.items.slice(0, index),
                    ...state.items.slice(index + 1),
                ]
            }
        case FAVORITES_SET_FETCH:
            return {
                ...state,
                fetch: action.payload,
            }
        default:
            return state

    }

}

export default favoriteReducer
