import {
    ALL_SERVICES_SET_RESPONSE,
    CHANGE_REFERRAL_STATE,
    HANDLE_ERROR,
    UNSET_HANDLE_ERROR_VISIBLE
} from "../actions/types";

const initialState = {
    items: [],
    errorVisible:false,
    errorTitle:'',
    errorDescription:'',
    referralPopUp:false
}

const servicesReducer = (state = initialState, action) =>{
    switch (action.type) {
        case ALL_SERVICES_SET_RESPONSE:
            return {
                ...state,
                items : action.payload.services
            }
        case UNSET_HANDLE_ERROR_VISIBLE:
            return {
                ...state,
                errorVisible: false,
            }
        case HANDLE_ERROR:
            return {
                ...state,
                errorVisible: true,
                errorTitle: !!action.payload.errorTitle ? action.payload.errorTitle : null,
                errorDescription: action.payload.errorDescription
            }
        case CHANGE_REFERRAL_STATE:
            return {
                ...state,
                referralPopUp:action.payload
            }
        default:
            return state

    }

}

export default servicesReducer