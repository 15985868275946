import {currentOperation} from "../../helpers/constants";
import {localStorageKeys, operations} from "../../helpers/enums";

const pr = {
    shareMessage:(serviceName) => `Assista ${serviceName} on yabadoo `,
    shareTitle:'',
    movie: 'Filme',
    series: 'Séries',
    clips: 'Clipes',
    clip: 'Clipe',
    plays: 'Jogos',
    play: 'Jogo',
    home: 'Menu Principal',
    myContent: 'Meu conteúdo',
    theContent: 'O Conteúdo',
    favorites: 'Favoritos',
    history: 'Histórico',
    feedback: 'Comentários',
    support: 'Assistencia',
    settings: 'Configurações',
    go: 'Ir',
    bundles: 'PACOTES',
    channels: 'CANAIS',
    search: 'pesquisa',
    searchMsg: `Você escolhe ... Eu encontro`,
    movies: 'Filmes',
    continueWatching: 'Continua Assistindo',
    appTitle: 'Yabadoo',
    Or:'OU',
    enjoyableEntertainment: 'Entretenimento Agradável',
    loginTextFirst: 'Entretenimento',
    loginTextSecond: 'Agradável',

    autoRenew: 'Renovação Automatica',
    autoRenewed: 'Auto Renewed for cancellation go to My content',
    autoRenewed2:'Renovado Automaticamente',
    notAutoRenewed2:'Não Renovado Automaticamente',
    autoRenewMsg: 'As renovações automáticas acontecem às 9h, 12h e 18h.',
    feedbackMsg: 'O que acha do aplicativo?',
    feedbackInput: 'Dê-nos a sua opinião, entraremos em contacto consigo imediatamente.',
    feedbackSuccessMsg: 'Obrigado,os seus cometários são importante para nós.',
    feedbackFillMsg: 'Preencha o formulário de comentários antes de enviar.',
    submit: 'Confirmar',
    readMore: 'Leia mais',
    readLess: 'Leia menos',
    notifications: 'Notificações',
    devices: 'Dispositivos',
    acceptNotifications: 'concordo em aceitar notificações push',
    acceptNotificationsMsg: 'As notificações incluem os últimos programas de TV, filmes, sugestões personalizadas e vários outros avisos.',
    selectLanguage: 'Seleciona a Lingua',
    pressToDeactivate: 'Pressiona para desactivar',
    linkDevice: 'Conecta um dispositivo',
    linkDeviceMsg1: 'Se deseja ligar outro dispositivo à conta actual, insira o seguinte código:',
    code: 'Código:',
    linkDeviceMsg2: 'Se deseja ligar este dispositivo à outra conta, insira o código dessa conta:',
    ok: 'OK',
    myDevices: 'Porfavor,selecione no dispositivo que deseja desativar.',
    userProfile: 'Perfil do usuário',
    paymentMethod: 'Forma de pagamento',
    deactivate: 'Desactivar',
    deviceId: 'Código do dispositivo:',
    deviceType: 'Tipo de dispositivo:',
    deviceOS: 'Sistema operativo do dispositivo:',
    deviceOSVersion: 'Versão do sistema operativo do dispositivo:',
    Version:'Versão',
    deviceAppVersion: 'Versão do aplicativo do dispositivo:',
    phoneNumber: 'Número de telefone:',
    verificationCode: 'Código de activação:',
    verificationCodeWrong:'Não recebeu a SMS? Tenta novamente , se persistir liga para a linha do cliente',
    verificationCodeWrongTitle:'Ops, o código inserido é invalido.',
    sendCode: 'Send Code',
    register: 'Registar',
    reset: 'Redefinir',
    fromPhoneCredit: 'Via conta principal',
    loadMore: 'Load more',
    youMayAlsoLike: 'Também pode gostar : ',
    movieS: 'Fime(s)',
    youGet: 'Você obtém',
    theyGet: 'Eles obtém',
    dayS: 'dia(s)',
    buy: 'Comprar',
    gift: 'Presente',
    watch: 'Assista',
    validFor: 'Válido por',
    valid:'Validade',
    willDeducted: 'Será deduzido do seu pacote',
    subscription: 'Subscrição',
    packages: 'Pacotes',
    watchFromYourPackages: 'Assistir do pacote',
    ppv: 'Aluguer',
    fullView: 'Acesso único',
    hourS: 'Hora(s)',
    minuteS: 'Minuto(s)',
    secondS:'Segundo(s)',
    playS: 'Jogo(s)',
    clipS: 'Clipe(s)',
    plan: 'Plano',
    orderId: 'Código do pedido:',
    paidOn: 'Pago em',
    videos: 'Videos',
    vodPackages: 'VOD packages',
    expiresWithin: 'Expira em',
    expired:'Expirou em',
    remainingDuration: 'Duração restante',
    currentDevice: 'Dispositivo actual',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    seasons: 'Seasons',
    episodes: 'Episódios',
    episode: 'Episódio',
    chooseSeason: 'Choose season to view details',
    live: 'Directo',
    typeMessage: 'Escrever mensagem...',
    permanentAccess: '(Acesso Permanente).',
    skip: 'Ignorar',
    no: 'Nenhum',
    yes: 'Sim',
    empty: 'Vazia',
    change: 'Mudança',
    delete: 'Excluir',

    //trans
    changeAvatarMsg: 'Escolha uma opção',
    updateAvailable: 'Nova actualização disponível. Clique para aproveitar os recursos e benefícios mais recentes',
    loading: 'Carregando...',
    watchConfirm: 'Tem certeza que deseja assistir?',
    deleteNotificationsConfirm: 'Tem certeza que deseja deletar as notificações?',
    logout: 'Sair',
    shareMsg: 'Com o Yabadoo, você pode desfrutar de mais de 40 canais de TV ao vivo em seu smartphone sem usar seus dados:',
    shareReferral2:(referralCode) => `Use o código de referência ${referralCode} e ganhe 30 dias de TV grátis!`,
    seeMore: 'Ver mais',
    seeLess: 'Veja menos',
    pleaseRegisterFirst: 'Por favor, registre-se primeiro',
    noData: 'Sem dados',
    unknownError: 'Verifique sua conectividade e tente novamente mais tarde',
    enterGiftNumber: 'Por favor, insira o número do presente',
    error404: 'Você parece perdido... tente pesquisar algo...',
    chooseValidFile: 'Escolha um arquivo válido...',
    playerAlreadyOpen: 'O jogador já está aberto',
    errorLoadingVideo: 'Erro ao carregar o vídeo',
    giftNumber: 'Número do presente...',
    vodCategories: 'Categorias VOD',
    playlist: 'Lista de reprodução',
    deactivateDevice: 'Desativar dispositivo',
    refreshTitle:'Obtenha a versão mais recente',
    refreshDesc:'Existe uma nova versão disponível. Atualize agora e divirta-se assistindo',
    copyright1: 'Copyright © 2020',
    copyright2:'Aplitv.',
    copyright3:'Todos os direitos reservados',
    notValidphoneNumber:'Por favor digita um número de telefone válido',
    wrong:'Algo deu errado, tente novamente mais tarde.',
    renewed_note:'Seu tempo de antena será deduzido automaticamente para renovar sua assinatura. Se você não deseja mais renovar, visite a página “Meus conteúdos” e desactive a renovação automática. Um desconto pode ser aplicado no momento da renovação.',
    notrenewed_note:'Observação: a subscrição não sera renovada automaticamente',
    no_balance:'Saldo suficiente.',
    nonetwork:'Verifique sua conectividade e tente novamente mais tarde',
    noContent:'Não havia mais conteúdo para exibir',
    setlanguageFailed:'Erro ao alterar o idioma',
    notValidphoneNumberHint:'Número de telefone inválido',
    uh_oh:'UH-OH',
    shareSuccess:'Compartilhamento de sucesso',
    deactiveDevice: 'Por favor insira o seu código',
    referral_description:'Compartilhe Yabadoo com amigos e tenha acesso gratuito à TV sempre que um amigo se registrar usando seu código',
    justTap:'Basta tocar ',
    thenAddToHomeScreen:` então 'Adicionar à tela inicial'`,
    addToHomeScreen:` para adicionar PWA à tela inicial`,
    notShowAgain:`Não mostrar de novo`,
    downloadAndroid:'Baixar aplicativo móvel',
    dontShowAgain:`Não mostrar isto de novo.`,
    haveSubscriptionTitle:'Oh, parece que você já tem uma assinatura ativa desse conteúdo',
    haveSubscriptionText:'Entre em contato com o atendimento ao cliente para reativar seu acesso.',
    logout_title:'Tem certeza que deseja sair ? ',
    logout_description:'Terminar a sessão não interrompe as subscrições activas, pode fazer gestão a partir do menu “Meus conteúdo”',



    refresh: 'Refrescar',
    SYP: 'SYP',
    CFA: 'FCFA',
    MT:'MT',
    USD: '$',
    purchaseConfirm: 'Confirmar',
    giftConfirm: (name) => `Por favor, confirma o envio  ${name} Presente para:`,
    name: 'Nome',
    price: 'Preço',
    validity: 'Validade:',
    waitSeconds: (seconds) => `Por favor aguarda ${seconds} segundos antes de enviar nova mensagem...`,
    subscribeToEnableChat: 'Subscreva para habilitar o chat',
    share: 'Compartilhar',
    SHARE: 'COMPARTILHAR',
    shareReferral1:'Com Yabadoo você pode desfrutar de TV e Rádio ao vivo sem usar seus dados! Clique no link para se cadastrar e ganhe 30 dias grátis! ',
    aboutUs: 'About Us',
    // errors
    noNotifications: 'Sem Notificações',
    noHistory: `Ainda não efectuao nenhuma compra`,
    noFavorites: `Sem conteúdos favoritos`,
    noMyContent: 'sem conteúdo comprado para exibir',
    unexpectedError: 'Oops ... Ocorreu um erro',
    sharingError:'',
    noItemsOnTheListTitle:'Aviso: Sem canais activos',
    noItemsOnTheList: 'Por favor subscreva qualquer canal ou liga para a linha cliente',
    giftSuccess: 'O seu presente foi entregue com sucesso.',
    giftSuccessTitle: 'Você acaba de fazer alguém muito feliz!',
    msgToAllowPurchese: 'To watch this clip, please send CLIP to 1055. Enjoy!',
    send:'ENVIAR',
    selectSeason:'Selecione...',
    searchKeyLength:'Insira pelo menos 3 caracteres',
    alsoAvailable:'Ou compre em pacotes',
    alsoAvailableGift:'Ou presente em pacotes',
    unlimited:'Ilimitado',
    retry:'Tentar novamente',
    exit:'Sair',
    selectCaption:'Seleciona a legenda',
    selectBitRate:'Seleciona o bit rate',
    chooseyourCountry:'Escolha o país',
    chooseyourOperator:'Escolha o operador',
    please_try_again_laterTitle:'Bem, isso é constrangedor;',
    please_try_again_later:'Parece que estamos tendo problemas para conectar você ao serviço mais incrível deste planeta! Por favor, descubra conosco e tente novamente em alguns minutos, se ainda não funcionar, entre em contacto com o suporte para resolver o seu problema.',
    feedback_title:'Entendi!',
    login:'REGISTAR',
    journey_text:' ',
    like_it:'Gosto',
    love_it:'Excelente!',
    not_realy:'Mau',
    unauthorized_access:'Acesso não autorizado',
    no_balanceTitle:'Falhou',
    owened_content_subtitle:'Actualize ou reinicie o aplicativo e tente novamente; se o problema persistir, contacte a linha do cliente.',
    owened_content_subtitleTitle:'Já tem acesso a este conteúdo!',
    not_operator_number:'O destinatário não é subscritor TMCEL.',
    not_operator_numberTitle:'Ops, não foi possível enviar o seu presente',
    nonetworkTitle:'Falha',
    autorenewFailed:'Não foi possível gravar as alterações',
    autorenewFailedDes:'Provavel problema de conectividade, tenta novamente, se continuar, liga a linha do cliente.',
    notValidphoneNumberTitle:'Oops, não foi possível processar seu número de telefone',
    confirmation_code_text :'Código de confirmação enviado por SMS.',
    //new
    deviceExceed:'Atingiu o limite máximo de dispositivos registados. Desactive um dos dispositivos para continuar.',
    gift_already_owenedTitle:'Ops, não foi possível enviar o seu presente',
    gift_already_owened:'O destinatário já tem este conteúdo; Seleciona outro presente',
    //streamlimit
    reachLimit:'Atingiu o limite de transmissão, por favor, adquira mais tempo para continuar aproveitando o Yabadoo',
    reachLimit0:'Atingiu o limite diário para este canal. por favor, tenta novamente amanhã',
    reachLimit01:'Atingiu o limite diário para este canal. por favor, tenta novamente amanhã',
    deactivateSuccessTitle: 'Sucesso',
    deactivateSuccess: 'Desconectado com sucesso deste dispositivo.',
    //new user journey
    Message:'Welcome to Yabadoo, the home of the best local and regional video and TV content. Congrats! Because you’ve successfully activated your account, you have won a 7 days subscription to enjoy streaming STV, Canal2 Movies and CRTV without using your internet MB!',
    Title:'Hi, I`m Ivy. Your personal assistant',
    Object:'33',
    watchFromBundle:'Para assistir este conteúdo, você deve subscrever num dos pacotes abaixo.',
    giftFromBundle:'Dos pacotes abaixo, escolha um que deseja oferecer.',
    next:'Próxima',
    referral_code_checkbox:'Eu tenho um código de referência',
    enter_referral_code:'Insira o código de referência',
    referral_code:'Código de Referência',
    referral_title:'Obtenha acesso gratuito à TV!',
    menuTitle:'Yabadoo',
    install:'Instalar',
    operatorName:'Rede',
    startingIn:(seconds) => `Começando em ${seconds} ...`,
    nickname:'Digite seu nome',
    description:'Quem é Você?',
    save:'Gravar',
    proposals:'Propostas para você',
    proposalFillHint:`Preencha clicando em 'adicionar aos favoritos' na página de detalhes`,
    noSearchTitle:'Nenhum resultado encontrado',
    noSearchHint:'Tenta novamente usando palavras diferentes',
    Profile:'Perfil',
    startWatching:'Comece a assistir',
    radio:'Rádio',
    radioHint:'Incrível, agora você está no modo de Rádio! Para voltar ao Modo TV (Vídeo), pressione o ícone da TV no canto superior direito.',
    loyalityStreakTitle:'Presente de fidelidade',
    loyalityStreakDesc:`Faça login no Yabadoo 6 dias seguidos e ganhe um dia de acesso gratuito ao nosso maior buquê para apreciar a sua fidelidade. Nós te amamos`,
    loyalityStreak0:'Novo começo',
    loyalityStreak1:'Pode vir',
    loyalityStreak2:`Uau, você está próximo`,
    loyalityStreak3:`Vai Campeão. Você está quase lá`,
    loyalityStreak4:'Você está muito perto',
    loyalityStreak5:'atingiu 5 dias!!! faça login amanhã para receber o seu presente',
    payMobile:'Paga via Mobile Money',
    payAirtime:'Paga via conta principal',
    pendingMobileMoney:' Foi iniciada uma transacção móvel. Por favor validar através da sua aplicação mKesh ou digita *123#.',
    failureMobileMoney:'Sua transação de mKesh não foi bem-sucedida. Por favor, tenta novamente',
    alreadyPendingMobileMoney:'Tem uma transação mKesh pendente para este pacote. Por favor, valida para aceder a este conteúdo no Yabadoo.',
    pendingAirtimeTransaction:`Uma Transação foi iniciada. Por favor, seja paciente para uma confirmação para assistir os seus conteudos favoritos.`,
    failureAirtimeTransaction:`Sua transação não foi bem-sucedida, tenta novamente.`,
    alreadyPendingAirtimeTransaction:'Você tem uma transação pendente para este pacote. Por favor, seja paciente para confirmação.',
    historyAirtimeName:'Tempo de antena',
    historyMobileMoneyName:'mKesh',
    from:'Apartir de:',
    timeOutTitle:'Ops, A sua sessão expirou.',
    timeOutDesc:`Vamos actualizar a sua sessão, pressione OK para continuar.`,
    codeAttemptsTitle:`Muitas tentativas de registro`,
    codeAttemptsDes:'Sua conta foi bloqueada temporariamente. Tente novamente após 10 minutos.',
    Question:'Pergunta',


    liveStream:'Live Stream',
    followers:'Seguidores',
    report:'Relatório',
    reportTitle:'Por que você está denunciando esta sessão?',
    reportDesc:`Sua denúncia é anônima.  Se alguém estiver em perigo imediato, ligue para os serviços de emergência locais - não espere`,
    reportSuccessTitle:'Sessão de relatório',
    reportSuccessDesc:'O seu relatório já foi recebido, obrigado por nos ajudar a melhorar.',
    reportSuccessDesc2:'O seu relatório já foi recebido, obrigado por nos ajudar a melhorar.',
    shareLive:(username) => `Assista ${username} comigo sem usar seus dados `,
    sessionEndTitle:'Esta sessão não está mais ao vivo.',
    follower:'Seguidores',
    following:'A seguir',

    eventEndTitle:'Transmissão encerrada',
    eventEndDesc:`Esta sessão não está mais ao vivo`,
    symbol:'mz',
}


let operationLang = {}

if (currentOperation === operations.tmcel || currentOperation === operations.yabadoo || currentOperation === operations.vodacom){
    operationLang = {
        symbol:'mz',
        no_balanceTitle:`Não tem saldo suficiente`,
        no_balance:'Não tem saldo suficiente . Por favor recarrega',
        nonetworkTitle:`Problemas de  ligação, verifica se está a usar a rede (3G/4G) da ${localStorage.getItem(localStorageKeys.operatorName)} para poder assistir.`,
        nonetwork:`Mais Informação: Activa os dados moveis no seu dispositivo tendo em conta que Yabadoo não consume os seus dados. Ou usa uma rede sem fio (WIFI) que seja (3G/4.5G) da ${localStorage.getItem(localStorageKeys.operatorName)}. Verifica se tem bom sinal de rede, se não altera a sua localização e tenta novamente. Se o problema persistir liga para a linha do cliente.`,
        not_operator_number:`O destinatário não é subscritor ${localStorage.getItem(localStorageKeys.operatorName)}.`,
        //trans
        notValidphoneNumber:`O número de telefone não parece ser um ${localStorage.getItem(localStorageKeys.operatorName)} válido número. Verifique e tente novamente; se ainda não funcionar, entre em contato com o suporte para resolver seu problema `,
        journey_text:'*Ao ativar a sua conta, você terá direito a uma subscrição gratuita de 30 dias do pacote “ Pacote De Prata ”, após os quais será cobrado 8MT/dia. Para cancelar a suscrição, visita o menu “Meus conteúdos". ',
        register:'Bem-vindo a Yabadoo',

        Message:'Welcome to Yabadoo, it’s the right time all the time! Your account has been successfully activated and YOU HAVE WON A 30 DAYS SUBSCRIPTION to the bundle “Green bundle” to enjoy EQUINOXE, STV and CANAL2 MOVIES. Without using your data bundle!',
        Title:'Hi, I`m Ivy. Your personal assistant',
        Object:'15',
        Image:'https://mtntv.mtncameroon.net/media/Ivy/ivy_welcome.png',
        // shareReferral1:'Com  Yabadoo, você pode desfrutar de mais de 81+ canais de TV em directo no seu smartphone sem usar seus dados ',
        shareReferral2:(referralCode) => `Usa o código de referência ${referralCode} e ganhe 30 dias de TV grátis!`,
        share:'Obtenha TV grátis',
    }
}else if (currentOperation === operations.mtnCameroon || currentOperation === operations.orange){
    operationLang = {
        symbol:'cm',
    }
}


export default {...pr, ...operationLang}
