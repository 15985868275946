import React from 'react'
import Menu from "../Menu";
import Footer from "../Footer";
import {addRTL} from "../../helpers/functions";
import {useSelector} from "react-redux";

const Layout = props => {
    const {rtl, network} = useSelector(state => state.settings)
    const {authorized} = useSelector(state => state.auth)
    if (!authorized) return props.children
    return (
        <>
            <Menu rtl={rtl} route={props.route}/>
            <div className={`site-content ${addRTL(rtl)} ${network === 'offline' ? 'offline' : ''}`}>
                {props.children}
            </div>
            <Footer rtl={rtl}/>
        </>
    )
}

export default Layout
