import {
    ALL_SERVICES_SET_RESPONSE,
    CLIPS_MORE_CLIPS,
    HAS_MORE_FALSE_CLIPS,
    SELECT_CLIPS,
    CLIPS_SETUNLOCK, CLIPS_SET_FETCHING
} from "../actions/types";

const initialState = {
    items: [],
    item: {},
    hasMore: true,
    index: 0,
    fetching: false,
    fetchingMovies:false
}

const clipsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_SERVICES_SET_RESPONSE:
            return {
                ...state,
                    items: action.payload.clips,
                    //hasMore: action.payload.clips.length >= loadMorePageSize
                fetchingMovies: true
            }
        case CLIPS_MORE_CLIPS:
            return {
                ...state,
                items: [...state.items, ...action.payload.items],
                hasMore: true,
                index: action.payload.index,
                fetching: false,
            }
        case HAS_MORE_FALSE_CLIPS:
            return {
                ...state,
                hasMore: false,
                fetching: false,
            }
        case SELECT_CLIPS:
            return {
                ...state,
                item: action.payload
            }
        case CLIPS_SETUNLOCK:
            return {
                ...state,
                    items: action.payload
            }
        case CLIPS_SET_FETCHING:
            return {
                ...state,
                fetching: true,
            }
        default:
            return state
    }
}
export default clipsReducer
