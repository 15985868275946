import React from 'react'
import {wideLogo} from "../../assets/img/exports";
import {localize} from "../../helpers/functions";

const GeneralError = props => {
    return(
        <div className='general-error-container'>
            <img src={wideLogo} alt="" width={400}/>
            <h2 className='text-center text-light mt-4'>{localize('unexpectedError')}</h2>
            <button className="btn btn-dark bordered text-center mt-4" onClick={()=>window.location.reload()}>{localize('refresh')}</button>
        </div>
    )
}

export default GeneralError