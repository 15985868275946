import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
// import 'es5-shim'
import {
    configureURLWindowParams,
    deleteAllCookies,
    initializeFirebase,
    jsonToFormData,
    one10LabsCall,
    renderRefreshUI,
    resetPlayerVisibility,
    resetPlayerVisibilty,
    secureData,
    setFirstTimeViewed
} from "./helpers/functions";
import * as serviceWorker from "./serviceWorker";
import initWorkboxRefresh from "@loopmode/cra-workbox-refresh";
import {countryOperator, localStorageKeys, operations} from "./helpers/enums";
import ReactGA from "react-ga";
import {AUTH_SET_TOKEN, PLAYER_SET_VISIBLE, SET_FETCHING, SET_IOS_POPUP, SET_PHONE_NUMBER} from "./redux/actions/types";
import {navigate} from "@reach/router";
import {Routes} from "./routes/AppRouter";
import {appLink, currentOperation, iOS, iOSSafari, isActive, isChrome, isheaderEnrichment, pwaLink} from "./helpers/constants";
import store from "./redux/store";
import axios from "axios";
import {getSetUserSettings, host, saveVideoBookmark} from "./redux/api/endPoints";
import {bootstrapCodeActivation, setHeaderEnrichmentData} from "./redux/actions/authActions";
import {setHeaderFetching} from "./redux/actions/settingsActions";
// ReactGA.initialize('G-JZJ4S2E9DV');


ReactDOM.render(<App/>, document.getElementById('root'));
serviceWorker.register({onUpdate: registration => initWorkboxRefresh(registration, {render: renderRefreshUI})});
document.oncontextmenu = document.body.oncontextmenu = function() {return true;}
initializeFirebase()
deleteAllCookies()
configureURLWindowParams()

localStorage.removeItem(localStorageKeys.headerSet)
localStorage.removeItem(localStorageKeys.currentOperation)



