import store from "../store";
import {handleHttpError, jsonToFormData, localize, setHintDialog} from "../../helpers/functions";
import axios from "axios";
import {getFollowStatus, setFollowUnFollow, host, getViewersNumber, getSessionReportOptions, reportLiveSession, getContentLink} from "../api/endPoints";
import {
    SET_FOLLOW,
    SET_REPORT_MODEL,
    SET_SESSION_REPORT_OPTIONS,
    SET_REPORT_MODEL_INSERT,
    SET_VIEWERS_NUMBER,
    SET_SESSION_END_USERINFO,
    SET_SESSION_END_VIEWMODEL,
    SET_VIEWERS_LIST,
    SET_VIEWERS_LIST_STATUS, CHANGE_FOLLOW_STATE_VIEWERS_LIST, SET_LIVE_LOADING_FETCH
} from "./types";
import {pwaLink} from "../../helpers/constants";
import {localStorageKeys} from "../../helpers/enums";
import {getUserInfo} from "./settingsActions";
import {openLiveSetLink} from "./playerActions";

const dispatch = store.dispatch

export const checkFollowStatus = (username) => {
    followStatus(username).then(res => {
        if(res.status === 200){
            dispatch({
                type:SET_FOLLOW,
                payload:res.data.msg
            })
        }
    }).catch(error => {
        console.log(error)
    })
}

export const checkViewersNumber = (username, list = false) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        service_id:username
    }
    if(list) req['detailed_response'] = true
    const params = jsonToFormData(req)
    if(list) liveStreamPlayerFetch(true)
    axios.post(host + getViewersNumber, params).then(res => {
        liveStreamPlayerFetch(false)
        if(res.status === 200){
            if(list){
                if(res.data.msg.length > 0){
                    dispatch({
                        type:SET_VIEWERS_LIST,
                        payload:res.data.msg
                    })
                }
            }else{
                dispatch({
                    type:SET_VIEWERS_NUMBER,
                    payload:res.data.msg
                })
            }
        }
    }).catch(error => {
        liveStreamPlayerFetch(false)
        // console.log(error)
    })
}


export const setFollowUnfollowStatus = (username,followingStatus = 0) => {
    pressFollowUnFollow(username,followingStatus).then(res => {
       if(res.status === 200) {
           dispatch({
               type:SET_FOLLOW,
               payload:followingStatus === 0 ? 1 : 0
           })
       }
    }).catch(error => {
        console.log(error)
    })
}

export const getSessionReportItems = () => {
    const auth = store.getState().auth.authToken
    const req = {
        auth
    }
    const params = jsonToFormData(req)
    axios.post(host + getSessionReportOptions, params).then(res => {
        if(res.status === 200) {
            dispatch({
                type:SET_SESSION_REPORT_OPTIONS,
                payload:res.data.options
            })
        }
    }).catch(error => {
        console.log(error)
    })
}

export const setReportModel = (mainModel) => {
    if(mainModel.from === 'MainModel'){
        dispatch({
            type:SET_REPORT_MODEL,
            payload:mainModel.status
        })
    }else{
        if(mainModel.status){// to set main to false, and show the insert model
            dispatch({
                type:SET_REPORT_MODEL,
                payload:false
            })
        }
        dispatch({
            type:SET_REPORT_MODEL_INSERT,
            payload:mainModel.status
        })
    }
}

export const reportSession = (reason,sessionItem) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        report_reason:reason,
        go_live_session:sessionItem
    }
    const params = jsonToFormData(req)
    dispatch({
        type:SET_REPORT_MODEL,
        payload:false
    })
    dispatch({
        type:SET_REPORT_MODEL_INSERT,
        payload:false
    })
    axios.post(host + reportLiveSession, params ).then(res => {
        if(res.status === 200){
            setHintDialog('reportSuccessDesc',localize('reportSuccessTitle'))
        }
    }).catch(error => {
        handleHttpError(error,'reportLiveSession')//406
    })
}


export const shareLiveStream = (nickname,username) => {
    if(navigator.share){
        navigator.share({
            title: '',
            text: localize('shareLive')(nickname),
            url: `https://play.yabadoo.tv/deeplink/?Type=Event&Object=${username}`,
        })
            //.then(() => setError('shareSuccess'))
            .catch((error) => console.log('Error sharing', error));
    }
}


export const showDialogSessionEnd = (username) => {
    //check follow status
    checkFollowStatus(username)
    getUserInfo(username).then(res => {
        if(res.status === 200) {
            dispatch({
                type:SET_SESSION_END_USERINFO,
                payload:{username:username,nickname:res.data.nickname}
            })
            sessionEndViewModel(true)
            localStorage.removeItem(localStorageKeys.liveStreamUsername)
            localStorage.removeItem(localStorageKeys.liveSessionId)
        }
    })
}

export const sessionEndViewModel = (status) => {
    dispatch({
        type:SET_SESSION_END_VIEWMODEL,
        payload:status
    })
}

export const viewersListModel = (status) => {
    dispatch({
        type:SET_VIEWERS_LIST_STATUS,
        payload:status
    })
}

export const changeViewerFollowStatus = (username,followStatus,index) => {
   pressFollowUnFollow(username,followStatus).then(res => {
        if(res.status === 200){
            dispatch({
                type:CHANGE_FOLLOW_STATE_VIEWERS_LIST,
                payload:{index:index,msg:followStatus === 0 ? 1 : 0}
            })
        }
    }).catch(error => {
        console.log(error)
    })
}

export const pressFollowUnFollow = (username,followStatus) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        service_id:username,
        is_follow:followStatus === 0 ? 1 : 0
    }
    const params = jsonToFormData(req)
    return axios.post(host + setFollowUnFollow, params)
}

export const followStatus = (username) => {
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        service_id:username
    }
    const params = jsonToFormData(req)
    return axios.post(host + getFollowStatus, params)
}

export const liveStreamPlayerFetch = (status) => {
    dispatch({
        type:SET_LIVE_LOADING_FETCH,
        payload:status
    })
}

export const getLiveSessionLink = (service_id,go_live_session) => {
    const auth = store.getState().auth.authToken
    let req = {
        auth,
        service_id,
        go_live_session
    }
    const params = jsonToFormData(req)
    return axios.post(host + getContentLink, params)
}

export const checkLiveSessionStatus = (liveSessionUsername, liveSessionId = undefined) => {
    const item = store.getState().liveStream.liveStreamList.find(item => item.id === liveSessionUsername && !!liveSessionId ? item.go_live_session === liveSessionId : null)
    if(!!item){
        const liveItem = {
            url:item.link,
            live_userName:item.name,
            username:item.id,
            isLiveStream:true,
            sessionId:item.go_live_session
        }
        openLiveSetLink(liveItem,true)
        localStorage.removeItem(localStorageKeys.liveStreamUsername)
        localStorage.removeItem(localStorageKeys.liveSessionId)
    }else{
        showDialogSessionEnd(liveSessionUsername)
    }
}
