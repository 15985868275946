import {combineReducers} from "redux";
import settingsReducer from "./settingsReducer";
import authReducer from "./authReducer";
import menuReducer from "./menuReducer";
import packagesReducer from "./packagesReducer";
import playlistReducer from "./playlistReducer";
import bundlesReducer from "./bundlesReducer";
import favoriteReducer from "./favoriteReducer";
import historyReducer from "./historyReducer";
import searchReducer from "./searchReducer";
import notificationReducer from "./notificationReducer";
import devicesReducer from "./devicesReducer";
import profileReducer from "./profileReducer";
import chatReducer from "./chatReducer";
import liveReducer from "./liveReducer";
import clipsReducer from "./clipsReducer";
import seriesReducer from "./seriesReducer";
import playsReducer from "./plays";
import movieReducer from "./movieReducer";
import playerReducer from "./playerReducer";
import servicesReducer from "./servicesReducer";
import bookmarkReducer from "./bookmarkReducer";
import liveStreamReducer from "./liveStreamReducer";


export default combineReducers({
    // test: testReducer,
    settings: settingsReducer,
    player:playerReducer,
    auth: authReducer,
    menu: menuReducer,
    clips:clipsReducer,
    bookmark:bookmarkReducer,
    movies:movieReducer,
    series:seriesReducer,
    services:servicesReducer,
    plays:playsReducer,
    live:liveReducer,
    packages: packagesReducer,
    playlist: playlistReducer,
    //services:servicesReducer,
    bundles: bundlesReducer,
    favorites: favoriteReducer,
    history: historyReducer,
    search: searchReducer,
    notification: notificationReducer,
    devices: devicesReducer,
    profile: profileReducer,
    chat: chatReducer,
    liveStream: liveStreamReducer,
})
