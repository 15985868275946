import {
    BUNDLES_HAS_MORE_FALSE, BUNDLES_HAS_MORE_SERVICES_FALSE,
    BUNDLES_MORE, BUNDLES_MORE_SERVICES, BUNDLES_SELECT_BUNDLE,
    BUNDLES_SET_FETCHING,
    BUNDLES_SET_RESPONSE, BUNDLES_SET_UNLOCKED,
    BUNDLES_UNSET_FETCHING,
    SET_BUNDLE_UNLOCK,
    BUNDLES_UPDATE_ITEM_SERVICES
} from "../actions/types";

const initialState = {
    items: [],
    item: {services: [], hasMore: true},
    bundleServices:[],
    hasMore: true,
    fetching: false,
    index: 0,
    fetchingBundle:false
}

const bundlesReducer = (state = initialState, action) => {

    switch (action.type) {
        case BUNDLES_SET_FETCHING:
            return {
                ...state,
                fetching: true,
            }
        case BUNDLES_UNSET_FETCHING:
            return {
                ...state,
                fetching: false,
            }
        case BUNDLES_HAS_MORE_FALSE:
            return {
                ...state,
                hasMore: false,
                fetching: false,
            }
        case BUNDLES_SET_RESPONSE:
            return {
                ...state,
                items: action.payload.bundles,
                fetchingBundle:true,
                bundleServices: action.payload.bundleServices,
                //hasMore: action.payload.length >= loadMorePageSize
            }
        case BUNDLES_MORE:
            return {
                ...state,
                items: [...state.items, ...action.payload.bundles],
                //hasMore: action.payload.bundles.length >= loadMorePageSize,
                fetching:true,
                index: action.payload.index
            }
        case BUNDLES_SELECT_BUNDLE:
            return {
                ...state,
                item: action.payload,
            }
        case BUNDLES_MORE_SERVICES:
            return {
                ...state,
                item: {
                    ...state.item,
                    services: [...state.item.services, ...action.payload],
                    //hasMore: action.payload.length >= loadMorePageSize
                },
            }
        case BUNDLES_HAS_MORE_SERVICES_FALSE:
            return {
                ...state,
                item: {
                    ...state.item,
                    hasMore: false
                },
            }
        case BUNDLES_SET_UNLOCKED:
            return {
                ...state,
                item: action.payload
            }
        case SET_BUNDLE_UNLOCK:
            return {
                ...state,
                items:[
                    ...state.items.slice(0,action.payload),
                    {...state.items[action.payload],status: 'unlocked'},
                    ...state.items.slice(action.payload + 1),
                ],
                fetching: false
            }
        case BUNDLES_UPDATE_ITEM_SERVICES:
            return {
                ...state,
                item: {
                    ...state.item,
                    services: action.payload,
                },
            }
        default:
            return state

    }
}

export default bundlesReducer
