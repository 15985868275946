import React, {useEffect} from 'react'
import {Modal} from "antd";
import {addRTL, localize, unsetError} from "../../helpers/functions";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {localStorageKeys} from "../../helpers/enums";
import {hidePlayer} from "../../redux/actions/playerActions";

const ErrorModel = props => {
    const {errorVisible,errorTitle,errorDescription} = useSelector(state => state.services)
    const {rtl} = store.getState().settings


    useEffect(() => {
        if(errorTitle === localize('timeOutTitle')){
            // localStorage.removeItem(localStorageKeys.headerSet)
            // localStorage.removeItem(localStorageKeys.currentOperation)
            localStorage.removeItem(localStorageKeys.countryName)
            localStorage.removeItem(localStorageKeys.operatorName)
            sessionStorage.removeItem(localStorageKeys.number)
        }
    },[])

    const retryPage = () => {
        unsetError()
        window.location.reload()
    }

    const closePlayer = () => {
        unsetError()
        hidePlayer()
    }

    return (
        <Modal
            className={`error-modal ${addRTL(rtl)}`}
            visible={!!errorVisible && errorVisible ? true : false}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => errorTitle === localize('timeOutTitle') ? retryPage() : errorTitle === localize('eventEndTitle') ? closePlayer() :  unsetError()}
            centered
        >
            {!!errorTitle ? <p className='error-modal-title'>{errorTitle}</p> : null}
            <p className='error-modal-message'>{errorDescription}</p>
            <div className='error-modal-buttons'>
                <button className='error-btn' onClick={() => errorTitle === localize('timeOutTitle') ? retryPage() : errorTitle === localize('eventEndTitle') ? closePlayer() : unsetError()}>{localize('ok')}</button>
            </div>

        </Modal>
    )
}


export default ErrorModel
