import {
    ALL_SERVICES_SET_RESPONSE,
    MORE_PLAYS,
    HAS_MORE_FALSE_PLAYS,
    SELECT_PLAY,
    PLAYS_SET_FETCHING,
} from "../actions/types";


const initialState = {
    items: [],
    item: {},
    hasMore: true,
    index: 0,
    fetching: false,
    fetchingPlays:false
}
const playsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_SERVICES_SET_RESPONSE:
            return {
                ...state,
                items: action.payload.plays,
                fetchingPlays:true
                //hasMore: action.payload.live.length >= loadMorePageSize
            }
        case MORE_PLAYS:
            return {
                ...state,
                items: [...state.items, ...action.payload.items],
                hasMore: true,
                index: action.payload.index,
                fetching: false,
            }
        case HAS_MORE_FALSE_PLAYS:
            return {
                ...state,
                hasMore: false,
                fetching: false,
            }
        case SELECT_PLAY:
            return {
                ...state,
                item: action.payload
            }
        case PLAYS_SET_FETCHING:
            return {
                ...state,
                fetching: true,
            }
        default:
            return state

    }

}

export default playsReducer
