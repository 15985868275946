/*
* references
* http://plnkr.co/edit/EhQHDsYpDhrECmaaIlZO?p=preview
* http://strophe.im/strophejs/doc/1.3.0/files/strophe-js.html
* https://github.com/strophe/strophejs-plugin-muc/blob/muc/src/strophe.muc.coffee
*
* */

import React, {useEffect, useRef, useState} from 'react'
import {handleHttpError, localize} from "../../helpers/functions";
import {FiSend} from "react-icons/fi";
import ChatItem from "./ChatItem";
import {$pres, Strophe} from 'strophe.js'
import 'strophejs-plugin-muc'
import {avatarLink, chatConnectLink, chatRoomLink, chatStropheLink, useragent} from "../../helpers/constants";
import {useSelector} from "react-redux";
import {addMessage, unsetMessages} from "../../redux/actions/chatActions";
import useInterval from "react-use/lib/useInterval";
import {Modal} from 'antd';
import {localStorageKeys} from "../../helpers/enums";
import {getUserInfo, setUserProfile, showPurchaseModal} from "../../redux/actions/settingsActions";
import {navigate} from "@reach/router";
import {Routes} from "../../routes/AppRouter";
import store from "../../redux/store";
import {SETTINGS_SET_NICKNAME} from "../../redux/actions/types";
import {addPrediction, getPredictions, hidePlayer} from "../../redux/actions/playerActions";
import {loadingImages} from "../../assets/img/exports";
import {followStatus, liveStreamPlayerFetch, pressFollowUnFollow, setFollowUnfollowStatus} from "../../redux/actions/liveStreamActions";
import {FaTimes} from "react-icons/fa";

const Chat = props => {
    const avatarPlaceholder = require('../../assets/img/placeholder/avatar.png')
    const [chatConnection, setChatConnection] = useState(undefined)
    const [chatRoom, setChatRoom] = useState(undefined)
    const [message, setMessage] = useState('')
    const {predictions,fetchingPrediction} = useSelector(state => state.playlist)
    const [messageCounter, setMessageCounter] = useState(0)
    const [messageTimer, setMessageTimer] = useState(0)
    const [imgModal, setImgModal] = useState({visible: false, img: avatarPlaceholder, text: '',nickname:store.getState().settings.profileNickname,description:store.getState().settings.profileDescription,followers:'',following:''})
    const {messages} = useSelector(state => state.chat)
    const {displayPrediction,profileNickname,profileDescription,profileFollowersNumber} = useSelector(state => state.settings)
    const messageInputRef = useRef()
    const isTest = localStorage.getItem(localStorageKeys.isTest)
    const [msg,setMsg] = useState(true)
    const [active,setActive] = useState(false)
    const [followStat,setFollowStatus] = useState(undefined)
    const [currentTime,setCurrentTime] = useState(null)
    const [replyView,setReplyView] = useState(null)
    const [showPredictionAnsw,setShowPredictionAnsw] = useState(false)
    const {goLivePermission,fetchLoading} = useSelector(state => state.liveStream)
    // const deviceId = localStorage.getItem(localStorageKeys.uuid)
    // const connectLink = username => `${username}@${domain}/web/${deviceId}`
    // const roomLink = id => `${id}@conference.${domain}`
    // const avatarLink = username => `${scheme + domain}/media/profiles/${username}`
    const inputLocked = messageCounter >= 4 // the message input will lock after 4 seconds
    const now = new Date();
    const {serviceId} = props

    useEffect(() => {
        setShowPredictionAnsw(false)
        window.addEventListener('click', checkClickedTarget);
        setCurrentTime(now)

        unsetMessages()
        const {username} = props
        if (serviceId && username) {
            let currentRoom = undefined
            let connection = new Strophe.Connection(chatStropheLink);
            setChatConnection(connection)
            setReplyView(null)
            connection.connect(chatConnectLink(username), username, responseCode => {
                if (responseCode === Strophe.Status.CONNECTED) {
                    // Strophe.log = (level, msg) => console.log({level, msg}) // use it if debug needed
                    connection.sendPresence($pres())
                    connection.muc.init(connection);
                    connection.muc.join(chatRoomLink(serviceId), username, (msg, room) => {
                        setChatRoom(room)
                        currentRoom = room
                        // , (presence, room) => console.log({presence, room}) // i can put this as a callback to join function
                        // let texts = [...msg.parentElement.children].map(item => item.textContent)
                        // console.log({msg, texts})
                        // connection.muc.groupchat(room.name, 'here', ''); // to send message
                    }, error => {
                        // console.log(error)
                        // connection.muc.createInstantRoom('the room', console.log, console.log)
                    });

                    connection.addHandler(msg => {
                        let from = msg.getAttribute('from');
                        let type = msg.getAttribute('type');
                        let elems = msg.getElementsByTagName('body');
                        if (type === "groupchat" && elems.length > 0) {
                            var msg = elems[0];
                            var replyMsg = elems[1]
                            var textMessage = Strophe.getText(msg)
                            var replyTxt = Strophe.getText(replyMsg)
                            // try {
                            //     let x = JSON.parse(body.textContent);
                            //     textMessage = !!x.message ? x.message : textMessage
                            //     replyTxt = !!x.replyTxt ? x.replyTxt : null
                            // } catch (error) {
                            //     // console.log(Strophe.getText(body))
                            //     // console.log("Invalid JSON");
                            // }
                            // let nick = Strophe.getResourceFromJid(from);
                            // let receivedMessage = {
                            //     text: Strophe.getText(body),
                            //     avatar: avatarLink(nick),
                            //     mine:nick === store.getState().settings.username,
                            //     username: nick,
                            //     color: Math.floor(Math.random()*16777215).toString(16)
                            // }

                            let nick = Strophe.getResourceFromJid(from);
                            let receivedMessage = {
                                text: textMessage,
                                replyTxt:replyTxt,
                                avatar: avatarLink(nick),
                                mine:nick === store.getState().settings.username,
                                username: nick,
                                color: Math.floor(Math.random()*16777215).toString(16)
                            }

                            addMessage(receivedMessage)
                            const chatElement = document.querySelector('.chat-body')
                            chatElement.scrollTop = chatElement.scrollHeight
                        }
                        return true // we must return true to keep the handler alive.
                    }, null, 'message', null, null, null);
                }
            })

            return () => {
                if (currentRoom) connection.muc.leave(currentRoom.name, currentRoom.nick) // exit chat room
                connection.disconnect()
                window.removeEventListener('click',checkClickedTarget)
            }

        }
    }, [serviceId])


    useEffect(() => {

    },[active])

    useInterval(() => {
        if (messageTimer === 10) {
            setMessageCounter(0)
            setMessageTimer(0)
        } else {
            setMessageTimer(messageTimer => messageTimer + 1)
        }
        // below is to start the timer after the first message
    }, messageCounter < 1 ? null : 1000)

    const checkClickedTarget = (event) => {
        const targetClass = event.target.className
        const validTarget = ['question-title','question-answers','question-text','question-answers-button']
        if ((targetClass === 'player-prediction-question' || targetClass === 'player-prediction-question-text') && !showPredictionAnsw){
            setShowPredictionAnsw(true)
        }
        else if(validTarget.findIndex((item) => item === targetClass) === -1)
        {
            setShowPredictionAnsw(false)
        }
    }

    const sendMessage = () => {
        if (chatRoom) {
            let replyText = !!replyView ? replyView.text : null
            chatConnection.muc.groupchat(chatRoom.name, message, replyText) // to send message
            // chatConnection.muc.groupchat(chatRoom.name,message)
            setMessage('')
            setReplyView(null)

            // messageInputRef.current.focus() // not used

            setMessageCounter(messageCounter => messageCounter + 1)
            if (messageCounter >= 1 && messageTimer === 0) setMessageTimer(1)
        }
    }

    const _handleKeyDown = e => {
        if (e.key === 'Enter' && message.trim().length > 0) {
            sendMessage()
        }
    }

    const _handleSendClick = e => {
        if (message.trim().length > 0) {
            sendMessage()
        }
    }

    const chatOutFocus = () => {
        props.onChange(false)
        setActive(false)
    }

    const onAvatarClick = message => {
        if(message.username !== store.getState().settings.username){
            getUserInfo(message.username).then(res => {
                if (res.status === 200) {
                    setImgModal({visible: true, img: message.avatar, text: message.username,nickname: res.data.nickname,description: res.data.description,followers:res.data.followers})
                }
            })
                .catch(error => {
                    setImgModal({...imgModal, visible: true})
                    // setImgModal({visible: true, img: message.avatar, text: message.username,nickname:store.getState().settings.profileNickname,description:store.getState().settings.profileDescription})
                })
            followStatus(message.username).then(res => {
                if(res.status === 200){
                    setFollowStatus(res.data.msg)
                }
            }).catch(error => {
                setImgModal({...imgModal, visible: true})
            })
        }else{
            setImgModal({visible: true, img: message.avatar, text: message.username,nickname:profileNickname,description:profileDescription ,followers:profileFollowersNumber})
            setFollowStatus(undefined)
        }
    }

    const newMessage = e => {
        props.onChange(true)
        setMessage(e.target.value)
    }

    const showPredictionPopUp = () => {
        setShowPredictionAnsw(true)
        if(predictions.is_predicit === "1") {
            if(!!currentTime && ((now.getTime() - currentTime.getTime()) / 60000) >= 1){
                getPredictions(serviceId)
            }
            setCurrentTime(now)
        }
    }

    const addButton = (index) => {
        return (
            <button className='question-answers-button' style={predictions.is_predicit === '1' ? {cursor:'unset', backgroundSize: predictions.answers[index].total_answers + '%', backgroundImage: 'linear-gradient(to right, #dbb20c, #dbb20c)'} : null} onClick={() =>  predictions.is_predicit === "0" && !fetchingPrediction ? addPrediction(index,serviceId) : null}>{predictions.answers[index].answer_title} {predictions.is_predicit === "1" ? `${' ' + predictions.answers[index].total_answers + '%'}` : null}</button>
        )
    }

    const changeFollowStatus = (username) => {
        liveStreamPlayerFetch(true)
        pressFollowUnFollow(username,followStat).then(res => {
            if(res.status === 200){
                liveStreamPlayerFetch(false)
                setFollowStatus(followStat === 0 ? 1 : 0)
            }
        }).catch(error => {
            liveStreamPlayerFetch(false)
        })
    }

    return (
        <div className="chat">
            {showPredictionAnsw ?
                <>
                    <div className='player-prediction-answers'>
                        {fetchingPrediction ?
                            <div className="loader">
                                <img width="10%" src={loadingImages} alt="loadingImages" />
                            </div>
                            : null }
                        <p className='question-title'>{localize('Question')}</p>
                        <p className='question-text'>{predictions.prediction_question}</p>
                        <div className='question-answers'>
                            {predictions.answers.map((item,index) => {
                                return addButton(index)})}
                        </div>
                    </div>
                </>
                : null}
            {!!predictions && !!predictions.answers && predictions.answers.length > 0 && displayPrediction && !showPredictionAnsw  ?
                <div className='player-prediction-question'
                     onClick={() => showPredictionPopUp()}>
                    <p className='player-prediction-question-text'>{predictions.prediction_question.length > 40 ? predictions.prediction_question.substr(0, 40) + '...' : predictions.prediction_question}</p>
                </div>  : null}

            {props.preview ? <div className="chat-overlay"><p>{localize('subscribeToEnableChat')}</p></div> : null}

            <div className="chat-body">
                <div>
                    {messages.map((item, index) => {
                        return <ChatItem key={index} index={index} message={item} setReplyView={(index) => setReplyView(messages[index])} onAvatarClick={() => onAvatarClick(item)}/>
                    })}
                </div>
            </div>
            {!!replyView ? <div className='reply-text-view'>
                <p>{replyView.text}</p>
                <span onClick={() => setReplyView(null)}><FaTimes/></span>
            </div> : null}
            <div className='chat-input' /*style={{marginBottom: active && (!!useragent.os.name && useragent.os.name === "Android") ? `${window.innerHeight * 0.2}px` : '0px'}}*/>
                <input
                    type="text"
                    onPaste={() => setMsg(false)}
                    className='chat-send-input'
                    placeholder={inputLocked ? localize('waitSeconds')(10 - messageTimer) : localize('typeMessage')}
                    value={message}
                    maxLength={160}
                    onFocus={() => setActive(true)}
                    onKeyDown={_handleKeyDown}
                    onBlur={() => chatOutFocus()}
                    onChange={e => msg ? newMessage(e) : setMsg(true)}
                    disabled={inputLocked}
                    ref={messageInputRef}
                />
                <span onClick={_handleSendClick}><FiSend className='menu-search-icon chat-send-button'/></span>
            </div>

            <Modal
                className={`img-modal`}
                visible={imgModal.visible}
                footer={null}
                closable={false}
                maskClosable={true}
                onCancel={() => setImgModal({...imgModal, visible: false})}
                centered
            >
                {fetchLoading ? <div className="fetch-app-pop"> <img src={loadingImages} alt="loadingImages" /> </div> : null}
                <img src={imgModal.img} alt="" onError={() => setImgModal({...imgModal, img: avatarPlaceholder})} width={300}/>
                {!!imgModal.nickname ? <p className='nickname'>{imgModal.nickname}</p> : null}
                {!!imgModal.text && localStorage.getItem(localStorageKeys.isTest) == '1' ? <p className='desc'>{imgModal.text}</p> : null}
                {!!imgModal.followers && goLivePermission ? <p className='followers'>{imgModal.followers + ' ' + localize('followers')}</p> : null}
                {followStat != undefined ? <img onClick={() => changeFollowStatus(imgModal.text)} className='follow-icon' src={followStat === 0 ? require('../../assets/img/icons/follow.png') : require('../../assets/img/icons/unfollow.png')}/> : null}
                {!!imgModal.description ? <p className='desc'>{imgModal.description}</p> : null}
            </Modal>
        </div>
    )
}

export default Chat
