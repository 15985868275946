import React, {useEffect} from 'react'
import {playerTypes} from "../../helpers/enums";
import {useSelector} from "react-redux";
import {Link} from "@reach/router";
import {addDefaultSrc} from "../../helpers/functions";

const PlayerPlaylist = props => {
    const playlist = useSelector(state => state.playlist)
    const {type, series} = playlist
    const currentItem = playlist.item
    const list = playlist.items


    useEffect(() => {
        // scroll to the selected item on the list
        document.querySelector('.player .btn.bordered-selected').scrollIntoView();
    }, [])

    let renderItems = []
    if (!!type) {
        if (type === playerTypes.series) {
            series.forEach((item, index) => {
                const playlistItem = {...item, index}
                const renderItem = (
                    <button className={`btn btn-dark item ${item.video_id === currentItem.video_id ? 'bordered-selected' : ''}`} key={index} onClick={() => props.onClick(playlistItem)}>
                        {item.video_name}
                    </button>
                )
                renderItems.push(renderItem)
            })
        } else if (type === playerTypes.live) {
            list.forEach((item, index) => {
                const playlistItem = {...item, index}
                const renderItem = (
                    <button className={`btn btn-dark item ${item.service_id === currentItem.service_id ? 'bordered-selected' : ''}`} key={index} onClick={() => props.onClick(playlistItem)}>
                        <img src={item.logo} onError={(event) => addDefaultSrc(event)}/>
                        <p>{item.service_name}</p>
                    </button>
                )
                renderItems.push(renderItem)
            })
        }
    }

    return (
        <div className="player-playlist-container" onMouseOver={() => props.mouseCheck(true)} onMouseLeave={() => props.mouseCheck(false)}>
            {/*{fetching ? (*/}
            {/*    <div className="player-playlist-loading-overlay">*/}
            {/*        <span className="spinner-border text-light"/>*/}
            {/*    </div>*/}
            {/*) : null}*/}
            {renderItems}
        </div>
    )
}
export default PlayerPlaylist
