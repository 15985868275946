import store from "../store";
import {handleHttpError, jsonToFormData} from "../../helpers/functions";
import axios from "axios";
import {clearNotification, getUserNotifications, host, incrementNotificationClicks} from "../api/endPoints";
import {
    NOTIFICATION_CLEAR,
    NOTIFICATION_HAS_MORE,
    NOTIFICATION_LOAD_MORE,
    NOTIFICATION_SET_FETCHING,
    NOTIFICATION_SET_READ,
    NOTIFICATION_SET_RESPONSE,
    NOTIFICATION_UNSET_FETCHING
} from "./types";
import {localStorageKeys} from "../../helpers/enums";

const dispatch = store.dispatch

export const getNotifications = (index = 0)  => {
    const auth = store.getState().auth.authToken
    const page = index * 10
    const req = {auth, index: page}
    const params = jsonToFormData(req)
    dispatch({
        type: NOTIFICATION_SET_FETCHING,
    })
    axios.post(host + getUserNotifications, params)
        .then(res => {
            if (res.status === 200) {
                const items = res.data.notifications
                if (index === 0) {
                    dispatch({
                        type: NOTIFICATION_SET_RESPONSE,
                        payload: items
                    })
                    dispatch({
                        type: NOTIFICATION_HAS_MORE,
                        payload:true
                    })
                } else {
                    dispatch({
                        type: NOTIFICATION_LOAD_MORE,
                        payload: {items, index}
                    })
                }
            } else if (res.status === 204) {
                dispatch({
                    type: NOTIFICATION_HAS_MORE,
                    payload:false
                })
            }

        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const clearNotifications = (notificationId = -1)  => { // -1 means all notifications
    const auth = store.getState().auth.authToken
    const notifications = store.getState().notification.items
    const req = {auth, notification_id: notificationId}
    const params = jsonToFormData(req)
    dispatch({
        type:NOTIFICATION_SET_FETCHING
    })
    axios.post(host + clearNotification, params)
        .then(res => {
            if (res.status === 200 && notificationId === -1) {
                dispatch({
                    type: NOTIFICATION_CLEAR,
                })
            }else if(res.status === 200){
                notifications.map((item,index) => {
                    if(item.id === notificationId){
                        notifications.splice(index,1)
                    }
                })
                dispatch({
                    type: NOTIFICATION_SET_RESPONSE,
                    payload:notifications
                })
            }
        })
        .catch(error => {
            dispatch({
                type: NOTIFICATION_UNSET_FETCHING,
            })
            handleHttpError(error)
        })

}

export const clearNotificationBadge = ()  =>{
    localStorage.setItem(localStorageKeys.unreadNotifications,'0')
}

export const incrementNotification = (notificationId)  => {
    const auth = store.getState().auth.authToken

    const req = {auth, id: notificationId}
    const params = jsonToFormData(req)
    dispatch({
        type: NOTIFICATION_SET_FETCHING,
    })
    axios.post(host + incrementNotificationClicks, params)
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: NOTIFICATION_SET_READ,
                    payload: notificationId
                })
            }
        })
        .catch(error => {
            dispatch({
                type: NOTIFICATION_UNSET_FETCHING,
            })
            handleHttpError(error)
        })

}

