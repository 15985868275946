import React, {lazy, Suspense, useEffect, useState} from 'react'
import {Location, navigate, Router} from "@reach/router";
import {changeNetworkStats, setDrawerVisible} from "../redux/actions/settingsActions";
import {disableContextMenu, listenToNotification, shuffle} from "../helpers/functions";
import Player from "../components/Player";
import {useSelector} from "react-redux";
import FetchingData from "../components/Fillers/FetchingData";
import Layout from "../components/Layout";
import {isActive, isClipsRootPage} from "../helpers/constants";
import ErrorModel from "../components/ErrorModel";
import ReferralModel from "../components/ReferralModel/ReferralPopUp";
import {localStorageKeys} from "../helpers/enums";
import Test from "../components/Test";
import {loadingImages} from "../assets/img/exports";
import ReactGA from 'react-ga';
import {PLAYER_SET_VISIBLE} from "../redux/actions/types";
import IOSPopUp from "../components/iOSPopUp";
import LogoutModal from "../components/LogoutModal";
import UserProfile from "./Settings/UserProfile";
import store from "../redux/store";
import SessionEndModel from "../components/LiveStreamModels/sessionEndModel";
// import PrivacyPolicy from "./PrivacyPolicy";
// import Home from "./Home";
// import Test from "./Test";
// import Bundles from "./Bundles";
// import Channels from "./Channels";
// import Clips from "./Clips";
// import Movies from "./Movies";
// import Plays from "./Plays";
// import Series from "./Series";
// import Search from "./Search";
// import Login from "./Login";
// import MovieDetails from "./Movies/MovieDetails";
// import SeriesDetails from "./Series/SeriesDetails";
// import BundleDetails from "./Bundles/BundleDetails";
// import NotFound404 from "./404/404";
// import Watch from "./Watch";
// import History from "./History";
// import Favorites from "./Favorites";
// import Settings from "./Settings";
// import MyContent from "./MyContent";
// import Devices from "./Settings/Devices";
// import DeactivateDevice from "./Settings/DactivateDevice";
// import Feedback from "./Settings/Feedback";
// import Notifications from "./Settings/Notifications";
// import MoviesCategories from "./Movies/MoviesCategories";
// import ChannelDetails from "./Channels/ChannelDetails";
// import Support from "./Settings/Support";
// import ClipsHome from "./ClipsHome";

const Home = lazy(() => import('./Home'));
// const Test = lazy(() => import('./Test'));
const Bundles = lazy(() => import('./Bundles'));
const Channels = lazy(() => import('./Channels'));
const Clips = lazy(() => import('./Clips'));
const Movies = lazy(() => import('./Movies'));
const Plays = lazy(() => import('./Plays'));
const Series = lazy(() => import('./Series'));
const Search = lazy(() => import('./Search'));
const Login = lazy(() => import('./Login'));
const MovieDetails = lazy(() => import('./Movies/MovieDetails'));
const SeriesDetails = lazy(() => import('./Series/SeriesDetails'));
const BundleDetails = lazy(() => import('./Bundles/BundleDetails'));
const NotFound404 = lazy(() => import('./404/404'));
const Watch = lazy(() => import('./Watch'));
const History = lazy(() => import('./History'));
const Favorites = lazy(() => import('./Favorites'));
const Settings = lazy(() => import('./Settings'));
const MyContent = lazy(() => import('./MyContent'));
const Devices = lazy(() => import('./Settings/Devices'));
const DeactivateDevice = lazy(() => import('./Settings/DactivateDevice'));
const Feedback = lazy(() => import('./Settings/Feedback'));
const Notifications = lazy(() => import('./Settings/Notifications'));
const MoviesCategories = lazy(() => import('./Movies/MoviesCategories'));
const ChannelDetails = lazy(() => import('./Channels/ChannelDetails'));
const Support = lazy(() => import('./Settings/Support'));
const ClipsHome = lazy(() => import('./ClipsHome'));
const Followers = lazy(() => import('./Followers'));

export const Routes = {
    root: '/home/',
    home: '/home/', // used only when isClipsRootPage is enabled
    test: '/test/',
    bundles: '/bundles/',
    channels: '/channels/',
    channel: '/channel/:id/',
    channelId: id => `/channel/${id}/`,
    clips: '/clips/',
    clip: '/clip/:id/',
    clipId: id => `/clip/${id}/`,
    plays: '/plays/',
    play: '/play/:id/',
    playId: id => `/play/${id}/`,
    movies: '/movies/',
    moviesCategories: '/movies/cat/:id/',
    moviesCategoriesTo: (id) => `/movies/cat/${id}/`,
    shows: '/shows/',
    searchNoKey: '/search/',
    search: '/search/:keyword',
    searchTo: key => `/search/${key}/`,
    login: '/login/',
    movie: '/vod/:id/:serviceId',
    movieTo: (id, serviceId) => `/vod/${id}/${serviceId}/`,
    show: '/show/:id',
    showTo: (id) => `/show/${id}/`,
    showWithSeason: '/show/:id/:seasonId',
    showWithSeasonTo: (id, seasonId) => `/show/${id}/${seasonId}/`,
    bundle: '/bundle/:id',
    bundleTo: (id) => `/bundle/${id}/`,
    watch: '/watch/:id',
    watchTo: (id) => `/watch/${id}/`,
    history: '/history/',
    favorites: '/favorites/',
    settings: '/settings/',
    devices: '/settings/devices',
    deactivateDevice: '/settings/devices/deactivate/:id',
    deactivateDeviceTo: (id) => `/settings/devices/deactivate/${id}/`,
    myContent: '/my-content/',
    feedback: '/feedback/',
    userProfile: '/userProfile/',
    notifications: '/notifications/',
    support: '/support/',
    clipsHome: '/clipsHome/',
    followers:'/followers',
}

const AppRouter = props => {
    const playerVisible = useSelector(state => state.playlist.visible)
    const {authorized} = useSelector(state => state.auth)
    const {iOSPopUp} = useSelector(state => state.settings)
    const logoutModelVisible = useSelector(state => state.menu.logoutVisible)
    const {errorVisible,referralPopUp} = useSelector(state => state.services)
    const [reload, setReload] = useState('0')
    const updateFound = !!localStorage.getItem(localStorageKeys.update) ? localStorage.getItem(localStorageKeys.update) : null
    const isIOS = !!localStorage.getItem(localStorageKeys.iOS) ? localStorage.getItem(localStorageKeys.iOS) : null
    //const video = require('../assets/others/ss.mp4')
    const list = shuffle(store.getState().live.items.filter(item => item.status == 'locked')).slice(0,6)
    const {sessionEndModelView,sessionEndUserInfo} = useSelector(state => state.liveStream)

    useEffect(() => {
        // ReactGA.pageview(window.location.pathname + window.location.search);
        disableContextMenu()
        listenToNotification()
        window.addEventListener('online', function (event) {
            changeNetworkStats('online')
        });
        window.addEventListener('offline', function (event) {
            changeNetworkStats('offline')
        });
    }, [errorVisible,reload,fetch,isIOS])

    // const endPlayer = () => {
    //     sessionStorage.setItem(localStorageKeys.reload,'1')
    //     setReload('1')
    // }
    // if(sessionStorage.getItem(localStorageKeys.reload) == null){
    //     sessionStorage.setItem(localStorageKeys.reload,'0')
    // }

    return (
        // !!sessionStorage.getItem(localStorageKeys.reload) && sessionStorage.getItem(localStorageKeys.reload) === '0' && authorized ?
        //     <div className="demo-player">
        //         <div className="content">
        //             <img src={wideLogo} alt="logo" width={400}/>
        //             <h1>{localize('loginTextFirst')}</h1>
        //             <h1>{localize('loginTextSecond')}</h1>
        //         </div>
        //         <div className="player-demo">
        //             <ReactPlayer
        //                 url={video}
        //                 onEnded={() => endPlayer()}
        //                 height="100%"
        //                 width="100%"
        //                 className='react-player'
        //                 playing
        //                 muted={true}
        //             />
        //         </div>
        //     </div> :
        <Layout>
            {updateFound === 'true' && !!authorized ? <Test/> : null}
            {/*{iOSPopUp ? <IOSPopUp/> : null}*/}
            {playerVisible ? <Player/> : null}
            {errorVisible ? <ErrorModel/> : null}
            {referralPopUp ? <ReferralModel/> : null}
            {logoutModelVisible ? <LogoutModal/> : null}
            {sessionEndModelView ? <SessionEndModel userInfo={sessionEndUserInfo}/> : null}
            <Suspense fallback={<FetchingData/>}>
                <Router className={'router'}>
                    <Login path={Routes.login}/>
                    <NotFound404 default/>
                    <Home path={isClipsRootPage ? Routes.home : Routes.root}/>
                    <Test path={Routes.test}/>
                    <Bundles path={Routes.bundles}/>
                    <Channels path={Routes.channels}/>
                    <ChannelDetails path={Routes.channel}/>
                    <Clips path={Routes.clips}/>
                    <SeriesDetails path={Routes.clip}/>
                    <Movies path={Routes.movies}/>
                    <Plays path={Routes.plays}/>
                    <SeriesDetails path={Routes.play}/>
                    <Series path={Routes.shows}/>
                    <Search path={Routes.search} list={list}/>
                    <Search path={Routes.searchNoKey}/>
                    <MovieDetails path={Routes.movie}/>
                    <MoviesCategories path={Routes.moviesCategories}/>
                    <SeriesDetails path={Routes.show}/>
                    <SeriesDetails path={Routes.showWithSeason}/>
                    <BundleDetails path={Routes.bundle}/>
                    <Watch path={Routes.watch}/>
                    <History path={Routes.history}/>
                    <Favorites path={Routes.favorites} list={list}/>
                    <Settings path={Routes.settings}/>
                    <Devices path={Routes.devices}/>
                    <MyContent path={Routes.myContent}/>
                    <DeactivateDevice path={Routes.deactivateDevice}/>
                    <Feedback path={Routes.feedback}/>
                    <UserProfile path={Routes.userProfile}/>
                    <Notifications path={Routes.notifications}/>
                    <Support path={Routes.support}/>
                    <Followers path={Routes.followers}/>
                    <ClipsHome path={isClipsRootPage ? Routes.root : Routes.clipsHome}/>
                </Router>
            </Suspense>
            <Location>
                {({location}) => <OnRouteChange location={location} authorized={authorized}/>}
            </Location>
        </Layout>
)
}

class OnRouteChange extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.authorized){
                setDrawerVisible(false)
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }else{
                navigate(Routes.login)
            }
        }
    }

    render() {
        return null
    }
}

export default AppRouter
