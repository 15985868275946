import React, {useEffect} from 'react'
import {navigate} from "@reach/router";
import Splash from "../Splash";
import {countryOperator, localStorageKeys, operations} from "../../helpers/enums";
import {bootstrapSplash, setAuthorized} from "../../redux/actions/authActions";
import {Routes} from "../../routes/AppRouter";
import {setBootstrap, unsetInit} from "../../redux/actions/settingsActions";
import {deviceLanguage} from "../../helpers/constants";
import {useSelector} from "react-redux";
import axios from "axios";


const WithAuth = Component => {
    const WithAuthHOC = props => {
        // const [authorized, setAuthorized] = useState(undefined)
        const settings = useSelector(state => state.settings)
        const {authorized} = useSelector(state => state.auth)
        const {init} = settings
        useEffect(() => {
            if (init) {
                const authToken = localStorage.getItem(localStorageKeys.authToken)
                const loggedIn = localStorage.getItem(localStorageKeys.loggedIn)
                let lang = localStorage.getItem(localStorageKeys.lang)
                let acceptNotifications = localStorage.getItem(localStorageKeys.acceptNotifications)
                if (lang === null) {
                    localStorage.setItem(localStorageKeys.lang, deviceLanguage)
                    lang = deviceLanguage
                }
                if (acceptNotifications === null) {
                    localStorage.setItem(localStorageKeys.acceptNotifications, '1')
                    acceptNotifications = '1'
                }
                setBootstrap(lang, acceptNotifications)
                let currentRoute = props.location.pathname
                // let currentRoute = '/home'
                const isLoginPage = props.path === Routes.login || props.path === Routes.login
                const isAuthorized = authToken && loggedIn
                if (isAuthorized) setAuthorized()
                if (isAuthorized) {
                    if (isLoginPage) currentRoute = Routes.root
                    if (props.location.state === null || !props.location.state.fromLogin) bootstrapSplash(authToken, currentRoute)
                } else {
                    navigate(Routes.login, {state: {error: isLoginPage ? undefined : 'auth'}})
                }
                unsetInit()
            }
        }, [init, props.location.pathname, props.location.state, props.path])
        if (!authorized && init) return <Splash/>
        else return <Component {...props} settings={settings}/>
    }
    return WithAuthHOC
}
export default WithAuth
