import {handleHttpError, jsonToFormData, shuffle} from "../../helpers/functions";
import {
    ADD_SERVICES,
    CHANGE_SERIES_SERVICE,
    CLIPS_MORE_CLIPS,
    CLIPS_SET_FETCHING,
    HAS_MORE_FALSE_CLIPS,
    HAS_MORE_FALSE_MOVIE_VIDEOS,
    HAS_MORE_FALSE_MOVIES,
    HAS_MORE_FALSE_PLAYS,
    LIVE_HAS_MORE_FALSE,
    LIVE_MORE_LIVE,
    LIVE_SET_FETCHING, LIVE_SET_UNLOCKED, LIVE_SET_UNLOCKED_LIST_ITEMS,
    MORE_MOVIE_VIDEOS,
    MORE_MOVIES,
    MORE_PLAYS,
    MOVIES_SET_FETCHING,
    PLAYS_SET_FETCHING,
    SELECT_CLIPS,
    SELECT_LIVE,
    SELECT_MOVIE,
    SELECT_MOVIE_VIDEO,
    SELECT_PLAY,
    SELECT_SERIES,
    SERIES_HAS_MORE_FALSE,
    SERIES_HAS_MORE_FALSE_SERIES_EPISODES,
    SERIES_HAS_MORE_FALSE_SERIES_SERVICES,
    SERIES_MORE_SERIES,
    SERIES_MORE_SERIES_EPISODES,
    SERIES_MORE_SERIES_SERVICES,
    SERIES_SET_FETCHING, SET_FETCHING,
} from "./types";
import axios from "axios";
import {
    GetBehaviorStatistics,
    getContentInfo as getContentInfoAPI,
    getServicesOfParent as getServicesOfParentAPI,
    host,
    loadMoreServices,
    loadMoreVideosOfServices
} from "../api/endPoints";
import store from "../store";
import {contentType, isMovie, localStorageKeys} from "../../helpers/enums";
import {showPurchaseModal} from "./settingsActions";
import {getLink, previewLive} from "./playlistActions";

const dispatch = store.dispatch

const loadMoreService = (index, isMovieType) => {

    const pageSizeInterval = localStorage.getItem(localStorageKeys.itemsinterval)
    const auth = store.getState().auth.authToken
    const page = index * pageSizeInterval


    let req = {
        auth,
        index: page,
    }
    if (isMovieType) req['is_movie'] = isMovieType
    const params = jsonToFormData(req)

    axios.post(host + loadMoreServices, params)
        .then(res => {
            if (isMovieType === isMovie.clips) {
                dispatch({
                    type: CLIPS_SET_FETCHING,
                })
            } else if (isMovieType === isMovie.movies) {
                dispatch({
                    type: MOVIES_SET_FETCHING,
                })
            } else if (isMovieType === isMovie.series) {
                dispatch({
                    type: SERIES_SET_FETCHING,
                })
            } else if (isMovieType === isMovie.plays) {
                dispatch({
                    type: PLAYS_SET_FETCHING,
                })
            } else if (isMovieType === isMovie.live) {
                dispatch({
                    type:LIVE_SET_FETCHING,
                })
            }
            if (res.status === 200) {
                const services = res.data.services
                if (isMovieType === isMovie.clips) {
                    dispatch({
                        type: CLIPS_MORE_CLIPS,
                        payload: {items:services, index}
                    })
                } else if (isMovieType === isMovie.movies) {
                    dispatch({
                        type: MORE_MOVIES,
                        payload: {items:services, index}
                    })
                } else if (isMovieType === isMovie.series) {
                    dispatch({
                        type: SERIES_MORE_SERIES,
                        payload: {items:services, index}
                    })
                } else if (isMovieType === isMovie.plays) {
                    dispatch({
                        type: MORE_PLAYS,
                        payload: {items:services, index}
                    })
                } else if (isMovieType === isMovie.live) {
                    dispatch({
                        type:LIVE_MORE_LIVE,
                        payload:{items:services,index}
                    })
                }
            } else if (res.status === 204) {
                if (isMovieType === isMovie.clips) {
                    dispatch({
                        type: HAS_MORE_FALSE_CLIPS,
                    })
                } else if (isMovieType === isMovie.movies) {
                    dispatch({
                        type: HAS_MORE_FALSE_MOVIES,
                    })
                } else if (isMovieType === isMovie.series) {
                    dispatch({
                        type: SERIES_HAS_MORE_FALSE,
                    })
                } else if (isMovieType === isMovie.plays) {
                    dispatch({
                        type: HAS_MORE_FALSE_PLAYS,
                    })
                } else if (isMovieType === isMovie.live) {
                    dispatch({
                        type:LIVE_HAS_MORE_FALSE
                    })
                }

            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const loadMoreClips = page => {
    return loadMoreService(page, isMovie.clips)
}

export const loadMoreMovies = page => {
    return loadMoreService(page, isMovie.movies)
}

export const loadMoreSeries = page => {
    return loadMoreService(page, isMovie.series)
}

export const loadMorePlays = page => {
    return loadMoreService(page, isMovie.plays)
}

export const loadMoreLive = page => {
    return loadMoreService(page, isMovie.live)
}

export const loadMoreMovieVideos = (index, serviceId) => {
    const pageSizeInterval = localStorage.getItem(localStorageKeys.itemsinterval)
    const auth = store.getState().auth.authToken
    const page = index * pageSizeInterval
    const req = {
        auth,
        index: page,
        service_id: serviceId,
    }
    const params = jsonToFormData(req)
    axios.post(host + loadMoreVideosOfServices, params)
        .then(res => {
            if (res.status === 200) {
                const items = res.data.msg
                dispatch({
                    type: MORE_MOVIE_VIDEOS,
                    payload: {items, serviceId}
                })

            } else if (res.status === 204) {
                dispatch({
                    type: HAS_MORE_FALSE_MOVIE_VIDEOS,
                    payload: {serviceId}
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const selectMovie = serviceId => {
    const movies = store.getState().movies.items
    const auth = store.getState().auth.authToken
    const service = !!serviceId && !!movies && movies.length > 0 ? movies.filter(item => item.service_id === serviceId)[0] : undefined
    if (service === undefined) {
        const req = {
            auth,
            index: 0,
            service_id: serviceId,
        }
        const params = jsonToFormData(req)
        axios.post(host + loadMoreVideosOfServices, params)
            .then(res => {
                if (res.status === 200) {
                    const videos = res.data.msg
                    const info = res.data.info
                    const service = {...info, videos}
                    dispatch({
                        type: SELECT_MOVIE,
                        payload: service
                    })
                }
            })
            .catch(error => {
                handleHttpError(error)
            })

    } else {
        dispatch({
            type: SELECT_MOVIE,
            payload: service
        })

    }
}

export const getContentInfo = (contentType, contentId, serviceId = undefined) => {
    const auth = store.getState().auth.authToken
    let req = {
        auth,
        content_type: contentType,
        content_id: contentId,
    }
    if (!!serviceId) req['service_id'] = serviceId
    const params = jsonToFormData(req)
    return axios.post(host + getContentInfoAPI, params)
}

const getServicesOfParent = (serviceId, index = 0) => {
    const page = index * 10
    const auth = store.getState().auth.authToken
    const req = {
        auth,
        service_id: serviceId,
        index: page,
    }
    const params = jsonToFormData(req)
    return axios.post(host + getServicesOfParentAPI, params)
}

const getVideos = (serviceId, index , contentId, list = undefined) => {
    const pageSizeInterval = localStorage.getItem(localStorageKeys.itemsinterval)
    const auth = store.getState().auth.authToken
    const page = index * pageSizeInterval
    const req = {
        auth,
        index: page,
        service_id: serviceId,
    }
    const params = jsonToFormData(req)
    let video = ''
    axios.post(host + loadMoreVideosOfServices, params).then(res => {
        if (res.status === 200) {
            const videos = res.data.msg
            const service = res.data.info
            videos.map((item) => {
                if (item.video_name === contentId) {
                    video = item
                    store.dispatch({
                        type: SELECT_MOVIE_VIDEO,
                        payload: {video: {video:item,list:list}, service},
                    })
                }
            })
        }
        if(video === ''){
            getVideos(serviceId,index + 1,contentId)
        }
    }).catch(error => {
            handleHttpError(error)
        })
}
const getVideosOfServices = (serviceId, index = 0) => {
    const pageSizeInterval = localStorage.getItem(localStorageKeys.itemsinterval)
    const auth = store.getState().auth.authToken
    const page = index * pageSizeInterval
    const req = {
        auth,
        index: page,
        service_id: serviceId,
    }
    const params = jsonToFormData(req)
    return axios.post(host + loadMoreVideosOfServices, params)

}

export const getVideoInfo = (contentId, serviceId = undefined) => {
    const movies = store.getState().movies.items
    const service = !!contentId && !!movies && movies.length > 0 ? movies.filter(item => item.service_id === serviceId)[0] : JSON.parse(localStorage.getItem(localStorageKeys.serviceItem))
    const list = !!service ? service.videos.filter((vitem,i) => vitem.video_name !== contentId) : []
    //const list = !!service ? service.videos.filter((item, index) => Number.parseInt(item.is_trailer) === 0 && item.video_name !== contentId && item.status === 'locked') : []
    const video = !!service ? service.videos.filter(item => item.video_id === serviceId)[0] : undefined
    shuffle(list)

    getContentInfo(contentType.video, contentId, serviceId)
        .then(res => {
            if (res.status === 200) {
                let newVideo = ''
                if(list.length > 0){
                    const item = res.data.msg.video
                    newVideo = {...item, ...video}
                    const service = res.data.msg.service
                    dispatch({
                        type: SELECT_MOVIE_VIDEO,
                        payload: {video: {video: newVideo, list}, service},
                    })
                }
                else {
                    getVideos(serviceId,0,contentId, list)
                    // for(let index = 0;index < 15; index++){
                    //     getVideosOfServices(serviceId,index).then(res => {
                    //             if (res.status === 200) {
                    //                 const videos = res.data.msg
                    //                 const service = res.data.info
                    //                 videos.map((item) => {
                    //                     if (item.video_name === contentId) {
                    //                         newVideo = item
                    //                         index = 15
                    //                     }else{
                    //                         index = 15
                    //                     }
                    //                 })
                    //                 dispatch({
                    //                     type: SELECT_MOVIE_VIDEO,
                    //                     payload: {video: {video:newVideo,list:list}, service},
                    //                 })
                    //             }
                    //         })
                    //         .catch(error => {
                    //             handleHttpError(error)
                    //         })
                    // }
                }
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}
export const getSeriesInfo = (serviceId, seasonId = undefined) => {
    const series = store.getState().series.items
    // const seriesServices = store.getState().services.series.item.services.items
    const service = !!serviceId && !!series && series.length > 0 ? series.filter(item => item.service_id === serviceId)[0] : undefined

    getContentInfo(contentType.service, serviceId)
        .then(res => {
            if (res.status === 200) {
                const item = res.data.msg
                const newService = {...service, ...item}
                if (seasonId === undefined) {
                    dispatch({
                        type: SELECT_SERIES,
                        payload: newService
                    })
                }
                if(item.is_videos_parent === '1'){
                    getVideosOfServices(serviceId)
                        .then(res => {
                            if (res.status === 200) {
                                const videos = res.data.msg

                                dispatch({
                                    type: SERIES_MORE_SERIES_EPISODES,
                                    payload: {items: videos, index: 1}
                                })
                            } else if (res.status === 204) {
                                dispatch({
                                    type: SERIES_HAS_MORE_FALSE_SERIES_EPISODES
                                })
                            }
                        })
                        .catch(error => {
                            handleHttpError(error)
                        })
                }else{
                    getServicesOfParent(serviceId)
                        .then(res => {
                            if (res.status === 200) {
                                const services = res.data.services
                                dispatch({
                                    type: SERIES_MORE_SERIES_SERVICES,
                                    payload: {items: services, index: 1}
                                })
                                if (store.getState().settings.itemServices.length === 0){
                                    dispatch({
                                        type:ADD_SERVICES,
                                        payload:{items:services, index:1}
                                    })
                                }
                                if (!!seasonId) {
                                    changeSeriesService(seasonId)
                                }
                                // const firstServiceId = services[0].service_id
                                // getVideosOfServices(firstServiceId)
                                //     .then(res => {
                                //         if (res.status === 200) {
                                //             const videos = res.data.msg
                                //             dispatch({
                                //                 type: SERVICES_MORE_SERIES_EPISODES,
                                //                 payload: {items: videos, index: 1}
                                //             })
                                //         } else if (res.status === 204) {
                                //             dispatch({
                                //                 type: SERVICES_HAS_MORE_FALSE_SERIES_EPISODES
                                //             })
                                //         }
                                //     })
                                //     .catch(error => {
                                //         handleHttpError(error)
                                //     })

                            } else if (res.status === 204) {
                                dispatch({
                                    type: SERIES_HAS_MORE_FALSE_SERIES_SERVICES
                                })
                                // getVideosOfServices(serviceId)
                                //     .then(res => {
                                //         if (res.status === 200) {
                                //             const videos = res.data.msg
                                //
                                //             dispatch({
                                //                 type: SERIES_MORE_SERIES_EPISODES,
                                //                 payload: {items: videos, index: 1}
                                //             })
                                //         } else if (res.status === 204) {
                                //             dispatch({
                                //                 type: SERIES_HAS_MORE_FALSE_SERIES_EPISODES
                                //             })
                                //         }
                                //     })
                                //     .catch(error => {
                                //         handleHttpError(error)
                                //     })
                            }
                        })
                        .catch(error => {
                            handleHttpError(error)
                        })
                }
            }
        })
        .catch(error => {
            handleHttpError(error)
        })

}

export const loadMoreSeriesServices = (serviceId, index) => {
    getServicesOfParent(serviceId, index)
        .then(res => {
            if (res.status === 200) {
                const services = res.data.services
                dispatch({
                    type: SERIES_MORE_SERIES_SERVICES,
                    payload: {items: services, index: ++index}
                })
                dispatch({
                    type:ADD_SERVICES,
                    payload:{items:services, index:++index}
                })
            } else if (res.status === 204) {
                dispatch({
                    type: SERIES_HAS_MORE_FALSE_SERIES_SERVICES
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const loadMoreSeriesEpisodes = (serviceId, index) => {
    getVideosOfServices(serviceId, index)
        .then(res => {
            if (res.status === 200) {
                const videos = res.data.msg
                dispatch({
                    type: SERIES_MORE_SERIES_EPISODES,
                    payload: {items: videos, index: ++index}
                })
            } else if (res.status === 204) {
                dispatch({
                    type: SERIES_HAS_MORE_FALSE_SERIES_EPISODES
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const changeSeriesService = serviceId => {
    const services = store.getState().series.item.services.items
    let service = store.getState().settings.itemServices.filter(item => item.service_id === serviceId)[0]
    if(service === undefined){
        service = services.filter(item => item.service_id === serviceId)[0]
    }
    getVideosOfServices(serviceId)
        .then(res => {
            if (res.status === 200) {
                const videos = res.data.msg

                dispatch({
                    type: CHANGE_SERIES_SERVICE,
                    payload: service
                })
                dispatch({
                    type: SERIES_MORE_SERIES_EPISODES,
                    payload: {items: videos, index: 1}
                })
            } else if (res.status === 204) {
                dispatch({
                    type: SERIES_HAS_MORE_FALSE_SERIES_EPISODES
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const selectChannel = serviceId => {
    const channels = store.getState().live.items
    const service = !!serviceId && !!channels && channels.length > 0 ? channels.filter(item => item.service_id === serviceId)[0] : undefined
    const list = channels.filter((item, index) => item.service_id !== serviceId && item.status === 'locked')
    shuffle(list)

    getContentInfo(contentType.service, serviceId)
        .then(res => {
            if (res.status === 200) {
                const item = res.data.msg
                const newService = {...service, ...item, list}
                dispatch({
                    type:SELECT_LIVE,
                    payload:newService
                })
                if(!!localStorage.getItem(localStorageKeys.continueWatchingPopup)){//from Continue watching popUp
                    showPurchaseModal()
                    localStorage.removeItem(localStorageKeys.continueWatchingPopup)
                }
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const selectClip = serviceId => {
    const clips = store.getState().clips.items
    const service = !!serviceId && !!clips && clips.length > 0 ? clips.filter(item => item.service_id === serviceId)[0] : undefined
    getContentInfo(contentType.service, serviceId)
        .then(res => {
            if (res.status === 200) {
                const item = res.data.msg
                const newService = {...service, ...item}
                dispatch({
                    type: SELECT_CLIPS,
                    payload: newService
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const selectPlay = serviceId => {
    const plays = store.getState().plays.items
    const service = !!serviceId && !!plays && plays.length > 0 ? plays.filter(item => item.service_id === serviceId)[0] : undefined
    getContentInfo(contentType.service, serviceId)
        .then(res => {
            if (res.status === 200) {
                const item = res.data.msg
                const newService = {...service, ...item}
                dispatch({
                    type:SELECT_PLAY,
                    payload: newService
                })
            }
        })
        .catch(error => {
            handleHttpError(error)
        })
}

export const getBehaviorStatistics = (action, desc = undefined) => {
    const auth = store.getState().auth.authToken
    let req = {
        auth,
        action,
    }
    if (!!desc) req['description'] = desc
    const params = jsonToFormData(req)
    axios.post(host + GetBehaviorStatistics, params).then(res => {
    })
}

export const isMobileMoneyGetContentInfo = (id,channelList,req) => {
    dispatch({
        type:SET_FETCHING,
        payload:true
    })
    getContentInfo(contentType.service, id).then(res => {
        if (res.status === 200) {
            dispatch({
                type:SET_FETCHING,
                payload:false
            })
            const item = res.data.msg

            if(item.status === 'unlocked'){
                if (!!channelList && channelList.length > 0) {// unlock channel in Home page list
                    channelList.forEach((item, index) => {
                        if (item.service_id === id) {
                            dispatch({
                                type: LIVE_SET_UNLOCKED_LIST_ITEMS,
                                payload: index
                            })
                        }
                    })
                }
                dispatch({// unlock channel in its info page
                    type:LIVE_SET_UNLOCKED
                })
                getLink(req, item)
            }else{
                previewLive(req)
            }
        }
    }).catch(error => {
        dispatch({
            type:SET_FETCHING,
            payload:false
        })
        handleHttpError(error)
    })
}
