import React from 'react'

const LoadMoreSpinner = props => {
    return (
        <div className='text-center'>
            <span className="spinner-border text-light mt-3"/>
        </div>
    )
}

export default LoadMoreSpinner