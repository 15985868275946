import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from './reducers'

const initialState = {}

const middleware = []

const store = createStore(rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production' ?  window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)
export default store