import React, {useEffect} from 'react'
import {Modal} from "antd";
import {refreshIcon} from "../../assets/img/exports";
import {localStorageKeys} from "../../helpers/enums";
import {localize} from "../../helpers/functions";
import store from "../../redux/store";
import {useSelector} from "react-redux";


const Test = props => {
    const {version} = useSelector(state => state.settings)

    useEffect(() => {

    }, [])

    const reload = () => {
        localStorage.setItem(localStorageKeys.update,'false')
        // if ('caches' in window) {
        //     caches.keys().then((cacheNames) => {
        //         cacheNames.forEach((cacheName) => {
        //             console.log(cacheName)
        //             caches.delete(cacheName);
        //         });
        //     });
        // }
        window.location.reload()
    }

    // console.log(version)
    return (
        <Modal
            className={`update-modal`}
            // visible={!!!version || (!!version && version !== '3')}
            visible={true}
            footer={null}
            closable={false}
            maskClosable={true}
        >
            <div className='refresh-image'><img className='img' src={refreshIcon} alt=""/></div>
            <p className='refresh-title'>{localize('refreshTitle')}</p>
            <p className='refresh-msg'>{localize('refreshDesc')}</p>
            <button className='update-button' onClick={() => reload()}>{localize('refresh')}</button>
        </Modal>
    )
    // return (
    //     <div className='browser-error'>
    //         <p>Hello Adel</p>
    //         <p>Hello Tarif</p>
    //         <p>Hello Adel</p>
    //
    //     </div>
    // )

}

export default Test

// <ReactPlayer
// url={video}
// onEnded={() => console.log("end")}
// //url='https://vimeo.com/243556536'
// width="100%"
// height="100%"
// className='react-player'
// playing
// muted={muted}
// />


// check if android device
// var ua = navigator.userAgent.toLowerCase();
// var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
// if(isAndroid) {
//     // Do something!
//     // Redirect to Android-site?
//     window.location = 'http://android.davidwalsh.name';
// }
