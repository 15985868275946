import React, {useEffect, useState} from 'react'
import {Modal} from "antd";
import {installAppChrome, installAppChromeAndroidDevice, placeholderImage, refreshIcon, shareIcon, shareIconChrome, wideLogo} from "../../assets/img/exports";
import {localStorageKeys} from "../../helpers/enums";
import {addRTL, downloadPwa, localize, unsetError} from "../../helpers/functions";
import {FiX} from "react-icons/fi";
import ReactDOM from "react-dom";
import App from "../../App";
import store from "../../redux/store";
import {deviceLanguage, isSafari, isActive, isChrome, isAndroid, isIphoneDevice, isAndroidDevice, androidAppLink, facebookLink, instagramLink, iphoneAppLink, isMac} from "../../helpers/constants";
import {useSelector} from "react-redux";


const IOSPopUp = props => {
    const {iOSPopUp} = useSelector(state => state.settings)
    const rtl = !!localStorage.getItem(localStorageKeys.lang) ? localStorage.getItem(localStorageKeys.lang) : deviceLanguage
    const [check,setCheck] = useState(false)

    useEffect(() => {

        }, [])

    const reload = () => {
        localStorage.removeItem(localStorageKeys.iOS)
        ReactDOM.render(<App/>, document.getElementById('root'));
    }

    const notShowAgain = () => {
        localStorage.setItem(localStorageKeys.iOS,'-1')
        ReactDOM.render(<App/>, document.getElementById('root'));
    }


    return (
        <Modal
            className={`iOS-modal`}
            visible={iOSPopUp && (isMac ? !isSafari : true)} //on mac Safari - not supported PWA installation
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => downloadPwa(check)}
            centered
        >
            {/*<div className={'removePopUp'} onClick={() => reload()}>*/}
            {/*    <FiX/>*/}
            {/*</div>*/}
            <div className='refresh-image'><img src={placeholderImage} alt=""/></div>
            <p className={`refresh-title-main ${addRTL(rtl === 'ar')}`}> {localize('install') + localize('appTitle')}</p>
            {/*<p className='refresh-msg'>Install this App on your home screen for quick and easy access</p>*/}
            <div className={`p-img ${addRTL(rtl === 'ar')}`}>
                <p className='refresh-title'>{localize('justTap')}</p>
                <img src={isChrome && isAndroidDevice ? installAppChromeAndroidDevice : isChrome && isActive ? installAppChrome : isSafari ? shareIcon : shareIconChrome}/>
                <p className='refresh-title'>{isSafari ? localize('thenAddToHomeScreen') : localize('addToHomeScreen')}</p>
            </div>

            {isAndroidDevice || isIphoneDevice ?
                <div className='download-link-view'>
                    <p>{localize('Or')}</p>
                    <a href={isAndroidDevice ? androidAppLink : iphoneAppLink} target="_blank">{localize('downloadAndroid')}</a>
                </div> : null}

            <div className='form-label-group-checkbox'>
                <label className="referral-box">
                    <input
                        type="checkbox"
                        className='checkbox-referral'
                        checked={check}
                        onChange={() => setCheck(!check)}
                    />
                    {localize('dontShowAgain')}
                </label>
            </div>
            <div className='iOS-modal-buttons'>
                <button onClick={() => /*notShowAgain()*/downloadPwa(check)}>{localize('ok')}</button>
            </div>
        </Modal>
    )
}

export default IOSPopUp
