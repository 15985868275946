import React, {useEffect, useState} from 'react'

const ChatItem = props => {
    const avatarPlaceholder = require('../../assets/img/placeholder/avatar.png')
    const [avatar, setAvatar] = useState(avatarPlaceholder)
    const message = !!props.message ? props.message : {text: '', mine: false, avatar}
    let startX = 0;
    let endX = 0;
    let selectedItem = 0

    useEffect(() => {
        // const swipeDivs = document.querySelectorAll('.message-text');
        // document.addEventListener('mouseup', handleMouseUp);
        // document.addEventListener('touchend', handleTouchEnd);
        //
        // swipeDivs.forEach((element, index) => {
        //     element.addEventListener('mousedown', (e) => handleMouseDown(e,index));
        //     element.addEventListener('touchstart', (e) => handleTouchStart(e, index));
        // });
        // return () => {
        //     document.removeEventListener('mouseup', handleMouseUp);
        //     document.removeEventListener('touchend', handleTouchEnd);
        //     swipeDivs.forEach((element, index) => {
        //         element.removeEventListener('mousedown', (e) => handleMouseDown(e,index));
        //         element.removeEventListener('touchstart', (e) => handleTouchStart(e, index));
        //     });
        // };

        setAvatar(message.avatar)
    }, [message.avatar])

    const handleMouseDown = (e,index) => {
        startX = e.clientX;
        selectedItem = index
    }

    const handleMouseUp = (e) => {
        if (startX > 0) {
            endX = e.clientX;
            handleSwipe();
        }
        startX = 0;
    }

    // Touch event handlers
    const handleTouchStart = (e, index) => {
        startX = e.touches[0].clientX;
        selectedItem = index
    }

    const handleTouchEnd = (e) => {
        if (startX > 0) {
            endX = e.changedTouches[0].clientX
            handleSwipe();
        }
        startX = 0;
    }

    const handleSwipe = () => {
        const swipeDistance = endX - startX;
        if (swipeDistance > 50 /* Swipe right*/ || swipeDistance < -50  /* Swipe left*/) {
            props.setReplyView(selectedItem);
        }
    }

    return (
        <div className={`chat-item ${message.mine ? 'mine' : ''}`}>
            <img style={{border:`3px solid #${message.color}`}} src={avatar} alt="" onError={() => setAvatar(avatarPlaceholder)} onClick={props.onAvatarClick}/>
            <div>
                {!!message.replyTxt ? <p className='reply-text'>{message.replyTxt.replace(/&apos;/g, "'").replace(/&quot;/g, '"')}</p> : null}
                <p className='message-text'>
                    {message.text.replace(/&apos;/g, "'").replace(/&quot;/g, '"')}
                </p>
            </div>
        </div>
    )
}

export default ChatItem
