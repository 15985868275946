import React from 'react'
import {Modal} from "antd";
import {sessionEndViewModel, setFollowUnfollowStatus} from "../../redux/actions/liveStreamActions";
import {avatarLink} from "../../helpers/constants";
import {useSelector} from "react-redux";
import {localize} from "../../helpers/functions";

const SessionEndModel = props => {
    const userInfo = !!props.userInfo ? props.userInfo : ''
    const {following} = useSelector(state => state.liveStream)

    return (
        <Modal
            className={`session-end-model`}
            visible={true}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={() => sessionEndViewModel(false)}
            centered
        >

            <img className='profile-icon' src={avatarLink(userInfo.username)}></img>
            <p>{userInfo.nickname}</p>
            <img className='follow-icon' onClick={() => setFollowUnfollowStatus(userInfo.username,following)} src={following === 0 ? require('../../assets/img/icons/follow.png') : require('../../assets/img/icons/unfollow.png')}/>
            <p>{localize('sessionEndTitle')}</p>

        </Modal>
    )
}


export default SessionEndModel
