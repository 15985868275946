import React, {useEffect} from 'react'
import {localize} from "../../helpers/functions";
import {apliTVWebsite, appVersion} from "../../helpers/constants";
import {countryOperator, localStorageKeys} from "../../helpers/enums";

const Footer = props => {
    // const linksClass = props.rtl ? 'justify-content-md-end' : 'justify-content-md-start'
    // const copyrightsClass = props.rtl ? 'justify-content-md-start' : 'justify-content-md-end'
    const operationName = !!localStorage.getItem(localStorageKeys.countryName) ? localStorage.getItem(localStorageKeys.countryName) : ''
    let logo = require('../../assets/img/ExpressoLogo.png')
    useEffect( () => {
    })
    return (
        <footer className={`footer`}>
            {/*{operationName === countryOperator.senegal.name && window.location.pathname === '/login/' ? <div>*/}
            {/*    <img src={logo} alt="" width={140} height={70}></img>*/}
            {/*</div> : null}*/}
            <div className={`d-flex`}>
                <p className='mb-0 copyrights' style={window.location.pathname !== '/login/' ? {color:"white"} :  null}>{localize('copyright1')} </p>
                {!!apliTVWebsite && apliTVWebsite ? <a className='mb-0 copyrights' style={window.location.pathname !== '/login/' ? {color:"white"} : null} href="http://apli.tv/" target="_blank" rel="noopener noreferrer">{' '}{localize('copyright2')}</a> : <p className='mb-0 copyrights' style={window.location.pathname !== '/login/' ? {color:"white"} : null}>{localize('copyright2')}</p>}
                <p className='mb-0 copyrights' style={window.location.pathname !== '/login/' ? {color:"white"} : null}>{localize('copyright3')}</p>
                <div><p className='mb-0 copyrights' style={window.location.pathname !== '/login/' ? {color:"white"} : null}>{localize('Version')} {appVersion}</p></div>
            </div>
        </footer>
    )
}

export default Footer
